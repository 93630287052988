import { Container, Button, Row, Col, Alert, Form, InputGroup, Popover, OverlayTrigger } from "react-bootstrap";
import Card from '../../card/Card.js';
import axios from 'axios';
import { useState, useEffect } from "react";
import { getAccessToken } from "../../../utils/auth.js";

const url = 'https://cms.api.fybrlabs.frontier.com/prod/alarms';

export default function AlertsList ({ }) {
	const [alarms, setItems] = useState([]);
	const [alarmChanges, setChanges] = useState({});	
	const [show, setShow] = useState(false);
	const [addMode, setAdd] = useState(false);
	const [delMode, setDelMode] = useState(false);
	const [newAlarmType, setAType] = useState('');
	const [newAlarmDesc, setDType] = useState('');	
	const [alertMsg, setAlertMsg] = useState({});	
	let interval = null;

	function renderItems() {
		let renderedItems = [];

		renderedItems = alarms.map(({alarmType, shortDescription, className, delStatus}) => {	
			
			let delCallback = delItem;
			let deleteButText = 'Delete';
			let delInProgress = delMode;

			if (delStatus) {
				delCallback = del;
				deleteButText = 'Confirm';		
				delInProgress = false;		
			}			
			
			return <tr className={className} key={alarmType} >		
				<td className='alarm-type-col'>{alarmType}</td>				
				<td >
					<input					
					name='shortDescription' 
					value={shortDescription}
					placeholder={shortDescription} 
					onChange={(e) => editDescription(e, alarmType)}
					type='text'/> 
				</td>					
				<td className='del-buttons' display='none' visibility='hidden'>

					<OverlayTrigger trigger="click" rootClose onExit={() => getAlarms()} placement="left" overlay={popover}>
						<Button variant='danger' onClick={()=> delCallback(alarmType)} disabled={delInProgress}>{deleteButText}</Button>						
					</OverlayTrigger>
					
				</td>
			</tr>;
		});
		return renderedItems;
	  };

	const getAlarms = async ()=> {
		const token = await getAccessToken();
		let r = await axios.get(url, {headers: {'auth-token': token }});
		let alarmList = [];
		
		if (r.data) {
			alarmList = r.data;
		}
	
		setDelMode(false);
		setItems(alarmList);
	}

	const editDescription = async(e, alarmType)=> {		
		const { name, value } = e.target
		
		const editData = alarms.map((item) => {
			if (item.alarmType === alarmType && name) {
				
				if (!alarmChanges[alarmType]) {
					alarmChanges[alarmType] = item[name];				
				}

				item[name] = value;

				if (alarmChanges[alarmType] !== value) {
					item.className = 'changed'
				}				
				else {
					item.className = ''
				}
			}

			return item
			// item.alarmType === alarmType && name ? { ...item, [name]: value} : item
		})
		

		setChanges(alarmChanges);
		setItems(editData);
	}

	const save = async()=> {

		console.log('saveChanges Start');
		let body = null;
		
		let saveUrl = null;
		let callList = [];

		try {
			const token = await getAccessToken();

			for (var i in alarms) {
				let alarm = alarms[i];
				if (alarm.className && alarm.className === 'changed') {				
					body = {
						shortDescription: alarm.shortDescription
					}					
					saveUrl = url + '/' + alarm.alarmType;

					callList.push(axios.put(saveUrl, body, {headers: {'auth-token': token }}));
				}
			}
			
			let promAllResp = await Promise.all(callList); //response [[]]			
			let breakCheck = false;				

			for (var arrIdx in promAllResp) { //scan promise all results.
				let responses = promAllResp[arrIdx]; //[{}]

				for (var arrIdx2 in responses) {

					let response = responses[arrIdx2];		
					
					if (response.status === 200) {					
						console.log('saveChanges resp ', response);						
						setChanges({})
						getAlarms();
						breakCheck = true;
						break;
					}
					//should there be a failure case handling? show error?
				}
				if (breakCheck === true) {
					break;
				}
			}
			
		} catch(e) {
			console.error('saveChanges ', e);
		}
		// ONT Hardware/Software Problem

	}

	const del = async (alarmType) => {
		const token = await getAccessToken();
		let delUrl = url + '/' + alarmType;
		await axios.delete(delUrl, {headers: {'auth-token': token }});		
		getAlarms();
	}

	const add = async () => {
		
		console.log('add type ', newAlarmType);
		
		console.log('add descrption ', newAlarmDesc);	
		if ((!newAlarmType.match(/\S/gm) || newAlarmDesc.match(/\S/gm)) && (newAlarmDesc.length < 5)) {
			return; //do nothing.
		}

		let body = {
			shortDescription: newAlarmDesc
		}	
		let addUrl = url + '/' + newAlarmType;
		const token = await getAccessToken();
		await axios.put(addUrl, body, {headers: {'auth-token': token }});
		setAdd(false);
		setAType('');
		setDType('');
		getAlarms();
	}	
	
	const saveChanges = () => {		
		setAlertMsg({
			Header: 'Save', 
			Body:'Confirm changes, these will be immediately shown in production',
			okCallback: save
		});
		setShow(true);		
	}

	const delItem = (alarmType) => {
		
		let delStatusEdit = alarms.map((item) => {
			if (item.alarmType === alarmType) {
				item.delStatus = true;
			}
			return item;
		});
		setDelMode(true);
		setItems(delStatusEdit);		
	}

	const reset = () => {
		let resetActive = false;
		for (var i in alarms) {
			let alarm = alarms[i];
			if (alarm.className) {
				resetActive = true;
			}
		}		
		if (!resetActive) {
			return;
		}
		setAlertMsg({
			Header: 'Reset', 
			Body:'Are you sure you want to reset, your changes will be lost',
			okCallback: getAlarms
		});
		setShow(true);		
	}

	const okAlert = () => {
		setShow(false);		
		alertMsg.okCallback();
	}

	const cancelAlert = () => {
		setShow(false);
		setAdd(false);				
	}

	useEffect(() => {
		getAlarms();
		if (!interval) {
		  //interval = setInterval(getItems, 30000);
		}
		return function cleanup() {
		  clearInterval(interval);
		  interval = null;
		};
	  }, []);

	  const popover = (
		<Popover className="popover-alarm">
		  <Popover.Header as="h3">Warning</Popover.Header>
		  <Popover.Body>
		 	 This will remove this item permanently. Click anywhere else to cancel.			
		  </Popover.Body>
		</Popover>
	  );

	return <Container className="cms-alarm-list">
	
		<Alert show={show} variant="primary">
			<Alert.Heading>{alertMsg.Header}</Alert.Heading>
			<p>
				{alertMsg.Body}
			</p>
			<hr />
			<div className="d-flex justify-content-end">
			<Button onClick={()=> okAlert()} variant="outline-success">
				Proceed
			</Button>
			  <Button onClick={()=> cancelAlert()} variant="outline-success">
				Cancel
			  </Button>
			</div>
		</Alert>

		{addMode && <Alert variant="primary">
			<Alert.Heading>Add Alarm</Alert.Heading>

				<Form> 
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon1">alarmType</InputGroup.Text>
						<Form.Control
							placeholder="alarmType"
							aria-label="Username"
							aria-describedby="basic-addon1"		
							value={newAlarmType}	
							onChange={(e) => setAType(e.target.value)}					
							/>
					</InputGroup>

					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon1">alarmDescription</InputGroup.Text>
						<Form.Control
							placeholder="At least 5 characters or more"
							aria-label="Username"
							aria-describedby="basic-addon1"	
							value={newAlarmDesc}				
							onChange={(e) => setDType(e.target.value)}				
							/>
					</InputGroup>
					<Button onClick={()=> add()} variant="outline-success">
						Add
					</Button>

					<Button onClick={()=> cancelAlert()} variant="outline-success">
						Cancel
					</Button>
				</Form>
	
			<hr />
		</Alert>}

		<Row className='edit-buttons'>
			<Col style={{textAlign: 'left'}} >				
				<Button variant='primary' onClick={saveChanges}>Save Changes</Button>		
				<Button variant='secondary' onClick={reset}>Reset</Button>	
			</Col>
			<Col style={{textAlign: 'right'}} >
				<Button variant='info' onClick={()=>setAdd(!addMode)}>Add</Button>							
			</Col>
		</Row>

		<Card className="cms-alarms">			
			<section>
				<table className="table table-dark table-striped table-hover">
				<thead>
					<tr>
					<th>ALARM TYPE</th>
					<th>SHORT DESCRIPTION</th>					
					<th></th>
					</tr>
				</thead>
				<tbody>
					{renderItems()}
				</tbody>
				</table>
			</section>
		</Card>

	</Container>;
}