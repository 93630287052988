import axios from "axios";
import { getAccessToken } from "./auth";
const sessionId = 'psiconsole';
const techId = 'psiconsole';

export const psiSearchSubscriber = async (field, value) => {
  const token = await getAccessToken();
  let r = await axios.get(`https://subscriber.api.fybrlabs.frontier.com/Prod/v1/search?${field}=${value}&uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}
