import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { getSmithResults } from '../../utils/psi-smith-apis';
import { Col, Form, Row, Button } from 'react-bootstrap';

export default function SmithHistory ({}) {
	const [loading, setLoading] = useState(false);
	const [ontSerialNumber, setOntSerialNumber] = useState('all');
	const [tempOntSerialNumber, setTempOntSerialNumber] = useState('');
	const [history, setHistory] = useState(null);
	const [error, setError] = useState(false);
	const [latencyId, setLatencyId] = useState(null);
	const [moreResults, setMoreResults] = useState(false);
	const [page, setPage] = useState(0);
	const pageSize = 20;
	let content;
	const latencyResultsClassName = 'test-results-latency';
	
	useEffect(() => {
		getData();
	}, [ontSerialNumber, page]);

	const getData = async () => {
		try {
			setLoading(true);
			let data = await getSmithResults(ontSerialNumber, pageSize, page);
			let newData = [];
			if (history) {
				newData = history.concat(data);
			}
			else {
				newData = data;
			}
			if (data.length === pageSize) {
				setMoreResults(true);
			}
			else {
				setMoreResults(false);
			}
			setHistory(newData);
			setLoading(false);
		}
		catch (e) {
			setError(`An error occurred: ${e.toString()}`);
		}
	}

	const toggleLatencyTests = (ev) => {
		ev.preventDefault();
		console.log(ev.target.dataset.id);
		if (ev.target.dataset.id !== latencyId) {
			setLatencyId(ev.target.dataset.id);
		}
		else {
			setLatencyId(null);
		}
	}

	const getTableRows = () => {
		let rows = history.map((test, i) => {

			let localTime = (new Date(test.timestamp * 1000)).toLocaleString();

			const latencyItemClassName = latencyId === test._id ? 'visible' : 'hidden';
			const latencyLinkText = latencyId === test._id ? 'Hide' : 'Show';

			return <>
				<tr key={`test-results-key-${test._id}`}>
					<td>{test.ontSerialNumber}</td>
					<td>{localTime}</td>
					<td>{test.ontUptime || ''}</td>
					<td>{test.speedTest?.serverUrl || ''}</td>
					<td>{test.speedTest?.download || ''}</td>
					<td>{test.speedTest?.upload || ''}</td>
					<td>{test.speedTest?.latency?.idleMin || ''}</td>
					<td>{test.speedTest?.latency?.downMin || ''}</td>
					<td>{test.speedTest?.latency?.upMin || ''}</td>
					<td><a href='#' onClick={toggleLatencyTests} data-id={test._id}>{latencyLinkText}</a></td>
				</tr>
				<tr key={`test-results-key-${test._id}-latency`} id={`test-results-key-${test._id}-latency`} className={latencyResultsClassName + ' ' + (latencyItemClassName)}>
					<td colSpan={10}>
						<table style={{width: '100%'}}>
							<thead>
								<tr>
									<td>URL</td>
									<td>Name Lookup</td>
									<td>Connect</td>
									<td>App Connect</td>
									<td>Pretransfer</td>
									<td>Redirect</td>
									<td>Start Transfer</td>
									<td>Total</td>
								</tr>
							</thead>
							<tbody>
							{test.urlLatency && test.urlLatency.map((latencyResult, idx) => {
								return <tr key={`test-results-key-${test._id}-latency-${idx}`}>
									<td>{latencyResult.url}</td>
									<td>{latencyResult.time_namelookup}</td>
									<td>{latencyResult.time_connect}</td>
									<td>{latencyResult.time_appconnect}</td>
									<td>{latencyResult.time_pretransfer}</td>
									<td>{latencyResult.time_redirect}</td>
									<td>{latencyResult.time_starttransfer}</td>
									<td>{latencyResult.time_total}</td>
								</tr>
							})}
							</tbody>
						</table>
					</td>
				</tr>
			</>;
		});
		return rows;
	}

	const loadNextPage = () => {
		setPage(page + 1);
	}

	if (!error && !history) {
		content = <h3>Loading...</h3>;
	}
	else if (error) {
		content = <h3 className='error'>{error}</h3>
	}
	else if (history.length) {
		content = <>
			<table className='table table-dark table-striped table-hover' style={{display: 'block', maxWidth: '100%', overflowX: 'scroll'}}>
				<thead>
					<tr>
						<td>ONT Serial Number</td>  
						<td>Timestamp</td>  
						<td>ONT Uptime</td>  
						<td>ST Server</td>
						<td>Download</td>
						<td>Upload</td>
						<td>Latency Idle Min</td>
						<td>Latency Down Min</td>
						<td>Latency Up Min</td>
						<td>Latency Tests</td>
					</tr>
				</thead>
				<tbody>
					{getTableRows()}
				</tbody>
			</table>
			{
				moreResults && !loading && <Row>
					<Button variant="primary" onClick={loadNextPage} className='mt-4'>Load more</Button>
				</Row>
			}
			{
				moreResults && loading && <Row>
					<h3>Loading...</h3>
				</Row>
			}
		</>;
	}
	else {
		content = <h3>No data available.</h3>
	}

	const searchOnt = async (ev) => {
		ev.preventDefault();

		if (tempOntSerialNumber === ontSerialNumber || ontSerialNumber === 'all' && !tempOntSerialNumber) {
			return;
		}

		setPage(0);
		setHistory(null);
		setError(false);
		setMoreResults(false);

		if (!tempOntSerialNumber) {
			setTempOntSerialNumber('');
			setOntSerialNumber('all');
		}
		else {
			setOntSerialNumber(tempOntSerialNumber);
		}
	}
	
	return (
		<Card className={'top-10'}>
			<header>Agent Smith Results</header>
			<section>
				<Form onSubmit={searchOnt}>
					<Row className="mb-5">
						<Col md={6}>
							<Form.Group>
								<Form.Label>ONT Serial Number</Form.Label>
								<Form.Control type="text" value={tempOntSerialNumber} onChange={e => {setTempOntSerialNumber(e.target.value)}}/>
							</Form.Group>
						</Col>
						<Col md={2}><Button variant="primary" onClick={searchOnt} className='mt-4'>Search</Button></Col>
					</Row>
				</Form>
				{ content }
			</section>
		</Card>
	)
}

