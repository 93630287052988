import axios from "axios";
import { getAccessToken } from "./auth";

export const getTerminalOutage = async (startTime, endTime) => {
  const token = await getAccessToken();
  try {
    let r = await axios.get(`https://outage.api.fybrlabs.frontier.com/prod/terminal-down-list?windowStart=${startTime}&windowEnd=${endTime}`, {headers: { 'auth-token': token }});
    let data = [];
    r.data.forEach(terminal => {
      if (terminal.terminalLoc && terminal.terminalLoc.lat && terminal.terminalLoc.lon && terminal.noOfONTsDown) {
        data.push(terminal);
      }
    })
    return data;
  }
  catch (e) {
    console.error(`Outage: Error getting data.`);
    console.log(e);
    throw e;
  }
}