import axios from "axios";
import { getAccessToken } from "./auth";

const _configs = {
	rgUrl : 'https://rg.api.fybrlabs.frontier.com/Prod'
}

export const getSpeedTestResults = async (serialNumber) => {
	console.log('getSpeedTestResults');

	let testResults = [];
	var response = {};
	try {
		const token = await getAccessToken();
		let url = `${_configs.rgUrl}/v1/${serialNumber}/tests/speed`;
		response = await axios.get(url, {headers: {'auth-token': token }});
	} catch(e) {
		console.log('getSpeedTestResults ', e.message);

	}		
	finally {
		if (response.status === 200) {
			testResults = response.data || [];
		}
		
		return testResults;
	}
}