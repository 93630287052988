import { Container, Stack, Row, Col, Button, Card, InputGroup, Form } from "react-bootstrap";
import { useState, useEffect } from "react";

import MultiRangeSlider from "multi-range-slider-react";

import { getAccessToken } from "../../../utils/auth.js";

import axios from 'axios';

const _thresholdConfigs = require('./defaultConfigs/thresholdConfigs.json');
const _serviceConfigs = require('./defaultConfigs/serviceConfigs.json');

const rgUrl = 'https://rg.api.fybrlabs.frontier.com';
const thresholdsPath = '/Prod/v1/config/thresholds';
const featuresPath = '/Prod/v1/config/features';


export default function WifiAnalyzerConfigs ({}) {  
    //data for the thresholds  
    
    const [thresholdConfigsCopy, setThresholdsCopy] = useState([]);
    const [badRSSI, set_badRSSI] = useState(-90); 
    const [goodRSSI, set_goodRSSI] = useState(-55);
    const [badLatency, set_badLatency] = useState(100);
    const [goodLatency, set_goodLatency] = useState(10);

    const [serviceConfigsChanges, setServiceConfigsChanges] = useState({});
    const [serviceConfigs, setServiceConfigs] = useState({});//data for the service criteria

    const [serviceServerConfigsDecoy, setServiceServerConfigsDecoy] = useState({});    

    const [serviceServerConfigs, setServiceServerConfigs] = useState({});//data for the service criteria

    //Todo:ADD TITLES
    const renderThresholdConfigs = () => {
        let title = 'Thresholds'
        return (
            <Card bg="dark"> 
                <Card.Body>
                    <Card.Title>{title}</Card.Title> 
                    <div>RSSI</div>
                    <MultiRangeSlider
                        min={-120}
                        max={-1}
                        step={5}
                        minValue={badRSSI}
                        maxValue={goodRSSI}
                        barLeftColor="red"
                        barInnerColor="yellow"
                        barRightColor="green"
                        thumbLeftColor="white"
                        thumbRightColor="white"
                        onInput={(e) => {
                            handleRssiThresholdInput(e);
                        }}
                        />
                    <div>LATENCY</div>
                    <MultiRangeSlider
                        min={0}
                        max={300}
                        step={5}
                        minValue={goodLatency}
                        maxValue={badLatency}
                        barLeftColor="green"
                        barInnerColor="yellow"
                        barRightColor="red"
                        thumbLeftColor="white"
                        thumbRightColor="white"
                        onInput={(e) => {
                            handleLatencyThresholdInput(e);
                        }}
                        />

                 </Card.Body>        
            </Card>
        );
    }

    const handleRssiThresholdInput = (e) => {
        set_badRSSI(e.minValue);
        set_goodRSSI(e.maxValue);    
    }

    const handleLatencyThresholdInput = (e) => {
        set_badLatency(e.maxValue);
        set_goodLatency(e.minValue);    
    }

    const renderServiceConfigs = () => {
        //Todo
        let title = 'Service Configurations';

        let arrserviceConfigs = Object.values(serviceConfigs);

        let tableItems = arrserviceConfigs.map(Item => {
            return (
                <tr>
                    <td>
                        {Item.feature}
                    </td>
                    <td>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type
                            placeholder={Item.maxLatency}
                            aria-label={Item.maxLatency}
                            aria-describedby="basic-addon1"
                            onChange={(e) => handleServiceChange(e.target.value, Item.feature, 'maxLatency')}
                            />                 
                    </InputGroup>                 
                    </td>                        
                    <td>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder={Item.minBandwidth}
                            aria-label={Item.minBandwidth} 
                            aria-describedby="basic-addon1"
                            onChange={(e) => handleServiceChange(e.target.value, Item.feature, 'minBandwidth')}
                            />       
                    </InputGroup>                           
                    </td>
                    <td className="serviceIcon">
                        <img/>                                   
                    </td>                            
                </tr>
            )
        });

        return (
            <Card bg="dark"> 
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                        <table className="table table-dark table-striped table-hover">
                            <thead>
                                <tr>
                                <th>SERVICE</th>
                                <th>LATENCY</th>								
                                <th>BANDWIDTH (Mbps)</th>					
                                <th>ICON</th>					
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableItems}
                            </tbody>
                        </table>
                </Card.Body>
            </Card>    
        );
    }

    const handleServiceChange = (value, service, attribute) => {
        if (!isNaN(value)){
            value = parseInt(value);
        }
        let currentChanges = serviceConfigsChanges[service] || {} ;
        let newChanges = Object.assign(currentChanges, {[attribute]: value})

        let changes = {
            [service]: newChanges
        };
        // serviceConfigsChanges[service];        
        
        Object.assign(serviceConfigsChanges, changes);
        
        console.log('handleServiceChange serviceConfigsChanges', serviceConfigsChanges);
    }

    const renderServiceServerConfigs = () => {
        console.log('renderServiceServerConfigs');
        let title = 'Service Servers';
        
        let tbody = [];
        let typeMapString = {
            videoStream: 'VIDEO STREAM',
            gaming: 'GAMING',
            videoChat: 'VIDEO CHAT',
            browse: 'BROWSING',
        }

        for (let key in serviceServerConfigs) {
            // debugger;
            let inputList = serviceServerConfigs[key].map(serverItem => {
                return <InputGroup className="mb-3 srvrList">
                    <Form.Control
                        placeholder={serverItem}
                        aria-label={serverItem}
                        aria-describedby="basic-addon1"
                        onChange={(e) => handleServiceServerChange(e.target.value, key, serverItem)}
                        />   
                </InputGroup>     
            });
            
            inputList.push(<InputGroup className="mb-3 srvrList">
                    <Form.Control
                        aria-describedby="basic-addon1"
                        onChange={(e) => handleServiceServerChange(e.target.value, key, '')}
                        />   
                </InputGroup>)  


            tbody.push(<tr>
                    <td>{typeMapString[key]}</td>
                    <td>
                        <Stack>
                            {inputList}
                        </Stack>
                    </td>
                </tr>
            )

        }

        return (
            <Card bg="dark"> 
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                        <table className="table table-dark table-striped table-hover">
                        <thead>
                                <tr>
                                <th></th>
                                <th></th>								
                                </tr>
                        </thead>
                        <tbody>
                           {tbody}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>    
        );
    }
    
    const handleServiceServerChange = async (value, type, currItem) => {
    //    debugger;
        let currList = serviceServerConfigs[type];
        // let currConfigs = serviceServerConfigs;
        let idxOfCurItem = currList.indexOf(currItem);
        if (idxOfCurItem > -1) {//its on the list. 
            if (value === '') {//deleting 
                currList.splice(idxOfCurItem, 1);
                serviceServerConfigs[type] = currList;
            }
            else {
                currList.splice(idxOfCurItem, 1, value);
                serviceServerConfigs[type] = currList;
            }
        }        
        else {
            serviceServerConfigs[type].push(value);
        }
       
        // setServiceServerConfigs(serviceServerConfigs);
        setServiceServerConfigsDecoy({}); //needed since page doesn't rerender due to an attribute is changed not the data root.
        console.log(' handleServiceServerChange serviceServerConfigs ', serviceServerConfigs);    
    }

    const getThresholdConfigs = async () => {   
        let thresholdConfigs = []; 
        let dbThresholds = [];
        let token = await getAccessToken();

        thresholdConfigs = _thresholdConfigs;
        
        let r = await axios.get(`${rgUrl}${thresholdsPath}`, {headers: {'auth-token': token }});
        if (r.data) {
            dbThresholds = r.data;
        }

        Object.assign(thresholdConfigs, dbThresholds);

        thresholdConfigs.forEach(item => {
            if (item.property.includes('rssi')) {
                set_badRSSI(item.badValue);
                set_goodRSSI(item.goodValue);
            }
            if (item.property.includes('latencyLvls')) {
                set_badLatency(item.badValue);
                set_goodLatency(item.goodValue);
            }
        });
        setThresholdsCopy(thresholdConfigs);
    
    }

    const getServiceConfigs = async () => {
        console.log('getServiceConfigs');
        let svcConfigs = _serviceConfigs;
        let svcConfigObj = {};
        let svcServerConfigs = {};        
        let dbServices = [];
        // debugger;
        let token = await getAccessToken();
        let url = `${rgUrl}${featuresPath}`;

        let r = await axios.get(`${rgUrl}${featuresPath}`, {headers: {'auth-token': token }});
        if (r.data) {
            dbServices = r.data;
        }

        Object.assign(svcConfigs, dbServices);

        //changing to object to get easy reference on feature
        svcConfigs.forEach(Item => {
            svcConfigObj[Item.feature] = Item;
            //get all servers 
            /*
                {video: [], gaming:[]}
            */
            if (!svcServerConfigs[Item.type]) {
                svcServerConfigs[Item.type] = Item.latencyServers;                
            }


        });
        
        setServiceServerConfigs(svcServerConfigs);
        setServiceConfigs(svcConfigObj);        
    }

    //Gets Data Before Mount
    useEffect(() => {
		getThresholdConfigs();
        getServiceConfigs();
    }, []);

    const saveChanges = async (e) => {
        // debugger;
        console.log('saveChanges ', e.target);

        await _saveThresholdChanges();
        await _saveServiceConfigChanges();        
    }

    const _saveThresholdChanges = async () => {
        let token = await getAccessToken();        
        let thresholdPromises = [];
        console.log('_saveThresholdChanges thresholdConfigsCopy', thresholdConfigsCopy);
        thresholdConfigsCopy.forEach(item => {     
            let url = `${rgUrl}${thresholdsPath}/${item.property}`
            let changes = {
                property: item.property
            };        
            if (item.property.includes('rssi')) {                   
                if ((item.badValue != badRSSI) || (item.goodValue != goodRSSI)) {
                    changes.badValue = badRSSI;
                    changes.goodValue = goodRSSI;
                    let r =  axios.put(url, changes, {headers: {'auth-token': token }});   
                    thresholdPromises.push(r); 
                }               
            }
            if (item.property.includes('latencyLvls')) {
                if ((item.badValue != badLatency) || (item.goodValue != goodLatency)) {
                    changes.badValue = badLatency;
                    changes.goodValue = goodLatency;
                    let r =  axios.put(url, changes, {headers: {'auth-token': token }});   
                    thresholdPromises.push(r); 
                }    
            }

        });

        let responses = await Promise.allSettled(thresholdPromises);
        responses.forEach((r) => {
            if (r.status === 'fulfilled') {   
                console.log('fullfilled status ', r);
            }
            console.log('status ', r);
        })

    }

    const _saveServiceConfigChanges = async () => {
        console.log('serviceConfigsChanges ', serviceConfigsChanges);        
        let token = await getAccessToken();  
        let thresholdPromises = [];
        
        for (let featType in serviceServerConfigs) {
            let addressChanges = serviceServerConfigs[featType];
            let arrServiceConfigs = Object.values(serviceConfigs);     
            
            arrServiceConfigs.forEach(feat => {
                if (feat.type === featType) {                                                          
                    handleServiceChange(addressChanges, feat.feature, 'latencyServers');                                        
                }
            });
        }

        for (let feature in serviceConfigsChanges) {
            let payload = {
                feature: feature,
                maxLatency: serviceConfigs[feature].maxLatency,
                minBandwidth: serviceConfigs[feature].minBandwidth,
                latencyServers: serviceConfigs[feature].latencyServers,
                type: serviceConfigs[feature].type
            };        
            let thefeature = serviceConfigsChanges[feature];

            let latencyChange = thefeature.maxLatency;
            let bandWidthChange = thefeature.minBandwidth;
            //check differences 
            if (latencyChange && latencyChange !== serviceConfigs[feature].maxLatency) {
                payload.maxLatency = latencyChange;
            }
            if (bandWidthChange && bandWidthChange !== serviceConfigs[feature].minBandwidth) {
                payload.minBandwidth = bandWidthChange;
            }
            if (thefeature.latencyServers && thefeature.latencyServers.length > 0) {
                payload.latencyServers = thefeature.latencyServers
            }            

            console.log('_saveServiceConfigChanges payload', payload);

            if (Object.values(payload).length > 1) {
                let url = `${rgUrl}${featuresPath}/${feature}`            
                console.log('_saveServiceConfigChanges SAVING', url);
                let r =  axios.put(url, payload, {headers: {'auth-token': token }});  
                thresholdPromises.push(r); 
            }
        }
        
         let responses = await Promise.allSettled(thresholdPromises);
         responses.forEach((r) => {
             if (r.status === 'fulfilled') {   
                 console.log('fullfilled status ', r);
             }
             console.log('status ', r);
         });

    }

    const resetChanges = () => {
        console.log('resetChanges ');
        //TODO: need to clear forms and cached changes. 
        //Reloading this way for now, could not clear states 
        window.location.reload();
    }

    return <Container className="cms-alarm-list">

            <Stack gap={3}>                        
                <Row className='edit-buttons'>
                    <Col style={{textAlign: 'left'}} >				
                        <Button variant='primary' onClick={saveChanges}>Save Changes</Button>		
                        <Button variant='secondary' onClick={resetChanges}>Reset</Button>	
                    </Col>
                </Row>
                
                {renderThresholdConfigs()}

                {renderServiceConfigs()}

                {renderServiceServerConfigs()}

            </Stack>

    </Container>;

}