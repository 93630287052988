import axios from "axios"
import { getAccessToken } from "./auth";

const cmsUrl = 'https://cms.api.fybrlabs.frontier.com/prod';

export const getAllAlerts = async (token) => {
  let r = await axios.get(`${cmsUrl}/alerts`, {headers: { 'auth-token': token }});
  return r.data;
}

export const putAlert = async (alert, token) => {
  let r = await axios.put(`${cmsUrl}/alerts/${alert.name}`, alert, {headers: { 'auth-token': token }});
  return r.data;
}

export const deleteAlert = async (alert, token) => {
  let r = await axios.delete(`${cmsUrl}/alerts/${alert.name}`, {headers: { 'auth-token': token }});
  return r.data;
}

export const getAllOntSpeedTestConfigs = async () => {
  const token = await getAccessToken();
  let r = await axios.get(`${cmsUrl}/config/ont/speedTest`, {headers: { 'auth-token': token }});
  return r.data;
}

export const putOntSpeedTestConfig = async (item) => {
  const token = await getAccessToken();
  let r = await axios.put(`${cmsUrl}/config/ont/speedTest/${item.id}`, item, {headers: { 'auth-token': token }});
  return r.data;
}

export const deleteOntSpeedTestConfigs = async (id) => {
  const token = await getAccessToken();
  let r = await axios.delete(`${cmsUrl}/config/ont/speedTest/${id}`, {headers: { 'auth-token': token }});
  return r.data;
}