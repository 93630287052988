import { Stage, Layer, Rect, Circle, Label, Tag, Text, Line } from 'react-konva';
import React, { useRef, useEffect, useState, useCallback } from "react";
import axios from 'axios';
import PointPopup from './pointPopup';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from '../card/Card'
import { Container } from 'react-bootstrap';

import { getDataPoints, heatmapFields, exportHeatmapRawData, getAnomalies } from './heatmapDataProcessor';
import { getAccessToken } from '../../utils/auth';

function heatMapColorforValue(value){
  var r, g, b = 0;
  let perc = (1-value) * 100;
	if(perc < 50) {
		r = 255;
		g = Math.round(3.1 * perc);
	}
	else {
		g = 235;
		r = Math.round(510 - 5.10 * perc);
	}
	var h = r * 0x10000 + g * 0x100 + b * 0x1;
	return '#' + ('000000' + h.toString(16)).slice(-6);
}

let animationTimeout;

export const Heatmap2 = () => {
  const minScale = 0.2;
  const [heatmapAxes, setHeatmapAxes] = useState({x: heatmapFields.ontDistance.field, y: heatmapFields.oltRxSignalLevel.field});
  const [clli, setCLLI] = useState('');
  const [stageScale, setStageScale] = useState(minScale);
  const [stageX, setStageX] = useState(30);
  const [stageY, setStageY] = useState(20);
  const [rawData, setRawData] = useState(null);
  const [data, setData] = useState(null);
  const [popup, setPopup] = useState({value: null});
  const [tempXAxis, setTempXAxis] = useState(heatmapFields.ontDistance.field);
  const [tempYAxis, setTempYAxis] = useState(heatmapFields.oltRxSignalLevel.field);
  const [inited, setInited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dayIndex, setDayIndex] = useState(0);
  const [animated, setAnimated] = useState(true);
  const [anomalies, setAnomalies] = useState({});
  
  const maxScale = 5;
  const pointWidth = 10;
  const pointHeight = 10;
  const apiEndpoint = 'https://goldilocks.api.fybrlabs.frontier.com/Prod/v1/getONTOpticsData';


  let points = [];
  let gridLines = [];
  let axisLabels = [];
  const gridHeight = 4000;
  const gridWidth = 4500;
  let days;

  const handleWheel = e => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    newScale = Math.max(newScale, minScale);
    newScale = Math.min(newScale, maxScale);

    setStageScale(newScale);
    setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
    setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
  };

  const handleSubmission = async (ev) => {
    ev.preventDefault();
    let r;
    let daysToGet = [];
    for (let i = 0; i < 7; i++) {
      let day = new Date();
      day.setDate(day.getDate() - i);
      let dayString = day.toISOString().split('T')[0];
      daysToGet.push(dayString);
    }
    const date = daysToGet.join(',');//'2023-02-26,2023-02-27,2023-02-28,2023-03-01,2023-03-02,2023-03-03';//(new Date()).toISOString().split('T')[0];
    const url = `${apiEndpoint}?date=${date}&objectName=${clli}`;

    try {
      setLoading(true);
      const token = await getAccessToken();
      r = await axios.get(url, {headers: {'auth-token': token}});
      setLoading(false);
      if (Object.keys(r.data).length) {
        console.log('Got response');
        setRawData(r.data);
        setAnomalies(getAnomalies(r.data))
      } else {
        console.log('Got empty response');
      }
    } catch(e) {
      if (e.response && e.response.data) {
        console.log(e.response.data);
      } else {
        console.log(e);
      }
    }
  }

  const handleHeatmapConfigurationUpdate = async (ev) => {
    ev.preventDefault();
    let newAxes = {};
    let update = false;
    if (tempXAxis !== heatmapAxes.x) {
      newAxes.x = tempXAxis;
      update = true;
    } else {
      newAxes.x = heatmapAxes.x;
    }
    if (tempYAxis !== heatmapAxes.y) {
      newAxes.y = tempYAxis;
      update = true;
    } else {
      newAxes.y = heatmapAxes.y;
    }
    console.log('Updating axes: ' + update);
    if (update) {
      setHeatmapAxes(newAxes);
    }
  }

  const handleAnimationPlayPause = async (ev) => {
    setAnimated(!animated);
  }

  const clearPage = async (ev) => {
    setCLLI('');
    setRawData(null);
    setData(null);
    clearTimeout(animationTimeout);
    setAnimated(true);
    setDayIndex(0);
    setInited(false);
  }

  const processData = (data, x, y, date) => {
    let processedData = getDataPoints(data, x, y);
    
    return {
      processed: processedData.oltPoints,
      date: date,
      axes: {
        x: heatmapAxes.x,
        y: heatmapAxes.y
      }
    }
  }

  const handleHover = useCallback((ev, pos) => {
    if (!ev) {
      setPopup({})
    } else {
      setPopup({
        ...ev.target.attrs.data.point, 
        position: ev.target.getAbsolutePosition()
      });
    }
  }, []);

  const getCanvasPoints = (dataPoints, max) => {
    let points = [];
    if (dataPoints) {
      points = dataPoints.map((point, i) => {
        return <Rect 
          width={pointWidth}
          height={pointHeight}
          data={{point}}
          fill={heatMapColorforValue(point.value / max)}
          x={point.x }
          y={point.y }
          key={`${data.date}_point_${i}`}
          onMouseEnter={ev => handleHover(ev)}
          onMouseLeave={ev => handleHover(null)}
          />
      });
    }
    return points;
  }

  if (rawData) {
    days = Object.keys(rawData);
    if (!inited) {
      setData(processData(rawData[days[dayIndex]], heatmapAxes.x, heatmapAxes.y, dayIndex));
      setInited(true);
    } else if (data) {
      if (heatmapAxes.x !== data.axes.x || heatmapAxes.y !== data.axes.y || data.date !== dayIndex) {
        setData(processData(rawData[days[dayIndex]], heatmapAxes.x, heatmapAxes.y, dayIndex));
      }
      if (data.processed?.dataPoints) {
        points = getCanvasPoints(data.processed.dataPoints, data.processed.max);
      }
    }

    if (data && animated) {
      let newDayIndex = dayIndex;
      if (days.length > 1) {
        if (days.length > data.date + 1) {
          newDayIndex++;
        } else {
          newDayIndex = 0;
        }
        if (animationTimeout) {
          clearTimeout(animationTimeout);
        }
        animationTimeout = setTimeout(() => {
          if (newDayIndex >= days.length) {
            newDayIndex = 0;
          }
          setDayIndex(newDayIndex);
        }, 1000);
      }
    } else if (!animated) {
      clearTimeout(animationTimeout);
      animationTimeout = null;
    }
  }

  for (let i = 0; i <= gridWidth; i+=100) {
    gridLines.push(
      <Line
        points={[i, 0, i, gridHeight]}
        stroke='red'
        strokeWidth={5}
        lineCap='round'
        lineJoin='round'
      />
    );
    axisLabels.push(<Text
      text={(i * 0.01) + 'km'}
      fill={'white'}
      x={i - 20}
      y={-40}
      align="center"
      verticalAlign="middle"
      fontSize={30}
    />);
  }
  for (let i = 0; i <= gridHeight; i+=100) {
    gridLines.push(
      <Line
        points={[0, i, gridWidth, i]}
        stroke={'red'}
        strokeWidth={5}
        lineCap={'round'}
        lineJoin={'round'}
      />
    );
    axisLabels.push(<Text
      text={(i === 0 ? '' : '-') + (i * 0.01) + 'dBm'}
      fill={'white'}
      x={-110}
      y={i - 20}
      align="right"
      verticalAlign="middle"
      fontSize={30}
    />);
  }

  const getHeatmapConfigurationForm = () => {
    return <Card>
      <header>Heatmap Settings</header>
      <section>
        <Form onSubmit={handleHeatmapConfigurationUpdate}>
          <Row>
            <Form.Group className="mb-3" as={Col} md="3">
              <Form.Label>X Axis</Form.Label>
              <Form.Select value={tempXAxis} required onChange={e => {
                setTempXAxis(e.target.value);
              }}>
                <option value={heatmapFields.ontDistance.field}>Distance</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" as={Col} md="3">
              <Form.Label>Y Axis</Form.Label>
              <Form.Select value={tempYAxis} required onChange={e => {
                setTempYAxis(e.target.value);
              }}>
                <option value={heatmapFields.oltRxSignalLevel.field}>OLT RX</option>
                <option value={heatmapFields.ontRxSignalLevel.field}>ONT RX</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
              <Button variant="primary" type="submit">
                Update heatmap
              </Button>
              <Button variant="secondary" type="reset" onClick={e => {setTempXAxis(heatmapAxes.x); setTempYAxis(heatmapAxes.y);}}>
                Reset
              </Button>
          </Row>
        </Form>
      </section>
    </Card>;
  }

  const getHeatmapCanvas = () => {
    return <div className='main-stage'>
    <Stage
      width={1296}
      height={window.innerHeight / 1.5}
      scaleX={stageScale}
      scaleY={stageScale}
      x={stageX}
      y={stageY}
      onWheel={handleWheel}
      draggable
      >
      <Layer>
        {points}
      </Layer>
      <Layer>
        {gridLines}
        {axisLabels}
      </Layer>
    </Stage>

    {popup.value && (
      <PointPopup 
        distance={popup.distance}
        ontRxSignalLevel={popup.ontRxSignalLevel}
        value={popup.value}
        position={popup.position}
        onClose={() => {
          setPopup({ value: null });
        }}
        data={popup}
      />
    )}
  </div>;
  }

  const getHeatmapControls = () => {
    return <>
      <Col>
        <p>Day: {days[dayIndex]}</p>
      </Col>
      <Col>
        <p>Animation<Button onClick={handleAnimationPlayPause}>{animated ? 'Pause' : 'Play'}</Button></p>
      </Col>
      <Col>
        <p><Button onClick={() => exportHeatmapRawData(rawData, `heatmap-${clli}.csv`)}>Export to CSV</Button></p>
      </Col>
    </>
  }

  const getLoadingComponent = () => {
    return <div><h1>Loading...</h1></div>;
  }

  const renderAnomalies = () => {
    if (loading || !rawData) {
      return '';
    }
    
    return <>
      <Card className={'anomalies'}>
        <header>
          ONTs with biggest change {'> 3dBm'}
        </header>
        <section>
          <table className='table table-dark table-striped table-hover'>
            <thead>
              <tr>
                <td>ONT SN</td>
                <td>Lowest</td>
                <td>Highest</td>
                <td>Difference</td>
              </tr>
            </thead>
            <tbody>
              {anomalies.moved.map(ont => {
                return <tr key={`anomalies-${ont.sn}`}>
                  <td>{ont.sn}</td>
                  <td>{ont.lowest} ({ont.lowestDate})</td>
                  <td>{ont.highest} ({ont.highestDate})</td>
                  <td>{ont.diff}</td>
                </tr>
              })}
            </tbody>
          </table>
        </section>
      </Card>
      <Card className={'anomalies'}>
        <header>
          ONTs outside of Goldilocks
        </header>
        <section>
          <table className='table table-dark table-striped table-hover'>
            <thead>
              <tr>
                <td>ONT SN</td>
                <td>RX</td>
              </tr>
            </thead>
            <tbody>
              {Object.values(anomalies.outsideGoldilocks.olt).sort((a, b) => b.oltRx - a.oltRx).map(ont => {
                return <tr key={`outside-goldilocks-${ont.sn}`}>
                  <td>{ont.sn}</td>
                  <td>{ont.oltRx}</td>
                </tr>
              })}
            </tbody>
          </table>
        </section>
      </Card>
    </>
  }

  return (
    <section className='heatmap2-wrapper'>
      <Container>
        <Row>
          <Col>
            <Card>
              <header>Heatmap</header>
              <section>
                <Form onSubmit={handleSubmission}>
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>CLLI</Form.Label>
                      <Form.Control type="text" placeholder="Enter CLLI" value={clli} onChange={e => setCLLI(e.target.value)} required />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                    <Button variant="secondary" type="reset" onClick={e => {clearPage();}}>
                      Cancel
                    </Button>
                  </Row>
                </Form>
              </section>
            </Card>
            {!loading && rawData && getHeatmapConfigurationForm()}
            {loading && getLoadingComponent()}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className='heatmap-controls'>
              {!loading && rawData && getHeatmapControls()}
            </Row>
            <Row>
              {!loading && rawData && getHeatmapCanvas()}
            </Row>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
          {renderAnomalies()}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
