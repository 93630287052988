import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { getSmithSchedules } from '../../utils/psi-smith-apis';
import { Col, Form, Row, Button } from 'react-bootstrap';

export default function SmithSchedules ({}) {
	const [loading, setLoading] = useState(false);
	const [ontSerialNumber, setOntSerialNumber] = useState('all');
	const [tempOntSerialNumber, setTempOntSerialNumber] = useState('');
	const [schedules, setSchedules] = useState(null);
	const [error, setError] = useState(false);
	const [latencyId, setLatencyId] = useState(null);
	const [moreResults, setMoreResults] = useState(false);
	const [page, setPage] = useState(0);
	const pageSize = 20;
	let content;
	
	useEffect(() => {
		getData();
	}, [ontSerialNumber, page]);

	const getData = async () => {
		try {
			setLoading(true);
			let data = await getSmithSchedules(ontSerialNumber, pageSize, page);
			let newData = [];
			if (schedules) {
				newData = schedules.concat(data);
			}
			else {
				newData = data;
			}
			if (data.length === pageSize) {
				setMoreResults(true);
			}
			else {
				setMoreResults(false);
			}
			setSchedules(newData);
			setLoading(false);
		}
		catch (e) {
			setError(`An error occurred: ${e.toString()}`);
		}
	}

	const getTableRows = () => {
		let rows = schedules.map((s, i) => {

			let localTime = (new Date(s.startTime * 1000)).toLocaleString();
			const latencyLinkText = latencyId === s._id ? 'Hide' : 'Show';

			return <tr key={`schedules-key-${s._id}`}>
				<td>{s.ontSerialNumber}</td>
				<td>{localTime}</td>
				<td>{s.urlSpeedTestServer}</td>
				<td>{s.urlLatency.map(url => <p>{url}</p>)}</td>
				<td>{s.ulBandwith}</td>
				<td>{s.ulBandwidth}</td>
			</tr>;
		});
		return rows;
	}

	const loadNextPage = () => {
		setPage(page + 1);
	}

	if (!error && !schedules) {
		content = <h3>Loading...</h3>;
	}
	else if (error) {
		content = <h3 className='error'>{error}</h3>
	}
	else if (schedules.length) {
		content = <>
			<table className='table table-dark table-striped table-hover' style={{display: 'block', maxWidth: '100%', overflowX: 'scroll'}}>
				<thead>
					<tr>
						<td>ONT Serial Number</td>  
						<td>Start Time</td>  
						<td>Speed Test Server</td>  
						<td>Latency Tests</td>
						<td>ulBandwith</td>
						<td>ulBandwidth</td>
					</tr>
				</thead>
				<tbody>
					{getTableRows()}
				</tbody>
			</table>
			{
				moreResults && !loading && <Row>
					<Button variant="primary" onClick={loadNextPage} className='mt-4'>Load more</Button>
				</Row>
			}
			{
				moreResults && loading && <Row>
					<h3>Loading...</h3>
				</Row>
			}
		</>;
	}
	else {
		content = <h3>No data available.</h3>
	}

	const searchOnt = async (ev) => {
		ev.preventDefault();

		if (tempOntSerialNumber === ontSerialNumber || ontSerialNumber === 'all' && !tempOntSerialNumber) {
			return;
		}

		setPage(0);
		setSchedules(null);
		setError(false);
		setMoreResults(false);

		if (!tempOntSerialNumber) {
			setTempOntSerialNumber('');
			setOntSerialNumber('all');
		}
		else {
			setOntSerialNumber(tempOntSerialNumber);
		}
	}
	
	return (
		<Card className={'top-10'}>
			<header>Agent Smith Schedules</header>
			<section>
				<Form onSubmit={searchOnt}>
					<Row className="mb-5">
						<Col md={6}>
							<Form.Group>
								<Form.Label>ONT Serial Number</Form.Label>
								<Form.Control type="text" value={tempOntSerialNumber} onChange={e => {setTempOntSerialNumber(e.target.value)}}/>
							</Form.Group>
						</Col>
						<Col md={2}><Button variant="primary" type='submit' className='mt-4'>Search</Button></Col>
					</Row>
				</Form>
				{ content }
			</section>
		</Card>
	)
}

