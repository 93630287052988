import React, { useEffect, useState } from "react";
import { getTerminalOutage } from "../../utils/outage";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Card from "../card/Card";
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import LeafletHeatmap from "../leafletMap/heatmap";
import { Button, Pagination, Row, Col } from "react-bootstrap";
import { loginRequest } from "../../msAuthConfig";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

export default function TerminalOutageMap ({ startDate, endDate }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [errorMsg, setErrorMsg] = useState(false);
  const [timeWindowData, setTimeWindowData] = useState(null);
  const [animated, setAnimated] = useState(false);
  const [animationWindowIdx, setAnimationWindowIdx] = useState(0);
  const [animationInterval, setAnimationInterval] = useState(null);
  const { msalInstance } = useMsal();

  console.log(`Start Date: ${startDate}, end date: ${endDate}`);
  console.log(`Time Window Data`, timeWindowData);
  console.log(`Data: `, data);
  console.log(`AnimationInterval: `, animationInterval);
  
  const getData = async () => {
    setLoading(true);
    const windows = getTimeWindows();
    console.log(windows);
    try {
      let r = await getTerminalOutage(startDate.getTime(), endDate.getTime());
      setData(r);
      let promises = [];
      windows.forEach(w => {
        promises.push(getTerminalOutage(w.startTime, w.endTime));
      });
      try {
        let responses = await Promise.all(promises);
        console.log(responses);
        setTimeWindowData(responses.map((r, i) => {
          return {
            timeWindow: windows[i],
            data: r
          };
        }));
      }
      catch (e) {
        console.error(`Error loading animation data.`);
      }
    }
    catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(loginRequest);
      }
      console.error('Error getting data.');
      console.error(e);
      setErrorMsg(e.toLocaleString());
    }
    setLoading(false);
  }

  function getTimeWindows() {
    const halfHourInMs = 1800000;
    const oneHourInMs = 3600000;
    const oneDayInMs = 86400000;
    const endTime = endDate.getTime();
    const startTime = startDate.getTime();
    const fullWindowSizeInMs = endTime - startTime;
    let windowSizeInMs = 0;
    let windows = [];
    let windowStartTime = startTime;

    if (fullWindowSizeInMs > oneDayInMs) {
      windowSizeInMs = fullWindowSizeInMs / 30;
    }
    else if (fullWindowSizeInMs > oneDayInMs / 2) {
      windowSizeInMs = oneHourInMs;
    }
    else {
      windowSizeInMs = halfHourInMs;
    }
    console.log(`Start time ${startTime}, end time: ${endTime}`);
    console.log(`Window size: ${windowSizeInMs}`);

    while (windowStartTime < endTime) {
      let window = {
        startTime: windowStartTime,
        endTime: windowStartTime + windowSizeInMs
      };
      windows.push(window);
      windowStartTime = window.endTime;
    }
    return windows;
  }

  function startAnimation() {
    if (animationInterval) {
      clearInterval(animationInterval);
    }
    let windowIdx = animationWindowIdx;
    console.log('Animation started.');
    let animationInt = setInterval(() => {
      windowIdx = windowIdx + 1;
      if (windowIdx >= timeWindowData.length) {
        windowIdx = 0;
      }
      console.log(`animation new index: ${windowIdx}`);
      setAnimationWindowIdx(windowIdx);
      setData(timeWindowData[windowIdx].data);
      setAnimated(true);
    }, 1000);
    setAnimationInterval(animationInt);
  }

  function pauseAnimation() {
    if (animationInterval) {
      console.log('Pausing animation.')
      clearInterval(animationInterval);
      setAnimated(false);
    }
    else {
      console.log('No animationInterval', animationInterval);
    }
  }

  function goToTimeWindow(i, e) {
    console.log(i, e);
    if (animated) {
      pauseAnimation();
    }
    setAnimationWindowIdx(i);
    setData(timeWindowData[i].data);
  }

  useEffect(() => {
      console.log('Getting data');
      setAnimated(false);
      setAnimationWindowIdx(0);
      getData();

      return function cleanup() {
        if (animationInterval) {
          clearInterval(animationInterval);
        }
      }
  }, [startDate, endDate]);

  let content = '';
  if (loading) {
    content = <h2 className="mt-5">Loading...</h2>;
  }
  else if(errorMsg) {
    content = <div>
      <p>There was an error getting the data</p>
      <pre>{errorMsg}</pre>
    </div>;
  }
  else {

    let animationContent = '';

    if (timeWindowData) {
      let windowStartDate = new Date(timeWindowData[animationWindowIdx].timeWindow.startTime);
      let windowEndDate = new Date(timeWindowData[animationWindowIdx].timeWindow.endTime);
      let button = '';

      if (animated) {
        button = <Button variant="primary" onClick={pauseAnimation}>Pause animation</Button>;
      }
      else {
        button = <Button variant="primary" onClick={startAnimation}>Start animation</Button>;
      }

      animationContent = <div className="animation">
        <Row>
          <Col md={3} className="mb-3">
            {button}
          </Col>
          <Col xl={9}>
            <Pagination size="sm">
              {timeWindowData.map((w, i) => {
                return <Pagination.Item key={i} active={i === animationWindowIdx} onClick={goToTimeWindow.bind(null, i)}>
                  {i + 1}
                </Pagination.Item>;
              })}
            </Pagination>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{windowStartDate.toLocaleString()} - {windowEndDate.toLocaleString()}</h3>
          </Col>
        </Row>
      </div>;
    }

    content = <>
    {animationContent}
    <MapContainer center={[38, -95]} zoom={zoom} scrollWheelZoom={false} style={{ height: '40vh'}} key={'terminalmap'}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LeafletHeatmap points={data.map(terminal => [terminal.terminalLoc.lat, terminal.terminalLoc.lon, terminal.noOfONTsDown])} />
    </MapContainer>
    </>;
  }

  return <Card className={'terminal-outage-map'}>
      <header>Terminals with all ONTs down</header>
      <section style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
        {content}
      </section>
    </Card>;
}
