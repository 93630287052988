import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { getOltSessions } from '../../utils/vqc';

export default function OltSessions () {
  const [olts, setOlts] = useState([]);

  useEffect(() => {
    getData();
    setInterval(getData, 30000);
  }, []);

  const getData = async () => {
    let data = await getOltSessions();
    let returnData = [];
    let processedOlts = [];
    for(let datum of data)
      {
        if(processedOlts.includes(datum.clli))
          {continue;}
        processedOlts.push(datum.clli);
        returnData.push(datum);
      }
    returnData.sort((a, b) => ((b.sumConnections !== a.sumConnections) ? b.sumConnections - a.sumConnections : b.clli.localeCompare(a.sumConnections)));
    setOlts(data);
  }

  const getTableRows = () => {
    let rows = olts.map((olt, i) => {
      return <tr key={`olt-session-key-${olt.clli}`}>
        <td>{olt.clli}</td>
        <td>{olt.ip}</td>
        <td>{olt.sumConnections}</td>
      </tr>;
    });
    return rows;
  }

  let content = <h3>No active sessions.</h3>;
  if (olts.length) {
    content = <table className='table table-dark table-striped table-hover'>
      <thead>
        <tr>
          <td>CLLI</td>
          <td>IP</td>
          <td>Connections</td>
        </tr>
      </thead>
      <tbody>
        {getTableRows()}
      </tbody>
    </table>;
  }
  
  return (
    
    <Card className={'top-10 olt-sessions'}>
      <header>Active OLT Sessions</header>
      <section>
        { content }
      </section>
    </Card>
  )
}

