const tr181Parameters = [
'Device.',
'Device.RootDataModelVersion',
'Device.InterfaceStackNumberOfEntries',
'Device.Services.',
'Device.DeviceInfo.',
'Device.DeviceInfo.DeviceCategory',
'Device.DeviceInfo.Manufacturer',
'Device.DeviceInfo.ManufacturerOUI',
'Device.DeviceInfo.SupportedDataModelNumberOfEntries',
'Device.DeviceInfo.ModelName',
'Device.DeviceInfo.ModelNumber',
'Device.DeviceInfo.Description',
'Device.DeviceInfo.ProductClass',
'Device.DeviceInfo.SerialNumber',
'Device.DeviceInfo.HardwareVersion',
'Device.DeviceInfo.SoftwareVersion',
'Device.DeviceInfo.ActiveFirmwareImage',
'Device.DeviceInfo.BootFirmwareImage',
'Device.DeviceInfo.AdditionalHardwareVersion',
'Device.DeviceInfo.AdditionalSoftwareVersion',
'Device.DeviceInfo.ProvisioningCode',
'Device.DeviceInfo.UpTime',
'Device.DeviceInfo.FirstUseDate',
'Device.DeviceInfo.FirmwareImageNumberOfEntries',
'Device.DeviceInfo.VendorConfigFileNumberOfEntries',
'Device.DeviceInfo.ProcessorNumberOfEntries',
'Device.DeviceInfo.VendorLogFileNumberOfEntries',
'Device.DeviceInfo.LocationNumberOfEntries',
'Device.DeviceInfo.DeviceImageNumberOfEntries',
'Device.DeviceInfo.VendorConfigFile.{i}.',
'Device.DeviceInfo.VendorConfigFile.{i}.Alias',
'Device.DeviceInfo.VendorConfigFile.{i}.Name',
'Device.DeviceInfo.VendorConfigFile.{i}.Version',
'Device.DeviceInfo.VendorConfigFile.{i}.Date',
'Device.DeviceInfo.VendorConfigFile.{i}.Description',
'Device.DeviceInfo.VendorConfigFile.{i}.UseForBackupRestore',
'Device.DeviceInfo.MemoryStatus.',
'Device.DeviceInfo.MemoryStatus.Total',
'Device.DeviceInfo.MemoryStatus.Free',
'Device.DeviceInfo.MemoryStatus.TotalPersistent',
'Device.DeviceInfo.MemoryStatus.FreePersistent',
'Device.DeviceInfo.ProcessStatus.',
'Device.DeviceInfo.ProcessStatus.CPUUsage',
'Device.DeviceInfo.ProcessStatus.ProcessNumberOfEntries',
'Device.DeviceInfo.ProcessStatus.Process.{i}.',
'Device.DeviceInfo.ProcessStatus.Process.{i}.PID',
'Device.DeviceInfo.ProcessStatus.Process.{i}.Command',
'Device.DeviceInfo.ProcessStatus.Process.{i}.Size',
'Device.DeviceInfo.ProcessStatus.Process.{i}.Priority',
'Device.DeviceInfo.ProcessStatus.Process.{i}.CPUTime',
'Device.DeviceInfo.ProcessStatus.Process.{i}.State',
'Device.DeviceInfo.SupportedDataModel.{i}.',
'Device.DeviceInfo.SupportedDataModel.{i}.Alias',
'Device.DeviceInfo.SupportedDataModel.{i}.URL',
'Device.DeviceInfo.SupportedDataModel.{i}.UUID',
'Device.DeviceInfo.SupportedDataModel.{i}.URN',
'Device.DeviceInfo.SupportedDataModel.{i}.Features',
'Device.DeviceInfo.ProxierInfo.',
'Device.DeviceInfo.ProxierInfo.ManufacturerOUI',
'Device.DeviceInfo.ProxierInfo.ProductClass',
'Device.DeviceInfo.ProxierInfo.SerialNumber',
'Device.DeviceInfo.ProxierInfo.ProxyProtocol',
'Device.DeviceInfo.TemperatureStatus.',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensorNumberOfEntries',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.Alias',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.Enable',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.Status',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.Reset',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.ResetTime',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.Name',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.Value',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.LastUpdate',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.MinValue',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.MinTime',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.MaxValue',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.MaxTime',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.LowAlarmValue',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.LowAlarmTime',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.HighAlarmValue',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.PollingInterval',
'Device.DeviceInfo.TemperatureStatus.TemperatureSensor.{i}.HighAlarmTime',
'Device.DeviceInfo.NetworkProperties.',
'Device.DeviceInfo.NetworkProperties.MaxTCPWindowSize',
'Device.DeviceInfo.NetworkProperties.TCPImplementation',
'Device.DeviceInfo.Processor.{i}.',
'Device.DeviceInfo.Processor.{i}.Alias',
'Device.DeviceInfo.Processor.{i}.Architecture',
'Device.DeviceInfo.VendorLogFile.{i}.',
'Device.DeviceInfo.VendorLogFile.{i}.Alias',
'Device.DeviceInfo.VendorLogFile.{i}.Name',
'Device.DeviceInfo.VendorLogFile.{i}.MaximumSize',
'Device.DeviceInfo.VendorLogFile.{i}.Persistent',
'Device.DeviceInfo.Location.{i}.',
'Device.DeviceInfo.Location.{i}.Source',
'Device.DeviceInfo.Location.{i}.AcquiredTime',
'Device.DeviceInfo.Location.{i}.ExternalSource',
'Device.DeviceInfo.Location.{i}.ExternalProtocol',
'Device.DeviceInfo.Location.{i}.DataObject',
'Device.DeviceInfo.DeviceImageFile.{i}.',
'Device.DeviceInfo.DeviceImageFile.{i}.Alias',
'Device.DeviceInfo.DeviceImageFile.{i}.Location',
'Device.DeviceInfo.DeviceImageFile.{i}.Image',
'Device.DeviceInfo.FirmwareImage.{i}.',
'Device.DeviceInfo.FirmwareImage.{i}.Alias',
'Device.DeviceInfo.FirmwareImage.{i}.Name',
'Device.DeviceInfo.FirmwareImage.{i}.Version',
'Device.DeviceInfo.FirmwareImage.{i}.Available',
'Device.DeviceInfo.FirmwareImage.{i}.Status',
'Device.DeviceInfo.FirmwareImage.{i}.BootFailureLog',
'Device.ManagementServer.',
'Device.ManagementServer.EnableCWMP',
'Device.ManagementServer.URL',
'Device.ManagementServer.Username',
'Device.ManagementServer.Password',
'Device.ManagementServer.ScheduleReboot',
'Device.ManagementServer.DelayReboot',
'Device.ManagementServer.PeriodicInformEnable',
'Device.ManagementServer.PeriodicInformInterval',
'Device.ManagementServer.PeriodicInformTime',
'Device.ManagementServer.ParameterKey',
'Device.ManagementServer.ConnectionRequestURL',
'Device.ManagementServer.ConnectionRequestUsername',
'Device.ManagementServer.ConnectionRequestPassword',
'Device.ManagementServer.UpgradesManaged',
'Device.ManagementServer.KickURL',
'Device.ManagementServer.DownloadProgressURL',
'Device.ManagementServer.DefaultActiveNotificationThrottle',
'Device.ManagementServer.CWMPRetryMinimumWaitInterval',
'Device.ManagementServer.CWMPRetryIntervalMultiplier',
'Device.ManagementServer.HTTPConnectionRequestEnable',
'Device.ManagementServer.UDPConnectionRequestAddress',
'Device.ManagementServer.STUNEnable',
'Device.ManagementServer.STUNServerAddress',
'Device.ManagementServer.STUNServerPort',
'Device.ManagementServer.STUNUsername',
'Device.ManagementServer.STUNPassword',
'Device.ManagementServer.STUNMaximumKeepAlivePeriod',
'Device.ManagementServer.STUNMinimumKeepAlivePeriod',
'Device.ManagementServer.NATDetected',
'Device.ManagementServer.AliasBasedAddressing',
'Device.ManagementServer.InstanceMode',
'Device.ManagementServer.AutoCreateInstances',
'Device.ManagementServer.ManageableDeviceNumberOfEntries',
'Device.ManagementServer.ManageableDeviceNotificationLimit',
'Device.ManagementServer.EmbeddedDeviceNumberOfEntries',
'Device.ManagementServer.VirtualDeviceNumberOfEntries',
'Device.ManagementServer.SupportedConnReqMethods',
'Device.ManagementServer.ConnReqXMPPConnection',
'Device.ManagementServer.ConnReqAllowedJabberIDs',
'Device.ManagementServer.ConnReqJabberID',
'Device.ManagementServer.HTTPCompressionSupported',
'Device.ManagementServer.HTTPCompression',
'Device.ManagementServer.LightweightNotificationProtocolsSupported',
'Device.ManagementServer.LightweightNotificationProtocolsUsed',
'Device.ManagementServer.LightweightNotificationTriggerInterval',
'Device.ManagementServer.LightweightNotificationTriggerTime',
'Device.ManagementServer.UDPLightweightNotificationHost',
'Device.ManagementServer.UDPLightweightNotificationPort',
'Device.ManagementServer.InstanceWildcardsSupported',
'Device.ManagementServer.InformParameterNumberOfEntries',
'Device.ManagementServer.ManageableDevice.{i}.',
'Device.ManagementServer.ManageableDevice.{i}.Alias',
'Device.ManagementServer.ManageableDevice.{i}.ManufacturerOUI',
'Device.ManagementServer.ManageableDevice.{i}.SerialNumber',
'Device.ManagementServer.ManageableDevice.{i}.ProductClass',
'Device.ManagementServer.ManageableDevice.{i}.Host',
'Device.ManagementServer.AutonomousTransferCompletePolicy.',
'Device.ManagementServer.AutonomousTransferCompletePolicy.Enable',
'Device.ManagementServer.AutonomousTransferCompletePolicy.TransferTypeFilter',
'Device.ManagementServer.AutonomousTransferCompletePolicy.ResultTypeFilter',
'Device.ManagementServer.AutonomousTransferCompletePolicy.FileTypeFilter',
'Device.ManagementServer.DownloadAvailability.',
'Device.ManagementServer.DownloadAvailability.Announcement.',
'Device.ManagementServer.DownloadAvailability.Announcement.Enable',
'Device.ManagementServer.DownloadAvailability.Announcement.Status',
'Device.ManagementServer.DownloadAvailability.Announcement.GroupNumberOfEntries',
'Device.ManagementServer.DownloadAvailability.Announcement.Group.{i}.',
'Device.ManagementServer.DownloadAvailability.Announcement.Group.{i}.Alias',
'Device.ManagementServer.DownloadAvailability.Announcement.Group.{i}.Enable',
'Device.ManagementServer.DownloadAvailability.Announcement.Group.{i}.Status',
'Device.ManagementServer.DownloadAvailability.Announcement.Group.{i}.URL',
'Device.ManagementServer.DownloadAvailability.Query.',
'Device.ManagementServer.DownloadAvailability.Query.Enable',
'Device.ManagementServer.DownloadAvailability.Query.Status',
'Device.ManagementServer.DownloadAvailability.Query.URL',
'Device.ManagementServer.DUStateChangeComplPolicy.',
'Device.ManagementServer.DUStateChangeComplPolicy.Enable',
'Device.ManagementServer.DUStateChangeComplPolicy.OperationTypeFilter',
'Device.ManagementServer.DUStateChangeComplPolicy.ResultTypeFilter',
'Device.ManagementServer.DUStateChangeComplPolicy.FaultCodeFilter',
'Device.ManagementServer.EmbeddedDevice.{i}.',
'Device.ManagementServer.EmbeddedDevice.{i}.ControllerID',
'Device.ManagementServer.EmbeddedDevice.{i}.ProxiedDeviceID',
'Device.ManagementServer.EmbeddedDevice.{i}.Reference',
'Device.ManagementServer.EmbeddedDevice.{i}.SupportedDataModel',
'Device.ManagementServer.EmbeddedDevice.{i}.Host',
'Device.ManagementServer.EmbeddedDevice.{i}.ProxyProtocol',
'Device.ManagementServer.EmbeddedDevice.{i}.ProxyProtocolReference',
'Device.ManagementServer.EmbeddedDevice.{i}.DiscoveryProtocol',
'Device.ManagementServer.EmbeddedDevice.{i}.DiscoveryProtocolReference',
'Device.ManagementServer.EmbeddedDevice.{i}.CommandProcessed',
'Device.ManagementServer.EmbeddedDevice.{i}.CommandProcessingErrMsg',
'Device.ManagementServer.EmbeddedDevice.{i}.LastSyncTime',
'Device.ManagementServer.VirtualDevice.{i}.',
'Device.ManagementServer.VirtualDevice.{i}.ManufacturerOUI',
'Device.ManagementServer.VirtualDevice.{i}.ProductClass',
'Device.ManagementServer.VirtualDevice.{i}.SerialNumber',
'Device.ManagementServer.VirtualDevice.{i}.Host',
'Device.ManagementServer.VirtualDevice.{i}.ProxyProtocol',
'Device.ManagementServer.VirtualDevice.{i}.ProxyProtocolReference',
'Device.ManagementServer.VirtualDevice.{i}.DiscoveryProtocol',
'Device.ManagementServer.VirtualDevice.{i}.DiscoveryProtocolReference',
'Device.ManagementServer.StandbyPolicy.',
'Device.ManagementServer.StandbyPolicy.CRUnawarenessMaxDuration',
'Device.ManagementServer.StandbyPolicy.MaxMissedPeriodic',
'Device.ManagementServer.StandbyPolicy.NotifyMissedScheduled',
'Device.ManagementServer.StandbyPolicy.NetworkAwarenessCapable',
'Device.ManagementServer.StandbyPolicy.SelfTimerCapable',
'Device.ManagementServer.StandbyPolicy.CRAwarenessRequested',
'Device.ManagementServer.StandbyPolicy.PeriodicAwarenessRequested',
'Device.ManagementServer.StandbyPolicy.ScheduledAwarenessRequested',
'Device.ManagementServer.InformParameter.{i}.',
'Device.ManagementServer.InformParameter.{i}.Enable',
'Device.ManagementServer.InformParameter.{i}.Alias',
'Device.ManagementServer.InformParameter.{i}.ParameterName',
'Device.ManagementServer.InformParameter.{i}.EventList',
'Device.ManagementServer.HeartbeatPolicy.',
'Device.ManagementServer.HeartbeatPolicy.Enable',
'Device.ManagementServer.HeartbeatPolicy.ReportingInterval',
'Device.ManagementServer.HeartbeatPolicy.InitiationTime',
'Device.GatewayInfo.',
'Device.GatewayInfo.ManufacturerOUI',
'Device.GatewayInfo.ProductClass',
'Device.GatewayInfo.SerialNumber',
'Device.Time.',
'Device.Time.Enable',
'Device.Time.Status',
'Device.Time.NTPServer1',
'Device.Time.NTPServer2',
'Device.Time.NTPServer3',
'Device.Time.NTPServer4',
'Device.Time.NTPServer5',
'Device.Time.CurrentLocalTime',
'Device.Time.LocalTimeZone',
'Device.UserInterface.',
'Device.UserInterface.Enable',
'Device.UserInterface.PasswordRequired',
'Device.UserInterface.PasswordUserSelectable',
'Device.UserInterface.PasswordReset',
'Device.UserInterface.UpgradeAvailable',
'Device.UserInterface.WarrantyDate',
'Device.UserInterface.ISPName',
'Device.UserInterface.ISPHelpDesk',
'Device.UserInterface.ISPHomePage',
'Device.UserInterface.ISPHelpPage',
'Device.UserInterface.ISPLogo',
'Device.UserInterface.ISPLogoSize',
'Device.UserInterface.ISPMailServer',
'Device.UserInterface.ISPNewsServer',
'Device.UserInterface.TextColor',
'Device.UserInterface.BackgroundColor',
'Device.UserInterface.ButtonColor',
'Device.UserInterface.ButtonTextColor',
'Device.UserInterface.AutoUpdateServer',
'Device.UserInterface.UserUpdateServer',
'Device.UserInterface.AvailableLanguages',
'Device.UserInterface.CurrentLanguage',
'Device.UserInterface.RemoteAccess.',
'Device.UserInterface.RemoteAccess.Enable',
'Device.UserInterface.RemoteAccess.Port',
'Device.UserInterface.RemoteAccess.SupportedProtocols',
'Device.UserInterface.RemoteAccess.Protocol',
'Device.UserInterface.LocalDisplay.',
'Device.UserInterface.LocalDisplay.Movable',
'Device.UserInterface.LocalDisplay.Resizable',
'Device.UserInterface.LocalDisplay.PosX',
'Device.UserInterface.LocalDisplay.PosY',
'Device.UserInterface.LocalDisplay.Width',
'Device.UserInterface.LocalDisplay.Height',
'Device.UserInterface.LocalDisplay.DisplayWidth',
'Device.UserInterface.LocalDisplay.DisplayHeight',
'Device.UserInterface.Messages.',
'Device.UserInterface.Messages.Enable',
'Device.UserInterface.Messages.Title',
'Device.UserInterface.Messages.SubTitle',
'Device.UserInterface.Messages.Text',
'Device.UserInterface.Messages.IconType',
'Device.UserInterface.Messages.MessageColor',
'Device.UserInterface.Messages.BackgroundColor',
'Device.UserInterface.Messages.TitleColor',
'Device.UserInterface.Messages.SubTitleColor',
'Device.UserInterface.Messages.RequestedNumberOfRepetitions',
'Device.UserInterface.Messages.ExecutedNumberOfRepetitions',
'Device.InterfaceStack.{i}.',
'Device.InterfaceStack.{i}.Alias',
'Device.InterfaceStack.{i}.HigherLayer',
'Device.InterfaceStack.{i}.LowerLayer',
'Device.InterfaceStack.{i}.HigherAlias',
'Device.InterfaceStack.{i}.LowerAlias',
'Device.DSL.',
'Device.DSL.LineNumberOfEntries',
'Device.DSL.ChannelNumberOfEntries',
'Device.DSL.BondingGroupNumberOfEntries',
'Device.DSL.Line.{i}.',
'Device.DSL.Line.{i}.Enable',
'Device.DSL.Line.{i}.EnableDataGathering',
'Device.DSL.Line.{i}.Status',
'Device.DSL.Line.{i}.Alias',
'Device.DSL.Line.{i}.Name',
'Device.DSL.Line.{i}.LastChange',
'Device.DSL.Line.{i}.LowerLayers',
'Device.DSL.Line.{i}.Upstream',
'Device.DSL.Line.{i}.FirmwareVersion',
'Device.DSL.Line.{i}.LinkStatus',
'Device.DSL.Line.{i}.StandardsSupported',
'Device.DSL.Line.{i}.XTSE',
'Device.DSL.Line.{i}.StandardUsed',
'Device.DSL.Line.{i}.XTSUsed',
'Device.DSL.Line.{i}.LineEncoding',
'Device.DSL.Line.{i}.AllowedProfiles',
'Device.DSL.Line.{i}.CurrentProfile',
'Device.DSL.Line.{i}.PowerManagementState',
'Device.DSL.Line.{i}.SuccessFailureCause',
'Device.DSL.Line.{i}.UPBOKLER',
'Device.DSL.Line.{i}.UPBOKLEPb',
'Device.DSL.Line.{i}.UPBOKLERPb',
'Device.DSL.Line.{i}.RXTHRSHds',
'Device.DSL.Line.{i}.ACTRAMODEds',
'Device.DSL.Line.{i}.ACTRAMODEus',
'Device.DSL.Line.{i}.ACTINPROCds',
'Device.DSL.Line.{i}.ACTINPROCus',
'Device.DSL.Line.{i}.SNRMROCds',
'Device.DSL.Line.{i}.SNRMROCus',
'Device.DSL.Line.{i}.LastStateTransmittedDownstream',
'Device.DSL.Line.{i}.LastStateTransmittedUpstream',
'Device.DSL.Line.{i}.UPBOKLE',
'Device.DSL.Line.{i}.MREFPSDds',
'Device.DSL.Line.{i}.MREFPSDus',
'Device.DSL.Line.{i}.LIMITMASK',
'Device.DSL.Line.{i}.US0MASK',
'Device.DSL.Line.{i}.TRELLISds',
'Device.DSL.Line.{i}.TRELLISus',
'Device.DSL.Line.{i}.ACTSNRMODEds',
'Device.DSL.Line.{i}.ACTSNRMODEus',
'Device.DSL.Line.{i}.VirtualNoisePSDds',
'Device.DSL.Line.{i}.VirtualNoisePSDus',
'Device.DSL.Line.{i}.ACTUALCE',
'Device.DSL.Line.{i}.LineNumber',
'Device.DSL.Line.{i}.UpstreamMaxBitRate',
'Device.DSL.Line.{i}.DownstreamMaxBitRate',
'Device.DSL.Line.{i}.UpstreamNoiseMargin',
'Device.DSL.Line.{i}.DownstreamNoiseMargin',
'Device.DSL.Line.{i}.SNRMpbus',
'Device.DSL.Line.{i}.SNRMpbds',
'Device.DSL.Line.{i}.INMIATOds',
'Device.DSL.Line.{i}.INMIATSds',
'Device.DSL.Line.{i}.INMCCds',
'Device.DSL.Line.{i}.INMINPEQMODEds',
'Device.DSL.Line.{i}.UpstreamAttenuation',
'Device.DSL.Line.{i}.DownstreamAttenuation',
'Device.DSL.Line.{i}.UpstreamPower',
'Device.DSL.Line.{i}.DownstreamPower',
'Device.DSL.Line.{i}.XTURVendor',
'Device.DSL.Line.{i}.XTURCountry',
'Device.DSL.Line.{i}.XTURANSIStd',
'Device.DSL.Line.{i}.XTURANSIRev',
'Device.DSL.Line.{i}.XTUCVendor',
'Device.DSL.Line.{i}.XTUCCountry',
'Device.DSL.Line.{i}.XTUCANSIStd',
'Device.DSL.Line.{i}.XTUCANSIRev',
'Device.DSL.Line.{i}.Stats.',
'Device.DSL.Line.{i}.Stats.BytesSent',
'Device.DSL.Line.{i}.Stats.BytesReceived',
'Device.DSL.Line.{i}.Stats.PacketsSent',
'Device.DSL.Line.{i}.Stats.PacketsReceived',
'Device.DSL.Line.{i}.Stats.ErrorsSent',
'Device.DSL.Line.{i}.Stats.ErrorsReceived',
'Device.DSL.Line.{i}.Stats.DiscardPacketsSent',
'Device.DSL.Line.{i}.Stats.DiscardPacketsReceived',
'Device.DSL.Line.{i}.Stats.TotalStart',
'Device.DSL.Line.{i}.Stats.ShowtimeStart',
'Device.DSL.Line.{i}.Stats.LastShowtimeStart',
'Device.DSL.Line.{i}.Stats.CurrentDayStart',
'Device.DSL.Line.{i}.Stats.QuarterHourStart',
'Device.DSL.Line.{i}.Stats.Total.',
'Device.DSL.Line.{i}.Stats.Total.ErroredSecs',
'Device.DSL.Line.{i}.Stats.Total.SeverelyErroredSecs',
'Device.DSL.Line.{i}.Stats.Showtime.',
'Device.DSL.Line.{i}.Stats.Showtime.ErroredSecs',
'Device.DSL.Line.{i}.Stats.Showtime.SeverelyErroredSecs',
'Device.DSL.Line.{i}.Stats.LastShowtime.',
'Device.DSL.Line.{i}.Stats.LastShowtime.ErroredSecs',
'Device.DSL.Line.{i}.Stats.LastShowtime.SeverelyErroredSecs',
'Device.DSL.Line.{i}.Stats.CurrentDay.',
'Device.DSL.Line.{i}.Stats.CurrentDay.ErroredSecs',
'Device.DSL.Line.{i}.Stats.CurrentDay.SeverelyErroredSecs',
'Device.DSL.Line.{i}.Stats.QuarterHour.',
'Device.DSL.Line.{i}.Stats.QuarterHour.ErroredSecs',
'Device.DSL.Line.{i}.Stats.QuarterHour.SeverelyErroredSecs',
'Device.DSL.Line.{i}.TestParams.',
'Device.DSL.Line.{i}.TestParams.HLOGGds',
'Device.DSL.Line.{i}.TestParams.HLOGGus',
'Device.DSL.Line.{i}.TestParams.HLOGpsds',
'Device.DSL.Line.{i}.TestParams.HLOGpsus',
'Device.DSL.Line.{i}.TestParams.HLOGMTds',
'Device.DSL.Line.{i}.TestParams.HLOGMTus',
'Device.DSL.Line.{i}.TestParams.QLNGds',
'Device.DSL.Line.{i}.TestParams.QLNGus',
'Device.DSL.Line.{i}.TestParams.QLNpsds',
'Device.DSL.Line.{i}.TestParams.QLNpsus',
'Device.DSL.Line.{i}.TestParams.QLNMTds',
'Device.DSL.Line.{i}.TestParams.QLNMTus',
'Device.DSL.Line.{i}.TestParams.SNRGds',
'Device.DSL.Line.{i}.TestParams.SNRGus',
'Device.DSL.Line.{i}.TestParams.SNRpsds',
'Device.DSL.Line.{i}.TestParams.SNRpsus',
'Device.DSL.Line.{i}.TestParams.SNRMTds',
'Device.DSL.Line.{i}.TestParams.SNRMTus',
'Device.DSL.Line.{i}.TestParams.LATNds',
'Device.DSL.Line.{i}.TestParams.LATNus',
'Device.DSL.Line.{i}.TestParams.SATNds',
'Device.DSL.Line.{i}.TestParams.SATNus',
'Device.DSL.Line.{i}.DataGathering.',
'Device.DSL.Line.{i}.DataGathering.LoggingDepthR',
'Device.DSL.Line.{i}.DataGathering.ActLoggingDepthReportingR',
'Device.DSL.Line.{i}.DataGathering.EventTraceBufferR',
'Device.DSL.Channel.{i}.',
'Device.DSL.Channel.{i}.Enable',
'Device.DSL.Channel.{i}.Status',
'Device.DSL.Channel.{i}.Alias',
'Device.DSL.Channel.{i}.Name',
'Device.DSL.Channel.{i}.LastChange',
'Device.DSL.Channel.{i}.LowerLayers',
'Device.DSL.Channel.{i}.LinkEncapsulationSupported',
'Device.DSL.Channel.{i}.LinkEncapsulationUsed',
'Device.DSL.Channel.{i}.LPATH',
'Device.DSL.Channel.{i}.INTLVDEPTH',
'Device.DSL.Channel.{i}.INTLVBLOCK',
'Device.DSL.Channel.{i}.ActualInterleavingDelay',
'Device.DSL.Channel.{i}.ACTINP',
'Device.DSL.Channel.{i}.INPREPORT',
'Device.DSL.Channel.{i}.NFEC',
'Device.DSL.Channel.{i}.RFEC',
'Device.DSL.Channel.{i}.LSYMB',
'Device.DSL.Channel.{i}.UpstreamCurrRate',
'Device.DSL.Channel.{i}.DownstreamCurrRate',
'Device.DSL.Channel.{i}.ACTNDR',
'Device.DSL.Channel.{i}.ACTINPREIN',
'Device.DSL.Channel.{i}.Stats.',
'Device.DSL.Channel.{i}.Stats.BytesSent',
'Device.DSL.Channel.{i}.Stats.BytesReceived',
'Device.DSL.Channel.{i}.Stats.PacketsSent',
'Device.DSL.Channel.{i}.Stats.PacketsReceived',
'Device.DSL.Channel.{i}.Stats.ErrorsSent',
'Device.DSL.Channel.{i}.Stats.ErrorsReceived',
'Device.DSL.Channel.{i}.Stats.DiscardPacketsSent',
'Device.DSL.Channel.{i}.Stats.DiscardPacketsReceived',
'Device.DSL.Channel.{i}.Stats.TotalStart',
'Device.DSL.Channel.{i}.Stats.ShowtimeStart',
'Device.DSL.Channel.{i}.Stats.LastShowtimeStart',
'Device.DSL.Channel.{i}.Stats.CurrentDayStart',
'Device.DSL.Channel.{i}.Stats.QuarterHourStart',
'Device.DSL.Channel.{i}.Stats.Total.',
'Device.DSL.Channel.{i}.Stats.Total.XTURFECErrors',
'Device.DSL.Channel.{i}.Stats.Total.XTUCFECErrors',
'Device.DSL.Channel.{i}.Stats.Total.XTURHECErrors',
'Device.DSL.Channel.{i}.Stats.Total.XTUCHECErrors',
'Device.DSL.Channel.{i}.Stats.Total.XTURCRCErrors',
'Device.DSL.Channel.{i}.Stats.Total.XTUCCRCErrors',
'Device.DSL.Channel.{i}.Stats.Showtime.',
'Device.DSL.Channel.{i}.Stats.Showtime.XTURFECErrors',
'Device.DSL.Channel.{i}.Stats.Showtime.XTUCFECErrors',
'Device.DSL.Channel.{i}.Stats.Showtime.XTURHECErrors',
'Device.DSL.Channel.{i}.Stats.Showtime.XTUCHECErrors',
'Device.DSL.Channel.{i}.Stats.Showtime.XTURCRCErrors',
'Device.DSL.Channel.{i}.Stats.Showtime.XTUCCRCErrors',
'Device.DSL.Channel.{i}.Stats.LastShowtime.',
'Device.DSL.Channel.{i}.Stats.LastShowtime.XTURFECErrors',
'Device.DSL.Channel.{i}.Stats.LastShowtime.XTUCFECErrors',
'Device.DSL.Channel.{i}.Stats.LastShowtime.XTURHECErrors',
'Device.DSL.Channel.{i}.Stats.LastShowtime.XTUCHECErrors',
'Device.DSL.Channel.{i}.Stats.LastShowtime.XTURCRCErrors',
'Device.DSL.Channel.{i}.Stats.LastShowtime.XTUCCRCErrors',
'Device.DSL.Channel.{i}.Stats.CurrentDay.',
'Device.DSL.Channel.{i}.Stats.CurrentDay.XTURFECErrors',
'Device.DSL.Channel.{i}.Stats.CurrentDay.XTUCFECErrors',
'Device.DSL.Channel.{i}.Stats.CurrentDay.XTURHECErrors',
'Device.DSL.Channel.{i}.Stats.CurrentDay.XTUCHECErrors',
'Device.DSL.Channel.{i}.Stats.CurrentDay.XTURCRCErrors',
'Device.DSL.Channel.{i}.Stats.CurrentDay.XTUCCRCErrors',
'Device.DSL.Channel.{i}.Stats.QuarterHour.',
'Device.DSL.Channel.{i}.Stats.QuarterHour.XTURFECErrors',
'Device.DSL.Channel.{i}.Stats.QuarterHour.XTUCFECErrors',
'Device.DSL.Channel.{i}.Stats.QuarterHour.XTURHECErrors',
'Device.DSL.Channel.{i}.Stats.QuarterHour.XTUCHECErrors',
'Device.DSL.Channel.{i}.Stats.QuarterHour.XTURCRCErrors',
'Device.DSL.Channel.{i}.Stats.QuarterHour.XTUCCRCErrors',
'Device.DSL.BondingGroup.{i}.',
'Device.DSL.BondingGroup.{i}.Enable',
'Device.DSL.BondingGroup.{i}.Status',
'Device.DSL.BondingGroup.{i}.Alias',
'Device.DSL.BondingGroup.{i}.Name',
'Device.DSL.BondingGroup.{i}.LastChange',
'Device.DSL.BondingGroup.{i}.LowerLayers',
'Device.DSL.BondingGroup.{i}.GroupStatus',
'Device.DSL.BondingGroup.{i}.GroupID',
'Device.DSL.BondingGroup.{i}.BondSchemesSupported',
'Device.DSL.BondingGroup.{i}.BondScheme',
'Device.DSL.BondingGroup.{i}.GroupCapacity',
'Device.DSL.BondingGroup.{i}.RunningTime',
'Device.DSL.BondingGroup.{i}.TargetUpRate',
'Device.DSL.BondingGroup.{i}.TargetDownRate',
'Device.DSL.BondingGroup.{i}.ThreshLowUpRate',
'Device.DSL.BondingGroup.{i}.ThreshLowDownRate',
'Device.DSL.BondingGroup.{i}.UpstreamDifferentialDelayTolerance',
'Device.DSL.BondingGroup.{i}.DownstreamDifferentialDelayTolerance',
'Device.DSL.BondingGroup.{i}.BondedChannelNumberOfEntries',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Alias',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Channel',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.UnderflowErrorsSent',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.CRCErrorsReceived',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.AlignmentErrorsReceived',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.ShortPacketsReceived',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.LongPacketsReceived',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.OverflowErrorsReceived',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.PauseFramesReceived',
'Device.DSL.BondingGroup.{i}.BondedChannel.{i}.Ethernet.Stats.FramesDropped',
'Device.DSL.BondingGroup.{i}.Stats.',
'Device.DSL.BondingGroup.{i}.Stats.BytesSent',
'Device.DSL.BondingGroup.{i}.Stats.BytesReceived',
'Device.DSL.BondingGroup.{i}.Stats.PacketsSent',
'Device.DSL.BondingGroup.{i}.Stats.PacketsReceived',
'Device.DSL.BondingGroup.{i}.Stats.ErrorsSent',
'Device.DSL.BondingGroup.{i}.Stats.ErrorsReceived',
'Device.DSL.BondingGroup.{i}.Stats.UnicastPacketsSent',
'Device.DSL.BondingGroup.{i}.Stats.UnicastPacketsReceived',
'Device.DSL.BondingGroup.{i}.Stats.DiscardPacketsSent',
'Device.DSL.BondingGroup.{i}.Stats.DiscardPacketsReceived',
'Device.DSL.BondingGroup.{i}.Stats.MulticastPacketsSent',
'Device.DSL.BondingGroup.{i}.Stats.MulticastPacketsReceived',
'Device.DSL.BondingGroup.{i}.Stats.BroadcastPacketsSent',
'Device.DSL.BondingGroup.{i}.Stats.BroadcastPacketsReceived',
'Device.DSL.BondingGroup.{i}.Stats.UnknownProtoPacketsReceived',
'Device.DSL.BondingGroup.{i}.Stats.TotalStart',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDayStart',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHourStart',
'Device.DSL.BondingGroup.{i}.Stats.Total.',
'Device.DSL.BondingGroup.{i}.Stats.Total.FailureReasons',
'Device.DSL.BondingGroup.{i}.Stats.Total.UpstreamRate',
'Device.DSL.BondingGroup.{i}.Stats.Total.DownstreamRate',
'Device.DSL.BondingGroup.{i}.Stats.Total.UpstreamPacketLoss',
'Device.DSL.BondingGroup.{i}.Stats.Total.DownstreamPacketLoss',
'Device.DSL.BondingGroup.{i}.Stats.Total.UpstreamDifferentialDelay',
'Device.DSL.BondingGroup.{i}.Stats.Total.DownstreamDifferentialDelay',
'Device.DSL.BondingGroup.{i}.Stats.Total.FailureCount',
'Device.DSL.BondingGroup.{i}.Stats.Total.ErroredSeconds',
'Device.DSL.BondingGroup.{i}.Stats.Total.SeverelyErroredSeconds',
'Device.DSL.BondingGroup.{i}.Stats.Total.UnavailableSeconds',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.FailureReasons',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.UpstreamRate',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.DownstreamRate',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.UpstreamPacketLoss',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.DownstreamPacketLoss',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.UpstreamDifferentialDelay',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.DownstreamDifferentialDelay',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.FailureCount',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.ErroredSeconds',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.SeverelyErroredSeconds',
'Device.DSL.BondingGroup.{i}.Stats.CurrentDay.UnavailableSeconds',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.FailureReasons',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.UpstreamRate',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.DownstreamRate',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.UpstreamPacketLoss',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.DownstreamPacketLoss',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.UpstreamDifferentialDelay',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.DownstreamDifferentialDelay',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.FailureCount',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.ErroredSeconds',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.SeverelyErroredSeconds',
'Device.DSL.BondingGroup.{i}.Stats.QuarterHour.UnavailableSeconds',
'Device.DSL.BondingGroup.{i}.Ethernet.',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFErrors',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFSmallFragments',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFLargeFragments',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFBadFragments',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFLostFragments',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFLateFragments',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFLostStarts',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFLostEnds',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PAFOverflows',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.PauseFramesSent',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.CRCErrorsReceived',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.AlignmentErrorsReceived',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.ShortPacketsReceived',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.LongPacketsReceived',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.OverflowErrorsReceived',
'Device.DSL.BondingGroup.{i}.Ethernet.Stats.FramesDropped',
'Device.DSL.Diagnostics.',
'Device.DSL.Diagnostics.ADSLLineTest.',
'Device.DSL.Diagnostics.ADSLLineTest.DiagnosticsState',
'Device.DSL.Diagnostics.ADSLLineTest.Interface',
'Device.DSL.Diagnostics.ADSLLineTest.ACTPSDds',
'Device.DSL.Diagnostics.ADSLLineTest.ACTPSDus',
'Device.DSL.Diagnostics.ADSLLineTest.ACTATPds',
'Device.DSL.Diagnostics.ADSLLineTest.ACTATPus',
'Device.DSL.Diagnostics.ADSLLineTest.HLINSCds',
'Device.DSL.Diagnostics.ADSLLineTest.HLINSCus',
'Device.DSL.Diagnostics.ADSLLineTest.HLINGds',
'Device.DSL.Diagnostics.ADSLLineTest.HLINGus',
'Device.DSL.Diagnostics.ADSLLineTest.HLOGGds',
'Device.DSL.Diagnostics.ADSLLineTest.HLOGGus',
'Device.DSL.Diagnostics.ADSLLineTest.HLOGpsds',
'Device.DSL.Diagnostics.ADSLLineTest.HLOGpsus',
'Device.DSL.Diagnostics.ADSLLineTest.HLOGMTds',
'Device.DSL.Diagnostics.ADSLLineTest.HLOGMTus',
'Device.DSL.Diagnostics.ADSLLineTest.LATNpbds',
'Device.DSL.Diagnostics.ADSLLineTest.LATNpbus',
'Device.DSL.Diagnostics.ADSLLineTest.SATNds',
'Device.DSL.Diagnostics.ADSLLineTest.SATNus',
'Device.DSL.Diagnostics.ADSLLineTest.HLINpsds',
'Device.DSL.Diagnostics.ADSLLineTest.HLINpsus',
'Device.DSL.Diagnostics.ADSLLineTest.QLNGds',
'Device.DSL.Diagnostics.ADSLLineTest.QLNGus',
'Device.DSL.Diagnostics.ADSLLineTest.QLNpsds',
'Device.DSL.Diagnostics.ADSLLineTest.QLNpsus',
'Device.DSL.Diagnostics.ADSLLineTest.QLNMTds',
'Device.DSL.Diagnostics.ADSLLineTest.QLNMTus',
'Device.DSL.Diagnostics.ADSLLineTest.SNRGds',
'Device.DSL.Diagnostics.ADSLLineTest.SNRGus',
'Device.DSL.Diagnostics.ADSLLineTest.SNRpsds',
'Device.DSL.Diagnostics.ADSLLineTest.SNRpsus',
'Device.DSL.Diagnostics.ADSLLineTest.SNRMTds',
'Device.DSL.Diagnostics.ADSLLineTest.SNRMTus',
'Device.DSL.Diagnostics.ADSLLineTest.BITSpsds',
'Device.DSL.Diagnostics.ADSLLineTest.BITSpsus',
'Device.DSL.Diagnostics.SELTUER.',
'Device.DSL.Diagnostics.SELTUER.DiagnosticsState',
'Device.DSL.Diagnostics.SELTUER.Interface',
'Device.DSL.Diagnostics.SELTUER.UERMaxMeasurementDuration',
'Device.DSL.Diagnostics.SELTUER.ExtendedBandwidthOperation',
'Device.DSL.Diagnostics.SELTUER.UER',
'Device.DSL.Diagnostics.SELTUER.UERScaleFactor',
'Device.DSL.Diagnostics.SELTUER.UERGroupSize',
'Device.DSL.Diagnostics.SELTUER.UERVar',
'Device.DSL.Diagnostics.SELTQLN.',
'Device.DSL.Diagnostics.SELTQLN.DiagnosticsState',
'Device.DSL.Diagnostics.SELTQLN.Interface',
'Device.DSL.Diagnostics.SELTQLN.QLNMaxMeasurementDuration',
'Device.DSL.Diagnostics.SELTQLN.ExtendedBandwidthOperation',
'Device.DSL.Diagnostics.SELTQLN.QLN',
'Device.DSL.Diagnostics.SELTQLN.QLNGroupSize',
'Device.DSL.Diagnostics.SELTP.',
'Device.DSL.Diagnostics.SELTP.DiagnosticsState',
'Device.DSL.Diagnostics.SELTP.Interface',
'Device.DSL.Diagnostics.SELTP.CapacityEstimateEnabling',
'Device.DSL.Diagnostics.SELTP.CapacitySignalPSD',
'Device.DSL.Diagnostics.SELTP.CapacityNoisePSD',
'Device.DSL.Diagnostics.SELTP.CapacityTargetMargin',
'Device.DSL.Diagnostics.SELTP.LoopTermination',
'Device.DSL.Diagnostics.SELTP.LoopLength',
'Device.DSL.Diagnostics.SELTP.LoopTopology',
'Device.DSL.Diagnostics.SELTP.AttenuationCharacteristics',
'Device.DSL.Diagnostics.SELTP.TFlogGroupSize',
'Device.DSL.Diagnostics.SELTP.MissingFilter',
'Device.DSL.Diagnostics.SELTP.CapacityEstimate',
'Device.PacketCaptureDiagnostics.',
'Device.PacketCaptureDiagnostics.DiagnosticsState',
'Device.PacketCaptureDiagnostics.Interface',
'Device.PacketCaptureDiagnostics.Format',
'Device.PacketCaptureDiagnostics.Duration',
'Device.PacketCaptureDiagnostics.PacketCount',
'Device.PacketCaptureDiagnostics.ByteCount',
'Device.PacketCaptureDiagnostics.FileTarget',
'Device.PacketCaptureDiagnostics.FilterExpression',
'Device.PacketCaptureDiagnostics.Username',
'Device.PacketCaptureDiagnostics.Password',
'Device.PacketCaptureDiagnostics.PacketCaptureResultNumberOfEntries',
'Device.PacketCaptureDiagnostics.PacketCaptureResult.{i}.',
'Device.PacketCaptureDiagnostics.PacketCaptureResult.{i}.FileLocation',
'Device.PacketCaptureDiagnostics.PacketCaptureResult.{i}.StartTime',
'Device.PacketCaptureDiagnostics.PacketCaptureResult.{i}.EndTime',
'Device.PacketCaptureDiagnostics.PacketCaptureResult.{i}.Count',
'Device.FAST.',
'Device.FAST.LineNumberOfEntries',
'Device.FAST.Line.{i}.',
'Device.FAST.Line.{i}.Enable',
'Device.FAST.Line.{i}.Status',
'Device.FAST.Line.{i}.Alias',
'Device.FAST.Line.{i}.Name',
'Device.FAST.Line.{i}.LastChange',
'Device.FAST.Line.{i}.LowerLayers',
'Device.FAST.Line.{i}.Upstream',
'Device.FAST.Line.{i}.FirmwareVersion',
'Device.FAST.Line.{i}.LinkStatus',
'Device.FAST.Line.{i}.AllowedProfiles',
'Device.FAST.Line.{i}.CurrentProfile',
'Device.FAST.Line.{i}.PowerManagementState',
'Device.FAST.Line.{i}.SuccessFailureCause',
'Device.FAST.Line.{i}.UPBOKLER',
'Device.FAST.Line.{i}.LastTransmittedDownstreamSignal',
'Device.FAST.Line.{i}.LastTransmittedUpstreamSignal',
'Device.FAST.Line.{i}.UPBOKLE',
'Device.FAST.Line.{i}.LineNumber',
'Device.FAST.Line.{i}.UpstreamMaxBitRate',
'Device.FAST.Line.{i}.DownstreamMaxBitRate',
'Device.FAST.Line.{i}.UpstreamNoiseMargin',
'Device.FAST.Line.{i}.DownstreamNoiseMargin',
'Device.FAST.Line.{i}.UpstreamAttenuation',
'Device.FAST.Line.{i}.DownstreamAttenuation',
'Device.FAST.Line.{i}.UpstreamPower',
'Device.FAST.Line.{i}.DownstreamPower',
'Device.FAST.Line.{i}.SNRMRMCds',
'Device.FAST.Line.{i}.SNRMRMCus',
'Device.FAST.Line.{i}.BITSRMCpsds',
'Device.FAST.Line.{i}.BITSRMCpsus',
'Device.FAST.Line.{i}.FEXTCANCELds',
'Device.FAST.Line.{i}.FEXTCANCELus',
'Device.FAST.Line.{i}.ETRds',
'Device.FAST.Line.{i}.ETRus',
'Device.FAST.Line.{i}.ATTETRds',
'Device.FAST.Line.{i}.ATTETRus',
'Device.FAST.Line.{i}.MINEFTR',
'Device.FAST.Line.{i}.Stats.',
'Device.FAST.Line.{i}.Stats.BytesSent',
'Device.FAST.Line.{i}.Stats.BytesReceived',
'Device.FAST.Line.{i}.Stats.PacketsSent',
'Device.FAST.Line.{i}.Stats.PacketsReceived',
'Device.FAST.Line.{i}.Stats.ErrorsSent',
'Device.FAST.Line.{i}.Stats.ErrorsReceived',
'Device.FAST.Line.{i}.Stats.DiscardPacketsSent',
'Device.FAST.Line.{i}.Stats.DiscardPacketsReceived',
'Device.FAST.Line.{i}.Stats.TotalStart',
'Device.FAST.Line.{i}.Stats.ShowtimeStart',
'Device.FAST.Line.{i}.Stats.LastShowtimeStart',
'Device.FAST.Line.{i}.Stats.CurrentDayStart',
'Device.FAST.Line.{i}.Stats.QuarterHourStart',
'Device.FAST.Line.{i}.Stats.Total.',
'Device.FAST.Line.{i}.Stats.Total.ErroredSecs',
'Device.FAST.Line.{i}.Stats.Total.SeverelyErroredSecs',
'Device.FAST.Line.{i}.Stats.Total.LOSS',
'Device.FAST.Line.{i}.Stats.Total.LORS',
'Device.FAST.Line.{i}.Stats.Total.UAS',
'Device.FAST.Line.{i}.Stats.Total.RTXUC',
'Device.FAST.Line.{i}.Stats.Total.RTXTX',
'Device.FAST.Line.{i}.Stats.Total.SuccessBSW',
'Device.FAST.Line.{i}.Stats.Total.SuccessSRA',
'Device.FAST.Line.{i}.Stats.Total.SuccessFRA',
'Device.FAST.Line.{i}.Stats.Total.SuccessRPA',
'Device.FAST.Line.{i}.Stats.Total.SuccessTIGA',
'Device.FAST.Line.{i}.Stats.Showtime.',
'Device.FAST.Line.{i}.Stats.Showtime.ErroredSecs',
'Device.FAST.Line.{i}.Stats.Showtime.SeverelyErroredSecs',
'Device.FAST.Line.{i}.Stats.Showtime.LOSS',
'Device.FAST.Line.{i}.Stats.Showtime.LORS',
'Device.FAST.Line.{i}.Stats.Showtime.UAS',
'Device.FAST.Line.{i}.Stats.Showtime.RTXUC',
'Device.FAST.Line.{i}.Stats.Showtime.RTXTX',
'Device.FAST.Line.{i}.Stats.Showtime.SuccessBSW',
'Device.FAST.Line.{i}.Stats.Showtime.SuccessSRA',
'Device.FAST.Line.{i}.Stats.Showtime.SuccessFRA',
'Device.FAST.Line.{i}.Stats.Showtime.SuccessRPA',
'Device.FAST.Line.{i}.Stats.Showtime.SuccessTIGA',
'Device.FAST.Line.{i}.Stats.LastShowtime.',
'Device.FAST.Line.{i}.Stats.LastShowtime.ErroredSecs',
'Device.FAST.Line.{i}.Stats.LastShowtime.SeverelyErroredSecs',
'Device.FAST.Line.{i}.Stats.LastShowtime.LOSS',
'Device.FAST.Line.{i}.Stats.LastShowtime.LORS',
'Device.FAST.Line.{i}.Stats.LastShowtime.UAS',
'Device.FAST.Line.{i}.Stats.LastShowtime.RTXUC',
'Device.FAST.Line.{i}.Stats.LastShowtime.RTXTX',
'Device.FAST.Line.{i}.Stats.LastShowtime.SuccessBSW',
'Device.FAST.Line.{i}.Stats.LastShowtime.SuccessSRA',
'Device.FAST.Line.{i}.Stats.LastShowtime.SuccessFRA',
'Device.FAST.Line.{i}.Stats.LastShowtime.SuccessRPA',
'Device.FAST.Line.{i}.Stats.LastShowtime.SuccessTIGA',
'Device.FAST.Line.{i}.Stats.CurrentDay.',
'Device.FAST.Line.{i}.Stats.CurrentDay.ErroredSecs',
'Device.FAST.Line.{i}.Stats.CurrentDay.SeverelyErroredSecs',
'Device.FAST.Line.{i}.Stats.CurrentDay.LOSS',
'Device.FAST.Line.{i}.Stats.CurrentDay.LORS',
'Device.FAST.Line.{i}.Stats.CurrentDay.UAS',
'Device.FAST.Line.{i}.Stats.CurrentDay.RTXUC',
'Device.FAST.Line.{i}.Stats.CurrentDay.RTXTX',
'Device.FAST.Line.{i}.Stats.CurrentDay.SuccessBSW',
'Device.FAST.Line.{i}.Stats.CurrentDay.SuccessSRA',
'Device.FAST.Line.{i}.Stats.CurrentDay.SuccessFRA',
'Device.FAST.Line.{i}.Stats.CurrentDay.SuccessRPA',
'Device.FAST.Line.{i}.Stats.CurrentDay.SuccessTIGA',
'Device.FAST.Line.{i}.Stats.QuarterHour.',
'Device.FAST.Line.{i}.Stats.QuarterHour.ErroredSecs',
'Device.FAST.Line.{i}.Stats.QuarterHour.SeverelyErroredSecs',
'Device.FAST.Line.{i}.Stats.QuarterHour.LOSS',
'Device.FAST.Line.{i}.Stats.QuarterHour.LORS',
'Device.FAST.Line.{i}.Stats.QuarterHour.UAS',
'Device.FAST.Line.{i}.Stats.QuarterHour.RTXUC',
'Device.FAST.Line.{i}.Stats.QuarterHour.RTXTX',
'Device.FAST.Line.{i}.Stats.QuarterHour.SuccessBSW',
'Device.FAST.Line.{i}.Stats.QuarterHour.SuccessSRA',
'Device.FAST.Line.{i}.Stats.QuarterHour.SuccessFRA',
'Device.FAST.Line.{i}.Stats.QuarterHour.SuccessRPA',
'Device.FAST.Line.{i}.Stats.QuarterHour.SuccessTIGA',
'Device.FAST.Line.{i}.TestParams.',
'Device.FAST.Line.{i}.TestParams.SNRGds',
'Device.FAST.Line.{i}.TestParams.SNRGus',
'Device.FAST.Line.{i}.TestParams.SNRpsds',
'Device.FAST.Line.{i}.TestParams.SNRpsus',
'Device.FAST.Line.{i}.TestParams.SNRMTds',
'Device.FAST.Line.{i}.TestParams.SNRMTus',
'Device.FAST.Line.{i}.TestParams.ACTINP',
'Device.FAST.Line.{i}.TestParams.NFEC',
'Device.FAST.Line.{i}.TestParams.RFEC',
'Device.FAST.Line.{i}.TestParams.UpstreamCurrRate',
'Device.FAST.Line.{i}.TestParams.DownstreamCurrRate',
'Device.FAST.Line.{i}.TestParams.ACTINPREIN',
'Device.Optical.',
'Device.Optical.InterfaceNumberOfEntries',
'Device.Optical.Interface.{i}.',
'Device.Optical.Interface.{i}.Enable',
'Device.Optical.Interface.{i}.Status',
'Device.Optical.Interface.{i}.Alias',
'Device.Optical.Interface.{i}.Name',
'Device.Optical.Interface.{i}.LastChange',
'Device.Optical.Interface.{i}.LowerLayers',
'Device.Optical.Interface.{i}.Upstream',
'Device.Optical.Interface.{i}.OpticalSignalLevel',
'Device.Optical.Interface.{i}.LowerOpticalThreshold',
'Device.Optical.Interface.{i}.UpperOpticalThreshold',
'Device.Optical.Interface.{i}.TransmitOpticalLevel',
'Device.Optical.Interface.{i}.LowerTransmitPowerThreshold',
'Device.Optical.Interface.{i}.UpperTransmitPowerThreshold',
'Device.Optical.Interface.{i}.Stats.',
'Device.Optical.Interface.{i}.Stats.BytesSent',
'Device.Optical.Interface.{i}.Stats.BytesReceived',
'Device.Optical.Interface.{i}.Stats.PacketsSent',
'Device.Optical.Interface.{i}.Stats.PacketsReceived',
'Device.Optical.Interface.{i}.Stats.ErrorsSent',
'Device.Optical.Interface.{i}.Stats.ErrorsReceived',
'Device.Optical.Interface.{i}.Stats.DiscardPacketsSent',
'Device.Optical.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.Cellular.',
'Device.Cellular.RoamingEnabled',
'Device.Cellular.RoamingStatus',
'Device.Cellular.InterfaceNumberOfEntries',
'Device.Cellular.AccessPointNumberOfEntries',
'Device.Cellular.Interface.{i}.',
'Device.Cellular.Interface.{i}.Enable',
'Device.Cellular.Interface.{i}.Status',
'Device.Cellular.Interface.{i}.Alias',
'Device.Cellular.Interface.{i}.Name',
'Device.Cellular.Interface.{i}.LastChange',
'Device.Cellular.Interface.{i}.LowerLayers',
'Device.Cellular.Interface.{i}.Upstream',
'Device.Cellular.Interface.{i}.IMEI',
'Device.Cellular.Interface.{i}.SupportedAccessTechnologies',
'Device.Cellular.Interface.{i}.PreferredAccessTechnology',
'Device.Cellular.Interface.{i}.CurrentAccessTechnology',
'Device.Cellular.Interface.{i}.AvailableNetworks',
'Device.Cellular.Interface.{i}.NetworkRequested',
'Device.Cellular.Interface.{i}.NetworkInUse',
'Device.Cellular.Interface.{i}.RSSI',
'Device.Cellular.Interface.{i}.RSRP',
'Device.Cellular.Interface.{i}.RSRQ',
'Device.Cellular.Interface.{i}.UpstreamMaxBitRate',
'Device.Cellular.Interface.{i}.DownstreamMaxBitRate',
'Device.Cellular.Interface.{i}.USIM.',
'Device.Cellular.Interface.{i}.USIM.Status',
'Device.Cellular.Interface.{i}.USIM.IMSI',
'Device.Cellular.Interface.{i}.USIM.ICCID',
'Device.Cellular.Interface.{i}.USIM.MSISDN',
'Device.Cellular.Interface.{i}.USIM.PINCheck',
'Device.Cellular.Interface.{i}.USIM.PIN',
'Device.Cellular.Interface.{i}.Stats.',
'Device.Cellular.Interface.{i}.Stats.BytesSent',
'Device.Cellular.Interface.{i}.Stats.BytesReceived',
'Device.Cellular.Interface.{i}.Stats.PacketsSent',
'Device.Cellular.Interface.{i}.Stats.PacketsReceived',
'Device.Cellular.Interface.{i}.Stats.ErrorsSent',
'Device.Cellular.Interface.{i}.Stats.ErrorsReceived',
'Device.Cellular.Interface.{i}.Stats.UnicastPacketsSent',
'Device.Cellular.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.Cellular.Interface.{i}.Stats.DiscardPacketsSent',
'Device.Cellular.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.Cellular.Interface.{i}.Stats.MulticastPacketsSent',
'Device.Cellular.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.Cellular.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.Cellular.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.Cellular.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Cellular.AccessPoint.{i}.',
'Device.Cellular.AccessPoint.{i}.Enable',
'Device.Cellular.AccessPoint.{i}.Alias',
'Device.Cellular.AccessPoint.{i}.APN',
'Device.Cellular.AccessPoint.{i}.Username',
'Device.Cellular.AccessPoint.{i}.Password',
'Device.Cellular.AccessPoint.{i}.Proxy',
'Device.Cellular.AccessPoint.{i}.ProxyPort',
'Device.Cellular.AccessPoint.{i}.Interface',
'Device.ATM.',
'Device.ATM.LinkNumberOfEntries',
'Device.ATM.Link.{i}.',
'Device.ATM.Link.{i}.Enable',
'Device.ATM.Link.{i}.Status',
'Device.ATM.Link.{i}.Alias',
'Device.ATM.Link.{i}.Name',
'Device.ATM.Link.{i}.LastChange',
'Device.ATM.Link.{i}.LowerLayers',
'Device.ATM.Link.{i}.LinkType',
'Device.ATM.Link.{i}.AutoConfig',
'Device.ATM.Link.{i}.DestinationAddress',
'Device.ATM.Link.{i}.Encapsulation',
'Device.ATM.Link.{i}.FCSPreserved',
'Device.ATM.Link.{i}.VCSearchList',
'Device.ATM.Link.{i}.AAL',
'Device.ATM.Link.{i}.Stats.',
'Device.ATM.Link.{i}.Stats.BytesSent',
'Device.ATM.Link.{i}.Stats.BytesReceived',
'Device.ATM.Link.{i}.Stats.PacketsSent',
'Device.ATM.Link.{i}.Stats.PacketsReceived',
'Device.ATM.Link.{i}.Stats.ErrorsSent',
'Device.ATM.Link.{i}.Stats.ErrorsReceived',
'Device.ATM.Link.{i}.Stats.UnicastPacketsSent',
'Device.ATM.Link.{i}.Stats.UnicastPacketsReceived',
'Device.ATM.Link.{i}.Stats.DiscardPacketsSent',
'Device.ATM.Link.{i}.Stats.DiscardPacketsReceived',
'Device.ATM.Link.{i}.Stats.MulticastPacketsSent',
'Device.ATM.Link.{i}.Stats.MulticastPacketsReceived',
'Device.ATM.Link.{i}.Stats.BroadcastPacketsSent',
'Device.ATM.Link.{i}.Stats.BroadcastPacketsReceived',
'Device.ATM.Link.{i}.Stats.UnknownProtoPacketsReceived',
'Device.ATM.Link.{i}.Stats.TransmittedBlocks',
'Device.ATM.Link.{i}.Stats.ReceivedBlocks',
'Device.ATM.Link.{i}.Stats.CRCErrors',
'Device.ATM.Link.{i}.Stats.HECErrors',
'Device.ATM.Link.{i}.QoS.',
'Device.ATM.Link.{i}.QoS.QoSClass',
'Device.ATM.Link.{i}.QoS.PeakCellRate',
'Device.ATM.Link.{i}.QoS.MaximumBurstSize',
'Device.ATM.Link.{i}.QoS.SustainableCellRate',
'Device.ATM.Diagnostics.',
'Device.ATM.Diagnostics.F5Loopback.',
'Device.ATM.Diagnostics.F5Loopback.DiagnosticsState',
'Device.ATM.Diagnostics.F5Loopback.Interface',
'Device.ATM.Diagnostics.F5Loopback.NumberOfRepetitions',
'Device.ATM.Diagnostics.F5Loopback.Timeout',
'Device.ATM.Diagnostics.F5Loopback.SuccessCount',
'Device.ATM.Diagnostics.F5Loopback.FailureCount',
'Device.ATM.Diagnostics.F5Loopback.AverageResponseTime',
'Device.ATM.Diagnostics.F5Loopback.MinimumResponseTime',
'Device.ATM.Diagnostics.F5Loopback.MaximumResponseTime',
'Device.DOCSIS.',
'Device.DOCSIS.CapabilitiesReq',
'Device.DOCSIS.CapabilitiesRsp',
'Device.DOCSIS.DownstreamNumberOfEntries',
'Device.DOCSIS.UpstreamNumberOfEntries',
'Device.DOCSIS.DownstreamChannelNumberOfEntries',
'Device.DOCSIS.UpstreamChannelNumberOfEntries',
'Device.DOCSIS.InterfaceNumberOfEntries',
'Device.DOCSIS.Downstream.{i}.',
'Device.DOCSIS.Downstream.{i}.Enable',
'Device.DOCSIS.Downstream.{i}.Status',
'Device.DOCSIS.Downstream.{i}.Alias',
'Device.DOCSIS.Downstream.{i}.Name',
'Device.DOCSIS.Downstream.{i}.LastChange',
'Device.DOCSIS.Downstream.{i}.LowerLayers',
'Device.DOCSIS.Downstream.{i}.Upstream',
'Device.DOCSIS.Downstream.{i}.MaxBitRate',
'Device.DOCSIS.Downstream.{i}.CurrentBitRate',
'Device.DOCSIS.Downstream.{i}.DownstreamChannelList',
'Device.DOCSIS.Downstream.{i}.Stats.',
'Device.DOCSIS.Downstream.{i}.Stats.BytesSent',
'Device.DOCSIS.Downstream.{i}.Stats.BytesReceived',
'Device.DOCSIS.Downstream.{i}.Stats.PacketsSent',
'Device.DOCSIS.Downstream.{i}.Stats.PacketsReceived',
'Device.DOCSIS.Downstream.{i}.Stats.ErrorsSent',
'Device.DOCSIS.Downstream.{i}.Stats.ErrorsReceived',
'Device.DOCSIS.Downstream.{i}.Stats.UnicastPacketsSent',
'Device.DOCSIS.Downstream.{i}.Stats.UnicastPacketsReceived',
'Device.DOCSIS.Downstream.{i}.Stats.DiscardPacketsSent',
'Device.DOCSIS.Downstream.{i}.Stats.DiscardPacketsReceived',
'Device.DOCSIS.Downstream.{i}.Stats.MulticastPacketsSent',
'Device.DOCSIS.Downstream.{i}.Stats.MulticastPacketsReceived',
'Device.DOCSIS.Downstream.{i}.Stats.BroadcastPacketsSent',
'Device.DOCSIS.Downstream.{i}.Stats.BroadcastPacketsReceived',
'Device.DOCSIS.Downstream.{i}.Stats.UnknownProtoPacketsReceived',
'Device.DOCSIS.Upstream.{i}.',
'Device.DOCSIS.Upstream.{i}.Enable',
'Device.DOCSIS.Upstream.{i}.Status',
'Device.DOCSIS.Upstream.{i}.Alias',
'Device.DOCSIS.Upstream.{i}.Name',
'Device.DOCSIS.Upstream.{i}.LastChange',
'Device.DOCSIS.Upstream.{i}.LowerLayers',
'Device.DOCSIS.Upstream.{i}.Upstream',
'Device.DOCSIS.Upstream.{i}.MaxBitRate',
'Device.DOCSIS.Upstream.{i}.CurrentBitRate',
'Device.DOCSIS.Upstream.{i}.UpstreamChannelList',
'Device.DOCSIS.Upstream.{i}.Stats.',
'Device.DOCSIS.Upstream.{i}.Stats.BytesSent',
'Device.DOCSIS.Upstream.{i}.Stats.BytesReceived',
'Device.DOCSIS.Upstream.{i}.Stats.PacketsSent',
'Device.DOCSIS.Upstream.{i}.Stats.PacketsReceived',
'Device.DOCSIS.Upstream.{i}.Stats.ErrorsSent',
'Device.DOCSIS.Upstream.{i}.Stats.ErrorsReceived',
'Device.DOCSIS.Upstream.{i}.Stats.UnicastPacketsSent',
'Device.DOCSIS.Upstream.{i}.Stats.UnicastPacketsReceived',
'Device.DOCSIS.Upstream.{i}.Stats.DiscardPacketsSent',
'Device.DOCSIS.Upstream.{i}.Stats.DiscardPacketsReceived',
'Device.DOCSIS.Upstream.{i}.Stats.MulticastPacketsSent',
'Device.DOCSIS.Upstream.{i}.Stats.MulticastPacketsReceived',
'Device.DOCSIS.Upstream.{i}.Stats.BroadcastPacketsSent',
'Device.DOCSIS.Upstream.{i}.Stats.BroadcastPacketsReceived',
'Device.DOCSIS.Upstream.{i}.Stats.UnknownProtoPacketsReceived',
'Device.DOCSIS.DownstreamChannel.{i}.',
'Device.DOCSIS.DownstreamChannel.{i}.Alias',
'Device.DOCSIS.DownstreamChannel.{i}.ID',
'Device.DOCSIS.DownstreamChannel.{i}.Frequency',
'Device.DOCSIS.DownstreamChannel.{i}.Width',
'Device.DOCSIS.DownstreamChannel.{i}.Modulation',
'Device.DOCSIS.DownstreamChannel.{i}.Interleave',
'Device.DOCSIS.DownstreamChannel.{i}.Power',
'Device.DOCSIS.DownstreamChannel.{i}.Annex',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.SignalNoise',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.Microreflections',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.EqualizationData',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.ExtUnerroreds',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.ExtCorrecteds',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQuality.ExtUncorrectables',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQualityExt.',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQualityExt.RxMER',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQualityExt.RxMerSamples',
'Device.DOCSIS.DownstreamChannel.{i}.SignalQualityExt.FbeNormalizationCoefficient',
'Device.DOCSIS.UpstreamChannel.{i}.',
'Device.DOCSIS.UpstreamChannel.{i}.Alias',
'Device.DOCSIS.UpstreamChannel.{i}.ID',
'Device.DOCSIS.UpstreamChannel.{i}.Frequency',
'Device.DOCSIS.UpstreamChannel.{i}.Width',
'Device.DOCSIS.UpstreamChannel.{i}.SlotSize',
'Device.DOCSIS.UpstreamChannel.{i}.TxTimingOffset',
'Device.DOCSIS.UpstreamChannel.{i}.RangingBackoffStart',
'Device.DOCSIS.UpstreamChannel.{i}.RangingBackoffEnd',
'Device.DOCSIS.UpstreamChannel.{i}.TxBackoffStart',
'Device.DOCSIS.UpstreamChannel.{i}.TxBackoffEnd',
'Device.DOCSIS.UpstreamChannel.{i}.Status.',
'Device.DOCSIS.UpstreamChannel.{i}.Status.TxPower',
'Device.DOCSIS.UpstreamChannel.{i}.Status.T3Timeouts',
'Device.DOCSIS.UpstreamChannel.{i}.Status.T4Timeouts',
'Device.DOCSIS.UpstreamChannel.{i}.Status.RangingAborteds',
'Device.DOCSIS.UpstreamChannel.{i}.Status.ModulationType',
'Device.DOCSIS.UpstreamChannel.{i}.Status.EqData',
'Device.DOCSIS.UpstreamChannel.{i}.Status.T3Exceededs',
'Device.DOCSIS.UpstreamChannel.{i}.Status.IsMuted',
'Device.DOCSIS.UpstreamChannel.{i}.Status.RangingStatus',
'Device.DOCSIS.Interface.{i}.',
'Device.DOCSIS.Interface.{i}.Enable',
'Device.DOCSIS.Interface.{i}.Status',
'Device.DOCSIS.Interface.{i}.Alias',
'Device.DOCSIS.Interface.{i}.Name',
'Device.DOCSIS.Interface.{i}.LastChange',
'Device.DOCSIS.Interface.{i}.LowerLayers',
'Device.DOCSIS.Interface.{i}.MACAddress',
'Device.DOCSIS.Interface.{i}.CMTSAddress',
'Device.DOCSIS.Interface.{i}.Capabilities',
'Device.DOCSIS.Interface.{i}.FirmwareVersion',
'Device.DOCSIS.Interface.{i}.DOCSISVersion',
'Device.DOCSIS.Interface.{i}.MdCfgIpProvMode',
'Device.DOCSIS.Interface.{i}.Stats.',
'Device.DOCSIS.Interface.{i}.Stats.BytesSent',
'Device.DOCSIS.Interface.{i}.Stats.BytesReceived',
'Device.DOCSIS.Interface.{i}.Stats.PacketsSent',
'Device.DOCSIS.Interface.{i}.Stats.PacketsReceived',
'Device.DOCSIS.Interface.{i}.Stats.ErrorsSent',
'Device.DOCSIS.Interface.{i}.Stats.ErrorsReceived',
'Device.DOCSIS.Interface.{i}.Stats.UnicastPacketsSent',
'Device.DOCSIS.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.DOCSIS.Interface.{i}.Stats.DiscardPacketsSent',
'Device.DOCSIS.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.DOCSIS.Interface.{i}.Stats.MulticastPacketsSent',
'Device.DOCSIS.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.DOCSIS.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.DOCSIS.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.DOCSIS.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.Value',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.StatusCode',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.Resets',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.LostSyncs',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.InvalidMaps',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.InvalidUcds',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.InvalidRangingRsps',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.InvalidRegRsps',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.T1Timeouts',
'Device.DOCSIS.Interface.{i}.ConnectivityStatus.T2Timeouts',
'Device.DOCSIS.SpectrumAnalysis.',
'Device.DOCSIS.SpectrumAnalysis.Enable',
'Device.DOCSIS.SpectrumAnalysis.InactivityTimeout',
'Device.DOCSIS.SpectrumAnalysis.FirstSegmentCenterFrequency',
'Device.DOCSIS.SpectrumAnalysis.LastSegmentCenterFrequency',
'Device.DOCSIS.SpectrumAnalysis.SegmentFrequencySpan',
'Device.DOCSIS.SpectrumAnalysis.NumBinsPerSegment',
'Device.DOCSIS.SpectrumAnalysis.EquivalentNoiseBandwidth',
'Device.DOCSIS.SpectrumAnalysis.WindowFunction',
'Device.DOCSIS.SpectrumAnalysis.NumberOfAverages',
'Device.DOCSIS.SpectrumAnalysis.ResultNumberOfEntries',
'Device.DOCSIS.SpectrumAnalysis.Result.{i}.',
'Device.DOCSIS.SpectrumAnalysis.Result.{i}.Frequency',
'Device.DOCSIS.SpectrumAnalysis.Result.{i}.AmplitudeData',
'Device.DOCSIS.SpectrumAnalysis.Result.{i}.TotalSegmentPower',
'Device.PTM.',
'Device.PTM.LinkNumberOfEntries',
'Device.PTM.Link.{i}.',
'Device.PTM.Link.{i}.Enable',
'Device.PTM.Link.{i}.Status',
'Device.PTM.Link.{i}.Alias',
'Device.PTM.Link.{i}.Name',
'Device.PTM.Link.{i}.LastChange',
'Device.PTM.Link.{i}.LowerLayers',
'Device.PTM.Link.{i}.MACAddress',
'Device.PTM.Link.{i}.Stats.',
'Device.PTM.Link.{i}.Stats.BytesSent',
'Device.PTM.Link.{i}.Stats.BytesReceived',
'Device.PTM.Link.{i}.Stats.PacketsSent',
'Device.PTM.Link.{i}.Stats.PacketsReceived',
'Device.PTM.Link.{i}.Stats.ErrorsSent',
'Device.PTM.Link.{i}.Stats.ErrorsReceived',
'Device.PTM.Link.{i}.Stats.UnicastPacketsSent',
'Device.PTM.Link.{i}.Stats.UnicastPacketsReceived',
'Device.PTM.Link.{i}.Stats.DiscardPacketsSent',
'Device.PTM.Link.{i}.Stats.DiscardPacketsReceived',
'Device.PTM.Link.{i}.Stats.MulticastPacketsSent',
'Device.PTM.Link.{i}.Stats.MulticastPacketsReceived',
'Device.PTM.Link.{i}.Stats.BroadcastPacketsSent',
'Device.PTM.Link.{i}.Stats.BroadcastPacketsReceived',
'Device.PTM.Link.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Ethernet.',
'Device.Ethernet.WoLSupported',
'Device.Ethernet.FlowControlSupported',
'Device.Ethernet.InterfaceNumberOfEntries',
'Device.Ethernet.LinkNumberOfEntries',
'Device.Ethernet.VLANTerminationNumberOfEntries',
'Device.Ethernet.RMONStatsNumberOfEntries',
'Device.Ethernet.LAGNumberOfEntries',
'Device.Ethernet.Interface.{i}.',
'Device.Ethernet.Interface.{i}.Enable',
'Device.Ethernet.Interface.{i}.Status',
'Device.Ethernet.Interface.{i}.Alias',
'Device.Ethernet.Interface.{i}.Name',
'Device.Ethernet.Interface.{i}.LastChange',
'Device.Ethernet.Interface.{i}.LowerLayers',
'Device.Ethernet.Interface.{i}.Upstream',
'Device.Ethernet.Interface.{i}.MACAddress',
'Device.Ethernet.Interface.{i}.MaxBitRate',
'Device.Ethernet.Interface.{i}.CurrentBitRate',
'Device.Ethernet.Interface.{i}.DuplexMode',
'Device.Ethernet.Interface.{i}.EEECapability',
'Device.Ethernet.Interface.{i}.EEEEnable',
'Device.Ethernet.Interface.{i}.Stats.',
'Device.Ethernet.Interface.{i}.Stats.BytesSent',
'Device.Ethernet.Interface.{i}.Stats.BytesReceived',
'Device.Ethernet.Interface.{i}.Stats.PacketsSent',
'Device.Ethernet.Interface.{i}.Stats.PacketsReceived',
'Device.Ethernet.Interface.{i}.Stats.ErrorsSent',
'Device.Ethernet.Interface.{i}.Stats.ErrorsReceived',
'Device.Ethernet.Interface.{i}.Stats.UnicastPacketsSent',
'Device.Ethernet.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.Ethernet.Interface.{i}.Stats.DiscardPacketsSent',
'Device.Ethernet.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.Ethernet.Interface.{i}.Stats.MulticastPacketsSent',
'Device.Ethernet.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.Ethernet.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.Ethernet.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.Ethernet.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Ethernet.Link.{i}.',
'Device.Ethernet.Link.{i}.Enable',
'Device.Ethernet.Link.{i}.Status',
'Device.Ethernet.Link.{i}.Alias',
'Device.Ethernet.Link.{i}.Name',
'Device.Ethernet.Link.{i}.LastChange',
'Device.Ethernet.Link.{i}.LowerLayers',
'Device.Ethernet.Link.{i}.MACAddress',
'Device.Ethernet.Link.{i}.PriorityTagging',
'Device.Ethernet.Link.{i}.FlowControl',
'Device.Ethernet.Link.{i}.Stats.',
'Device.Ethernet.Link.{i}.Stats.BytesSent',
'Device.Ethernet.Link.{i}.Stats.BytesReceived',
'Device.Ethernet.Link.{i}.Stats.PacketsSent',
'Device.Ethernet.Link.{i}.Stats.PacketsReceived',
'Device.Ethernet.Link.{i}.Stats.ErrorsSent',
'Device.Ethernet.Link.{i}.Stats.ErrorsReceived',
'Device.Ethernet.Link.{i}.Stats.UnicastPacketsSent',
'Device.Ethernet.Link.{i}.Stats.UnicastPacketsReceived',
'Device.Ethernet.Link.{i}.Stats.DiscardPacketsSent',
'Device.Ethernet.Link.{i}.Stats.DiscardPacketsReceived',
'Device.Ethernet.Link.{i}.Stats.MulticastPacketsSent',
'Device.Ethernet.Link.{i}.Stats.MulticastPacketsReceived',
'Device.Ethernet.Link.{i}.Stats.BroadcastPacketsSent',
'Device.Ethernet.Link.{i}.Stats.BroadcastPacketsReceived',
'Device.Ethernet.Link.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Ethernet.VLANTermination.{i}.',
'Device.Ethernet.VLANTermination.{i}.Enable',
'Device.Ethernet.VLANTermination.{i}.Status',
'Device.Ethernet.VLANTermination.{i}.Alias',
'Device.Ethernet.VLANTermination.{i}.Name',
'Device.Ethernet.VLANTermination.{i}.LastChange',
'Device.Ethernet.VLANTermination.{i}.LowerLayers',
'Device.Ethernet.VLANTermination.{i}.VLANID',
'Device.Ethernet.VLANTermination.{i}.TPID',
'Device.Ethernet.VLANTermination.{i}.Stats.',
'Device.Ethernet.VLANTermination.{i}.Stats.BytesSent',
'Device.Ethernet.VLANTermination.{i}.Stats.BytesReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.PacketsSent',
'Device.Ethernet.VLANTermination.{i}.Stats.PacketsReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.ErrorsSent',
'Device.Ethernet.VLANTermination.{i}.Stats.ErrorsReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.UnicastPacketsSent',
'Device.Ethernet.VLANTermination.{i}.Stats.UnicastPacketsReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.DiscardPacketsSent',
'Device.Ethernet.VLANTermination.{i}.Stats.DiscardPacketsReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.MulticastPacketsSent',
'Device.Ethernet.VLANTermination.{i}.Stats.MulticastPacketsReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.BroadcastPacketsSent',
'Device.Ethernet.VLANTermination.{i}.Stats.BroadcastPacketsReceived',
'Device.Ethernet.VLANTermination.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Ethernet.RMONStats.{i}.',
'Device.Ethernet.RMONStats.{i}.Enable',
'Device.Ethernet.RMONStats.{i}.Status',
'Device.Ethernet.RMONStats.{i}.Alias',
'Device.Ethernet.RMONStats.{i}.Name',
'Device.Ethernet.RMONStats.{i}.Interface',
'Device.Ethernet.RMONStats.{i}.VLANID',
'Device.Ethernet.RMONStats.{i}.Queue',
'Device.Ethernet.RMONStats.{i}.AllQueues',
'Device.Ethernet.RMONStats.{i}.DropEvents',
'Device.Ethernet.RMONStats.{i}.Bytes',
'Device.Ethernet.RMONStats.{i}.Packets',
'Device.Ethernet.RMONStats.{i}.BroadcastPackets',
'Device.Ethernet.RMONStats.{i}.MulticastPackets',
'Device.Ethernet.RMONStats.{i}.CRCErroredPackets',
'Device.Ethernet.RMONStats.{i}.UndersizePackets',
'Device.Ethernet.RMONStats.{i}.OversizePackets',
'Device.Ethernet.RMONStats.{i}.Packets64Bytes',
'Device.Ethernet.RMONStats.{i}.Packets65to127Bytes',
'Device.Ethernet.RMONStats.{i}.Packets128to255Bytes',
'Device.Ethernet.RMONStats.{i}.Packets256to511Bytes',
'Device.Ethernet.RMONStats.{i}.Packets512to1023Bytes',
'Device.Ethernet.RMONStats.{i}.Packets1024to1518Bytes',
'Device.Ethernet.WoL.',
'Device.Ethernet.WoL.SendMagicPacket',
'Device.Ethernet.WoL.MACAddress',
'Device.Ethernet.WoL.Password',
'Device.Ethernet.LAG.{i}.',
'Device.Ethernet.LAG.{i}.Enable',
'Device.Ethernet.LAG.{i}.Status',
'Device.Ethernet.LAG.{i}.Alias',
'Device.Ethernet.LAG.{i}.Name',
'Device.Ethernet.LAG.{i}.LastChange',
'Device.Ethernet.LAG.{i}.LowerLayers',
'Device.Ethernet.LAG.{i}.MACAddress',
'Device.Ethernet.LAG.{i}.Stats.',
'Device.Ethernet.LAG.{i}.Stats.BytesSent',
'Device.Ethernet.LAG.{i}.Stats.BytesReceived',
'Device.Ethernet.LAG.{i}.Stats.PacketsSent',
'Device.Ethernet.LAG.{i}.Stats.PacketsReceived',
'Device.Ethernet.LAG.{i}.Stats.ErrorsSent',
'Device.Ethernet.LAG.{i}.Stats.ErrorsReceived',
'Device.Ethernet.LAG.{i}.Stats.UnicastPacketsSent',
'Device.Ethernet.LAG.{i}.Stats.UnicastPacketsReceived',
'Device.Ethernet.LAG.{i}.Stats.DiscardPacketsSent',
'Device.Ethernet.LAG.{i}.Stats.DiscardPacketsReceived',
'Device.Ethernet.LAG.{i}.Stats.MulticastPacketsSent',
'Device.Ethernet.LAG.{i}.Stats.MulticastPacketsReceived',
'Device.Ethernet.LAG.{i}.Stats.BroadcastPacketsSent',
'Device.Ethernet.LAG.{i}.Stats.BroadcastPacketsReceived',
'Device.Ethernet.LAG.{i}.Stats.UnknownProtoPacketsReceived',
'Device.USB.',
'Device.USB.InterfaceNumberOfEntries',
'Device.USB.PortNumberOfEntries',
'Device.USB.Interface.{i}.',
'Device.USB.Interface.{i}.Enable',
'Device.USB.Interface.{i}.Status',
'Device.USB.Interface.{i}.Alias',
'Device.USB.Interface.{i}.Name',
'Device.USB.Interface.{i}.LastChange',
'Device.USB.Interface.{i}.LowerLayers',
'Device.USB.Interface.{i}.Upstream',
'Device.USB.Interface.{i}.MACAddress',
'Device.USB.Interface.{i}.MaxBitRate',
'Device.USB.Interface.{i}.Port',
'Device.USB.Interface.{i}.Stats.',
'Device.USB.Interface.{i}.Stats.BytesSent',
'Device.USB.Interface.{i}.Stats.BytesReceived',
'Device.USB.Interface.{i}.Stats.PacketsSent',
'Device.USB.Interface.{i}.Stats.PacketsReceived',
'Device.USB.Interface.{i}.Stats.ErrorsSent',
'Device.USB.Interface.{i}.Stats.ErrorsReceived',
'Device.USB.Interface.{i}.Stats.UnicastPacketsSent',
'Device.USB.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.USB.Interface.{i}.Stats.DiscardPacketsSent',
'Device.USB.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.USB.Interface.{i}.Stats.MulticastPacketsSent',
'Device.USB.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.USB.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.USB.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.USB.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.USB.Port.{i}.',
'Device.USB.Port.{i}.Alias',
'Device.USB.Port.{i}.Name',
'Device.USB.Port.{i}.Standard',
'Device.USB.Port.{i}.Type',
'Device.USB.Port.{i}.Receptacle',
'Device.USB.Port.{i}.Rate',
'Device.USB.Port.{i}.Power',
'Device.USB.USBHosts.',
'Device.USB.USBHosts.HostNumberOfEntries',
'Device.USB.USBHosts.Host.{i}.',
'Device.USB.USBHosts.Host.{i}.Alias',
'Device.USB.USBHosts.Host.{i}.Enable',
'Device.USB.USBHosts.Host.{i}.Name',
'Device.USB.USBHosts.Host.{i}.Type',
'Device.USB.USBHosts.Host.{i}.Reset',
'Device.USB.USBHosts.Host.{i}.PowerManagementEnable',
'Device.USB.USBHosts.Host.{i}.USBVersion',
'Device.USB.USBHosts.Host.{i}.DeviceNumberOfEntries',
'Device.USB.USBHosts.Host.{i}.Device.{i}.',
'Device.USB.USBHosts.Host.{i}.Device.{i}.DeviceNumber',
'Device.USB.USBHosts.Host.{i}.Device.{i}.USBVersion',
'Device.USB.USBHosts.Host.{i}.Device.{i}.DeviceClass',
'Device.USB.USBHosts.Host.{i}.Device.{i}.DeviceSubClass',
'Device.USB.USBHosts.Host.{i}.Device.{i}.DeviceVersion',
'Device.USB.USBHosts.Host.{i}.Device.{i}.DeviceProtocol',
'Device.USB.USBHosts.Host.{i}.Device.{i}.ProductID',
'Device.USB.USBHosts.Host.{i}.Device.{i}.VendorID',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Manufacturer',
'Device.USB.USBHosts.Host.{i}.Device.{i}.ProductClass',
'Device.USB.USBHosts.Host.{i}.Device.{i}.SerialNumber',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Port',
'Device.USB.USBHosts.Host.{i}.Device.{i}.USBPort',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Rate',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Parent',
'Device.USB.USBHosts.Host.{i}.Device.{i}.MaxChildren',
'Device.USB.USBHosts.Host.{i}.Device.{i}.IsSuspended',
'Device.USB.USBHosts.Host.{i}.Device.{i}.IsSelfPowered',
'Device.USB.USBHosts.Host.{i}.Device.{i}.ConfigurationNumberOfEntries',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.ConfigurationNumber',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.InterfaceNumberOfEntries',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.Interface.{i}.',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.Interface.{i}.InterfaceNumber',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.Interface.{i}.InterfaceClass',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.Interface.{i}.InterfaceSubClass',
'Device.USB.USBHosts.Host.{i}.Device.{i}.Configuration.{i}.Interface.{i}.InterfaceProtocol',
'Device.HPNA.',
'Device.HPNA.InterfaceNumberOfEntries',
'Device.HPNA.Interface.{i}.',
'Device.HPNA.Interface.{i}.Enable',
'Device.HPNA.Interface.{i}.Status',
'Device.HPNA.Interface.{i}.Alias',
'Device.HPNA.Interface.{i}.Name',
'Device.HPNA.Interface.{i}.LastChange',
'Device.HPNA.Interface.{i}.LowerLayers',
'Device.HPNA.Interface.{i}.Upstream',
'Device.HPNA.Interface.{i}.MACAddress',
'Device.HPNA.Interface.{i}.FirmwareVersion',
'Device.HPNA.Interface.{i}.NodeID',
'Device.HPNA.Interface.{i}.IsMaster',
'Device.HPNA.Interface.{i}.Synced',
'Device.HPNA.Interface.{i}.TotalSyncTime',
'Device.HPNA.Interface.{i}.MaxBitRate',
'Device.HPNA.Interface.{i}.NetworkUtilization',
'Device.HPNA.Interface.{i}.PossibleConnectionTypes',
'Device.HPNA.Interface.{i}.ConnectionType',
'Device.HPNA.Interface.{i}.PossibleSpectralModes',
'Device.HPNA.Interface.{i}.SpectralMode',
'Device.HPNA.Interface.{i}.MTU',
'Device.HPNA.Interface.{i}.NoiseMargin',
'Device.HPNA.Interface.{i}.DefaultNonLARQPER',
'Device.HPNA.Interface.{i}.LARQEnable',
'Device.HPNA.Interface.{i}.MinMulticastRate',
'Device.HPNA.Interface.{i}.NegMulticastRate',
'Device.HPNA.Interface.{i}.MasterSelectionMode',
'Device.HPNA.Interface.{i}.AssociatedDeviceNumberOfEntries',
'Device.HPNA.Interface.{i}.Stats.',
'Device.HPNA.Interface.{i}.Stats.BytesSent',
'Device.HPNA.Interface.{i}.Stats.BytesReceived',
'Device.HPNA.Interface.{i}.Stats.PacketsSent',
'Device.HPNA.Interface.{i}.Stats.PacketsReceived',
'Device.HPNA.Interface.{i}.Stats.ErrorsSent',
'Device.HPNA.Interface.{i}.Stats.ErrorsReceived',
'Device.HPNA.Interface.{i}.Stats.UnicastPacketsSent',
'Device.HPNA.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.HPNA.Interface.{i}.Stats.DiscardPacketsSent',
'Device.HPNA.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.HPNA.Interface.{i}.Stats.MulticastPacketsSent',
'Device.HPNA.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.HPNA.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.HPNA.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.HPNA.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.HPNA.Interface.{i}.QoS.',
'Device.HPNA.Interface.{i}.QoS.FlowSpecNumberOfEntries',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Enable',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Status',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Alias',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.TrafficClasses',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.FlowType',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Priority',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Latency',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Jitter',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.PacketSize',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.MinRate',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.AvgRate',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.MaxRate',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.PER',
'Device.HPNA.Interface.{i}.QoS.FlowSpec.{i}.Timeout',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.MACAddress',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.NodeID',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.IsMaster',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.Synced',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.TotalSyncTime',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.MaxBitRate',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.PHYDiagnosticsEnable',
'Device.HPNA.Interface.{i}.AssociatedDevice.{i}.Active',
'Device.HPNA.Diagnostics.',
'Device.HPNA.Diagnostics.PHYThroughput.',
'Device.HPNA.Diagnostics.PHYThroughput.DiagnosticsState',
'Device.HPNA.Diagnostics.PHYThroughput.Interface',
'Device.HPNA.Diagnostics.PHYThroughput.NumPacketsInBurst',
'Device.HPNA.Diagnostics.PHYThroughput.BurstInterval',
'Device.HPNA.Diagnostics.PHYThroughput.TestPacketPayloadLength',
'Device.HPNA.Diagnostics.PHYThroughput.PayloadEncoding',
'Device.HPNA.Diagnostics.PHYThroughput.PayloadDataGen',
'Device.HPNA.Diagnostics.PHYThroughput.PayloadType',
'Device.HPNA.Diagnostics.PHYThroughput.PriorityLevel',
'Device.HPNA.Diagnostics.PHYThroughput.ResultNumberOfEntries',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.SrcMACAddress',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.DestMACAddress',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.PHYRate',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.BaudRate',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.SNR',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.PacketsReceived',
'Device.HPNA.Diagnostics.PHYThroughput.Result.{i}.Attenuation',
'Device.HPNA.Diagnostics.PerformanceMonitoring.',
'Device.HPNA.Diagnostics.PerformanceMonitoring.DiagnosticsState',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Interface',
'Device.HPNA.Diagnostics.PerformanceMonitoring.SampleInterval',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.CurrentStart',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.CurrentEnd',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.NodeNumberOfEntries',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MACAddress',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BytesSent',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BytesReceived',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsSent',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsReceived',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BroadcastPacketsSent',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BroadcastPacketsReceived',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MulticastPacketsSent',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MulticastPacketsReceived',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsCrcErrored',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsCrcErroredHost',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsShortErrored',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsShortErroredHost',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.RxPacketsDropped',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.TxPacketsDropped',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.ControlRequestLocal',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.ControlReplyLocal',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.ControlRequestRemote',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.ControlReplyRemote',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsSentWire',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BroadcastPacketsSentWire',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MulticastPacketsSentWire',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsInternalControl',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BroadcastPacketsInternalControl',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsReceivedQueued',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsReceivedForwardUnknown',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.NodeUtilization',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.TimeStamp',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.ChannelNumberOfEntries',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.HostSrcMACAddress',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.HostDestMACAddress',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.HPNASrcMACAddress',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.HPNADestMACAddress',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.PHYRate',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.BaudRate',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.SNR',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.PacketsSent',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.PacketsReceived',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.LARQPacketsReceived',
'Device.HPNA.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.FlowSpec',
'Device.MoCA.',
'Device.MoCA.InterfaceNumberOfEntries',
'Device.MoCA.Interface.{i}.',
'Device.MoCA.Interface.{i}.Enable',
'Device.MoCA.Interface.{i}.Status',
'Device.MoCA.Interface.{i}.Alias',
'Device.MoCA.Interface.{i}.Name',
'Device.MoCA.Interface.{i}.LastChange',
'Device.MoCA.Interface.{i}.LowerLayers',
'Device.MoCA.Interface.{i}.Upstream',
'Device.MoCA.Interface.{i}.MACAddress',
'Device.MoCA.Interface.{i}.FirmwareVersion',
'Device.MoCA.Interface.{i}.MaxBitRate',
'Device.MoCA.Interface.{i}.MaxIngressBW',
'Device.MoCA.Interface.{i}.MaxEgressBW',
'Device.MoCA.Interface.{i}.HighestVersion',
'Device.MoCA.Interface.{i}.CurrentVersion',
'Device.MoCA.Interface.{i}.NetworkCoordinator',
'Device.MoCA.Interface.{i}.NodeID',
'Device.MoCA.Interface.{i}.MaxNodes',
'Device.MoCA.Interface.{i}.PreferredNC',
'Device.MoCA.Interface.{i}.BackupNC',
'Device.MoCA.Interface.{i}.PrivacyEnabledSetting',
'Device.MoCA.Interface.{i}.PrivacyEnabled',
'Device.MoCA.Interface.{i}.FreqCapabilityMask',
'Device.MoCA.Interface.{i}.FreqCurrentMaskSetting',
'Device.MoCA.Interface.{i}.FreqCurrentMask',
'Device.MoCA.Interface.{i}.CurrentOperFreq',
'Device.MoCA.Interface.{i}.LastOperFreq',
'Device.MoCA.Interface.{i}.KeyPassphrase',
'Device.MoCA.Interface.{i}.TxPowerLimit',
'Device.MoCA.Interface.{i}.PowerCntlPhyTarget',
'Device.MoCA.Interface.{i}.BeaconPowerLimit',
'Device.MoCA.Interface.{i}.NetworkTabooMask',
'Device.MoCA.Interface.{i}.NodeTabooMask',
'Device.MoCA.Interface.{i}.TxBcastRate',
'Device.MoCA.Interface.{i}.TxBcastPowerReduction',
'Device.MoCA.Interface.{i}.QAM256Capable',
'Device.MoCA.Interface.{i}.PacketAggregationCapability',
'Device.MoCA.Interface.{i}.AssociatedDeviceNumberOfEntries',
'Device.MoCA.Interface.{i}.Stats.',
'Device.MoCA.Interface.{i}.Stats.BytesSent',
'Device.MoCA.Interface.{i}.Stats.BytesReceived',
'Device.MoCA.Interface.{i}.Stats.PacketsSent',
'Device.MoCA.Interface.{i}.Stats.PacketsReceived',
'Device.MoCA.Interface.{i}.Stats.ErrorsSent',
'Device.MoCA.Interface.{i}.Stats.ErrorsReceived',
'Device.MoCA.Interface.{i}.Stats.UnicastPacketsSent',
'Device.MoCA.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.MoCA.Interface.{i}.Stats.DiscardPacketsSent',
'Device.MoCA.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.MoCA.Interface.{i}.Stats.MulticastPacketsSent',
'Device.MoCA.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.MoCA.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.MoCA.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.MoCA.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.MoCA.Interface.{i}.QoS.',
'Device.MoCA.Interface.{i}.QoS.EgressNumFlows',
'Device.MoCA.Interface.{i}.QoS.IngressNumFlows',
'Device.MoCA.Interface.{i}.QoS.FlowStatsNumberOfEntries',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.FlowID',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.PacketDA',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.MaxRate',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.MaxBurstSize',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.LeaseTime',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.LeaseTimeLeft',
'Device.MoCA.Interface.{i}.QoS.FlowStats.{i}.FlowPackets',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.MACAddress',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.NodeID',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.PreferredNC',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.HighestVersion',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.PHYTxRate',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.PHYRxRate',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.TxPowerControlReduction',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.RxPowerLevel',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.TxBcastRate',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.RxBcastPowerLevel',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.TxPackets',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.RxPackets',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.RxErroredAndMissedPackets',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.QAM256Capable',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.PacketAggregationCapability',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.RxSNR',
'Device.MoCA.Interface.{i}.AssociatedDevice.{i}.Active',
'Device.Ghn.',
'Device.Ghn.InterfaceNumberOfEntries',
'Device.Ghn.Interface.{i}.',
'Device.Ghn.Interface.{i}.Enable',
'Device.Ghn.Interface.{i}.Status',
'Device.Ghn.Interface.{i}.Alias',
'Device.Ghn.Interface.{i}.Name',
'Device.Ghn.Interface.{i}.LastChange',
'Device.Ghn.Interface.{i}.LowerLayers',
'Device.Ghn.Interface.{i}.Upstream',
'Device.Ghn.Interface.{i}.MACAddress',
'Device.Ghn.Interface.{i}.FirmwareVersion',
'Device.Ghn.Interface.{i}.ConnectionType',
'Device.Ghn.Interface.{i}.MaxTransmitRate',
'Device.Ghn.Interface.{i}.TargetDomainNames',
'Device.Ghn.Interface.{i}.DomainName',
'Device.Ghn.Interface.{i}.DomainNameIdentifier',
'Device.Ghn.Interface.{i}.DomainId',
'Device.Ghn.Interface.{i}.DeviceId',
'Device.Ghn.Interface.{i}.MaxBitRate',
'Device.Ghn.Interface.{i}.NodeTypeDMCapable',
'Device.Ghn.Interface.{i}.DMRequested',
'Device.Ghn.Interface.{i}.IsDM',
'Device.Ghn.Interface.{i}.NodeTypeSCCapable',
'Device.Ghn.Interface.{i}.SCRequested',
'Device.Ghn.Interface.{i}.IsSC',
'Device.Ghn.Interface.{i}.StandardVersions',
'Device.Ghn.Interface.{i}.MaxBandPlan',
'Device.Ghn.Interface.{i}.MediumType',
'Device.Ghn.Interface.{i}.TAIFG',
'Device.Ghn.Interface.{i}.NotchedAmateurRadioBands',
'Device.Ghn.Interface.{i}.PHYThroughputDiagnosticsEnable',
'Device.Ghn.Interface.{i}.PerformanceMonitoringDiagnosticsEnable',
'Device.Ghn.Interface.{i}.SMMaskedBandNumberOfEntries',
'Device.Ghn.Interface.{i}.NodeTypeDMConfig',
'Device.Ghn.Interface.{i}.NodeTypeDMStatus',
'Device.Ghn.Interface.{i}.NodeTypeSCStatus',
'Device.Ghn.Interface.{i}.AssociatedDeviceNumberOfEntries',
'Device.Ghn.Interface.{i}.PSM',
'Device.Ghn.Interface.{i}.Stats.',
'Device.Ghn.Interface.{i}.Stats.BytesSent',
'Device.Ghn.Interface.{i}.Stats.BytesReceived',
'Device.Ghn.Interface.{i}.Stats.PacketsSent',
'Device.Ghn.Interface.{i}.Stats.PacketsReceived',
'Device.Ghn.Interface.{i}.Stats.ErrorsSent',
'Device.Ghn.Interface.{i}.Stats.ErrorsReceived',
'Device.Ghn.Interface.{i}.Stats.UnicastPacketsSent',
'Device.Ghn.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.Ghn.Interface.{i}.Stats.DiscardPacketsSent',
'Device.Ghn.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.Ghn.Interface.{i}.Stats.MulticastPacketsSent',
'Device.Ghn.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.Ghn.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.Ghn.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.Ghn.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Ghn.Interface.{i}.Stats.MgmtBytesSent',
'Device.Ghn.Interface.{i}.Stats.MgmtBytesReceived',
'Device.Ghn.Interface.{i}.Stats.MgmtPacketsSent',
'Device.Ghn.Interface.{i}.Stats.MgmtPacketsReceived',
'Device.Ghn.Interface.{i}.Stats.BlocksSent',
'Device.Ghn.Interface.{i}.Stats.BlocksReceived',
'Device.Ghn.Interface.{i}.Stats.BlocksResent',
'Device.Ghn.Interface.{i}.Stats.BlocksErrorsReceived',
'Device.Ghn.Interface.{i}.AssociatedDevice.{i}.',
'Device.Ghn.Interface.{i}.AssociatedDevice.{i}.MACAddress',
'Device.Ghn.Interface.{i}.AssociatedDevice.{i}.DeviceId',
'Device.Ghn.Interface.{i}.AssociatedDevice.{i}.TxPhyRate',
'Device.Ghn.Interface.{i}.AssociatedDevice.{i}.RxPhyRate',
'Device.Ghn.Interface.{i}.AssociatedDevice.{i}.Active',
'Device.Ghn.Interface.{i}.DMInfo.',
'Device.Ghn.Interface.{i}.DMInfo.DomainName',
'Device.Ghn.Interface.{i}.DMInfo.DomainNameIdentifier',
'Device.Ghn.Interface.{i}.DMInfo.DomainId',
'Device.Ghn.Interface.{i}.DMInfo.MACCycleDuration',
'Device.Ghn.Interface.{i}.DMInfo.SCDeviceId',
'Device.Ghn.Interface.{i}.DMInfo.SCMACAddress',
'Device.Ghn.Interface.{i}.DMInfo.ReregistrationTimePeriod',
'Device.Ghn.Interface.{i}.DMInfo.TopologyPeriodicInterval',
'Device.Ghn.Interface.{i}.DMInfo.MinSupportedBandplan',
'Device.Ghn.Interface.{i}.DMInfo.MaxSupportedBandplan',
'Device.Ghn.Interface.{i}.SCInfo.',
'Device.Ghn.Interface.{i}.SCInfo.ModesSupported',
'Device.Ghn.Interface.{i}.SCInfo.ModeEnabled',
'Device.Ghn.Interface.{i}.SCInfo.MICSize',
'Device.Ghn.Interface.{i}.SCInfo.Location',
'Device.Ghn.Interface.{i}.SMMaskedBand.{i}.',
'Device.Ghn.Interface.{i}.SMMaskedBand.{i}.Enable',
'Device.Ghn.Interface.{i}.SMMaskedBand.{i}.BandNumber',
'Device.Ghn.Interface.{i}.SMMaskedBand.{i}.StartSubCarrier',
'Device.Ghn.Interface.{i}.SMMaskedBand.{i}.StopSubCarrier',
'Device.Ghn.Diagnostics.',
'Device.Ghn.Diagnostics.PHYThroughput.',
'Device.Ghn.Diagnostics.PHYThroughput.DiagnosticsState',
'Device.Ghn.Diagnostics.PerformanceMonitoring.',
'Device.Ghn.Diagnostics.PerformanceMonitoring.DiagnosticsState',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Interface',
'Device.Ghn.Diagnostics.PerformanceMonitoring.DiagnoseMACAddress',
'Device.Ghn.Diagnostics.PerformanceMonitoring.SampleInterval',
'Device.Ghn.Diagnostics.PerformanceMonitoring.SNRGroupLength',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.CurrentStart',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.CurrentEnd',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.NodeNumberOfEntries',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.DestinationMACAddress',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BytesSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BytesReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.PacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.ErrorsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.ErrorsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.UnicastPacketsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.UnicastPacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.DiscardPacketsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.DiscardPacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MulticastPacketsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MulticastPacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BroadcastPacketsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BroadcastPacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.UnknownProtoPacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MgmtBytesSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MgmtBytesReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MgmtPacketsSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.MgmtPacketsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BlocksSent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BlocksReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BlocksResent',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Nodes.Node.{i}.BlocksErrorsReceived',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Channels.',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Channels.TimeStamp',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Channels.ChannelNumberOfEntries',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.DestinationMACAddress',
'Device.Ghn.Diagnostics.PerformanceMonitoring.Channels.Channel.{i}.SNR',
'Device.HomePlug.',
'Device.HomePlug.InterfaceNumberOfEntries',
'Device.HomePlug.Interface.{i}.',
'Device.HomePlug.Interface.{i}.Enable',
'Device.HomePlug.Interface.{i}.Status',
'Device.HomePlug.Interface.{i}.Alias',
'Device.HomePlug.Interface.{i}.Name',
'Device.HomePlug.Interface.{i}.LastChange',
'Device.HomePlug.Interface.{i}.LowerLayers',
'Device.HomePlug.Interface.{i}.Upstream',
'Device.HomePlug.Interface.{i}.MACAddress',
'Device.HomePlug.Interface.{i}.LogicalNetwork',
'Device.HomePlug.Interface.{i}.Version',
'Device.HomePlug.Interface.{i}.FirmwareVersion',
'Device.HomePlug.Interface.{i}.ForceCCo',
'Device.HomePlug.Interface.{i}.NetworkPassword',
'Device.HomePlug.Interface.{i}.OtherNetworksPresent',
'Device.HomePlug.Interface.{i}.MaxBitRate',
'Device.HomePlug.Interface.{i}.AssociatedDeviceNumberOfEntries',
'Device.HomePlug.Interface.{i}.Stats.',
'Device.HomePlug.Interface.{i}.Stats.BytesSent',
'Device.HomePlug.Interface.{i}.Stats.BytesReceived',
'Device.HomePlug.Interface.{i}.Stats.PacketsSent',
'Device.HomePlug.Interface.{i}.Stats.PacketsReceived',
'Device.HomePlug.Interface.{i}.Stats.ErrorsSent',
'Device.HomePlug.Interface.{i}.Stats.ErrorsReceived',
'Device.HomePlug.Interface.{i}.Stats.UnicastPacketsSent',
'Device.HomePlug.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.HomePlug.Interface.{i}.Stats.DiscardPacketsSent',
'Device.HomePlug.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.HomePlug.Interface.{i}.Stats.MulticastPacketsSent',
'Device.HomePlug.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.HomePlug.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.HomePlug.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.HomePlug.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.HomePlug.Interface.{i}.Stats.MPDUTxAck',
'Device.HomePlug.Interface.{i}.Stats.MPDUTxCol',
'Device.HomePlug.Interface.{i}.Stats.MPDUTxFailed',
'Device.HomePlug.Interface.{i}.Stats.MPDURxAck',
'Device.HomePlug.Interface.{i}.Stats.MPDURxFailed',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.MACAddress',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.TxPhyRate',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.RxPhyRate',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.SNRPerTone',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.AvgAttenuation',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.EndStationMACs',
'Device.HomePlug.Interface.{i}.AssociatedDevice.{i}.Active',
'Device.UPA.',
'Device.UPA.InterfaceNumberOfEntries',
'Device.UPA.Interface.{i}.',
'Device.UPA.Interface.{i}.Enable',
'Device.UPA.Interface.{i}.Status',
'Device.UPA.Interface.{i}.Alias',
'Device.UPA.Interface.{i}.Name',
'Device.UPA.Interface.{i}.LastChange',
'Device.UPA.Interface.{i}.LowerLayers',
'Device.UPA.Interface.{i}.Upstream',
'Device.UPA.Interface.{i}.MACAddress',
'Device.UPA.Interface.{i}.FirmwareVersion',
'Device.UPA.Interface.{i}.MaxBitRate',
'Device.UPA.Interface.{i}.NodeType',
'Device.UPA.Interface.{i}.LogicalNetwork',
'Device.UPA.Interface.{i}.EncryptionMethod',
'Device.UPA.Interface.{i}.EncryptionKey',
'Device.UPA.Interface.{i}.PowerBackoffEnabled',
'Device.UPA.Interface.{i}.PowerBackoffMechanismActive',
'Device.UPA.Interface.{i}.EstApplicationThroughput',
'Device.UPA.Interface.{i}.ActiveNotchEnable',
'Device.UPA.Interface.{i}.ActiveNotchNumberOfEntries',
'Device.UPA.Interface.{i}.AssociatedDeviceNumberOfEntries',
'Device.UPA.Interface.{i}.BridgeForNumberOfEntries',
'Device.UPA.Interface.{i}.Stats.',
'Device.UPA.Interface.{i}.Stats.BytesSent',
'Device.UPA.Interface.{i}.Stats.BytesReceived',
'Device.UPA.Interface.{i}.Stats.PacketsSent',
'Device.UPA.Interface.{i}.Stats.PacketsReceived',
'Device.UPA.Interface.{i}.Stats.ErrorsSent',
'Device.UPA.Interface.{i}.Stats.ErrorsReceived',
'Device.UPA.Interface.{i}.Stats.UnicastPacketsSent',
'Device.UPA.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.UPA.Interface.{i}.Stats.DiscardPacketsSent',
'Device.UPA.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.UPA.Interface.{i}.Stats.MulticastPacketsSent',
'Device.UPA.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.UPA.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.UPA.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.UPA.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.MACAddress',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.Port',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.LogicalNetwork',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.PhyTxThroughput',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.PhyRxThroughput',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.RealPhyRxThroughput',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.EstimatedPLR',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.MeanEstimatedAtt',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.SmartRouteIntermediatePLCMAC',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.DirectRoute',
'Device.UPA.Interface.{i}.AssociatedDevice.{i}.Active',
'Device.UPA.Interface.{i}.ActiveNotch.{i}.',
'Device.UPA.Interface.{i}.ActiveNotch.{i}.Enable',
'Device.UPA.Interface.{i}.ActiveNotch.{i}.Alias',
'Device.UPA.Interface.{i}.ActiveNotch.{i}.StartFreq',
'Device.UPA.Interface.{i}.ActiveNotch.{i}.StopFreq',
'Device.UPA.Interface.{i}.ActiveNotch.{i}.Depth',
'Device.UPA.Interface.{i}.BridgeFor.{i}.',
'Device.UPA.Interface.{i}.BridgeFor.{i}.Alias',
'Device.UPA.Interface.{i}.BridgeFor.{i}.MACAddress',
'Device.UPA.Interface.{i}.BridgeFor.{i}.Port',
'Device.UPA.Diagnostics.',
'Device.UPA.Diagnostics.InterfaceMeasurement.',
'Device.UPA.Diagnostics.InterfaceMeasurement.DiagnosticsState',
'Device.UPA.Diagnostics.InterfaceMeasurement.Type',
'Device.UPA.Diagnostics.InterfaceMeasurement.Interface',
'Device.UPA.Diagnostics.InterfaceMeasurement.Port',
'Device.UPA.Diagnostics.InterfaceMeasurement.Measurements',
'Device.UPA.Diagnostics.InterfaceMeasurement.RxGain',
'Device.WiFi.',
'Device.WiFi.RadioNumberOfEntries',
'Device.WiFi.SSIDNumberOfEntries',
'Device.WiFi.AccessPointNumberOfEntries',
'Device.WiFi.EndPointNumberOfEntries',
'Device.WiFi.ResetCounter',
'Device.WiFi.ResetCause',
'Device.WiFi.Reset',
'Device.WiFi.MultiAP.',
'Device.WiFi.MultiAP.APDeviceNumberOfEntries',
'Device.WiFi.MultiAP.SteeringSummaryStats.',
'Device.WiFi.MultiAP.SteeringSummaryStats.NoCandidateAPFailures',
'Device.WiFi.MultiAP.SteeringSummaryStats.BlacklistAttempts',
'Device.WiFi.MultiAP.SteeringSummaryStats.BlacklistSuccesses',
'Device.WiFi.MultiAP.SteeringSummaryStats.BlacklistFailures',
'Device.WiFi.MultiAP.SteeringSummaryStats.BTMAttempts',
'Device.WiFi.MultiAP.SteeringSummaryStats.BTMSuccesses',
'Device.WiFi.MultiAP.SteeringSummaryStats.BTMFailures',
'Device.WiFi.MultiAP.SteeringSummaryStats.BTMQueryResponses',
'Device.WiFi.MultiAP.APDevice.{i}.',
'Device.WiFi.MultiAP.APDevice.{i}.MACAddress',
'Device.WiFi.MultiAP.APDevice.{i}.Manufacturer',
'Device.WiFi.MultiAP.APDevice.{i}.ManufacturerOUI',
'Device.WiFi.MultiAP.APDevice.{i}.ProductClass',
'Device.WiFi.MultiAP.APDevice.{i}.SerialNumber',
'Device.WiFi.MultiAP.APDevice.{i}.SoftwareVersion',
'Device.WiFi.MultiAP.APDevice.{i}.LastContactTime',
'Device.WiFi.MultiAP.APDevice.{i}.AssocIEEE1905DeviceRef',
'Device.WiFi.MultiAP.APDevice.{i}.BackhaulLinkType',
'Device.WiFi.MultiAP.APDevice.{i}.BackhaulMACAddress',
'Device.WiFi.MultiAP.APDevice.{i}.BackhaulBytesSent',
'Device.WiFi.MultiAP.APDevice.{i}.BackhaulBytesReceived',
'Device.WiFi.MultiAP.APDevice.{i}.BackhaulLinkUtilization',
'Device.WiFi.MultiAP.APDevice.{i}.BackhaulSignalStrength',
'Device.WiFi.MultiAP.APDevice.{i}.RadarDetections',
'Device.WiFi.MultiAP.APDevice.{i}.DFSEnable',
'Device.WiFi.MultiAP.APDevice.{i}.RadioNumberOfEntries',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.MACAddress',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.OperatingFrequencyBand',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.OperatingStandards',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.Channel',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.ExtensionChannel',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.PossibleChannels',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.CurrentOperatingChannelBandwidth',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.MCS',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.TransmitPower',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.TransmitPowerLimit',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.APNumberOfEntries',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.BSSID',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.SSID',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.BlacklistAttempts',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.BTMAttempts',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.BTMQueryResponses',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDeviceNumberOfEntries',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.MACAddress',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.OperatingStandard',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Active',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.AssociationTime',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.LastDataDownlinkRate',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.LastDataUplinkRate',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SignalStrength',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Noise',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistoryNumberOfEntries',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.BytesSent',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.BytesReceived',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.PacketsSent',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.PacketsReceived',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.ErrorsSent',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.ErrorsReceived',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.Stats.RetransCount',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.NoCandidateAPFailures',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BlacklistAttempts',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BlacklistSuccesses',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BlacklistFailures',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BTMAttempts',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BTMSuccesses',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BTMFailures',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.BTMQueryResponses',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringSummaryStats.LastSteerTime',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.Time',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.APOrigin',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.TriggerEvent',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.SteeringApproach',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.APDestination',
'Device.WiFi.MultiAP.APDevice.{i}.Radio.{i}.AP.{i}.AssociatedDevice.{i}.SteeringHistory.{i}.SteeringDuration',
'Device.WiFi.DataElements.',
'Device.WiFi.DataElements.Network.',
'Device.WiFi.DataElements.Network.ID',
'Device.WiFi.DataElements.Network.TimeStamp',
'Device.WiFi.DataElements.Network.ControllerID',
'Device.WiFi.DataElements.Network.DeviceNumberOfEntries',
'Device.WiFi.DataElements.Network.MSCSDisallowedStaList',
'Device.WiFi.DataElements.Network.SCSDisallowedStaList',
'Device.WiFi.DataElements.Network.SSIDNumberOfEntries',
'Device.WiFi.DataElements.Network.SSID.{i}.',
'Device.WiFi.DataElements.Network.SSID.{i}.SSID',
'Device.WiFi.DataElements.Network.SSID.{i}.Band',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.NoCandidateAPFailures',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BlacklistAttempts',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BlacklistSuccesses',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BlacklistFailures',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BTMAttempts',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BTMSuccesses',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BTMFailures',
'Device.WiFi.DataElements.Network.MultiAPSteeringSummaryStats.BTMQueryResponses',
'Device.WiFi.DataElements.Network.Device.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.ID',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPCapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.CollectionInterval',
'Device.WiFi.DataElements.Network.Device.{i}.ReportUnsuccessfulAssociations',
'Device.WiFi.DataElements.Network.Device.{i}.MaxReportingRate',
'Device.WiFi.DataElements.Network.Device.{i}.APMetricsReportingInterval',
'Device.WiFi.DataElements.Network.Device.{i}.Manufacturer',
'Device.WiFi.DataElements.Network.Device.{i}.SerialNumber',
'Device.WiFi.DataElements.Network.Device.{i}.ManufacturerModel',
'Device.WiFi.DataElements.Network.Device.{i}.SoftwareVersion',
'Device.WiFi.DataElements.Network.Device.{i}.ExecutionEnv',
'Device.WiFi.DataElements.Network.Device.{i}.DSCPMap',
'Device.WiFi.DataElements.Network.Device.{i}.MaxPrioritizationRules',
'Device.WiFi.DataElements.Network.Device.{i}.PrioritizationSupport',
'Device.WiFi.DataElements.Network.Device.{i}.MaxVIDs',
'Device.WiFi.DataElements.Network.Device.{i}.APMetricsWiFi6',
'Device.WiFi.DataElements.Network.Device.{i}.CountryCode',
'Device.WiFi.DataElements.Network.Device.{i}.LocalSteeringDisallowedSTAList',
'Device.WiFi.DataElements.Network.Device.{i}.BTMSteeringDisallowedSTAList',
'Device.WiFi.DataElements.Network.Device.{i}.DFSEnable',
'Device.WiFi.DataElements.Network.Device.{i}.ReportIndependentScans',
'Device.WiFi.DataElements.Network.Device.{i}.AssociatedSTAinAPMetricsWiFi6',
'Device.WiFi.DataElements.Network.Device.{i}.MaxUnsuccessfulAssociationReportingRate',
'Device.WiFi.DataElements.Network.Device.{i}.STASteeringState',
'Device.WiFi.DataElements.Network.Device.{i}.CoordinatedCACAllowed',
'Device.WiFi.DataElements.Network.Device.{i}.TrafficSeparationAllowed',
'Device.WiFi.DataElements.Network.Device.{i}.ServicePrioritizationAllowed',
'Device.WiFi.DataElements.Network.Device.{i}.RadioNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Default8021QNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.SSIDtoVIDMappingNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatusNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.IEEE1905SecurityNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.SPRuleNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelsNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsageNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Default8021Q.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Default8021Q.{i}.Enable',
'Device.WiFi.DataElements.Network.Device.{i}.Default8021Q.{i}.PrimaryVID',
'Device.WiFi.DataElements.Network.Device.{i}.Default8021Q.{i}.DefaultPCP',
'Device.WiFi.DataElements.Network.Device.{i}.SSIDtoVIDMapping.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.SSIDtoVIDMapping.{i}.SSID',
'Device.WiFi.DataElements.Network.Device.{i}.SSIDtoVIDMapping.{i}.VID',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACAvailableChannelNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACNonOccupancyChannelNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACActiveChannelNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACAvailableChannel.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACAvailableChannel.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACAvailableChannel.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACAvailableChannel.{i}.Minutes',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACNonOccupancyChannel.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACNonOccupancyChannel.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACNonOccupancyChannel.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACNonOccupancyChannel.{i}.Seconds',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACActiveChannel.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACActiveChannel.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACActiveChannel.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.CACStatus.{i}.CACActiveChannel.{i}.Countdown',
'Device.WiFi.DataElements.Network.Device.{i}.SPRule.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.SPRule.{i}.ID',
'Device.WiFi.DataElements.Network.Device.{i}.SPRule.{i}.Precedence',
'Device.WiFi.DataElements.Network.Device.{i}.SPRule.{i}.Output',
'Device.WiFi.DataElements.Network.Device.{i}.SPRule.{i}.AlwaysMatch',
'Device.WiFi.DataElements.Network.Device.{i}.IEEE1905Security.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.IEEE1905Security.{i}.OnboardingProtocol',
'Device.WiFi.DataElements.Network.Device.{i}.IEEE1905Security.{i}.IntegrityAlgorithm',
'Device.WiFi.DataElements.Network.Device.{i}.IEEE1905Security.{i}.EncryptionAlgorithm',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannels.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannels.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannels.{i}.ChannelList',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.ReferenceBSSID',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.EntryNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.BurstStartTime',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.BurstLength',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.Repetitions',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.BurstInterval',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.RUBitmask',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.TransmitterIdentifier',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.PowerLevel',
'Device.WiFi.DataElements.Network.Device.{i}.AnticipatedChannelUsage.{i}.Entry.{i}.ChannelUsageReason',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.ManufacturerOUI',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.LastContactTime',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.AssocIEEE1905DeviceRef',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.EasyMeshControllerOperationMode',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.EasyMeshAgentOperationMode',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.LinkType',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.BackhaulMACAddress',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.BackhaulDeviceID',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.MACAddress',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.CurrentOperatingClassProfileNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.CurrentOperatingClassProfile.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.CurrentOperatingClassProfile.{i}.Class',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.CurrentOperatingClassProfile.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.CurrentOperatingClassProfile.{i}.TxPower',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.CurrentOperatingClassProfile.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.BytesSent',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.BytesReceived',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.PacketsSent',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.PacketsReceived',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.ErrorsSent',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.ErrorsReceived',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.LinkUtilization',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.SignalStrength',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.LastDataDownlinkRate',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.LastDataUplinkRate',
'Device.WiFi.DataElements.Network.Device.{i}.MultiAPDevice.Backhaul.Stats.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Enabled',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Noise',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Utilization',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Transmit',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ReceiveSelf',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ReceiveOther',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.TrafficSeparationCombinedFronthaul',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.TrafficSeparationCombinedBackhaul',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SteeringPolicy',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ChannelUtilizationThreshold',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.RCPISteeringThreshold',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.STAReportingRCPIThreshold',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.STAReportingRCPIHysteresisMarginOverride',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ChannelUtilizationReportingThreshold',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.AssociatedSTATrafficStatsInclusionPolicy',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.AssociatedSTALinkMetricsInclusionPolicy',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ChipsetVendor',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.APMetricsWiFi6',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfileNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.UnassociatedSTANumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSSNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResultNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.DisAllowedOpClassChannelsNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScanNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.OperatingClass',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScanNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.Utilization',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.Noise',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSSNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.BSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.SSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.SignalStrength',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.ChannelBandwidth',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.ChannelUtilization',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanResult.{i}.OpClassScan.{i}.ChannelScan.{i}.NeighborBSS.{i}.StationCount',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BackhaulSta.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BackhaulSta.MACAddress',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.OnBootOnly',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.Impact',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.MinimumInterval',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.OpClassChannelsNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.OpClassChannels.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.OpClassChannels.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.ScanCapability.OpClassChannels.{i}.ChannelList',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethodNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.Method',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.NumberOfSeconds',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.OpClassChannelsNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.OpClassChannels.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.OpClassChannels.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CACCapability.CACMethod.{i}.OpClassChannels.{i}.ChannelList',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.HTCapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.VHTCapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.HECapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.CapableOperatingClassProfileNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMFrontHaulNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMBackhaulNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.HE160',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.HE8080',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MCSNSS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.SUBeamformer',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.SUBeamformee',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MUBeamformer',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.Beamformee80orLess',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.BeamformeeAbove80',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.ULMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.ULOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MaxDLMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MaxULMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MaxDLOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MaxULOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.RTS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MURTS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MultiBSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.MUEDCA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.TWTRequestor',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.TWTResponder',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.SpatialReuse',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6APRole.AnticipatedChannelUsage',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.HE160',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.HE8080',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MCSNSS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.SUBeamformer',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.SUBeamformee',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MUBeamformer',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.Beamformee80orLess',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.BeamformeeAbove80',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.ULMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.ULOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MaxDLMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MaxULMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MaxDLOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MaxULOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.RTS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MURTS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MultiBSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.MUEDCA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.TWTRequestor',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.TWTResponder',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.SpatialReuse',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.WiFi6bSTARole.AnticipatedChannelUsage',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMFrontHaul.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMFrontHaul.{i}.OUI',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMFrontHaul.{i}.Type',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMBackhaul.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMBackhaul.{i}.OUI',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.AKMBackhaul.{i}.Type',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.CapableOperatingClassProfile.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.CapableOperatingClassProfile.{i}.Class',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.CapableOperatingClassProfile.{i}.MaxTxPower',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.CapableOperatingClassProfile.{i}.NonOperable',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.Capabilities.CapableOperatingClassProfile.{i}.NumberOfNonOperChan',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfile.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfile.{i}.Class',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfile.{i}.Channel',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfile.{i}.TxPower',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfile.{i}.TransmitPowerLimit',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.CurrentOperatingClassProfile.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.DisAllowedOpClassChannels.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.DisAllowedOpClassChannels.{i}.Enable',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.DisAllowedOpClassChannels.{i}.OpClass',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.DisAllowedOpClassChannels.{i}.ChannelList',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.PartialBSSColor',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.BSSColor',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.HESIGASpatialReuseValue15Allowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.SRGInformationValid',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.NonSRGOffsetValid',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.PSRDisallowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.NonSRGOBSSPDMaxOffset',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.SRGOBSSPDMinOffset',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.SRGOBSSPDMaxOffset',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.SRGBSSColorBitmap',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.SRGPartialBSSIDBitmap',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.SpatialReuse.NeighborBSSColorInUseBitmap',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.BSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.SSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.Enabled',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.LastChange',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.UnicastBytesSent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.UnicastBytesReceived',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MulticastBytesSent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MulticastBytesReceived',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.BroadcastBytesSent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.BroadcastBytesReceived',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.ByteCounterUnits',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.Profile1bSTAsDisallowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.Profile2bSTAsDisallowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.AssociationAllowanceStatus',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.EstServiceParametersBE',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.EstServiceParametersBK',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.EstServiceParametersVI',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.EstServiceParametersVO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.BackhaulUse',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.FronthaulUse',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.R1disallowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.R2disallowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MultiBSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.TransmittedBSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.FronthaulAKMsAllowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.BackhaulAKMsAllowed',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STANumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.QMDescriptorNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.QMDescriptor.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.QMDescriptor.{i}.ClientMAC',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.QMDescriptor.{i}.DescriptorElement',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MultiAPSteering.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MultiAPSteering.BlacklistAttempts',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MultiAPSteering.BTMAttempts',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.MultiAPSteering.BTMQueryResponses',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MACAddress',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.TimeStamp',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.HTCapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.VHTCapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.HECapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.ClientCapabilities',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.LastDataDownlinkRate',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.LastDataUplinkRate',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.UtilizationReceive',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.UtilizationTransmit',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.EstMACDataRateDownlink',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.EstMACDataRateUplink',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.SignalStrength',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.LastConnectTime',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.BytesSent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.BytesReceived',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.PacketsSent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.PacketsReceived',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.ErrorsSent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.ErrorsReceived',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.RetransCount',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MeasurementReport',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.NumberOfMeasureReports',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.IPV4Address',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.IPV6Address',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.Hostname',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.CellularDataPreference',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.ReAssociationDelay',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.TIDQueueSizesNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.AssociationTime',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.Noise',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistoryNumberOfEntries',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.NoCandidateAPFailures',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BlacklistAttempts',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BlacklistSuccesses',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BlacklistFailures',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BTMAttempts',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BTMSuccesses',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BTMFailures',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.BTMQueryResponses',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringSummaryStats.LastSteerTime',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.Time',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.APOrigin',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.TriggerEvent',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.SteeringApproach',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.APDestination',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.MultiAPSTA.SteeringHistory.{i}.SteeringDuration',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.HE160',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.HE8080',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MCSNSS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.SUBeamformer',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.SUBeamformee',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MUBeamformer',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.Beamformee80orLess',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.BeamformeeAbove80',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.ULMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.ULOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MaxDLMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MaxULMUMIMO',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MaxDLOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MaxULOFDMA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.RTS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MURTS',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MultiBSSID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.MUEDCA',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.TWTRequestor',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.TWTResponder',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.SpatialReuse',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.WiFi6Capabilities.AnticipatedChannelUsage',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.TIDQueueSizes.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.TIDQueueSizes.{i}.TID',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.BSS.{i}.STA.{i}.TIDQueueSizes.{i}.Size',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.UnassociatedSTA.{i}.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.UnassociatedSTA.{i}.MACAddress',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.UnassociatedSTA.{i}.SignalStrength',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.MultiAPRadio.',
'Device.WiFi.DataElements.Network.Device.{i}.Radio.{i}.MultiAPRadio.RadarDetections',
'Device.WiFi.DataElements.AssociationEvent.',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventDataNumberOfEntries',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.BSSID',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.MACAddress',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.StatusCode',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.HTCapabilities',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.VHTCapabilities',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.HECapabilities',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.TimeStamp',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.HE160',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.HE8080',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MCSNSS',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.SUBeamformer',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.SUBeamformee',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MUBeamformer',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.Beamformee80orLess',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.BeamformeeAbove80',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.ULMUMIMO',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.ULOFDMA',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MaxDLMUMIMO',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MaxULMUMIMO',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MaxDLOFDMA',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MaxULOFDMA',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.RTS',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MURTS',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MultiBSSID',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.MUEDCA',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.TWTRequestor',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.TWTResponder',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.SpatialReuse',
'Device.WiFi.DataElements.AssociationEvent.AssociationEventData.{i}.WiFi6Capabilities.AnticipatedChannelUsage',
'Device.WiFi.DataElements.DisassociationEvent.',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventDataNumberOfEntries',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.BSSID',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.MACAddress',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.ReasonCode',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.BytesSent',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.BytesReceived',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.PacketsSent',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.PacketsReceived',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.ErrorsSent',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.ErrorsReceived',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.RetransCount',
'Device.WiFi.DataElements.DisassociationEvent.DisassociationEventData.{i}.TimeStamp',
'Device.WiFi.DataElements.FailedConnectionEvent.',
'Device.WiFi.DataElements.FailedConnectionEvent.FailedConnectionEventDataNumberOfEntries',
'Device.WiFi.DataElements.FailedConnectionEvent.FailedConnectionEventData.{i}.',
'Device.WiFi.DataElements.FailedConnectionEvent.FailedConnectionEventData.{i}.MACAddress',
'Device.WiFi.DataElements.FailedConnectionEvent.FailedConnectionEventData.{i}.StatusCode',
'Device.WiFi.DataElements.FailedConnectionEvent.FailedConnectionEventData.{i}.ReasonCode',
'Device.WiFi.DataElements.FailedConnectionEvent.FailedConnectionEventData.{i}.TimeStamp',
'Device.WiFi.Radio.{i}.',
'Device.WiFi.Radio.{i}.Enable',
'Device.WiFi.Radio.{i}.Status',
'Device.WiFi.Radio.{i}.Alias',
'Device.WiFi.Radio.{i}.Name',
'Device.WiFi.Radio.{i}.LastChange',
'Device.WiFi.Radio.{i}.LowerLayers',
'Device.WiFi.Radio.{i}.Upstream',
'Device.WiFi.Radio.{i}.MaxBitRate',
'Device.WiFi.Radio.{i}.SupportedFrequencyBands',
'Device.WiFi.Radio.{i}.OperatingFrequencyBand',
'Device.WiFi.Radio.{i}.SupportedStandards',
'Device.WiFi.Radio.{i}.OperatingStandards',
'Device.WiFi.Radio.{i}.PossibleChannels',
'Device.WiFi.Radio.{i}.ChannelsInUse',
'Device.WiFi.Radio.{i}.Channel',
'Device.WiFi.Radio.{i}.AutoChannelSupported',
'Device.WiFi.Radio.{i}.AutoChannelEnable',
'Device.WiFi.Radio.{i}.AutoChannelRefreshPeriod',
'Device.WiFi.Radio.{i}.ChannelLastChange',
'Device.WiFi.Radio.{i}.ChannelLastSelectionReason',
'Device.WiFi.Radio.{i}.MaxSupportedSSIDs',
'Device.WiFi.Radio.{i}.MaxSupportedAssociations',
'Device.WiFi.Radio.{i}.FirmwareVersion',
'Device.WiFi.Radio.{i}.SupportedOperatingChannelBandwidths',
'Device.WiFi.Radio.{i}.OperatingChannelBandwidth',
'Device.WiFi.Radio.{i}.CurrentOperatingChannelBandwidth',
'Device.WiFi.Radio.{i}.ExtensionChannel',
'Device.WiFi.Radio.{i}.GuardInterval',
'Device.WiFi.Radio.{i}.CenterFrequencySegement0',
'Device.WiFi.Radio.{i}.CenterFrequencySegement1',
'Device.WiFi.Radio.{i}.CenterFrequencySegment0',
'Device.WiFi.Radio.{i}.CenterFrequencySegment1',
'Device.WiFi.Radio.{i}.MCS',
'Device.WiFi.Radio.{i}.TransmitPowerSupported',
'Device.WiFi.Radio.{i}.TransmitPower',
'Device.WiFi.Radio.{i}.IEEE80211hSupported',
'Device.WiFi.Radio.{i}.IEEE80211hEnabled',
'Device.WiFi.Radio.{i}.RegulatoryDomain',
'Device.WiFi.Radio.{i}.RetryLimit',
'Device.WiFi.Radio.{i}.CCARequest',
'Device.WiFi.Radio.{i}.CCAReport',
'Device.WiFi.Radio.{i}.RPIHistogramRequest',
'Device.WiFi.Radio.{i}.RPIHistogramReport',
'Device.WiFi.Radio.{i}.FragmentationThreshold',
'Device.WiFi.Radio.{i}.RTSThreshold',
'Device.WiFi.Radio.{i}.LongRetryLimit',
'Device.WiFi.Radio.{i}.BeaconPeriod',
'Device.WiFi.Radio.{i}.DTIMPeriod',
'Device.WiFi.Radio.{i}.PacketAggregationEnable',
'Device.WiFi.Radio.{i}.PreambleType',
'Device.WiFi.Radio.{i}.BasicDataTransmitRates',
'Device.WiFi.Radio.{i}.OperationalDataTransmitRates',
'Device.WiFi.Radio.{i}.SupportedDataTransmitRates',
'Device.WiFi.Radio.{i}.EnableRRM',
'Device.WiFi.Radio.{i}.ManagementPacketRate',
'Device.WiFi.Radio.{i}.Stats.',
'Device.WiFi.Radio.{i}.Stats.CtsReceived',
'Device.WiFi.Radio.{i}.Stats.NoCtsReceived',
'Device.WiFi.Radio.{i}.Stats.FrameHeaderError',
'Device.WiFi.Radio.{i}.Stats.GoodPLCPReceived',
'Device.WiFi.Radio.{i}.Stats.DPacketOtherMACReceived',
'Device.WiFi.Radio.{i}.Stats.MPacketOtherMACReceived',
'Device.WiFi.Radio.{i}.Stats.CPacketOtherMACReceived',
'Device.WiFi.Radio.{i}.Stats.CtsOtherMACReceived',
'Device.WiFi.Radio.{i}.Stats.RtsOtherMACReceived',
'Device.WiFi.Radio.{i}.Stats.BytesSent',
'Device.WiFi.Radio.{i}.Stats.BytesReceived',
'Device.WiFi.Radio.{i}.Stats.PacketsSent',
'Device.WiFi.Radio.{i}.Stats.PacketsReceived',
'Device.WiFi.Radio.{i}.Stats.ErrorsSent',
'Device.WiFi.Radio.{i}.Stats.ErrorsReceived',
'Device.WiFi.Radio.{i}.Stats.DiscardPacketsSent',
'Device.WiFi.Radio.{i}.Stats.DiscardPacketsReceived',
'Device.WiFi.Radio.{i}.Stats.PLCPErrorCount',
'Device.WiFi.Radio.{i}.Stats.FCSErrorCount',
'Device.WiFi.Radio.{i}.Stats.InvalidMACCount',
'Device.WiFi.Radio.{i}.Stats.PacketsOtherReceived',
'Device.WiFi.Radio.{i}.Stats.Noise',
'Device.WiFi.Radio.{i}.Stats.TotalChannelChangeCount',
'Device.WiFi.Radio.{i}.Stats.ManualChannelChangeCount',
'Device.WiFi.Radio.{i}.Stats.AutoStartupChannelChangeCount',
'Device.WiFi.Radio.{i}.Stats.AutoUserChannelChangeCount',
'Device.WiFi.Radio.{i}.Stats.AutoRefreshChannelChangeCount',
'Device.WiFi.Radio.{i}.Stats.AutoDynamicChannelChangeCount',
'Device.WiFi.Radio.{i}.Stats.AutoDFSChannelChangeCount',
'Device.WiFi.CCAMeasurementDiagnostic.',
'Device.WiFi.CCAMeasurementDiagnostic.DiagnosticsState',
'Device.WiFi.CCAMeasurementDiagnostic.Channel',
'Device.WiFi.CCAMeasurementDiagnostic.DwellTime',
'Device.WiFi.CCAMeasurementDiagnostic.CCAReport',
'Device.WiFi.RemoteMeasurementDiagnostic.',
'Device.WiFi.RemoteMeasurementDiagnostic.DiagnosticsState',
'Device.WiFi.RemoteMeasurementDiagnostic.TargetBSS',
'Device.WiFi.RemoteMeasurementDiagnostic.Timeout',
'Device.WiFi.RemoteMeasurementDiagnostic.OperatingClass',
'Device.WiFi.RemoteMeasurementDiagnostic.Channel',
'Device.WiFi.RemoteMeasurementDiagnostic.SignalStrength',
'Device.WiFi.RemoteMeasurementDiagnostic.Noise',
'Device.WiFi.NeighboringWiFiDiagnostic.',
'Device.WiFi.NeighboringWiFiDiagnostic.DiagnosticsState',
'Device.WiFi.NeighboringWiFiDiagnostic.ResultNumberOfEntries',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.Radio',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.SSID',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.BSSID',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.Mode',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.Channel',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.SignalStrength',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.SecurityModeEnabled',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.EncryptionMode',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.OperatingFrequencyBand',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.SupportedStandards',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.OperatingStandards',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.OperatingChannelBandwidth',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.BeaconPeriod',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.Noise',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.BasicDataTransferRates',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.SupportedDataTransferRates',
'Device.WiFi.NeighboringWiFiDiagnostic.Result.{i}.DTIMPeriod',
'Device.WiFi.SSID.{i}.',
'Device.WiFi.SSID.{i}.Enable',
'Device.WiFi.SSID.{i}.Status',
'Device.WiFi.SSID.{i}.Alias',
'Device.WiFi.SSID.{i}.Name',
'Device.WiFi.SSID.{i}.LastChange',
'Device.WiFi.SSID.{i}.LowerLayers',
'Device.WiFi.SSID.{i}.BSSID',
'Device.WiFi.SSID.{i}.MACAddress',
'Device.WiFi.SSID.{i}.SSID',
'Device.WiFi.SSID.{i}.Upstream',
'Device.WiFi.SSID.{i}.ATFEnable',
'Device.WiFi.SSID.{i}.FlushATFTable',
'Device.WiFi.SSID.{i}.SetATF',
'Device.WiFi.SSID.{i}.Stats.',
'Device.WiFi.SSID.{i}.Stats.BytesSent',
'Device.WiFi.SSID.{i}.Stats.BytesReceived',
'Device.WiFi.SSID.{i}.Stats.PacketsSent',
'Device.WiFi.SSID.{i}.Stats.PacketsReceived',
'Device.WiFi.SSID.{i}.Stats.ErrorsSent',
'Device.WiFi.SSID.{i}.Stats.RetransCount',
'Device.WiFi.SSID.{i}.Stats.FailedRetransCount',
'Device.WiFi.SSID.{i}.Stats.RetryCount',
'Device.WiFi.SSID.{i}.Stats.MultipleRetryCount',
'Device.WiFi.SSID.{i}.Stats.ACKFailureCount',
'Device.WiFi.SSID.{i}.Stats.AggregatedPacketCount',
'Device.WiFi.SSID.{i}.Stats.ErrorsReceived',
'Device.WiFi.SSID.{i}.Stats.UnicastPacketsSent',
'Device.WiFi.SSID.{i}.Stats.UnicastPacketsReceived',
'Device.WiFi.SSID.{i}.Stats.DiscardPacketsSent',
'Device.WiFi.SSID.{i}.Stats.DiscardPacketsReceived',
'Device.WiFi.SSID.{i}.Stats.MulticastPacketsSent',
'Device.WiFi.SSID.{i}.Stats.MulticastPacketsReceived',
'Device.WiFi.SSID.{i}.Stats.BroadcastPacketsSent',
'Device.WiFi.SSID.{i}.Stats.BroadcastPacketsReceived',
'Device.WiFi.SSID.{i}.Stats.UnknownProtoPacketsReceived',
'Device.WiFi.SSID.{i}.Stats.DiscardPacketsSentBufOverflow',
'Device.WiFi.SSID.{i}.Stats.DiscardPacketsSentNoAssoc',
'Device.WiFi.SSID.{i}.Stats.FragSent',
'Device.WiFi.SSID.{i}.Stats.SentNoAck',
'Device.WiFi.SSID.{i}.Stats.DupReceived',
'Device.WiFi.SSID.{i}.Stats.TooLongReceived',
'Device.WiFi.SSID.{i}.Stats.TooShortReceived',
'Device.WiFi.SSID.{i}.Stats.AckUcastReceived',
'Device.WiFi.AccessPoint.{i}.',
'Device.WiFi.AccessPoint.{i}.Enable',
'Device.WiFi.AccessPoint.{i}.Status',
'Device.WiFi.AccessPoint.{i}.Alias',
'Device.WiFi.AccessPoint.{i}.SSIDReference',
'Device.WiFi.AccessPoint.{i}.SSIDAdvertisementEnabled',
'Device.WiFi.AccessPoint.{i}.RetryLimit',
'Device.WiFi.AccessPoint.{i}.WMMCapability',
'Device.WiFi.AccessPoint.{i}.UAPSDCapability',
'Device.WiFi.AccessPoint.{i}.WMMEnable',
'Device.WiFi.AccessPoint.{i}.UAPSDEnable',
'Device.WiFi.AccessPoint.{i}.AssociatedDeviceNumberOfEntries',
'Device.WiFi.AccessPoint.{i}.MaxAssociatedDevices',
'Device.WiFi.AccessPoint.{i}.IsolationEnable',
'Device.WiFi.AccessPoint.{i}.MACAddressControlEnabled',
'Device.WiFi.AccessPoint.{i}.AllowedMACAddress',
'Device.WiFi.AccessPoint.{i}.MaxAllowedAssociations',
'Device.WiFi.AccessPoint.{i}.CpeOperationMode',
'Device.WiFi.AccessPoint.{i}.Security.',
'Device.WiFi.AccessPoint.{i}.Security.ModesSupported',
'Device.WiFi.AccessPoint.{i}.Security.ModeEnabled',
'Device.WiFi.AccessPoint.{i}.Security.EncryptionMode',
'Device.WiFi.AccessPoint.{i}.Security.WEPKey',
'Device.WiFi.AccessPoint.{i}.Security.PreSharedKey',
'Device.WiFi.AccessPoint.{i}.Security.KeyPassphrase',
'Device.WiFi.AccessPoint.{i}.Security.RekeyingInterval',
'Device.WiFi.AccessPoint.{i}.Security.SAEPassphrase',
'Device.WiFi.AccessPoint.{i}.Security.RadiusServerIPAddr',
'Device.WiFi.AccessPoint.{i}.Security.SecondaryRadiusServerIPAddr',
'Device.WiFi.AccessPoint.{i}.Security.RadiusServerPort',
'Device.WiFi.AccessPoint.{i}.Security.SecondaryRadiusServerPort',
'Device.WiFi.AccessPoint.{i}.Security.RadiusSecret',
'Device.WiFi.AccessPoint.{i}.Security.SecondaryRadiusSecret',
'Device.WiFi.AccessPoint.{i}.Security.MFPConfig',
'Device.WiFi.AccessPoint.{i}.Security.Reset',
'Device.WiFi.AccessPoint.{i}.WPS.',
'Device.WiFi.AccessPoint.{i}.WPS.Enable',
'Device.WiFi.AccessPoint.{i}.WPS.ConfigMethodsSupported',
'Device.WiFi.AccessPoint.{i}.WPS.ConfigMethodsEnabled',
'Device.WiFi.AccessPoint.{i}.WPS.Status',
'Device.WiFi.AccessPoint.{i}.WPS.Version',
'Device.WiFi.AccessPoint.{i}.WPS.PIN',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.MACAddress',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Type',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.SetStaATF',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.OperatingStandard',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.AuthenticationState',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.LastDataDownlinkRate',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.LastDataUplinkRate',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.AssociationTime',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.SignalStrength',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Noise',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Retransmissions',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Active',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.BytesSent',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.BytesReceived',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.PacketsSent',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.PacketsReceived',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.ErrorsSent',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.ErrorsReceived',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.RetransCount',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.FailedRetransCount',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.RetryCount',
'Device.WiFi.AccessPoint.{i}.AssociatedDevice.{i}.Stats.MultipleRetryCount',
'Device.WiFi.AccessPoint.{i}.AC.{i}.',
'Device.WiFi.AccessPoint.{i}.AC.{i}.AccessCategory',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Alias',
'Device.WiFi.AccessPoint.{i}.AC.{i}.AIFSN',
'Device.WiFi.AccessPoint.{i}.AC.{i}.ECWMin',
'Device.WiFi.AccessPoint.{i}.AC.{i}.ECWMax',
'Device.WiFi.AccessPoint.{i}.AC.{i}.TxOpMax',
'Device.WiFi.AccessPoint.{i}.AC.{i}.AckPolicy',
'Device.WiFi.AccessPoint.{i}.AC.{i}.OutQLenHistogramIntervals',
'Device.WiFi.AccessPoint.{i}.AC.{i}.OutQLenHistogramSampleInterval',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.BytesSent',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.BytesReceived',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.PacketsSent',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.PacketsReceived',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.ErrorsSent',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.ErrorsReceived',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.DiscardPacketsSent',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.DiscardPacketsReceived',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.RetransCount',
'Device.WiFi.AccessPoint.{i}.AC.{i}.Stats.OutQLenHistogram',
'Device.WiFi.AccessPoint.{i}.Accounting.',
'Device.WiFi.AccessPoint.{i}.Accounting.Enable',
'Device.WiFi.AccessPoint.{i}.Accounting.ServerIPAddr',
'Device.WiFi.AccessPoint.{i}.Accounting.SecondaryServerIPAddr',
'Device.WiFi.AccessPoint.{i}.Accounting.ServerPort',
'Device.WiFi.AccessPoint.{i}.Accounting.SecondaryServerPort',
'Device.WiFi.AccessPoint.{i}.Accounting.Secret',
'Device.WiFi.AccessPoint.{i}.Accounting.SecondarySecret',
'Device.WiFi.AccessPoint.{i}.Accounting.InterimInterval',
'Device.WiFi.EndPoint.{i}.',
'Device.WiFi.EndPoint.{i}.Enable',
'Device.WiFi.EndPoint.{i}.Status',
'Device.WiFi.EndPoint.{i}.Alias',
'Device.WiFi.EndPoint.{i}.ProfileReference',
'Device.WiFi.EndPoint.{i}.SSIDReference',
'Device.WiFi.EndPoint.{i}.ProfileNumberOfEntries',
'Device.WiFi.EndPoint.{i}.Stats.',
'Device.WiFi.EndPoint.{i}.Stats.LastDataDownlinkRate',
'Device.WiFi.EndPoint.{i}.Stats.LastDataUplinkRate',
'Device.WiFi.EndPoint.{i}.Stats.SignalStrength',
'Device.WiFi.EndPoint.{i}.Stats.Retransmissions',
'Device.WiFi.EndPoint.{i}.Security.',
'Device.WiFi.EndPoint.{i}.Security.ModesSupported',
'Device.WiFi.EndPoint.{i}.Profile.{i}.',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Enable',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Status',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Alias',
'Device.WiFi.EndPoint.{i}.Profile.{i}.SSID',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Location',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Priority',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.ModeEnabled',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.WEPKey',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.PreSharedKey',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.KeyPassphrase',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.SAEPassphrase',
'Device.WiFi.EndPoint.{i}.Profile.{i}.Security.MFPConfig',
'Device.WiFi.EndPoint.{i}.WPS.',
'Device.WiFi.EndPoint.{i}.WPS.Enable',
'Device.WiFi.EndPoint.{i}.WPS.ConfigMethodsSupported',
'Device.WiFi.EndPoint.{i}.WPS.ConfigMethodsEnabled',
'Device.WiFi.EndPoint.{i}.WPS.Status',
'Device.WiFi.EndPoint.{i}.WPS.Version',
'Device.WiFi.EndPoint.{i}.WPS.PIN',
'Device.WiFi.EndPoint.{i}.AC.{i}.',
'Device.WiFi.EndPoint.{i}.AC.{i}.AccessCategory',
'Device.WiFi.EndPoint.{i}.AC.{i}.Alias',
'Device.WiFi.EndPoint.{i}.AC.{i}.AIFSN',
'Device.WiFi.EndPoint.{i}.AC.{i}.ECWMin',
'Device.WiFi.EndPoint.{i}.AC.{i}.ECWMax',
'Device.WiFi.EndPoint.{i}.AC.{i}.TxOpMax',
'Device.WiFi.EndPoint.{i}.AC.{i}.AckPolicy',
'Device.WiFi.EndPoint.{i}.AC.{i}.OutQLenHistogramIntervals',
'Device.WiFi.EndPoint.{i}.AC.{i}.OutQLenHistogramSampleInterval',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.BytesSent',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.BytesReceived',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.PacketsSent',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.PacketsReceived',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.ErrorsSent',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.ErrorsReceived',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.DiscardPacketsSent',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.DiscardPacketsReceived',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.RetransCount',
'Device.WiFi.EndPoint.{i}.AC.{i}.Stats.OutQLenHistogram',
'Device.ZigBee.',
'Device.ZigBee.InterfaceNumberOfEntries',
'Device.ZigBee.ZDONumberOfEntries',
'Device.ZigBee.Interface.{i}.',
'Device.ZigBee.Interface.{i}.Enable',
'Device.ZigBee.Interface.{i}.Status',
'Device.ZigBee.Interface.{i}.Alias',
'Device.ZigBee.Interface.{i}.Name',
'Device.ZigBee.Interface.{i}.LastChange',
'Device.ZigBee.Interface.{i}.LowerLayers',
'Device.ZigBee.Interface.{i}.IEEEAddress',
'Device.ZigBee.Interface.{i}.NetworkAddress',
'Device.ZigBee.Interface.{i}.ZDOReference',
'Device.ZigBee.Interface.{i}.AssociatedDeviceNumberOfEntries',
'Device.ZigBee.Interface.{i}.Stats.',
'Device.ZigBee.Interface.{i}.Stats.BytesSent',
'Device.ZigBee.Interface.{i}.Stats.BytesReceived',
'Device.ZigBee.Interface.{i}.Stats.PacketsSent',
'Device.ZigBee.Interface.{i}.Stats.PacketsReceived',
'Device.ZigBee.Interface.{i}.Stats.ErrorsSent',
'Device.ZigBee.Interface.{i}.Stats.ErrorsReceived',
'Device.ZigBee.Interface.{i}.Stats.UnicastPacketsSent',
'Device.ZigBee.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.ZigBee.Interface.{i}.Stats.DiscardPacketsSent',
'Device.ZigBee.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.ZigBee.Interface.{i}.Stats.MulticastPacketsSent',
'Device.ZigBee.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.ZigBee.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.ZigBee.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.ZigBee.Interface.{i}.Stats.UnknownPacketsReceived',
'Device.ZigBee.Interface.{i}.AssociatedDevice.{i}.',
'Device.ZigBee.Interface.{i}.AssociatedDevice.{i}.IEEEAddress',
'Device.ZigBee.Interface.{i}.AssociatedDevice.{i}.NetworkAddress',
'Device.ZigBee.Interface.{i}.AssociatedDevice.{i}.Active',
'Device.ZigBee.Interface.{i}.AssociatedDevice.{i}.ZDOReference',
'Device.ZigBee.ZDO.{i}.',
'Device.ZigBee.ZDO.{i}.Alias',
'Device.ZigBee.ZDO.{i}.IEEEAddress',
'Device.ZigBee.ZDO.{i}.NetworkAddress',
'Device.ZigBee.ZDO.{i}.BindingTableNumberOfEntries',
'Device.ZigBee.ZDO.{i}.GroupNumberOfEntries',
'Device.ZigBee.ZDO.{i}.ApplicationEndpointNumberOfEntries',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.LogicalType',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.ComplexDescriptorSupported',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.UserDescriptorSupported',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.FrequencyBand',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.MACCapability',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.ManufactureCode',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.MaximumBufferSize',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.MaximumIncomingTransferSize',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.MaximumOutgoingTransferSize',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.ServerMask',
'Device.ZigBee.ZDO.{i}.NodeDescriptor.DescriptorCapability',
'Device.ZigBee.ZDO.{i}.PowerDescriptor.',
'Device.ZigBee.ZDO.{i}.PowerDescriptor.CurrentPowerMode',
'Device.ZigBee.ZDO.{i}.PowerDescriptor.AvailablePowerSource',
'Device.ZigBee.ZDO.{i}.PowerDescriptor.CurrentPowerSource',
'Device.ZigBee.ZDO.{i}.PowerDescriptor.CurrentPowerSourceLevel',
'Device.ZigBee.ZDO.{i}.UserDescriptor.',
'Device.ZigBee.ZDO.{i}.UserDescriptor.DescriptorAvailable',
'Device.ZigBee.ZDO.{i}.UserDescriptor.Description',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.DescriptorAvailable',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.Language',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.CharacterSet',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.ManufacturerName',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.ModelName',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.SerialNumber',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.DeviceURL',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.Icon',
'Device.ZigBee.ZDO.{i}.ComplexDescriptor.IconURL',
'Device.ZigBee.ZDO.{i}.Security.',
'Device.ZigBee.ZDO.{i}.Security.TrustCenterAddress',
'Device.ZigBee.ZDO.{i}.Security.SecurityLevel',
'Device.ZigBee.ZDO.{i}.Security.TimeOutPeriod',
'Device.ZigBee.ZDO.{i}.Network.',
'Device.ZigBee.ZDO.{i}.Network.NeighborNumberOfEntries',
'Device.ZigBee.ZDO.{i}.Network.Neighbor.{i}.',
'Device.ZigBee.ZDO.{i}.Network.Neighbor.{i}.Neighbor',
'Device.ZigBee.ZDO.{i}.Network.Neighbor.{i}.LQI',
'Device.ZigBee.ZDO.{i}.Network.Neighbor.{i}.Relationship',
'Device.ZigBee.ZDO.{i}.Network.Neighbor.{i}.PermitJoin',
'Device.ZigBee.ZDO.{i}.Network.Neighbor.{i}.Depth',
'Device.ZigBee.ZDO.{i}.NodeManager.',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTableNumberOfEntries',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.DestinationAddress',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.NextHopAddress',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.Status',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.MemoryConstrained',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.ManyToOne',
'Device.ZigBee.ZDO.{i}.NodeManager.RoutingTable.{i}.RouteRecordRequired',
'Device.ZigBee.ZDO.{i}.Binding.{i}.',
'Device.ZigBee.ZDO.{i}.Binding.{i}.Enable',
'Device.ZigBee.ZDO.{i}.Binding.{i}.Alias',
'Device.ZigBee.ZDO.{i}.Binding.{i}.SourceEndpoint',
'Device.ZigBee.ZDO.{i}.Binding.{i}.SourceAddress',
'Device.ZigBee.ZDO.{i}.Binding.{i}.ClusterId',
'Device.ZigBee.ZDO.{i}.Binding.{i}.DestinationAddressMode',
'Device.ZigBee.ZDO.{i}.Binding.{i}.DestinationEndpoint',
'Device.ZigBee.ZDO.{i}.Binding.{i}.IEEEDestinationAddress',
'Device.ZigBee.ZDO.{i}.Binding.{i}.GroupDestinationAddress',
'Device.ZigBee.ZDO.{i}.Group.{i}.',
'Device.ZigBee.ZDO.{i}.Group.{i}.Enable',
'Device.ZigBee.ZDO.{i}.Group.{i}.Alias',
'Device.ZigBee.ZDO.{i}.Group.{i}.GroupId',
'Device.ZigBee.ZDO.{i}.Group.{i}.EndpointList',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.Enable',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.Alias',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.EndpointId',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.SimpleDescriptor.',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.SimpleDescriptor.ProfileId',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.SimpleDescriptor.DeviceId',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.SimpleDescriptor.DeviceVersion',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.SimpleDescriptor.InputClusterList',
'Device.ZigBee.ZDO.{i}.ApplicationEndpoint.{i}.SimpleDescriptor.OutputClusterList',
'Device.ZigBee.Discovery.',
'Device.ZigBee.Discovery.AreaNetworkNumberOfEntries',
'Device.ZigBee.Discovery.AreaNetwork.{i}.',
'Device.ZigBee.Discovery.AreaNetwork.{i}.Enable',
'Device.ZigBee.Discovery.AreaNetwork.{i}.LastUpdate',
'Device.ZigBee.Discovery.AreaNetwork.{i}.Status',
'Device.ZigBee.Discovery.AreaNetwork.{i}.Alias',
'Device.ZigBee.Discovery.AreaNetwork.{i}.Coordinator',
'Device.ZigBee.Discovery.AreaNetwork.{i}.ZDOReference',
'Device.ZigBee.Discovery.AreaNetwork.{i}.ZDOList',
'Device.Bridging.',
'Device.Bridging.MaxBridgeEntries',
'Device.Bridging.MaxDBridgeEntries',
'Device.Bridging.MaxQBridgeEntries',
'Device.Bridging.MaxVLANEntries',
'Device.Bridging.MaxProviderBridgeEntries',
'Device.Bridging.ProviderBridgeNumberOfEntries',
'Device.Bridging.MaxFilterEntries',
'Device.Bridging.BridgeNumberOfEntries',
'Device.Bridging.FilterNumberOfEntries',
'Device.Bridging.Bridge.{i}.',
'Device.Bridging.Bridge.{i}.Enable',
'Device.Bridging.Bridge.{i}.Status',
'Device.Bridging.Bridge.{i}.Alias',
'Device.Bridging.Bridge.{i}.Standard',
'Device.Bridging.Bridge.{i}.PortNumberOfEntries',
'Device.Bridging.Bridge.{i}.VLANNumberOfEntries',
'Device.Bridging.Bridge.{i}.VLANPortNumberOfEntries',
'Device.Bridging.Bridge.{i}.Port.{i}.',
'Device.Bridging.Bridge.{i}.Port.{i}.Enable',
'Device.Bridging.Bridge.{i}.Port.{i}.Status',
'Device.Bridging.Bridge.{i}.Port.{i}.Alias',
'Device.Bridging.Bridge.{i}.Port.{i}.Name',
'Device.Bridging.Bridge.{i}.Port.{i}.LastChange',
'Device.Bridging.Bridge.{i}.Port.{i}.LowerLayers',
'Device.Bridging.Bridge.{i}.Port.{i}.ManagementPort',
'Device.Bridging.Bridge.{i}.Port.{i}.Type',
'Device.Bridging.Bridge.{i}.Port.{i}.DefaultUserPriority',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityRegeneration',
'Device.Bridging.Bridge.{i}.Port.{i}.PortState',
'Device.Bridging.Bridge.{i}.Port.{i}.PVID',
'Device.Bridging.Bridge.{i}.Port.{i}.TPID',
'Device.Bridging.Bridge.{i}.Port.{i}.AcceptableFrameTypes',
'Device.Bridging.Bridge.{i}.Port.{i}.IngressFiltering',
'Device.Bridging.Bridge.{i}.Port.{i}.ServiceAccessPrioritySelection',
'Device.Bridging.Bridge.{i}.Port.{i}.ServiceAccessPriorityTranslation',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityTagging',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.BytesSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.BytesReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.PacketsSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.PacketsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.ErrorsSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.ErrorsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.UnicastPacketsSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.UnicastPacketsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.DiscardPacketsSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.DiscardPacketsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.MulticastPacketsSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.MulticastPacketsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.BroadcastPacketsSent',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.BroadcastPacketsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.Stats.UnknownProtoPacketsReceived',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityCodePoint.',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityCodePoint.PCPSelection',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityCodePoint.UseDEI',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityCodePoint.RequireDropEncoding',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityCodePoint.PCPEncoding',
'Device.Bridging.Bridge.{i}.Port.{i}.PriorityCodePoint.PCPDecoding',
'Device.Bridging.Bridge.{i}.VLAN.{i}.',
'Device.Bridging.Bridge.{i}.VLAN.{i}.Enable',
'Device.Bridging.Bridge.{i}.VLAN.{i}.Alias',
'Device.Bridging.Bridge.{i}.VLAN.{i}.Name',
'Device.Bridging.Bridge.{i}.VLAN.{i}.VLANID',
'Device.Bridging.Bridge.{i}.VLANPort.{i}.',
'Device.Bridging.Bridge.{i}.VLANPort.{i}.Enable',
'Device.Bridging.Bridge.{i}.VLANPort.{i}.Alias',
'Device.Bridging.Bridge.{i}.VLANPort.{i}.VLAN',
'Device.Bridging.Bridge.{i}.VLANPort.{i}.Port',
'Device.Bridging.Bridge.{i}.VLANPort.{i}.Untagged',
'Device.Bridging.Filter.{i}.',
'Device.Bridging.Filter.{i}.Enable',
'Device.Bridging.Filter.{i}.Alias',
'Device.Bridging.Filter.{i}.Status',
'Device.Bridging.Filter.{i}.Bridge',
'Device.Bridging.Filter.{i}.Order',
'Device.Bridging.Filter.{i}.Interface',
'Device.Bridging.Filter.{i}.DHCPType',
'Device.Bridging.Filter.{i}.VLANIDFilter',
'Device.Bridging.Filter.{i}.EthertypeFilterList',
'Device.Bridging.Filter.{i}.EthertypeFilterExclude',
'Device.Bridging.Filter.{i}.SourceMACAddressFilterList',
'Device.Bridging.Filter.{i}.SourceMACAddressFilterExclude',
'Device.Bridging.Filter.{i}.DestMACAddressFilterList',
'Device.Bridging.Filter.{i}.DestMACAddressFilterExclude',
'Device.Bridging.Filter.{i}.SourceMACFromVendorClassIDFilter',
'Device.Bridging.Filter.{i}.SourceMACFromVendorClassIDFilterv6',
'Device.Bridging.Filter.{i}.SourceMACFromVendorClassIDFilterExclude',
'Device.Bridging.Filter.{i}.SourceMACFromVendorClassIDMode',
'Device.Bridging.Filter.{i}.DestMACFromVendorClassIDFilter',
'Device.Bridging.Filter.{i}.DestMACFromVendorClassIDFilterv6',
'Device.Bridging.Filter.{i}.DestMACFromVendorClassIDFilterExclude',
'Device.Bridging.Filter.{i}.DestMACFromVendorClassIDMode',
'Device.Bridging.Filter.{i}.SourceMACFromClientIDFilter',
'Device.Bridging.Filter.{i}.SourceMACFromClientIDFilterExclude',
'Device.Bridging.Filter.{i}.DestMACFromClientIDFilter',
'Device.Bridging.Filter.{i}.DestMACFromClientIDFilterExclude',
'Device.Bridging.Filter.{i}.SourceMACFromUserClassIDFilter',
'Device.Bridging.Filter.{i}.SourceMACFromUserClassIDFilterExclude',
'Device.Bridging.Filter.{i}.DestMACFromUserClassIDFilter',
'Device.Bridging.Filter.{i}.DestMACFromUserClassIDFilterExclude',
'Device.Bridging.Filter.{i}.DestIP',
'Device.Bridging.Filter.{i}.DestMask',
'Device.Bridging.Filter.{i}.DestIPExclude',
'Device.Bridging.Filter.{i}.SourceIP',
'Device.Bridging.Filter.{i}.SourceMask',
'Device.Bridging.Filter.{i}.SourceIPExclude',
'Device.Bridging.Filter.{i}.Protocol',
'Device.Bridging.Filter.{i}.ProtocolExclude',
'Device.Bridging.Filter.{i}.DestPort',
'Device.Bridging.Filter.{i}.DestPortRangeMax',
'Device.Bridging.Filter.{i}.DestPortExclude',
'Device.Bridging.Filter.{i}.SourcePort',
'Device.Bridging.Filter.{i}.SourcePortRangeMax',
'Device.Bridging.Filter.{i}.SourcePortExclude',
'Device.Bridging.ProviderBridge.{i}.',
'Device.Bridging.ProviderBridge.{i}.Enable',
'Device.Bridging.ProviderBridge.{i}.Status',
'Device.Bridging.ProviderBridge.{i}.Alias',
'Device.Bridging.ProviderBridge.{i}.Type',
'Device.Bridging.ProviderBridge.{i}.SVLANcomponent',
'Device.Bridging.ProviderBridge.{i}.CVLANcomponents',
'Device.PPP.',
'Device.PPP.InterfaceNumberOfEntries',
'Device.PPP.SupportedNCPs',
'Device.PPP.Interface.{i}.',
'Device.PPP.Interface.{i}.Enable',
'Device.PPP.Interface.{i}.Status',
'Device.PPP.Interface.{i}.Alias',
'Device.PPP.Interface.{i}.Name',
'Device.PPP.Interface.{i}.LastChange',
'Device.PPP.Interface.{i}.LowerLayers',
'Device.PPP.Interface.{i}.Reset',
'Device.PPP.Interface.{i}.ConnectionStatus',
'Device.PPP.Interface.{i}.LastConnectionError',
'Device.PPP.Interface.{i}.AutoDisconnectTime',
'Device.PPP.Interface.{i}.IdleDisconnectTime',
'Device.PPP.Interface.{i}.WarnDisconnectDelay',
'Device.PPP.Interface.{i}.Username',
'Device.PPP.Interface.{i}.Password',
'Device.PPP.Interface.{i}.EncryptionProtocol',
'Device.PPP.Interface.{i}.CompressionProtocol',
'Device.PPP.Interface.{i}.AuthenticationProtocol',
'Device.PPP.Interface.{i}.MaxMRUSize',
'Device.PPP.Interface.{i}.CurrentMRUSize',
'Device.PPP.Interface.{i}.ConnectionTrigger',
'Device.PPP.Interface.{i}.LCPEcho',
'Device.PPP.Interface.{i}.LCPEchoRetry',
'Device.PPP.Interface.{i}.IPCPEnable',
'Device.PPP.Interface.{i}.IPv6CPEnable',
'Device.PPP.Interface.{i}.PPPoE.',
'Device.PPP.Interface.{i}.PPPoE.SessionID',
'Device.PPP.Interface.{i}.PPPoE.ACName',
'Device.PPP.Interface.{i}.PPPoE.ServiceName',
'Device.PPP.Interface.{i}.IPCP.',
'Device.PPP.Interface.{i}.IPCP.LocalIPAddress',
'Device.PPP.Interface.{i}.IPCP.RemoteIPAddress',
'Device.PPP.Interface.{i}.IPCP.DNSServers',
'Device.PPP.Interface.{i}.IPCP.PassthroughEnable',
'Device.PPP.Interface.{i}.IPCP.PassthroughDHCPPool',
'Device.PPP.Interface.{i}.IPv6CP.',
'Device.PPP.Interface.{i}.IPv6CP.LocalInterfaceIdentifier',
'Device.PPP.Interface.{i}.IPv6CP.RemoteInterfaceIdentifier',
'Device.PPP.Interface.{i}.Stats.',
'Device.PPP.Interface.{i}.Stats.BytesSent',
'Device.PPP.Interface.{i}.Stats.BytesReceived',
'Device.PPP.Interface.{i}.Stats.PacketsSent',
'Device.PPP.Interface.{i}.Stats.PacketsReceived',
'Device.PPP.Interface.{i}.Stats.ErrorsSent',
'Device.PPP.Interface.{i}.Stats.ErrorsReceived',
'Device.PPP.Interface.{i}.Stats.UnicastPacketsSent',
'Device.PPP.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.PPP.Interface.{i}.Stats.DiscardPacketsSent',
'Device.PPP.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.PPP.Interface.{i}.Stats.MulticastPacketsSent',
'Device.PPP.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.PPP.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.PPP.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.PPP.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.PPP.Interface.{i}.PPPoA.',
'Device.IP.',
'Device.IP.IPv4Capable',
'Device.IP.IPv4Enable',
'Device.IP.IPv4Status',
'Device.IP.IPv6Capable',
'Device.IP.IPv6Enable',
'Device.IP.IPv6Status',
'Device.IP.ULAPrefix',
'Device.IP.InterfaceNumberOfEntries',
'Device.IP.ActivePortNumberOfEntries',
'Device.IP.Interface.{i}.',
'Device.IP.Interface.{i}.Enable',
'Device.IP.Interface.{i}.IPv4Enable',
'Device.IP.Interface.{i}.IPv6Enable',
'Device.IP.Interface.{i}.ULAEnable',
'Device.IP.Interface.{i}.Status',
'Device.IP.Interface.{i}.Alias',
'Device.IP.Interface.{i}.Name',
'Device.IP.Interface.{i}.LastChange',
'Device.IP.Interface.{i}.LowerLayers',
'Device.IP.Interface.{i}.Router',
'Device.IP.Interface.{i}.Reset',
'Device.IP.Interface.{i}.MaxMTUSize',
'Device.IP.Interface.{i}.Type',
'Device.IP.Interface.{i}.Loopback',
'Device.IP.Interface.{i}.IPv4AddressNumberOfEntries',
'Device.IP.Interface.{i}.IPv6AddressNumberOfEntries',
'Device.IP.Interface.{i}.IPv6PrefixNumberOfEntries',
'Device.IP.Interface.{i}.AutoIPEnable',
'Device.IP.Interface.{i}.TWAMPReflectorNumberOfEntries',
'Device.IP.Interface.{i}.IPv4Address.{i}.',
'Device.IP.Interface.{i}.IPv4Address.{i}.Enable',
'Device.IP.Interface.{i}.IPv4Address.{i}.Status',
'Device.IP.Interface.{i}.IPv4Address.{i}.Alias',
'Device.IP.Interface.{i}.IPv4Address.{i}.IPAddress',
'Device.IP.Interface.{i}.IPv4Address.{i}.SubnetMask',
'Device.IP.Interface.{i}.IPv4Address.{i}.AddressingType',
'Device.IP.Interface.{i}.Stats.',
'Device.IP.Interface.{i}.Stats.BytesSent',
'Device.IP.Interface.{i}.Stats.BytesReceived',
'Device.IP.Interface.{i}.Stats.PacketsSent',
'Device.IP.Interface.{i}.Stats.PacketsReceived',
'Device.IP.Interface.{i}.Stats.ErrorsSent',
'Device.IP.Interface.{i}.Stats.ErrorsReceived',
'Device.IP.Interface.{i}.Stats.UnicastPacketsSent',
'Device.IP.Interface.{i}.Stats.UnicastPacketsReceived',
'Device.IP.Interface.{i}.Stats.DiscardPacketsSent',
'Device.IP.Interface.{i}.Stats.DiscardPacketsReceived',
'Device.IP.Interface.{i}.Stats.MulticastPacketsSent',
'Device.IP.Interface.{i}.Stats.MulticastPacketsReceived',
'Device.IP.Interface.{i}.Stats.BroadcastPacketsSent',
'Device.IP.Interface.{i}.Stats.BroadcastPacketsReceived',
'Device.IP.Interface.{i}.Stats.UnknownProtoPacketsReceived',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.Enable',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.Status',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.Alias',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.Port',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.MaximumTTL',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.IPAllowedList',
'Device.IP.Interface.{i}.TWAMPReflector.{i}.PortAllowedList',
'Device.IP.Interface.{i}.IPv6Address.{i}.',
'Device.IP.Interface.{i}.IPv6Address.{i}.Enable',
'Device.IP.Interface.{i}.IPv6Address.{i}.Status',
'Device.IP.Interface.{i}.IPv6Address.{i}.IPAddressStatus',
'Device.IP.Interface.{i}.IPv6Address.{i}.Alias',
'Device.IP.Interface.{i}.IPv6Address.{i}.IPAddress',
'Device.IP.Interface.{i}.IPv6Address.{i}.Origin',
'Device.IP.Interface.{i}.IPv6Address.{i}.Prefix',
'Device.IP.Interface.{i}.IPv6Address.{i}.PreferredLifetime',
'Device.IP.Interface.{i}.IPv6Address.{i}.ValidLifetime',
'Device.IP.Interface.{i}.IPv6Address.{i}.Anycast',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.Enable',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.Status',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.PrefixStatus',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.Alias',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.Prefix',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.Origin',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.StaticType',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.ParentPrefix',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.ChildPrefixBits',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.OnLink',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.Autonomous',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.PreferredLifetime',
'Device.IP.Interface.{i}.IPv6Prefix.{i}.ValidLifetime',
'Device.IP.ActivePort.{i}.',
'Device.IP.ActivePort.{i}.LocalIPAddress',
'Device.IP.ActivePort.{i}.LocalPort',
'Device.IP.ActivePort.{i}.RemoteIPAddress',
'Device.IP.ActivePort.{i}.RemotePort',
'Device.IP.ActivePort.{i}.Status',
'Device.IP.Diagnostics.',
'Device.IP.Diagnostics.IPv4PingSupported',
'Device.IP.Diagnostics.IPv6PingSupported',
'Device.IP.Diagnostics.IPv4TraceRouteSupported',
'Device.IP.Diagnostics.IPv6TraceRouteSupported',
'Device.IP.Diagnostics.IPv4DownloadDiagnosticsSupported',
'Device.IP.Diagnostics.IPv6DownloadDiagnosticsSupported',
'Device.IP.Diagnostics.IPv4UploadDiagnosticsSupported',
'Device.IP.Diagnostics.IPv6UploadDiagnosticsSupported',
'Device.IP.Diagnostics.IPv4UDPEchoDiagnosticsSupported',
'Device.IP.Diagnostics.IPv6UDPEchoDiagnosticsSupported',
'Device.IP.Diagnostics.IPLayerCapacitySupported',
'Device.IP.Diagnostics.IPv4ServerSelectionDiagnosticsSupported',
'Device.IP.Diagnostics.IPv6ServerSelectionDiagnosticsSupported',
'Device.IP.Diagnostics.IPPing.',
'Device.IP.Diagnostics.IPPing.DiagnosticsState',
'Device.IP.Diagnostics.IPPing.Interface',
'Device.IP.Diagnostics.IPPing.ProtocolVersion',
'Device.IP.Diagnostics.IPPing.Host',
'Device.IP.Diagnostics.IPPing.NumberOfRepetitions',
'Device.IP.Diagnostics.IPPing.Timeout',
'Device.IP.Diagnostics.IPPing.DataBlockSize',
'Device.IP.Diagnostics.IPPing.DSCP',
'Device.IP.Diagnostics.IPPing.IPAddressUsed',
'Device.IP.Diagnostics.IPPing.SuccessCount',
'Device.IP.Diagnostics.IPPing.FailureCount',
'Device.IP.Diagnostics.IPPing.AverageResponseTime',
'Device.IP.Diagnostics.IPPing.MinimumResponseTime',
'Device.IP.Diagnostics.IPPing.MaximumResponseTime',
'Device.IP.Diagnostics.IPPing.AverageResponseTimeDetailed',
'Device.IP.Diagnostics.IPPing.MinimumResponseTimeDetailed',
'Device.IP.Diagnostics.IPPing.MaximumResponseTimeDetailed',
'Device.IP.Diagnostics.TraceRoute.',
'Device.IP.Diagnostics.TraceRoute.DiagnosticsState',
'Device.IP.Diagnostics.TraceRoute.Interface',
'Device.IP.Diagnostics.TraceRoute.ProtocolVersion',
'Device.IP.Diagnostics.TraceRoute.Host',
'Device.IP.Diagnostics.TraceRoute.NumberOfTries',
'Device.IP.Diagnostics.TraceRoute.Timeout',
'Device.IP.Diagnostics.TraceRoute.DataBlockSize',
'Device.IP.Diagnostics.TraceRoute.DSCP',
'Device.IP.Diagnostics.TraceRoute.MaxHopCount',
'Device.IP.Diagnostics.TraceRoute.RouteHopsNumberOfEntries',
'Device.IP.Diagnostics.TraceRoute.IPAddressUsed',
'Device.IP.Diagnostics.TraceRoute.ResponseTime',
'Device.IP.Diagnostics.TraceRoute.RouteHops.{i}.',
'Device.IP.Diagnostics.TraceRoute.RouteHops.{i}.Host',
'Device.IP.Diagnostics.TraceRoute.RouteHops.{i}.HostAddress',
'Device.IP.Diagnostics.TraceRoute.RouteHops.{i}.ErrorCode',
'Device.IP.Diagnostics.TraceRoute.RouteHops.{i}.RTTimes',
'Device.IP.Diagnostics.DownloadDiagnostics.',
'Device.IP.Diagnostics.DownloadDiagnostics.DiagnosticsState',
'Device.IP.Diagnostics.DownloadDiagnostics.Interface',
'Device.IP.Diagnostics.DownloadDiagnostics.DownloadURL',
'Device.IP.Diagnostics.DownloadDiagnostics.DownloadTransports',
'Device.IP.Diagnostics.DownloadDiagnostics.DownloadDiagnosticMaxConnections',
'Device.IP.Diagnostics.DownloadDiagnostics.DownloadDiagnosticsMaxIncrementalResult',
'Device.IP.Diagnostics.DownloadDiagnostics.DSCP',
'Device.IP.Diagnostics.DownloadDiagnostics.EthernetPriority',
'Device.IP.Diagnostics.DownloadDiagnostics.TimeBasedTestDuration',
'Device.IP.Diagnostics.DownloadDiagnostics.TimeBasedTestMeasurementInterval',
'Device.IP.Diagnostics.DownloadDiagnostics.TimeBasedTestMeasurementOffset',
'Device.IP.Diagnostics.DownloadDiagnostics.ProtocolVersion',
'Device.IP.Diagnostics.DownloadDiagnostics.NumberOfConnections',
'Device.IP.Diagnostics.DownloadDiagnostics.IPAddressUsed',
'Device.IP.Diagnostics.DownloadDiagnostics.ROMTime',
'Device.IP.Diagnostics.DownloadDiagnostics.BOMTime',
'Device.IP.Diagnostics.DownloadDiagnostics.EOMTime',
'Device.IP.Diagnostics.DownloadDiagnostics.TestBytesReceived',
'Device.IP.Diagnostics.DownloadDiagnostics.TotalBytesReceived',
'Device.IP.Diagnostics.DownloadDiagnostics.TotalBytesSent',
'Device.IP.Diagnostics.DownloadDiagnostics.TestBytesReceivedUnderFullLoading',
'Device.IP.Diagnostics.DownloadDiagnostics.TotalBytesReceivedUnderFullLoading',
'Device.IP.Diagnostics.DownloadDiagnostics.TotalBytesSentUnderFullLoading',
'Device.IP.Diagnostics.DownloadDiagnostics.PeriodOfFullLoading',
'Device.IP.Diagnostics.DownloadDiagnostics.TCPOpenRequestTime',
'Device.IP.Diagnostics.DownloadDiagnostics.TCPOpenResponseTime',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResultNumberOfEntries',
'Device.IP.Diagnostics.DownloadDiagnostics.EnablePerConnectionResults',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResultNumberOfEntries',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.ROMTime',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.BOMTime',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.EOMTime',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.TestBytesReceived',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.TotalBytesReceived',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.TotalBytesSent',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.TCPOpenRequestTime',
'Device.IP.Diagnostics.DownloadDiagnostics.PerConnectionResult.{i}.TCPOpenResponseTime',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResult.{i}.',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResult.{i}.TestBytesReceived',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResult.{i}.TotalBytesReceived',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResult.{i}.TotalBytesSent',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResult.{i}.StartTime',
'Device.IP.Diagnostics.DownloadDiagnostics.IncrementalResult.{i}.EndTime',
'Device.IP.Diagnostics.UploadDiagnostics.',
'Device.IP.Diagnostics.UploadDiagnostics.DiagnosticsState',
'Device.IP.Diagnostics.UploadDiagnostics.Interface',
'Device.IP.Diagnostics.UploadDiagnostics.UploadURL',
'Device.IP.Diagnostics.UploadDiagnostics.UploadTransports',
'Device.IP.Diagnostics.UploadDiagnostics.UploadDiagnosticsMaxConnections',
'Device.IP.Diagnostics.UploadDiagnostics.UploadDiagnosticsMaxIncrementalResult',
'Device.IP.Diagnostics.UploadDiagnostics.DSCP',
'Device.IP.Diagnostics.UploadDiagnostics.EthernetPriority',
'Device.IP.Diagnostics.UploadDiagnostics.TestFileLength',
'Device.IP.Diagnostics.UploadDiagnostics.TimeBasedTestDuration',
'Device.IP.Diagnostics.UploadDiagnostics.TimeBasedTestMeasurementInterval',
'Device.IP.Diagnostics.UploadDiagnostics.TimeBasedTestMeasurementOffset',
'Device.IP.Diagnostics.UploadDiagnostics.ProtocolVersion',
'Device.IP.Diagnostics.UploadDiagnostics.NumberOfConnections',
'Device.IP.Diagnostics.UploadDiagnostics.IPAddressUsed',
'Device.IP.Diagnostics.UploadDiagnostics.ROMTime',
'Device.IP.Diagnostics.UploadDiagnostics.BOMTime',
'Device.IP.Diagnostics.UploadDiagnostics.EOMTime',
'Device.IP.Diagnostics.UploadDiagnostics.TestBytesSent',
'Device.IP.Diagnostics.UploadDiagnostics.TotalBytesReceived',
'Device.IP.Diagnostics.UploadDiagnostics.TotalBytesSent',
'Device.IP.Diagnostics.UploadDiagnostics.TestBytesSentUnderFullLoading',
'Device.IP.Diagnostics.UploadDiagnostics.TotalBytesReceivedUnderFullLoading',
'Device.IP.Diagnostics.UploadDiagnostics.TotalBytesSentUnderFullLoading',
'Device.IP.Diagnostics.UploadDiagnostics.PeriodOfFullLoading',
'Device.IP.Diagnostics.UploadDiagnostics.TCPOpenRequestTime',
'Device.IP.Diagnostics.UploadDiagnostics.TCPOpenResponseTime',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResultNumberOfEntries',
'Device.IP.Diagnostics.UploadDiagnostics.EnablePerConnectionResults',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResultNumberOfEntries',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.ROMTime',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.BOMTime',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.EOMTime',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.TestBytesSent',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.TotalBytesReceived',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.TotalBytesSent',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.TCPOpenRequestTime',
'Device.IP.Diagnostics.UploadDiagnostics.PerConnectionResult.{i}.TCPOpenResponseTime',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResult.{i}.',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResult.{i}.TestBytesSent',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResult.{i}.TotalBytesReceived',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResult.{i}.TotalBytesSent',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResult.{i}.StartTime',
'Device.IP.Diagnostics.UploadDiagnostics.IncrementalResult.{i}.EndTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.',
'Device.IP.Diagnostics.UDPEchoDiagnostics.DiagnosticsState',
'Device.IP.Diagnostics.UDPEchoDiagnostics.Interface',
'Device.IP.Diagnostics.UDPEchoDiagnostics.Host',
'Device.IP.Diagnostics.UDPEchoDiagnostics.Port',
'Device.IP.Diagnostics.UDPEchoDiagnostics.NumberOfRepetitions',
'Device.IP.Diagnostics.UDPEchoDiagnostics.Timeout',
'Device.IP.Diagnostics.UDPEchoDiagnostics.DataBlockSize',
'Device.IP.Diagnostics.UDPEchoDiagnostics.DSCP',
'Device.IP.Diagnostics.UDPEchoDiagnostics.InterTransmissionTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.ProtocolVersion',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IPAddressUsed',
'Device.IP.Diagnostics.UDPEchoDiagnostics.SuccessCount',
'Device.IP.Diagnostics.UDPEchoDiagnostics.FailureCount',
'Device.IP.Diagnostics.UDPEchoDiagnostics.AverageResponseTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.MinimumResponseTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.MaximumResponseTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.EnableIndividualPacketResults',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResultNumberOfEntries',
'Device.IP.Diagnostics.UDPEchoDiagnostics.UDPEchoDiagnosticsMaxResults',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.PacketSuccess',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.PacketSendTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.PacketReceiveTime',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.TestGenSN',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.TestRespSN',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.TestRespRcvTimeStamp',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.TestRespReplyTimeStamp',
'Device.IP.Diagnostics.UDPEchoDiagnostics.IndividualPacketResult.{i}.TestRespReplyFailureCount',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.DiagnosticsState',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPLayerMaxConnections',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPLayerMaxIncrementalResult',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPLayerCapSupportedSoftwareVersion',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPLayerCapSupportedControlProtocolVersion',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPLayerCapSupportedMetrics',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.Interface',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.Role',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.Host',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.Port',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.JumboFramesPermitted',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.NumberOfConnections',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.EthernetPriority',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.DSCP',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ProtocolVersion',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.UDPPayloadMin',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.UDPPayloadMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.UDPPayloadContent',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PortMin',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PortMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PortOptionalMin',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PortOptionalMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TestType',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPDVEnable',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPRREnable',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.RIPREnable',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PreambleDuration',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.StartSendingRate',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.NumberTestSubIntervals',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.NumberFirstModeTestSubIntervals',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TestSubInterval',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.StatusFeedbackInterval',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TimeoutNoTraffic',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TimeoutNoTestTraffic',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TimeoutNoStatusMessage',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.Tmax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TmaxRTT',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TimestampResolution',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.SeqErrThresh',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ReordDupIgnoreEnable',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.LowerThresh',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.UpperThresh',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.HighSpeedDelta',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.SlowAdjThresh',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.HSpeedThresh',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.BOMTime',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.EOMTime',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TmaxUsed',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TestInterval',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MaxIPLayerCapacity',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TimeOfMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MaxETHCapacityNoFCS',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MaxETHCapacityWithFCS',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MaxETHCapacityWithFCSVLAN',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.LossRatioAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.RTTRangeAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PDVRangeAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MinOnewayDelayAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ReorderedRatioAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ReplicatedRatioAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.InterfaceEthMbpsAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IPLayerCapacitySummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.LossRatioSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.RTTRangeSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.PDVRangeSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MinOnewayDelaySummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.MinRTTSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ReorderedRatioSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ReplicatedRatioSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.InterfaceEthMbpsSummary',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResultNumberOfEntries',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResultNumberOfEntries',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TmaxRTTUsed',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.TimestampResolutionUsed',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.MaxIPLayerCapacity',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.TimeOfMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.MaxETHCapacityNoFCS',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.MaxETHCapacityWithFCS',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.MaxETHCapacityWithFCSVLAN',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.LossRatioAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.RTTRangeAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.PDVRangeAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.MinOnewayDelayAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.ReorderedRatioAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.ReplicatedRatioAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.ModalResult.{i}.InterfaceEthMbpsAtMax',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.IPLayerCapacity',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.TimeOfSubInterval',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.LossRatio',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.RTTRange',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.PDVRange',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.MinOnewayDelay',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.ReorderedRatio',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.ReplicatedRatio',
'Device.IP.Diagnostics.IPLayerCapacityMetrics.IncrementalResult.{i}.InterfaceEthMbps',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.DiagnosticsState',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.Interface',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.ProtocolVersion',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.Protocol',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.HostList',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.NumberOfRepetitions',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.Timeout',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.FastestHost',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.MinimumResponseTime',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.AverageResponseTime',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.MaximumResponseTime',
'Device.IP.Diagnostics.ServerSelectionDiagnostics.IPAddressUsed',
'Device.IP.Diagnostics.UDPEchoConfig.',
'Device.IP.Diagnostics.UDPEchoConfig.Enable',
'Device.IP.Diagnostics.UDPEchoConfig.Interface',
'Device.IP.Diagnostics.UDPEchoConfig.SourceIPAddress',
'Device.IP.Diagnostics.UDPEchoConfig.UDPPort',
'Device.IP.Diagnostics.UDPEchoConfig.EchoPlusEnabled',
'Device.IP.Diagnostics.UDPEchoConfig.EchoPlusSupported',
'Device.IP.Diagnostics.UDPEchoConfig.PacketsReceived',
'Device.IP.Diagnostics.UDPEchoConfig.PacketsResponded',
'Device.IP.Diagnostics.UDPEchoConfig.BytesReceived',
'Device.IP.Diagnostics.UDPEchoConfig.BytesResponded',
'Device.IP.Diagnostics.UDPEchoConfig.TimeFirstPacketReceived',
'Device.IP.Diagnostics.UDPEchoConfig.TimeLastPacketReceived',
'Device.LLDP.',
'Device.LLDP.Discovery.',
'Device.LLDP.Discovery.DeviceNumberOfEntries',
'Device.LLDP.Discovery.Device.{i}.',
'Device.LLDP.Discovery.Device.{i}.Interface',
'Device.LLDP.Discovery.Device.{i}.ChassisIDSubtype',
'Device.LLDP.Discovery.Device.{i}.ChassisID',
'Device.LLDP.Discovery.Device.{i}.Host',
'Device.LLDP.Discovery.Device.{i}.PortNumberOfEntries',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.PortIDSubtype',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.PortID',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.TTL',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.PortDescription',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.MACAddressList',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.LastUpdate',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.LinkInformation.',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.LinkInformation.InterfaceType',
'Device.LLDP.Discovery.Device.{i}.Port.{i}.LinkInformation.MACForwardingTable',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.DeviceCategory',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.ManufacturerOUI',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.ModelName',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.ModelNumber',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.VendorSpecificNumberOfEntries',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.VendorSpecific.{i}.',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.VendorSpecific.{i}.OrganizationCode',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.VendorSpecific.{i}.InformationType',
'Device.LLDP.Discovery.Device.{i}.DeviceInformation.VendorSpecific.{i}.Information',
'Device.IPsec.',
'Device.IPsec.Enable',
'Device.IPsec.Status',
'Device.IPsec.AHSupported',
'Device.IPsec.IKEv2SupportedEncryptionAlgorithms',
'Device.IPsec.ESPSupportedEncryptionAlgorithms',
'Device.IPsec.IKEv2SupportedPseudoRandomFunctions',
'Device.IPsec.SupportedIntegrityAlgorithms',
'Device.IPsec.SupportedDiffieHellmanGroupTransforms',
'Device.IPsec.MaxFilterEntries',
'Device.IPsec.MaxProfileEntries',
'Device.IPsec.FilterNumberOfEntries',
'Device.IPsec.ProfileNumberOfEntries',
'Device.IPsec.TunnelNumberOfEntries',
'Device.IPsec.IKEv2SANumberOfEntries',
'Device.IPsec.Stats.',
'Device.IPsec.Stats.NegotiationFailures',
'Device.IPsec.Stats.BytesSent',
'Device.IPsec.Stats.BytesReceived',
'Device.IPsec.Stats.PacketsSent',
'Device.IPsec.Stats.PacketsReceived',
'Device.IPsec.Stats.ErrorsSent',
'Device.IPsec.Stats.UnknownSPIErrors',
'Device.IPsec.Stats.DecryptionErrors',
'Device.IPsec.Stats.IntegrityErrors',
'Device.IPsec.Stats.ReplayErrors',
'Device.IPsec.Stats.PolicyErrors',
'Device.IPsec.Stats.OtherReceiveErrors',
'Device.IPsec.Filter.{i}.',
'Device.IPsec.Filter.{i}.Enable',
'Device.IPsec.Filter.{i}.Status',
'Device.IPsec.Filter.{i}.Order',
'Device.IPsec.Filter.{i}.Alias',
'Device.IPsec.Filter.{i}.Interface',
'Device.IPsec.Filter.{i}.AllInterfaces',
'Device.IPsec.Filter.{i}.DestIP',
'Device.IPsec.Filter.{i}.DestMask',
'Device.IPsec.Filter.{i}.DestIPExclude',
'Device.IPsec.Filter.{i}.SourceIP',
'Device.IPsec.Filter.{i}.SourceMask',
'Device.IPsec.Filter.{i}.SourceIPExclude',
'Device.IPsec.Filter.{i}.Protocol',
'Device.IPsec.Filter.{i}.ProtocolExclude',
'Device.IPsec.Filter.{i}.DestPort',
'Device.IPsec.Filter.{i}.DestPortRangeMax',
'Device.IPsec.Filter.{i}.DestPortExclude',
'Device.IPsec.Filter.{i}.SourcePort',
'Device.IPsec.Filter.{i}.SourcePortRangeMax',
'Device.IPsec.Filter.{i}.SourcePortExclude',
'Device.IPsec.Filter.{i}.ProcessingChoice',
'Device.IPsec.Filter.{i}.Profile',
'Device.IPsec.Profile.{i}.',
'Device.IPsec.Profile.{i}.Alias',
'Device.IPsec.Profile.{i}.MaxChildSAs',
'Device.IPsec.Profile.{i}.RemoteEndpoints',
'Device.IPsec.Profile.{i}.ForwardingPolicy',
'Device.IPsec.Profile.{i}.Protocol',
'Device.IPsec.Profile.{i}.IKEv2AuthenticationMethod',
'Device.IPsec.Profile.{i}.IKEv2AllowedEncryptionAlgorithms',
'Device.IPsec.Profile.{i}.ESPAllowedEncryptionAlgorithms',
'Device.IPsec.Profile.{i}.IKEv2AllowedPseudoRandomFunctions',
'Device.IPsec.Profile.{i}.IKEv2AllowedIntegrityAlgorithms',
'Device.IPsec.Profile.{i}.AHAllowedIntegrityAlgorithms',
'Device.IPsec.Profile.{i}.ESPAllowedIntegrityAlgorithms',
'Device.IPsec.Profile.{i}.IKEv2AllowedDiffieHellmanGroupTransforms',
'Device.IPsec.Profile.{i}.IKEv2DeadPeerDetectionTimeout',
'Device.IPsec.Profile.{i}.IKEv2NATTKeepaliveTimeout',
'Device.IPsec.Profile.{i}.AntiReplayWindowSize',
'Device.IPsec.Profile.{i}.DoNotFragment',
'Device.IPsec.Profile.{i}.DSCPMarkPolicy',
'Device.IPsec.Profile.{i}.IKEv2SATrafficLimit',
'Device.IPsec.Profile.{i}.IKEv2SATimeLimit',
'Device.IPsec.Profile.{i}.IKEv2SAExpiryAction',
'Device.IPsec.Profile.{i}.ChildSATrafficLimit',
'Device.IPsec.Profile.{i}.ChildSATimeLimit',
'Device.IPsec.Profile.{i}.ChildSAExpiryAction',
'Device.IPsec.Profile.{i}.SentCPAttrNumberOfEntries',
'Device.IPsec.Profile.{i}.SentCPAttr.{i}.',
'Device.IPsec.Profile.{i}.SentCPAttr.{i}.Enable',
'Device.IPsec.Profile.{i}.SentCPAttr.{i}.Alias',
'Device.IPsec.Profile.{i}.SentCPAttr.{i}.Type',
'Device.IPsec.Profile.{i}.SentCPAttr.{i}.Value',
'Device.IPsec.Tunnel.{i}.',
'Device.IPsec.Tunnel.{i}.Alias',
'Device.IPsec.Tunnel.{i}.TunnelInterface',
'Device.IPsec.Tunnel.{i}.TunneledInterface',
'Device.IPsec.Tunnel.{i}.Filters',
'Device.IPsec.Tunnel.{i}.Stats.',
'Device.IPsec.Tunnel.{i}.Stats.DecryptionErrors',
'Device.IPsec.Tunnel.{i}.Stats.IntegrityErrors',
'Device.IPsec.Tunnel.{i}.Stats.ReplayErrors',
'Device.IPsec.Tunnel.{i}.Stats.PolicyErrors',
'Device.IPsec.Tunnel.{i}.Stats.OtherReceiveErrors',
'Device.IPsec.IKEv2SA.{i}.',
'Device.IPsec.IKEv2SA.{i}.Status',
'Device.IPsec.IKEv2SA.{i}.Alias',
'Device.IPsec.IKEv2SA.{i}.Tunnel',
'Device.IPsec.IKEv2SA.{i}.LocalAddress',
'Device.IPsec.IKEv2SA.{i}.RemoteAddress',
'Device.IPsec.IKEv2SA.{i}.EncryptionAlgorithm',
'Device.IPsec.IKEv2SA.{i}.EncryptionKeyLength',
'Device.IPsec.IKEv2SA.{i}.PseudoRandomFunction',
'Device.IPsec.IKEv2SA.{i}.IntegrityAlgorithm',
'Device.IPsec.IKEv2SA.{i}.DiffieHellmanGroupTransform',
'Device.IPsec.IKEv2SA.{i}.CreationTime',
'Device.IPsec.IKEv2SA.{i}.NATDetected',
'Device.IPsec.IKEv2SA.{i}.ReceivedCPAttrNumberOfEntries',
'Device.IPsec.IKEv2SA.{i}.ChildSANumberOfEntries',
'Device.IPsec.IKEv2SA.{i}.Stats.',
'Device.IPsec.IKEv2SA.{i}.Stats.BytesSent',
'Device.IPsec.IKEv2SA.{i}.Stats.BytesReceived',
'Device.IPsec.IKEv2SA.{i}.Stats.PacketsSent',
'Device.IPsec.IKEv2SA.{i}.Stats.PacketsReceived',
'Device.IPsec.IKEv2SA.{i}.Stats.ErrorsSent',
'Device.IPsec.IKEv2SA.{i}.Stats.DecryptionErrors',
'Device.IPsec.IKEv2SA.{i}.Stats.IntegrityErrors',
'Device.IPsec.IKEv2SA.{i}.Stats.OtherReceiveErrors',
'Device.IPsec.IKEv2SA.{i}.ReceivedCPAttr.{i}.',
'Device.IPsec.IKEv2SA.{i}.ReceivedCPAttr.{i}.Type',
'Device.IPsec.IKEv2SA.{i}.ReceivedCPAttr.{i}.Value',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Status',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Alias',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.InboundSPI',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.OutboundSPI',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.CreationTime',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.BytesSent',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.BytesReceived',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.PacketsSent',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.PacketsReceived',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.ErrorsSent',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.DecryptionErrors',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.IntegrityErrors',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.ReplayErrors',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.PolicyErrors',
'Device.IPsec.IKEv2SA.{i}.ChildSA.{i}.Stats.OtherReceiveErrors',
'Device.GRE.',
'Device.GRE.TunnelNumberOfEntries',
'Device.GRE.FilterNumberOfEntries',
'Device.GRE.Tunnel.{i}.',
'Device.GRE.Tunnel.{i}.Enable',
'Device.GRE.Tunnel.{i}.Status',
'Device.GRE.Tunnel.{i}.Alias',
'Device.GRE.Tunnel.{i}.RemoteEndpoints',
'Device.GRE.Tunnel.{i}.KeepAlivePolicy',
'Device.GRE.Tunnel.{i}.KeepAliveTimeout',
'Device.GRE.Tunnel.{i}.KeepAliveThreshold',
'Device.GRE.Tunnel.{i}.DeliveryHeaderProtocol',
'Device.GRE.Tunnel.{i}.DefaultDSCPMark',
'Device.GRE.Tunnel.{i}.ConnectedRemoteEndpoint',
'Device.GRE.Tunnel.{i}.InterfaceNumberOfEntries',
'Device.GRE.Tunnel.{i}.Stats.',
'Device.GRE.Tunnel.{i}.Stats.KeepAliveSent',
'Device.GRE.Tunnel.{i}.Stats.KeepAliveReceived',
'Device.GRE.Tunnel.{i}.Stats.BytesSent',
'Device.GRE.Tunnel.{i}.Stats.BytesReceived',
'Device.GRE.Tunnel.{i}.Stats.PacketsSent',
'Device.GRE.Tunnel.{i}.Stats.PacketsReceived',
'Device.GRE.Tunnel.{i}.Stats.ErrorsSent',
'Device.GRE.Tunnel.{i}.Stats.ErrorsReceived',
'Device.GRE.Tunnel.{i}.Interface.{i}.',
'Device.GRE.Tunnel.{i}.Interface.{i}.Enable',
'Device.GRE.Tunnel.{i}.Interface.{i}.Status',
'Device.GRE.Tunnel.{i}.Interface.{i}.Alias',
'Device.GRE.Tunnel.{i}.Interface.{i}.Name',
'Device.GRE.Tunnel.{i}.Interface.{i}.LastChange',
'Device.GRE.Tunnel.{i}.Interface.{i}.LowerLayers',
'Device.GRE.Tunnel.{i}.Interface.{i}.ProtocolIdOverride',
'Device.GRE.Tunnel.{i}.Interface.{i}.UseChecksum',
'Device.GRE.Tunnel.{i}.Interface.{i}.KeyIdentifierGenerationPolicy',
'Device.GRE.Tunnel.{i}.Interface.{i}.KeyIdentifier',
'Device.GRE.Tunnel.{i}.Interface.{i}.UseSequenceNumber',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.BytesSent',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.BytesReceived',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.PacketsSent',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.PacketsReceived',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.ErrorsSent',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.ErrorsReceived',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.DiscardChecksumReceived',
'Device.GRE.Tunnel.{i}.Interface.{i}.Stats.DiscardSequenceNumberReceived',
'Device.GRE.Filter.{i}.',
'Device.GRE.Filter.{i}.Enable',
'Device.GRE.Filter.{i}.Status',
'Device.GRE.Filter.{i}.Order',
'Device.GRE.Filter.{i}.Alias',
'Device.GRE.Filter.{i}.Interface',
'Device.GRE.Filter.{i}.AllInterfaces',
'Device.GRE.Filter.{i}.VLANIDCheck',
'Device.GRE.Filter.{i}.VLANIDExclude',
'Device.GRE.Filter.{i}.DSCPMarkPolicy',
'Device.L2TPv3.',
'Device.L2TPv3.TunnelNumberOfEntries',
'Device.L2TPv3.FilterNumberOfEntries',
'Device.L2TPv3.Tunnel.{i}.',
'Device.L2TPv3.Tunnel.{i}.Enable',
'Device.L2TPv3.Tunnel.{i}.Status',
'Device.L2TPv3.Tunnel.{i}.Alias',
'Device.L2TPv3.Tunnel.{i}.RemoteEndpoints',
'Device.L2TPv3.Tunnel.{i}.KeepAlivePolicy',
'Device.L2TPv3.Tunnel.{i}.KeepAliveTimeout',
'Device.L2TPv3.Tunnel.{i}.KeepAliveThreshold',
'Device.L2TPv3.Tunnel.{i}.DeliveryHeaderProtocol',
'Device.L2TPv3.Tunnel.{i}.DefaultDSCPMark',
'Device.L2TPv3.Tunnel.{i}.TunnelEncapsulation',
'Device.L2TPv3.Tunnel.{i}.ConnectedRemoteEndpoint',
'Device.L2TPv3.Tunnel.{i}.InterfaceNumberOfEntries',
'Device.L2TPv3.Tunnel.{i}.UDP.',
'Device.L2TPv3.Tunnel.{i}.UDP.SourcePort',
'Device.L2TPv3.Tunnel.{i}.UDP.RemotePort',
'Device.L2TPv3.Tunnel.{i}.UDP.EnableChecksum',
'Device.L2TPv3.Tunnel.{i}.Stats.',
'Device.L2TPv3.Tunnel.{i}.Stats.KeepAliveSent',
'Device.L2TPv3.Tunnel.{i}.Stats.KeepAliveReceived',
'Device.L2TPv3.Tunnel.{i}.Stats.BytesSent',
'Device.L2TPv3.Tunnel.{i}.Stats.BytesReceived',
'Device.L2TPv3.Tunnel.{i}.Stats.PacketsSent',
'Device.L2TPv3.Tunnel.{i}.Stats.PacketsReceived',
'Device.L2TPv3.Tunnel.{i}.Stats.ErrorsSent',
'Device.L2TPv3.Tunnel.{i}.Stats.ErrorsReceived',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Enable',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Status',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Alias',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Name',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.LastChange',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.LowerLayers',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.SessionID',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.CookiePolicy',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Cookie',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.BytesSent',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.BytesReceived',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.PacketsSent',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.PacketsReceived',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.ErrorsSent',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.ErrorsReceived',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.DiscardChecksumReceived',
'Device.L2TPv3.Tunnel.{i}.Interface.{i}.Stats.DiscardSequenceNumberReceived',
'Device.L2TPv3.Filter.{i}.',
'Device.L2TPv3.Filter.{i}.Enable',
'Device.L2TPv3.Filter.{i}.Status',
'Device.L2TPv3.Filter.{i}.Order',
'Device.L2TPv3.Filter.{i}.Alias',
'Device.L2TPv3.Filter.{i}.Interface',
'Device.L2TPv3.Filter.{i}.AllInterfaces',
'Device.L2TPv3.Filter.{i}.VLANIDCheck',
'Device.L2TPv3.Filter.{i}.VLANIDExclude',
'Device.L2TPv3.Filter.{i}.DSCPMarkPolicy',
'Device.VXLAN.',
'Device.VXLAN.TunnelNumberOfEntries',
'Device.VXLAN.FilterNumberOfEntries',
'Device.VXLAN.Tunnel.{i}.',
'Device.VXLAN.Tunnel.{i}.Enable',
'Device.VXLAN.Tunnel.{i}.Status',
'Device.VXLAN.Tunnel.{i}.Alias',
'Device.VXLAN.Tunnel.{i}.RemoteEndpoints',
'Device.VXLAN.Tunnel.{i}.KeepAlivePolicy',
'Device.VXLAN.Tunnel.{i}.KeepAliveTimeout',
'Device.VXLAN.Tunnel.{i}.KeepAliveThreshold',
'Device.VXLAN.Tunnel.{i}.DeliveryHeaderProtocol',
'Device.VXLAN.Tunnel.{i}.DefaultDSCPMark',
'Device.VXLAN.Tunnel.{i}.ConnectedRemoteEndpoint',
'Device.VXLAN.Tunnel.{i}.InterfaceNumberOfEntries',
'Device.VXLAN.Tunnel.{i}.SourcePort',
'Device.VXLAN.Tunnel.{i}.RemotePort',
'Device.VXLAN.Tunnel.{i}.Stats.',
'Device.VXLAN.Tunnel.{i}.Stats.KeepAliveSent',
'Device.VXLAN.Tunnel.{i}.Stats.KeepAliveReceived',
'Device.VXLAN.Tunnel.{i}.Stats.BytesSent',
'Device.VXLAN.Tunnel.{i}.Stats.BytesReceived',
'Device.VXLAN.Tunnel.{i}.Stats.PacketsSent',
'Device.VXLAN.Tunnel.{i}.Stats.PacketsReceived',
'Device.VXLAN.Tunnel.{i}.Stats.ErrorsSent',
'Device.VXLAN.Tunnel.{i}.Stats.ErrorsReceived',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Enable',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Status',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Alias',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Name',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.LastChange',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.LowerLayers',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.VNI',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.BytesSent',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.BytesReceived',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.PacketsSent',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.PacketsReceived',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.ErrorsSent',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.ErrorsReceived',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.DiscardChecksumReceived',
'Device.VXLAN.Tunnel.{i}.Interface.{i}.Stats.DiscardSequenceNumberReceived',
'Device.VXLAN.Filter.{i}.',
'Device.VXLAN.Filter.{i}.Enable',
'Device.VXLAN.Filter.{i}.Status',
'Device.VXLAN.Filter.{i}.Order',
'Device.VXLAN.Filter.{i}.Alias',
'Device.VXLAN.Filter.{i}.Interface',
'Device.VXLAN.Filter.{i}.AllInterfaces',
'Device.VXLAN.Filter.{i}.VLANIDCheck',
'Device.VXLAN.Filter.{i}.VLANIDExclude',
'Device.VXLAN.Filter.{i}.DSCPMarkPolicy',
'Device.MAP.',
'Device.MAP.Enable',
'Device.MAP.DomainNumberOfEntries',
'Device.MAP.Domain.{i}.',
'Device.MAP.Domain.{i}.Enable',
'Device.MAP.Domain.{i}.Status',
'Device.MAP.Domain.{i}.Alias',
'Device.MAP.Domain.{i}.TransportMode',
'Device.MAP.Domain.{i}.WANInterface',
'Device.MAP.Domain.{i}.IPv6Prefix',
'Device.MAP.Domain.{i}.BRIPv6Prefix',
'Device.MAP.Domain.{i}.DSCPMarkPolicy',
'Device.MAP.Domain.{i}.PSIDOffset',
'Device.MAP.Domain.{i}.PSIDLength',
'Device.MAP.Domain.{i}.PSID',
'Device.MAP.Domain.{i}.IncludeSystemPorts',
'Device.MAP.Domain.{i}.RuleNumberOfEntries',
'Device.MAP.Domain.{i}.Rule.{i}.',
'Device.MAP.Domain.{i}.Rule.{i}.Enable',
'Device.MAP.Domain.{i}.Rule.{i}.Status',
'Device.MAP.Domain.{i}.Rule.{i}.Alias',
'Device.MAP.Domain.{i}.Rule.{i}.Origin',
'Device.MAP.Domain.{i}.Rule.{i}.IPv6Prefix',
'Device.MAP.Domain.{i}.Rule.{i}.IPv4Prefix',
'Device.MAP.Domain.{i}.Rule.{i}.EABitsLength',
'Device.MAP.Domain.{i}.Rule.{i}.IsFMR',
'Device.MAP.Domain.{i}.Rule.{i}.PSIDOffset',
'Device.MAP.Domain.{i}.Rule.{i}.PSIDLength',
'Device.MAP.Domain.{i}.Rule.{i}.PSID',
'Device.MAP.Domain.{i}.Rule.{i}.IncludeSystemPorts',
'Device.MAP.Domain.{i}.Interface.',
'Device.MAP.Domain.{i}.Interface.Enable',
'Device.MAP.Domain.{i}.Interface.Status',
'Device.MAP.Domain.{i}.Interface.Alias',
'Device.MAP.Domain.{i}.Interface.Name',
'Device.MAP.Domain.{i}.Interface.LastChange',
'Device.MAP.Domain.{i}.Interface.LowerLayers',
'Device.MAP.Domain.{i}.Interface.Stats.',
'Device.MAP.Domain.{i}.Interface.Stats.BytesSent',
'Device.MAP.Domain.{i}.Interface.Stats.BytesReceived',
'Device.MAP.Domain.{i}.Interface.Stats.PacketsSent',
'Device.MAP.Domain.{i}.Interface.Stats.PacketsReceived',
'Device.MAP.Domain.{i}.Interface.Stats.ErrorsSent',
'Device.MAP.Domain.{i}.Interface.Stats.ErrorsReceived',
'Device.MAP.Domain.{i}.Interface.Stats.UnicastPacketsSent',
'Device.MAP.Domain.{i}.Interface.Stats.UnicastPacketsReceived',
'Device.MAP.Domain.{i}.Interface.Stats.DiscardPacketsSent',
'Device.MAP.Domain.{i}.Interface.Stats.DiscardPacketsReceived',
'Device.MAP.Domain.{i}.Interface.Stats.MulticastPacketsSent',
'Device.MAP.Domain.{i}.Interface.Stats.MulticastPacketsReceived',
'Device.MAP.Domain.{i}.Interface.Stats.BroadcastPacketsSent',
'Device.MAP.Domain.{i}.Interface.Stats.BroadcastPacketsReceived',
'Device.MAP.Domain.{i}.Interface.Stats.UnknownProtoPacketsReceived',
'Device.CaptivePortal.',
'Device.CaptivePortal.Enable',
'Device.CaptivePortal.Status',
'Device.CaptivePortal.AllowedList',
'Device.CaptivePortal.URL',
'Device.Routing.',
'Device.Routing.RouterNumberOfEntries',
'Device.Routing.Router.{i}.',
'Device.Routing.Router.{i}.Enable',
'Device.Routing.Router.{i}.Status',
'Device.Routing.Router.{i}.Alias',
'Device.Routing.Router.{i}.IPv4ForwardingNumberOfEntries',
'Device.Routing.Router.{i}.IPv6ForwardingNumberOfEntries',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.Enable',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.Status',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.Alias',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.StaticRoute',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.DestIPAddress',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.DestSubnetMask',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.ForwardingPolicy',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.GatewayIPAddress',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.Interface',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.Origin',
'Device.Routing.Router.{i}.IPv4Forwarding.{i}.ForwardingMetric',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.Enable',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.Status',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.Alias',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.DestIPPrefix',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.ForwardingPolicy',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.NextHop',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.Interface',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.Origin',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.ForwardingMetric',
'Device.Routing.Router.{i}.IPv6Forwarding.{i}.ExpirationTime',
'Device.Routing.RIP.',
'Device.Routing.RIP.Enable',
'Device.Routing.RIP.SupportedModes',
'Device.Routing.RIP.InterfaceSettingNumberOfEntries',
'Device.Routing.RIP.InterfaceSetting.{i}.',
'Device.Routing.RIP.InterfaceSetting.{i}.Enable',
'Device.Routing.RIP.InterfaceSetting.{i}.Status',
'Device.Routing.RIP.InterfaceSetting.{i}.Alias',
'Device.Routing.RIP.InterfaceSetting.{i}.Interface',
'Device.Routing.RIP.InterfaceSetting.{i}.AcceptRA',
'Device.Routing.RIP.InterfaceSetting.{i}.SendRA',
'Device.Routing.RouteInformation.',
'Device.Routing.RouteInformation.Enable',
'Device.Routing.RouteInformation.InterfaceSettingNumberOfEntries',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.Status',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.Interface',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.SourceRouter',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.PreferredRouteFlag',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.Prefix',
'Device.Routing.RouteInformation.InterfaceSetting.{i}.RouteLifetime',
'Device.Routing.Babel.',
'Device.Routing.Babel.Enable',
'Device.Routing.Babel.Status',
'Device.Routing.Babel.ImplementationVersion',
'Device.Routing.Babel.SelfRouterID',
'Device.Routing.Babel.SelfSeqno',
'Device.Routing.Babel.SupportedMetricCompAlgorithms',
'Device.Routing.Babel.SupportedSecurityMechanisms',
'Device.Routing.Babel.SupportedMACAlgorithms',
'Device.Routing.Babel.SupportedDTLSCertTypes',
'Device.Routing.Babel.StatsEnable',
'Device.Routing.Babel.InterfaceSettingNumberOfEntries',
'Device.Routing.Babel.RouteNumberOfEntries',
'Device.Routing.Babel.MACKeySetNumberOfEntries',
'Device.Routing.Babel.DTLSCertSetNumberOfEntries',
'Device.Routing.Babel.StatsReset',
'Device.Routing.Babel.Constants.',
'Device.Routing.Babel.Constants.UDPPort',
'Device.Routing.Babel.Constants.MulticastGroup',
'Device.Routing.Babel.InterfaceSetting.{i}.',
'Device.Routing.Babel.InterfaceSetting.{i}.Enable',
'Device.Routing.Babel.InterfaceSetting.{i}.Status',
'Device.Routing.Babel.InterfaceSetting.{i}.Alias',
'Device.Routing.Babel.InterfaceSetting.{i}.InterfaceReference',
'Device.Routing.Babel.InterfaceSetting.{i}.InterfaceMetricAlgorithm',
'Device.Routing.Babel.InterfaceSetting.{i}.SplitHorizonEnabled',
'Device.Routing.Babel.InterfaceSetting.{i}.McastHelloSeqno',
'Device.Routing.Babel.InterfaceSetting.{i}.McastHelloInterval',
'Device.Routing.Babel.InterfaceSetting.{i}.UpdateInterval',
'Device.Routing.Babel.InterfaceSetting.{i}.MACEnabled',
'Device.Routing.Babel.InterfaceSetting.{i}.InterfaceMACKeySets',
'Device.Routing.Babel.InterfaceSetting.{i}.MACVerify',
'Device.Routing.Babel.InterfaceSetting.{i}.DTLSEnabled',
'Device.Routing.Babel.InterfaceSetting.{i}.InterfaceDTLSCertSets',
'Device.Routing.Babel.InterfaceSetting.{i}.CachedInfoEnabled',
'Device.Routing.Babel.InterfaceSetting.{i}.DTLSCertPrefer',
'Device.Routing.Babel.InterfaceSetting.{i}.PacketLogEnable',
'Device.Routing.Babel.InterfaceSetting.{i}.PacketLog',
'Device.Routing.Babel.InterfaceSetting.{i}.NeighborNumberOfEntries',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.SentMcastHello',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.SentMcastUpdate',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.SentUcastHello',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.SentUcastUpdate',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.SentIHU',
'Device.Routing.Babel.InterfaceSetting.{i}.Stats.ReceivedPackets',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.NeighborAddress',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.HelloMCastHistory',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.HelloUCastHistory',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.TXCost',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.ExpectedMCastHelloSeqno',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.ExpectedUCastHelloSeqno',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.UnicastHelloSeqno',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.UnicastHelloInterval',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.RXCost',
'Device.Routing.Babel.InterfaceSetting.{i}.Neighbor.{i}.Cost',
'Device.Routing.Babel.Route.{i}.',
'Device.Routing.Babel.Route.{i}.RoutePrefix',
'Device.Routing.Babel.Route.{i}.SourceRouterID',
'Device.Routing.Babel.Route.{i}.Neighbor',
'Device.Routing.Babel.Route.{i}.ReceivedMetric',
'Device.Routing.Babel.Route.{i}.CalculatedMetric',
'Device.Routing.Babel.Route.{i}.RouteSeqno',
'Device.Routing.Babel.Route.{i}.NextHop',
'Device.Routing.Babel.Route.{i}.RouteFeasible',
'Device.Routing.Babel.Route.{i}.RouteSelected',
'Device.Routing.Babel.MACKeySet.{i}.',
'Device.Routing.Babel.MACKeySet.{i}.Alias',
'Device.Routing.Babel.MACKeySet.{i}.DefaultApply',
'Device.Routing.Babel.MACKeySet.{i}.MACKeyNumberOfEntries',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.Alias',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.KeyUseSend',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.KeyUseVerify',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.KeyValue',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.MACKeyAlgorithm',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.MACTestState',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.InputString',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.InputMAC',
'Device.Routing.Babel.MACKeySet.{i}.MACKey.{i}.Match',
'Device.Routing.Babel.DTLSCertSet.{i}.',
'Device.Routing.Babel.DTLSCertSet.{i}.Alias',
'Device.Routing.Babel.DTLSCertSet.{i}.DefaultApply',
'Device.Routing.Babel.DTLSCertSet.{i}.DTLSCertNumberOfEntries',
'Device.Routing.Babel.DTLSCertSet.{i}.DTLSCert.{i}.',
'Device.Routing.Babel.DTLSCertSet.{i}.DTLSCert.{i}.Alias',
'Device.Routing.Babel.DTLSCertSet.{i}.DTLSCert.{i}.CertValue',
'Device.Routing.Babel.DTLSCertSet.{i}.DTLSCert.{i}.CertType',
'Device.Routing.Babel.DTLSCertSet.{i}.DTLSCert.{i}.CertPrivateKey',
'Device.USPAgent.',
'Device.USPAgent.EndpointID',
'Device.USPAgent.SoftwareVersion',
'Device.USPAgent.UpTime',
'Device.USPAgent.SupportedProtocols',
'Device.USPAgent.SupportedFingerprintAlgorithms',
'Device.USPAgent.AdvertisedDeviceSubtypes',
'Device.USPAgent.MaxSubscriptionChangeAdoptionTime',
'Device.USPAgent.MTPNumberOfEntries',
'Device.USPAgent.ControllerNumberOfEntries',
'Device.USPAgent.CertificateNumberOfEntries',
'Device.USPAgent.AddCertificate',
'Device.USPAgent.MTP.{i}.',
'Device.USPAgent.MTP.{i}.Alias',
'Device.USPAgent.MTP.{i}.Enable',
'Device.USPAgent.MTP.{i}.Status',
'Device.USPAgent.MTP.{i}.Protocol',
'Device.USPAgent.MTP.{i}.EnableMDNS',
'Device.USPAgent.MTP.{i}.CoAP.',
'Device.USPAgent.MTP.{i}.CoAP.Interfaces',
'Device.USPAgent.MTP.{i}.CoAP.Port',
'Device.USPAgent.MTP.{i}.CoAP.Path',
'Device.USPAgent.MTP.{i}.CoAP.IsEncrypted',
'Device.USPAgent.MTP.{i}.CoAP.EnableEncryption',
'Device.USPAgent.MTP.{i}.STOMP.',
'Device.USPAgent.MTP.{i}.STOMP.Reference',
'Device.USPAgent.MTP.{i}.STOMP.Destination',
'Device.USPAgent.MTP.{i}.STOMP.DestinationFromServer',
'Device.USPAgent.MTP.{i}.WebSocket.',
'Device.USPAgent.MTP.{i}.WebSocket.Interfaces',
'Device.USPAgent.MTP.{i}.WebSocket.Port',
'Device.USPAgent.MTP.{i}.WebSocket.Path',
'Device.USPAgent.MTP.{i}.WebSocket.EnableEncryption',
'Device.USPAgent.MTP.{i}.WebSocket.KeepAliveInterval',
'Device.USPAgent.MTP.{i}.MQTT.',
'Device.USPAgent.MTP.{i}.MQTT.Reference',
'Device.USPAgent.MTP.{i}.MQTT.ResponseTopicConfigured',
'Device.USPAgent.MTP.{i}.MQTT.ResponseTopicDiscovered',
'Device.USPAgent.MTP.{i}.MQTT.PublishQoS',
'Device.USPAgent.Controller.{i}.',
'Device.USPAgent.Controller.{i}.Alias',
'Device.USPAgent.Controller.{i}.EndpointID',
'Device.USPAgent.Controller.{i}.ControllerCode',
'Device.USPAgent.Controller.{i}.ProvisioningCode',
'Device.USPAgent.Controller.{i}.Enable',
'Device.USPAgent.Controller.{i}.AssignedRole',
'Device.USPAgent.Controller.{i}.InheritedRole',
'Device.USPAgent.Controller.{i}.Credential',
'Device.USPAgent.Controller.{i}.PeriodicNotifInterval',
'Device.USPAgent.Controller.{i}.PeriodicNotifTime',
'Device.USPAgent.Controller.{i}.USPNotifRetryMinimumWaitInterval',
'Device.USPAgent.Controller.{i}.USPNotifRetryIntervalMultiplier',
'Device.USPAgent.Controller.{i}.MTPNumberOfEntries',
'Device.USPAgent.Controller.{i}.SendOnBoardRequest',
'Device.USPAgent.Controller.{i}.MTP.{i}.',
'Device.USPAgent.Controller.{i}.MTP.{i}.Alias',
'Device.USPAgent.Controller.{i}.MTP.{i}.Enable',
'Device.USPAgent.Controller.{i}.MTP.{i}.Protocol',
'Device.USPAgent.Controller.{i}.MTP.{i}.Order',
'Device.USPAgent.Controller.{i}.MTP.{i}.CoAP.',
'Device.USPAgent.Controller.{i}.MTP.{i}.CoAP.Host',
'Device.USPAgent.Controller.{i}.MTP.{i}.CoAP.Port',
'Device.USPAgent.Controller.{i}.MTP.{i}.CoAP.Path',
'Device.USPAgent.Controller.{i}.MTP.{i}.CoAP.EnableEncryption',
'Device.USPAgent.Controller.{i}.MTP.{i}.STOMP.',
'Device.USPAgent.Controller.{i}.MTP.{i}.STOMP.Reference',
'Device.USPAgent.Controller.{i}.MTP.{i}.STOMP.Destination',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.Host',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.Port',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.Path',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.IsEncrypted',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.EnableEncryption',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.KeepAliveInterval',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.CurrentRetryCount',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.SessionRetryMinimumWaitInterval',
'Device.USPAgent.Controller.{i}.MTP.{i}.WebSocket.SessionRetryIntervalMultiplier',
'Device.USPAgent.Controller.{i}.MTP.{i}.MQTT.',
'Device.USPAgent.Controller.{i}.MTP.{i}.MQTT.Reference',
'Device.USPAgent.Controller.{i}.MTP.{i}.MQTT.Topic',
'Device.USPAgent.Controller.{i}.MTP.{i}.MQTT.PublishRetainResponse',
'Device.USPAgent.Controller.{i}.MTP.{i}.MQTT.PublishRetainNotify',
'Device.USPAgent.Certificate.{i}.',
'Device.USPAgent.Certificate.{i}.Alias',
'Device.USPAgent.Certificate.{i}.Enable',
'Device.USPAgent.Certificate.{i}.SerialNumber',
'Device.USPAgent.Certificate.{i}.Issuer',
'Device.USPAgent.ControllerTrust.',
'Device.USPAgent.ControllerTrust.UntrustedRole',
'Device.USPAgent.ControllerTrust.BannedRole',
'Device.USPAgent.ControllerTrust.TOFUAllowed',
'Device.USPAgent.ControllerTrust.TOFUInactivityTimer',
'Device.USPAgent.ControllerTrust.RoleNumberOfEntries',
'Device.USPAgent.ControllerTrust.CredentialNumberOfEntries',
'Device.USPAgent.ControllerTrust.ChallengeNumberOfEntries',
'Device.USPAgent.ControllerTrust.Role.{i}.',
'Device.USPAgent.ControllerTrust.Role.{i}.Alias',
'Device.USPAgent.ControllerTrust.Role.{i}.Enable',
'Device.USPAgent.ControllerTrust.Role.{i}.Name',
'Device.USPAgent.ControllerTrust.Role.{i}.PermissionNumberOfEntries',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.Alias',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.Enable',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.Order',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.Targets',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.Param',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.Obj',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.InstantiatedObj',
'Device.USPAgent.ControllerTrust.Role.{i}.Permission.{i}.CommandEvent',
'Device.USPAgent.ControllerTrust.Credential.{i}.',
'Device.USPAgent.ControllerTrust.Credential.{i}.Alias',
'Device.USPAgent.ControllerTrust.Credential.{i}.Enable',
'Device.USPAgent.ControllerTrust.Credential.{i}.Role',
'Device.USPAgent.ControllerTrust.Credential.{i}.Credential',
'Device.USPAgent.ControllerTrust.Credential.{i}.AllowedUses',
'Device.USPAgent.ControllerTrust.Challenge.{i}.',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Alias',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Description',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Role',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Enable',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Type',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Value',
'Device.USPAgent.ControllerTrust.Challenge.{i}.ValueType',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Instruction',
'Device.USPAgent.ControllerTrust.Challenge.{i}.InstructionType',
'Device.USPAgent.ControllerTrust.Challenge.{i}.Retries',
'Device.USPAgent.ControllerTrust.Challenge.{i}.LockoutPeriod',
'Device.NeighborDiscovery.',
'Device.NeighborDiscovery.Enable',
'Device.NeighborDiscovery.InterfaceSettingNumberOfEntries',
'Device.NeighborDiscovery.InterfaceSetting.{i}.',
'Device.NeighborDiscovery.InterfaceSetting.{i}.Enable',
'Device.NeighborDiscovery.InterfaceSetting.{i}.Status',
'Device.NeighborDiscovery.InterfaceSetting.{i}.Alias',
'Device.NeighborDiscovery.InterfaceSetting.{i}.Interface',
'Device.NeighborDiscovery.InterfaceSetting.{i}.DADTransmits',
'Device.NeighborDiscovery.InterfaceSetting.{i}.RetransTimer',
'Device.NeighborDiscovery.InterfaceSetting.{i}.RtrSolicitationInterval',
'Device.NeighborDiscovery.InterfaceSetting.{i}.MaxRtrSolicitations',
'Device.NeighborDiscovery.InterfaceSetting.{i}.NUDEnable',
'Device.NeighborDiscovery.InterfaceSetting.{i}.RSEnable',
'Device.RouterAdvertisement.',
'Device.RouterAdvertisement.Enable',
'Device.RouterAdvertisement.InterfaceSettingNumberOfEntries',
'Device.RouterAdvertisement.InterfaceSetting.{i}.',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Enable',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Status',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Alias',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Interface',
'Device.RouterAdvertisement.InterfaceSetting.{i}.ManualPrefixes',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Prefixes',
'Device.RouterAdvertisement.InterfaceSetting.{i}.MaxRtrAdvInterval',
'Device.RouterAdvertisement.InterfaceSetting.{i}.MinRtrAdvInterval',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvDefaultLifetime',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvManagedFlag',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvOtherConfigFlag',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvMobileAgentFlag',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvPreferredRouterFlag',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvNDProxyFlag',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvLinkMTU',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvReachableTime',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvRetransTimer',
'Device.RouterAdvertisement.InterfaceSetting.{i}.AdvCurHopLimit',
'Device.RouterAdvertisement.InterfaceSetting.{i}.OptionNumberOfEntries',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Option.{i}.',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Option.{i}.Enable',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Option.{i}.Alias',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Option.{i}.Tag',
'Device.RouterAdvertisement.InterfaceSetting.{i}.Option.{i}.Value',
'Device.IPv6rd.',
'Device.IPv6rd.Enable',
'Device.IPv6rd.InterfaceSettingNumberOfEntries',
'Device.IPv6rd.InterfaceSetting.{i}.',
'Device.IPv6rd.InterfaceSetting.{i}.Enable',
'Device.IPv6rd.InterfaceSetting.{i}.Status',
'Device.IPv6rd.InterfaceSetting.{i}.Alias',
'Device.IPv6rd.InterfaceSetting.{i}.BorderRelayIPv4Addresses',
'Device.IPv6rd.InterfaceSetting.{i}.AllTrafficToBorderRelay',
'Device.IPv6rd.InterfaceSetting.{i}.SPIPv6Prefix',
'Device.IPv6rd.InterfaceSetting.{i}.IPv4MaskLength',
'Device.IPv6rd.InterfaceSetting.{i}.AddressSource',
'Device.IPv6rd.InterfaceSetting.{i}.TunnelInterface',
'Device.IPv6rd.InterfaceSetting.{i}.TunneledInterface',
'Device.DSLite.',
'Device.DSLite.Enable',
'Device.DSLite.InterfaceSettingNumberOfEntries',
'Device.DSLite.InterfaceSetting.{i}.',
'Device.DSLite.InterfaceSetting.{i}.Enable',
'Device.DSLite.InterfaceSetting.{i}.Status',
'Device.DSLite.InterfaceSetting.{i}.Alias',
'Device.DSLite.InterfaceSetting.{i}.EndpointAssignmentPrecedence',
'Device.DSLite.InterfaceSetting.{i}.EndpointAddressTypePrecedence',
'Device.DSLite.InterfaceSetting.{i}.EndpointAddressInUse',
'Device.DSLite.InterfaceSetting.{i}.EndpointName',
'Device.DSLite.InterfaceSetting.{i}.EndpointAddress',
'Device.DSLite.InterfaceSetting.{i}.Origin',
'Device.DSLite.InterfaceSetting.{i}.TunnelInterface',
'Device.DSLite.InterfaceSetting.{i}.TunneledInterface',
'Device.QoS.',
'Device.QoS.MaxClassificationEntries',
'Device.QoS.ClassificationNumberOfEntries',
'Device.QoS.MaxAppEntries',
'Device.QoS.AppNumberOfEntries',
'Device.QoS.MaxFlowEntries',
'Device.QoS.FlowNumberOfEntries',
'Device.QoS.MaxPolicerEntries',
'Device.QoS.PolicerNumberOfEntries',
'Device.QoS.MaxQueueEntries',
'Device.QoS.QueueNumberOfEntries',
'Device.QoS.QueueStatsNumberOfEntries',
'Device.QoS.MaxShaperEntries',
'Device.QoS.ShaperNumberOfEntries',
'Device.QoS.DefaultForwardingPolicy',
'Device.QoS.DefaultTrafficClass',
'Device.QoS.DefaultPolicer',
'Device.QoS.DefaultQueue',
'Device.QoS.DefaultDSCPMark',
'Device.QoS.DefaultEthernetPriorityMark',
'Device.QoS.DefaultInnerEthernetPriorityMark',
'Device.QoS.AvailableAppList',
'Device.QoS.Classification.{i}.',
'Device.QoS.Classification.{i}.Enable',
'Device.QoS.Classification.{i}.Status',
'Device.QoS.Classification.{i}.Order',
'Device.QoS.Classification.{i}.Alias',
'Device.QoS.Classification.{i}.DHCPType',
'Device.QoS.Classification.{i}.Interface',
'Device.QoS.Classification.{i}.AllInterfaces',
'Device.QoS.Classification.{i}.DestIP',
'Device.QoS.Classification.{i}.DestMask',
'Device.QoS.Classification.{i}.DestIPExclude',
'Device.QoS.Classification.{i}.SourceIP',
'Device.QoS.Classification.{i}.SourceMask',
'Device.QoS.Classification.{i}.SourceIPExclude',
'Device.QoS.Classification.{i}.Protocol',
'Device.QoS.Classification.{i}.ProtocolExclude',
'Device.QoS.Classification.{i}.DestPort',
'Device.QoS.Classification.{i}.DestPortRangeMax',
'Device.QoS.Classification.{i}.DestPortExclude',
'Device.QoS.Classification.{i}.SourcePort',
'Device.QoS.Classification.{i}.SourcePortRangeMax',
'Device.QoS.Classification.{i}.SourcePortExclude',
'Device.QoS.Classification.{i}.SourceMACAddress',
'Device.QoS.Classification.{i}.SourceMACMask',
'Device.QoS.Classification.{i}.SourceMACExclude',
'Device.QoS.Classification.{i}.DestMACAddress',
'Device.QoS.Classification.{i}.DestMACMask',
'Device.QoS.Classification.{i}.DestMACExclude',
'Device.QoS.Classification.{i}.Ethertype',
'Device.QoS.Classification.{i}.EthertypeExclude',
'Device.QoS.Classification.{i}.SSAP',
'Device.QoS.Classification.{i}.SSAPExclude',
'Device.QoS.Classification.{i}.DSAP',
'Device.QoS.Classification.{i}.DSAPExclude',
'Device.QoS.Classification.{i}.LLCControl',
'Device.QoS.Classification.{i}.LLCControlExclude',
'Device.QoS.Classification.{i}.SNAPOUI',
'Device.QoS.Classification.{i}.SNAPOUIExclude',
'Device.QoS.Classification.{i}.SourceVendorClassID',
'Device.QoS.Classification.{i}.SourceVendorClassIDv6',
'Device.QoS.Classification.{i}.SourceVendorClassIDExclude',
'Device.QoS.Classification.{i}.SourceVendorClassIDMode',
'Device.QoS.Classification.{i}.DestVendorClassID',
'Device.QoS.Classification.{i}.DestVendorClassIDv6',
'Device.QoS.Classification.{i}.DestVendorClassIDExclude',
'Device.QoS.Classification.{i}.DestVendorClassIDMode',
'Device.QoS.Classification.{i}.SourceClientID',
'Device.QoS.Classification.{i}.SourceClientIDExclude',
'Device.QoS.Classification.{i}.DestClientID',
'Device.QoS.Classification.{i}.DestClientIDExclude',
'Device.QoS.Classification.{i}.SourceUserClassID',
'Device.QoS.Classification.{i}.SourceUserClassIDExclude',
'Device.QoS.Classification.{i}.DestUserClassID',
'Device.QoS.Classification.{i}.DestUserClassIDExclude',
'Device.QoS.Classification.{i}.SourceVendorSpecificInfo',
'Device.QoS.Classification.{i}.SourceVendorSpecificInfoExclude',
'Device.QoS.Classification.{i}.SourceVendorSpecificInfoEnterprise',
'Device.QoS.Classification.{i}.SourceVendorSpecificInfoSubOption',
'Device.QoS.Classification.{i}.DestVendorSpecificInfo',
'Device.QoS.Classification.{i}.DestVendorSpecificInfoExclude',
'Device.QoS.Classification.{i}.DestVendorSpecificInfoEnterprise',
'Device.QoS.Classification.{i}.DestVendorSpecificInfoSubOption',
'Device.QoS.Classification.{i}.TCPACK',
'Device.QoS.Classification.{i}.TCPACKExclude',
'Device.QoS.Classification.{i}.IPLengthMin',
'Device.QoS.Classification.{i}.IPLengthMax',
'Device.QoS.Classification.{i}.IPLengthExclude',
'Device.QoS.Classification.{i}.DSCPCheck',
'Device.QoS.Classification.{i}.DSCPExclude',
'Device.QoS.Classification.{i}.DSCPMark',
'Device.QoS.Classification.{i}.EthernetPriorityCheck',
'Device.QoS.Classification.{i}.EthernetPriorityExclude',
'Device.QoS.Classification.{i}.EthernetPriorityMark',
'Device.QoS.Classification.{i}.InnerEthernetPriorityCheck',
'Device.QoS.Classification.{i}.InnerEthernetPriorityExclude',
'Device.QoS.Classification.{i}.InnerEthernetPriorityMark',
'Device.QoS.Classification.{i}.EthernetDEICheck',
'Device.QoS.Classification.{i}.EthernetDEIExclude',
'Device.QoS.Classification.{i}.VLANIDCheck',
'Device.QoS.Classification.{i}.VLANIDExclude',
'Device.QoS.Classification.{i}.OutOfBandInfo',
'Device.QoS.Classification.{i}.ForwardingPolicy',
'Device.QoS.Classification.{i}.TrafficClass',
'Device.QoS.Classification.{i}.Policer',
'Device.QoS.Classification.{i}.App',
'Device.QoS.App.{i}.',
'Device.QoS.App.{i}.Enable',
'Device.QoS.App.{i}.Status',
'Device.QoS.App.{i}.Alias',
'Device.QoS.App.{i}.ProtocolIdentifier',
'Device.QoS.App.{i}.Name',
'Device.QoS.App.{i}.DefaultForwardingPolicy',
'Device.QoS.App.{i}.DefaultTrafficClass',
'Device.QoS.App.{i}.DefaultPolicer',
'Device.QoS.App.{i}.DefaultDSCPMark',
'Device.QoS.App.{i}.DefaultEthernetPriorityMark',
'Device.QoS.App.{i}.DefaultInnerEthernetPriorityMark',
'Device.QoS.Flow.{i}.',
'Device.QoS.Flow.{i}.Enable',
'Device.QoS.Flow.{i}.Status',
'Device.QoS.Flow.{i}.Alias',
'Device.QoS.Flow.{i}.Type',
'Device.QoS.Flow.{i}.TypeParameters',
'Device.QoS.Flow.{i}.Name',
'Device.QoS.Flow.{i}.App',
'Device.QoS.Flow.{i}.ForwardingPolicy',
'Device.QoS.Flow.{i}.TrafficClass',
'Device.QoS.Flow.{i}.Policer',
'Device.QoS.Flow.{i}.DSCPMark',
'Device.QoS.Flow.{i}.EthernetPriorityMark',
'Device.QoS.Flow.{i}.InnerEthernetPriorityMark',
'Device.QoS.Policer.{i}.',
'Device.QoS.Policer.{i}.Enable',
'Device.QoS.Policer.{i}.Status',
'Device.QoS.Policer.{i}.Alias',
'Device.QoS.Policer.{i}.CommittedRate',
'Device.QoS.Policer.{i}.CommittedBurstSize',
'Device.QoS.Policer.{i}.ExcessBurstSize',
'Device.QoS.Policer.{i}.PeakRate',
'Device.QoS.Policer.{i}.PeakBurstSize',
'Device.QoS.Policer.{i}.MeterType',
'Device.QoS.Policer.{i}.PossibleMeterTypes',
'Device.QoS.Policer.{i}.ConformingAction',
'Device.QoS.Policer.{i}.PartialConformingAction',
'Device.QoS.Policer.{i}.NonConformingAction',
'Device.QoS.Policer.{i}.TotalCountedPackets',
'Device.QoS.Policer.{i}.TotalCountedBytes',
'Device.QoS.Policer.{i}.ConformingCountedPackets',
'Device.QoS.Policer.{i}.ConformingCountedBytes',
'Device.QoS.Policer.{i}.PartiallyConformingCountedPackets',
'Device.QoS.Policer.{i}.PartiallyConformingCountedBytes',
'Device.QoS.Policer.{i}.NonConformingCountedPackets',
'Device.QoS.Policer.{i}.NonConformingCountedBytes',
'Device.QoS.Queue.{i}.',
'Device.QoS.Queue.{i}.Enable',
'Device.QoS.Queue.{i}.Status',
'Device.QoS.Queue.{i}.Alias',
'Device.QoS.Queue.{i}.TrafficClasses',
'Device.QoS.Queue.{i}.Interface',
'Device.QoS.Queue.{i}.AllInterfaces',
'Device.QoS.Queue.{i}.HardwareAssisted',
'Device.QoS.Queue.{i}.BufferLength',
'Device.QoS.Queue.{i}.Weight',
'Device.QoS.Queue.{i}.Precedence',
'Device.QoS.Queue.{i}.REDThreshold',
'Device.QoS.Queue.{i}.REDPercentage',
'Device.QoS.Queue.{i}.DropAlgorithm',
'Device.QoS.Queue.{i}.SchedulerAlgorithm',
'Device.QoS.Queue.{i}.ShapingRate',
'Device.QoS.Queue.{i}.ShapingBurstSize',
'Device.QoS.QueueStats.{i}.',
'Device.QoS.QueueStats.{i}.Enable',
'Device.QoS.QueueStats.{i}.Status',
'Device.QoS.QueueStats.{i}.Alias',
'Device.QoS.QueueStats.{i}.Queue',
'Device.QoS.QueueStats.{i}.Interface',
'Device.QoS.QueueStats.{i}.OutputPackets',
'Device.QoS.QueueStats.{i}.OutputBytes',
'Device.QoS.QueueStats.{i}.DroppedPackets',
'Device.QoS.QueueStats.{i}.DroppedBytes',
'Device.QoS.QueueStats.{i}.QueueOccupancyPackets',
'Device.QoS.QueueStats.{i}.QueueOccupancyPercentage',
'Device.QoS.Shaper.{i}.',
'Device.QoS.Shaper.{i}.Enable',
'Device.QoS.Shaper.{i}.Status',
'Device.QoS.Shaper.{i}.Alias',
'Device.QoS.Shaper.{i}.Interface',
'Device.QoS.Shaper.{i}.ShapingRate',
'Device.QoS.Shaper.{i}.ShapingBurstSize',
'Device.LANConfigSecurity.',
'Device.LANConfigSecurity.ConfigPassword',
'Device.Hosts.',
'Device.Hosts.HostNumberOfEntries',
'Device.Hosts.AccessControlNumberOfEntries',
'Device.Hosts.Host.{i}.',
'Device.Hosts.Host.{i}.Alias',
'Device.Hosts.Host.{i}.PhysAddress',
'Device.Hosts.Host.{i}.IPAddress',
'Device.Hosts.Host.{i}.AddressSource',
'Device.Hosts.Host.{i}.DHCPClient',
'Device.Hosts.Host.{i}.LeaseTimeRemaining',
'Device.Hosts.Host.{i}.AssociatedDevice',
'Device.Hosts.Host.{i}.Layer1Interface',
'Device.Hosts.Host.{i}.Layer3Interface',
'Device.Hosts.Host.{i}.InterfaceType',
'Device.Hosts.Host.{i}.VendorClassID',
'Device.Hosts.Host.{i}.ClientID',
'Device.Hosts.Host.{i}.UserClassID',
'Device.Hosts.Host.{i}.HostName',
'Device.Hosts.Host.{i}.Active',
'Device.Hosts.Host.{i}.ActiveLastChange',
'Device.Hosts.Host.{i}.IPv4AddressNumberOfEntries',
'Device.Hosts.Host.{i}.IPv6AddressNumberOfEntries',
'Device.Hosts.Host.{i}.IPv4Address.{i}.',
'Device.Hosts.Host.{i}.IPv4Address.{i}.IPAddress',
'Device.Hosts.Host.{i}.IPv6Address.{i}.',
'Device.Hosts.Host.{i}.IPv6Address.{i}.IPAddress',
'Device.Hosts.Host.{i}.WANStats.',
'Device.Hosts.Host.{i}.WANStats.BytesSent',
'Device.Hosts.Host.{i}.WANStats.BytesReceived',
'Device.Hosts.Host.{i}.WANStats.PacketsSent',
'Device.Hosts.Host.{i}.WANStats.PacketsReceived',
'Device.Hosts.Host.{i}.WANStats.ErrorsSent',
'Device.Hosts.Host.{i}.WANStats.RetransCount',
'Device.Hosts.Host.{i}.WANStats.DiscardPacketsSent',
'Device.Hosts.AccessControl.{i}.',
'Device.Hosts.AccessControl.{i}.Alias',
'Device.Hosts.AccessControl.{i}.PhysAddress',
'Device.Hosts.AccessControl.{i}.HostName',
'Device.Hosts.AccessControl.{i}.Enable',
'Device.Hosts.AccessControl.{i}.AccessPolicy',
'Device.Hosts.AccessControl.{i}.ScheduleNumberOfEntries',
'Device.Hosts.AccessControl.{i}.Schedule.{i}.',
'Device.Hosts.AccessControl.{i}.Schedule.{i}.Alias',
'Device.Hosts.AccessControl.{i}.Schedule.{i}.Enable',
'Device.Hosts.AccessControl.{i}.Schedule.{i}.Day',
'Device.Hosts.AccessControl.{i}.Schedule.{i}.StartTime',
'Device.Hosts.AccessControl.{i}.Schedule.{i}.Duration',
'Device.DNS.',
'Device.DNS.SupportedRecordTypes',
'Device.DNS.Client.',
'Device.DNS.Client.Enable',
'Device.DNS.Client.Status',
'Device.DNS.Client.ServerNumberOfEntries',
'Device.DNS.Client.Server.{i}.',
'Device.DNS.Client.Server.{i}.Enable',
'Device.DNS.Client.Server.{i}.Status',
'Device.DNS.Client.Server.{i}.Alias',
'Device.DNS.Client.Server.{i}.DNSServer',
'Device.DNS.Client.Server.{i}.Interface',
'Device.DNS.Client.Server.{i}.Type',
'Device.DNS.Relay.',
'Device.DNS.Relay.Enable',
'Device.DNS.Relay.Status',
'Device.DNS.Relay.ForwardNumberOfEntries',
'Device.DNS.Relay.Forwarding.{i}.',
'Device.DNS.Relay.Forwarding.{i}.Enable',
'Device.DNS.Relay.Forwarding.{i}.Status',
'Device.DNS.Relay.Forwarding.{i}.Alias',
'Device.DNS.Relay.Forwarding.{i}.DNSServer',
'Device.DNS.Relay.Forwarding.{i}.Interface',
'Device.DNS.Relay.Forwarding.{i}.Type',
'Device.DNS.Diagnostics.',
'Device.DNS.Diagnostics.NSLookupDiagnostics.',
'Device.DNS.Diagnostics.NSLookupDiagnostics.DiagnosticsState',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Interface',
'Device.DNS.Diagnostics.NSLookupDiagnostics.HostName',
'Device.DNS.Diagnostics.NSLookupDiagnostics.DNSServer',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Timeout',
'Device.DNS.Diagnostics.NSLookupDiagnostics.NumberOfRepetitions',
'Device.DNS.Diagnostics.NSLookupDiagnostics.ResultNumberOfEntries',
'Device.DNS.Diagnostics.NSLookupDiagnostics.SuccessCount',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.Status',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.AnswerType',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.HostNameReturned',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.IPAddresses',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.DNSServerIP',
'Device.DNS.Diagnostics.NSLookupDiagnostics.Result.{i}.ResponseTime',
'Device.DNS.SD.',
'Device.DNS.SD.Enable',
'Device.DNS.SD.ServiceNumberOfEntries',
'Device.DNS.SD.AdvertisedInterfaces',
'Device.DNS.SD.Service.{i}.',
'Device.DNS.SD.Service.{i}.InstanceName',
'Device.DNS.SD.Service.{i}.ApplicationProtocol',
'Device.DNS.SD.Service.{i}.TransportProtocol',
'Device.DNS.SD.Service.{i}.Domain',
'Device.DNS.SD.Service.{i}.Port',
'Device.DNS.SD.Service.{i}.Target',
'Device.DNS.SD.Service.{i}.Status',
'Device.DNS.SD.Service.{i}.LastUpdate',
'Device.DNS.SD.Service.{i}.Host',
'Device.DNS.SD.Service.{i}.TimeToLive',
'Device.DNS.SD.Service.{i}.Priority',
'Device.DNS.SD.Service.{i}.Weight',
'Device.DNS.SD.Service.{i}.TextRecordNumberOfEntries',
'Device.DNS.SD.Service.{i}.TextRecord.{i}.',
'Device.DNS.SD.Service.{i}.TextRecord.{i}.Key',
'Device.DNS.SD.Service.{i}.TextRecord.{i}.Value',
'Device.NAT.',
'Device.NAT.InterfaceSettingNumberOfEntries',
'Device.NAT.PortMappingNumberOfEntries',
'Device.NAT.InterfaceSetting.{i}.',
'Device.NAT.InterfaceSetting.{i}.Enable',
'Device.NAT.InterfaceSetting.{i}.Status',
'Device.NAT.InterfaceSetting.{i}.Alias',
'Device.NAT.InterfaceSetting.{i}.Interface',
'Device.NAT.InterfaceSetting.{i}.TCPTranslationTimeout',
'Device.NAT.InterfaceSetting.{i}.UDPTranslationTimeout',
'Device.NAT.PortMapping.{i}.',
'Device.NAT.PortMapping.{i}.Enable',
'Device.NAT.PortMapping.{i}.Status',
'Device.NAT.PortMapping.{i}.Alias',
'Device.NAT.PortMapping.{i}.Interface',
'Device.NAT.PortMapping.{i}.AllInterfaces',
'Device.NAT.PortMapping.{i}.LeaseDuration',
'Device.NAT.PortMapping.{i}.RemoteHost',
'Device.NAT.PortMapping.{i}.ExternalPort',
'Device.NAT.PortMapping.{i}.ExternalPortEndRange',
'Device.NAT.PortMapping.{i}.InternalPort',
'Device.NAT.PortMapping.{i}.Protocol',
'Device.NAT.PortMapping.{i}.InternalClient',
'Device.NAT.PortMapping.{i}.Description',
'Device.PCP.',
'Device.PCP.SupportedVersions',
'Device.PCP.PreferredVersion',
'Device.PCP.OptionList',
'Device.PCP.ClientNumberOfEntries',
'Device.PCP.Client.{i}.',
'Device.PCP.Client.{i}.Enable',
'Device.PCP.Client.{i}.Alias',
'Device.PCP.Client.{i}.WANInterface',
'Device.PCP.Client.{i}.Status',
'Device.PCP.Client.{i}.MAPEnable',
'Device.PCP.Client.{i}.PEEREnable',
'Device.PCP.Client.{i}.ANNOUNCEEnable',
'Device.PCP.Client.{i}.THIRDPARTYEnable',
'Device.PCP.Client.{i}.THIRDPARTYStatus',
'Device.PCP.Client.{i}.FILTEREnable',
'Device.PCP.Client.{i}.ServerNumberOfEntries',
'Device.PCP.Client.{i}.PCPProxy.',
'Device.PCP.Client.{i}.PCPProxy.Enable',
'Device.PCP.Client.{i}.PCPProxy.HighestVersion',
'Device.PCP.Client.{i}.PCPProxy.Status',
'Device.PCP.Client.{i}.UPnPIWF.',
'Device.PCP.Client.{i}.UPnPIWF.Enable',
'Device.PCP.Client.{i}.UPnPIWF.Status',
'Device.PCP.Client.{i}.Server.{i}.',
'Device.PCP.Client.{i}.Server.{i}.Enable',
'Device.PCP.Client.{i}.Server.{i}.Status',
'Device.PCP.Client.{i}.Server.{i}.Alias',
'Device.PCP.Client.{i}.Server.{i}.Origin',
'Device.PCP.Client.{i}.Server.{i}.ServerNameOrAddress',
'Device.PCP.Client.{i}.Server.{i}.ServerAddressInUse',
'Device.PCP.Client.{i}.Server.{i}.AdditionalServerAddresses',
'Device.PCP.Client.{i}.Server.{i}.ExternalIPAddress',
'Device.PCP.Client.{i}.Server.{i}.CurrentVersion',
'Device.PCP.Client.{i}.Server.{i}.MaximumFilters',
'Device.PCP.Client.{i}.Server.{i}.PortQuota',
'Device.PCP.Client.{i}.Server.{i}.PreferredLifetime',
'Device.PCP.Client.{i}.Server.{i}.Capabilities',
'Device.PCP.Client.{i}.Server.{i}.InboundMappingNumberOfEntries',
'Device.PCP.Client.{i}.Server.{i}.OutboundMappingNumberOfEntries',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Enable',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Status',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.ErrorCode',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Alias',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Origin',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Lifetime',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.SuggestedExternalIPAddress',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.SuggestedExternalPort',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.SuggestedExternalPortEndRange',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.InternalPort',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.ProtocolNumber',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.ThirdPartyAddress',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Description',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.AssignedExternalIPAddress',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.AssignedExternalPort',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.AssignedExternalPortEndRange',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.FilterNumberOfEntries',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Filter.{i}.',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Filter.{i}.Alias',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Filter.{i}.RemoteHostIPAddress',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Filter.{i}.PrefixLength',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Filter.{i}.RemotePort',
'Device.PCP.Client.{i}.Server.{i}.InboundMapping.{i}.Filter.{i}.RemotePortEndRange',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.Enable',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.Status',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.ErrorCode',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.Alias',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.Origin',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.Lifetime',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.SuggestedExternalIPAddress',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.SuggestedExternalPort',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.RemoteHostIPAddress',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.InternalPort',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.RemotePort',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.ProtocolNumber',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.ThirdPartyAddress',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.Description',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.AssignedExternalIPAddress',
'Device.PCP.Client.{i}.Server.{i}.OutboundMapping.{i}.AssignedExternalPort',
'Device.DHCPv4.',
'Device.DHCPv4.ClientNumberOfEntries',
'Device.DHCPv4.Client.{i}.',
'Device.DHCPv4.Client.{i}.Enable',
'Device.DHCPv4.Client.{i}.Alias',
'Device.DHCPv4.Client.{i}.Interface',
'Device.DHCPv4.Client.{i}.Status',
'Device.DHCPv4.Client.{i}.DHCPStatus',
'Device.DHCPv4.Client.{i}.Renew',
'Device.DHCPv4.Client.{i}.IPAddress',
'Device.DHCPv4.Client.{i}.SubnetMask',
'Device.DHCPv4.Client.{i}.IPRouters',
'Device.DHCPv4.Client.{i}.DNSServers',
'Device.DHCPv4.Client.{i}.LeaseTimeRemaining',
'Device.DHCPv4.Client.{i}.DHCPServer',
'Device.DHCPv4.Client.{i}.PassthroughEnable',
'Device.DHCPv4.Client.{i}.PassthroughDHCPPool',
'Device.DHCPv4.Client.{i}.SentOptionNumberOfEntries',
'Device.DHCPv4.Client.{i}.ReqOptionNumberOfEntries',
'Device.DHCPv4.Client.{i}.SentOption.{i}.',
'Device.DHCPv4.Client.{i}.SentOption.{i}.Enable',
'Device.DHCPv4.Client.{i}.SentOption.{i}.Alias',
'Device.DHCPv4.Client.{i}.SentOption.{i}.Tag',
'Device.DHCPv4.Client.{i}.SentOption.{i}.Value',
'Device.DHCPv4.Client.{i}.ReqOption.{i}.',
'Device.DHCPv4.Client.{i}.ReqOption.{i}.Enable',
'Device.DHCPv4.Client.{i}.ReqOption.{i}.Order',
'Device.DHCPv4.Client.{i}.ReqOption.{i}.Alias',
'Device.DHCPv4.Client.{i}.ReqOption.{i}.Tag',
'Device.DHCPv4.Client.{i}.ReqOption.{i}.Value',
'Device.DHCPv4.Server.',
'Device.DHCPv4.Server.Enable',
'Device.DHCPv4.Server.PoolNumberOfEntries',
'Device.DHCPv4.Server.Pool.{i}.',
'Device.DHCPv4.Server.Pool.{i}.Enable',
'Device.DHCPv4.Server.Pool.{i}.Status',
'Device.DHCPv4.Server.Pool.{i}.Alias',
'Device.DHCPv4.Server.Pool.{i}.Order',
'Device.DHCPv4.Server.Pool.{i}.Interface',
'Device.DHCPv4.Server.Pool.{i}.VendorClassID',
'Device.DHCPv4.Server.Pool.{i}.VendorClassIDExclude',
'Device.DHCPv4.Server.Pool.{i}.VendorClassIDMode',
'Device.DHCPv4.Server.Pool.{i}.ClientID',
'Device.DHCPv4.Server.Pool.{i}.ClientIDExclude',
'Device.DHCPv4.Server.Pool.{i}.UserClassID',
'Device.DHCPv4.Server.Pool.{i}.UserClassIDExclude',
'Device.DHCPv4.Server.Pool.{i}.Chaddr',
'Device.DHCPv4.Server.Pool.{i}.ChaddrMask',
'Device.DHCPv4.Server.Pool.{i}.ChaddrExclude',
'Device.DHCPv4.Server.Pool.{i}.AllowedDevices',
'Device.DHCPv4.Server.Pool.{i}.MinAddress',
'Device.DHCPv4.Server.Pool.{i}.MaxAddress',
'Device.DHCPv4.Server.Pool.{i}.ReservedAddresses',
'Device.DHCPv4.Server.Pool.{i}.SubnetMask',
'Device.DHCPv4.Server.Pool.{i}.DNSServers',
'Device.DHCPv4.Server.Pool.{i}.DomainName',
'Device.DHCPv4.Server.Pool.{i}.IPRouters',
'Device.DHCPv4.Server.Pool.{i}.LeaseTime',
'Device.DHCPv4.Server.Pool.{i}.StaticAddressNumberOfEntries',
'Device.DHCPv4.Server.Pool.{i}.OptionNumberOfEntries',
'Device.DHCPv4.Server.Pool.{i}.ClientNumberOfEntries',
'Device.DHCPv4.Server.Pool.{i}.StaticAddress.{i}.',
'Device.DHCPv4.Server.Pool.{i}.StaticAddress.{i}.Enable',
'Device.DHCPv4.Server.Pool.{i}.StaticAddress.{i}.Alias',
'Device.DHCPv4.Server.Pool.{i}.StaticAddress.{i}.Chaddr',
'Device.DHCPv4.Server.Pool.{i}.StaticAddress.{i}.Yiaddr',
'Device.DHCPv4.Server.Pool.{i}.Option.{i}.',
'Device.DHCPv4.Server.Pool.{i}.Option.{i}.Enable',
'Device.DHCPv4.Server.Pool.{i}.Option.{i}.Alias',
'Device.DHCPv4.Server.Pool.{i}.Option.{i}.Tag',
'Device.DHCPv4.Server.Pool.{i}.Option.{i}.Value',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.Alias',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.Chaddr',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.Active',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.IPv4AddressNumberOfEntries',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.OptionNumberOfEntries',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.IPv4Address.{i}.',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.IPv4Address.{i}.IPAddress',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.IPv4Address.{i}.LeaseTimeRemaining',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.Option.{i}.',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.Option.{i}.Tag',
'Device.DHCPv4.Server.Pool.{i}.Client.{i}.Option.{i}.Value',
'Device.DHCPv4.Relay.',
'Device.DHCPv4.Relay.Enable',
'Device.DHCPv4.Relay.Status',
'Device.DHCPv4.Relay.ForwardingNumberOfEntries',
'Device.DHCPv4.Relay.Forwarding.{i}.',
'Device.DHCPv4.Relay.Forwarding.{i}.Enable',
'Device.DHCPv4.Relay.Forwarding.{i}.Status',
'Device.DHCPv4.Relay.Forwarding.{i}.Alias',
'Device.DHCPv4.Relay.Forwarding.{i}.Order',
'Device.DHCPv4.Relay.Forwarding.{i}.Interface',
'Device.DHCPv4.Relay.Forwarding.{i}.VendorClassID',
'Device.DHCPv4.Relay.Forwarding.{i}.VendorClassIDExclude',
'Device.DHCPv4.Relay.Forwarding.{i}.VendorClassIDMode',
'Device.DHCPv4.Relay.Forwarding.{i}.ClientID',
'Device.DHCPv4.Relay.Forwarding.{i}.ClientIDExclude',
'Device.DHCPv4.Relay.Forwarding.{i}.UserClassID',
'Device.DHCPv4.Relay.Forwarding.{i}.UserClassIDExclude',
'Device.DHCPv4.Relay.Forwarding.{i}.Chaddr',
'Device.DHCPv4.Relay.Forwarding.{i}.ChaddrMask',
'Device.DHCPv4.Relay.Forwarding.{i}.ChaddrExclude',
'Device.DHCPv4.Relay.Forwarding.{i}.LocallyServed',
'Device.DHCPv4.Relay.Forwarding.{i}.DHCPServerIPAddress',
'Device.DHCPv6.',
'Device.DHCPv6.ClientNumberOfEntries',
'Device.DHCPv6.Client.{i}.',
'Device.DHCPv6.Client.{i}.Enable',
'Device.DHCPv6.Client.{i}.Alias',
'Device.DHCPv6.Client.{i}.Interface',
'Device.DHCPv6.Client.{i}.Status',
'Device.DHCPv6.Client.{i}.DUID',
'Device.DHCPv6.Client.{i}.RequestAddresses',
'Device.DHCPv6.Client.{i}.RequestPrefixes',
'Device.DHCPv6.Client.{i}.RapidCommit',
'Device.DHCPv6.Client.{i}.Renew',
'Device.DHCPv6.Client.{i}.SuggestedT1',
'Device.DHCPv6.Client.{i}.SuggestedT2',
'Device.DHCPv6.Client.{i}.SupportedOptions',
'Device.DHCPv6.Client.{i}.RequestedOptions',
'Device.DHCPv6.Client.{i}.ServerNumberOfEntries',
'Device.DHCPv6.Client.{i}.SentOptionNumberOfEntries',
'Device.DHCPv6.Client.{i}.ReceivedOptionNumberOfEntries',
'Device.DHCPv6.Client.{i}.Server.{i}.',
'Device.DHCPv6.Client.{i}.Server.{i}.SourceAddress',
'Device.DHCPv6.Client.{i}.Server.{i}.DUID',
'Device.DHCPv6.Client.{i}.Server.{i}.InformationRefreshTime',
'Device.DHCPv6.Client.{i}.SentOption.{i}.',
'Device.DHCPv6.Client.{i}.SentOption.{i}.Enable',
'Device.DHCPv6.Client.{i}.SentOption.{i}.Alias',
'Device.DHCPv6.Client.{i}.SentOption.{i}.Tag',
'Device.DHCPv6.Client.{i}.SentOption.{i}.Value',
'Device.DHCPv6.Client.{i}.ReceivedOption.{i}.',
'Device.DHCPv6.Client.{i}.ReceivedOption.{i}.Tag',
'Device.DHCPv6.Client.{i}.ReceivedOption.{i}.Value',
'Device.DHCPv6.Client.{i}.ReceivedOption.{i}.Server',
'Device.DHCPv6.Server.',
'Device.DHCPv6.Server.Enable',
'Device.DHCPv6.Server.PoolNumberOfEntries',
'Device.DHCPv6.Server.Pool.{i}.',
'Device.DHCPv6.Server.Pool.{i}.Enable',
'Device.DHCPv6.Server.Pool.{i}.Status',
'Device.DHCPv6.Server.Pool.{i}.Alias',
'Device.DHCPv6.Server.Pool.{i}.Order',
'Device.DHCPv6.Server.Pool.{i}.Interface',
'Device.DHCPv6.Server.Pool.{i}.DUID',
'Device.DHCPv6.Server.Pool.{i}.DUIDExclude',
'Device.DHCPv6.Server.Pool.{i}.VendorClassID',
'Device.DHCPv6.Server.Pool.{i}.VendorClassIDExclude',
'Device.DHCPv6.Server.Pool.{i}.UserClassID',
'Device.DHCPv6.Server.Pool.{i}.UserClassIDExclude',
'Device.DHCPv6.Server.Pool.{i}.SourceAddress',
'Device.DHCPv6.Server.Pool.{i}.SourceAddressMask',
'Device.DHCPv6.Server.Pool.{i}.SourceAddressExclude',
'Device.DHCPv6.Server.Pool.{i}.IANAEnable',
'Device.DHCPv6.Server.Pool.{i}.IANAManualPrefixes',
'Device.DHCPv6.Server.Pool.{i}.IANAPrefixes',
'Device.DHCPv6.Server.Pool.{i}.IAPDEnable',
'Device.DHCPv6.Server.Pool.{i}.IAPDManualPrefixes',
'Device.DHCPv6.Server.Pool.{i}.IAPDPrefixes',
'Device.DHCPv6.Server.Pool.{i}.IAPDAddLength',
'Device.DHCPv6.Server.Pool.{i}.ClientNumberOfEntries',
'Device.DHCPv6.Server.Pool.{i}.OptionNumberOfEntries',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.Alias',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.SourceAddress',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.Active',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6AddressNumberOfEntries',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6PrefixNumberOfEntries',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.OptionNumberOfEntries',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Address.{i}.',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Address.{i}.IPAddress',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Address.{i}.PreferredLifetime',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Address.{i}.ValidLifetime',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Prefix.{i}.',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Prefix.{i}.Prefix',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Prefix.{i}.PreferredLifetime',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.IPv6Prefix.{i}.ValidLifetime',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.Option.{i}.',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.Option.{i}.Tag',
'Device.DHCPv6.Server.Pool.{i}.Client.{i}.Option.{i}.Value',
'Device.DHCPv6.Server.Pool.{i}.Option.{i}.',
'Device.DHCPv6.Server.Pool.{i}.Option.{i}.Enable',
'Device.DHCPv6.Server.Pool.{i}.Option.{i}.Alias',
'Device.DHCPv6.Server.Pool.{i}.Option.{i}.Tag',
'Device.DHCPv6.Server.Pool.{i}.Option.{i}.Value',
'Device.DHCPv6.Server.Pool.{i}.Option.{i}.PassthroughClient',
'Device.IEEE8021x.',
'Device.IEEE8021x.SupplicantNumberOfEntries',
'Device.IEEE8021x.Supplicant.{i}.',
'Device.IEEE8021x.Supplicant.{i}.Enable',
'Device.IEEE8021x.Supplicant.{i}.Status',
'Device.IEEE8021x.Supplicant.{i}.Alias',
'Device.IEEE8021x.Supplicant.{i}.Interface',
'Device.IEEE8021x.Supplicant.{i}.PAEState',
'Device.IEEE8021x.Supplicant.{i}.EAPIdentity',
'Device.IEEE8021x.Supplicant.{i}.MaxStart',
'Device.IEEE8021x.Supplicant.{i}.StartPeriod',
'Device.IEEE8021x.Supplicant.{i}.HeldPeriod',
'Device.IEEE8021x.Supplicant.{i}.AuthPeriod',
'Device.IEEE8021x.Supplicant.{i}.AuthenticationCapabilities',
'Device.IEEE8021x.Supplicant.{i}.StartFailurePolicy',
'Device.IEEE8021x.Supplicant.{i}.AuthenticationSuccessPolicy',
'Device.IEEE8021x.Supplicant.{i}.Reset',
'Device.IEEE8021x.Supplicant.{i}.Disconnect',
'Device.IEEE8021x.Supplicant.{i}.Stats.',
'Device.IEEE8021x.Supplicant.{i}.Stats.ReceivedFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.TransmittedFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.TransmittedStartFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.TransmittedLogoffFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.TransmittedResponseIdFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.TransmittedResponseFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.ReceivedRequestIdFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.ReceivedRequestFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.ReceivedInvalidFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.ReceivedLengthErrorFrames',
'Device.IEEE8021x.Supplicant.{i}.Stats.LastFrameVersion',
'Device.IEEE8021x.Supplicant.{i}.Stats.LastFrameSourceMACAddress',
'Device.IEEE8021x.Supplicant.{i}.EAPMD5.',
'Device.IEEE8021x.Supplicant.{i}.EAPMD5.Enable',
'Device.IEEE8021x.Supplicant.{i}.EAPMD5.SharedSecret',
'Device.IEEE8021x.Supplicant.{i}.EAPTLS.',
'Device.IEEE8021x.Supplicant.{i}.EAPTLS.Enable',
'Device.IEEE8021x.Supplicant.{i}.EAPTLS.MutualAuthenticationEnable',
'Device.Users.',
'Device.Users.UserNumberOfEntries',
'Device.Users.GroupNumberOfEntries',
'Device.Users.RoleNumberOfEntries',
'Device.Users.SupportedShellNumberOfEntries',
'Device.Users.User.{i}.',
'Device.Users.User.{i}.Alias',
'Device.Users.User.{i}.Enable',
'Device.Users.User.{i}.UserID',
'Device.Users.User.{i}.RemoteAccessCapable',
'Device.Users.User.{i}.Username',
'Device.Users.User.{i}.Password',
'Device.Users.User.{i}.GroupParticipation',
'Device.Users.User.{i}.RoleParticipation',
'Device.Users.User.{i}.StaticUser',
'Device.Users.User.{i}.Language',
'Device.Users.User.{i}.Shell',
'Device.Users.CheckCredentialsDiagnostics.',
'Device.Users.CheckCredentialsDiagnostics.DiagnosticsState',
'Device.Users.CheckCredentialsDiagnostics.Username',
'Device.Users.CheckCredentialsDiagnostics.Password',
'Device.Users.Group.{i}.',
'Device.Users.Group.{i}.Alias',
'Device.Users.Group.{i}.Enable',
'Device.Users.Group.{i}.GroupID',
'Device.Users.Group.{i}.Groupname',
'Device.Users.Group.{i}.RoleParticipation',
'Device.Users.Group.{i}.StaticGroup',
'Device.Users.SupportedShell.{i}.',
'Device.Users.SupportedShell.{i}.Alias',
'Device.Users.SupportedShell.{i}.Enable',
'Device.Users.SupportedShell.{i}.Name',
'Device.Users.Role.{i}.',
'Device.Users.Role.{i}.Alias',
'Device.Users.Role.{i}.Enable',
'Device.Users.Role.{i}.RoleID',
'Device.Users.Role.{i}.RoleName',
'Device.Users.Role.{i}.StaticRole',
'Device.SmartCardReaders.',
'Device.SmartCardReaders.SmartCardReaderNumberOfEntries',
'Device.SmartCardReaders.SmartCardReader.{i}.',
'Device.SmartCardReaders.SmartCardReader.{i}.Alias',
'Device.SmartCardReaders.SmartCardReader.{i}.Enable',
'Device.SmartCardReaders.SmartCardReader.{i}.Status',
'Device.SmartCardReaders.SmartCardReader.{i}.Name',
'Device.SmartCardReaders.SmartCardReader.{i}.Reset',
'Device.SmartCardReaders.SmartCardReader.{i}.ResetTime',
'Device.SmartCardReaders.SmartCardReader.{i}.DecryptionFailedCounter',
'Device.SmartCardReaders.SmartCardReader.{i}.DecryptionFailedNoKeyCounter',
'Device.SmartCardReaders.SmartCardReader.{i}.SmartCard.',
'Device.SmartCardReaders.SmartCardReader.{i}.SmartCard.Status',
'Device.SmartCardReaders.SmartCardReader.{i}.SmartCard.Type',
'Device.SmartCardReaders.SmartCardReader.{i}.SmartCard.Application',
'Device.SmartCardReaders.SmartCardReader.{i}.SmartCard.SerialNumber',
'Device.SmartCardReaders.SmartCardReader.{i}.SmartCard.ATR',
'Device.UPnP.',
'Device.UPnP.Device.',
'Device.UPnP.Device.Enable',
'Device.UPnP.Device.UPnPMediaServer',
'Device.UPnP.Device.UPnPMediaRenderer',
'Device.UPnP.Device.UPnPWLANAccessPoint',
'Device.UPnP.Device.UPnPQoSDevice',
'Device.UPnP.Device.UPnPQoSPolicyHolder',
'Device.UPnP.Device.UPnPIGD',
'Device.UPnP.Device.UPnPDMBasicMgmt',
'Device.UPnP.Device.UPnPDMConfigurationMgmt',
'Device.UPnP.Device.UPnPDMSoftwareMgmt',
'Device.UPnP.Device.Capabilities.',
'Device.UPnP.Device.Capabilities.UPnPArchitecture',
'Device.UPnP.Device.Capabilities.UPnPArchitectureMinorVer',
'Device.UPnP.Device.Capabilities.UPnPMediaServer',
'Device.UPnP.Device.Capabilities.UPnPMediaRenderer',
'Device.UPnP.Device.Capabilities.UPnPWLANAccessPoint',
'Device.UPnP.Device.Capabilities.UPnPBasicDevice',
'Device.UPnP.Device.Capabilities.UPnPQoSDevice',
'Device.UPnP.Device.Capabilities.UPnPQoSPolicyHolder',
'Device.UPnP.Device.Capabilities.UPnPIGD',
'Device.UPnP.Device.Capabilities.UPnPDMBasicMgmt',
'Device.UPnP.Device.Capabilities.UPnPDMConfigurationMgmt',
'Device.UPnP.Device.Capabilities.UPnPDMSoftwareMgmt',
'Device.UPnP.Discovery.',
'Device.UPnP.Discovery.RootDeviceNumberOfEntries',
'Device.UPnP.Discovery.DeviceNumberOfEntries',
'Device.UPnP.Discovery.ServiceNumberOfEntries',
'Device.UPnP.Discovery.RootDevice.{i}.',
'Device.UPnP.Discovery.RootDevice.{i}.Status',
'Device.UPnP.Discovery.RootDevice.{i}.UUID',
'Device.UPnP.Discovery.RootDevice.{i}.USN',
'Device.UPnP.Discovery.RootDevice.{i}.LeaseTime',
'Device.UPnP.Discovery.RootDevice.{i}.Location',
'Device.UPnP.Discovery.RootDevice.{i}.Server',
'Device.UPnP.Discovery.RootDevice.{i}.Host',
'Device.UPnP.Discovery.RootDevice.{i}.LastUpdate',
'Device.UPnP.Discovery.Device.{i}.',
'Device.UPnP.Discovery.Device.{i}.Status',
'Device.UPnP.Discovery.Device.{i}.UUID',
'Device.UPnP.Discovery.Device.{i}.USN',
'Device.UPnP.Discovery.Device.{i}.LeaseTime',
'Device.UPnP.Discovery.Device.{i}.Location',
'Device.UPnP.Discovery.Device.{i}.Server',
'Device.UPnP.Discovery.Device.{i}.Host',
'Device.UPnP.Discovery.Device.{i}.LastUpdate',
'Device.UPnP.Discovery.Service.{i}.',
'Device.UPnP.Discovery.Service.{i}.Status',
'Device.UPnP.Discovery.Service.{i}.USN',
'Device.UPnP.Discovery.Service.{i}.LeaseTime',
'Device.UPnP.Discovery.Service.{i}.Location',
'Device.UPnP.Discovery.Service.{i}.Server',
'Device.UPnP.Discovery.Service.{i}.Host',
'Device.UPnP.Discovery.Service.{i}.LastUpdate',
'Device.UPnP.Discovery.Service.{i}.ParentDevice',
'Device.UPnP.Description.',
'Device.UPnP.Description.DeviceDescriptionNumberOfEntries',
'Device.UPnP.Description.DeviceInstanceNumberOfEntries',
'Device.UPnP.Description.ServiceInstanceNumberOfEntries',
'Device.UPnP.Description.DeviceDescription.{i}.',
'Device.UPnP.Description.DeviceDescription.{i}.URLBase',
'Device.UPnP.Description.DeviceDescription.{i}.SpecVersion',
'Device.UPnP.Description.DeviceDescription.{i}.Host',
'Device.UPnP.Description.DeviceInstance.{i}.',
'Device.UPnP.Description.DeviceInstance.{i}.UDN',
'Device.UPnP.Description.DeviceInstance.{i}.ParentDevice',
'Device.UPnP.Description.DeviceInstance.{i}.DiscoveryDevice',
'Device.UPnP.Description.DeviceInstance.{i}.DeviceType',
'Device.UPnP.Description.DeviceInstance.{i}.FriendlyName',
'Device.UPnP.Description.DeviceInstance.{i}.DeviceCategory',
'Device.UPnP.Description.DeviceInstance.{i}.Manufacturer',
'Device.UPnP.Description.DeviceInstance.{i}.ManufacturerOUI',
'Device.UPnP.Description.DeviceInstance.{i}.ManufacturerURL',
'Device.UPnP.Description.DeviceInstance.{i}.ModelDescription',
'Device.UPnP.Description.DeviceInstance.{i}.ModelName',
'Device.UPnP.Description.DeviceInstance.{i}.ModelNumber',
'Device.UPnP.Description.DeviceInstance.{i}.ModelURL',
'Device.UPnP.Description.DeviceInstance.{i}.SerialNumber',
'Device.UPnP.Description.DeviceInstance.{i}.UPC',
'Device.UPnP.Description.DeviceInstance.{i}.PresentationURL',
'Device.UPnP.Description.ServiceInstance.{i}.',
'Device.UPnP.Description.ServiceInstance.{i}.ParentDevice',
'Device.UPnP.Description.ServiceInstance.{i}.ServiceId',
'Device.UPnP.Description.ServiceInstance.{i}.ServiceDiscovery',
'Device.UPnP.Description.ServiceInstance.{i}.ServiceType',
'Device.UPnP.Description.ServiceInstance.{i}.SCPDURL',
'Device.UPnP.Description.ServiceInstance.{i}.ControlURL',
'Device.UPnP.Description.ServiceInstance.{i}.EventSubURL',
'Device.DLNA.',
'Device.DLNA.Capabilities.',
'Device.DLNA.Capabilities.HNDDeviceClass',
'Device.DLNA.Capabilities.DeviceCapability',
'Device.DLNA.Capabilities.HIDDeviceClass',
'Device.DLNA.Capabilities.ImageClassProfileID',
'Device.DLNA.Capabilities.AudioClassProfileID',
'Device.DLNA.Capabilities.AVClassProfileID',
'Device.DLNA.Capabilities.MediaCollectionProfileID',
'Device.DLNA.Capabilities.PrinterClassProfileID',
'Device.SelfTestDiagnostics.',
'Device.SelfTestDiagnostics.DiagnosticsState',
'Device.SelfTestDiagnostics.Results',
'Device.Firewall.',
'Device.Firewall.Enable',
'Device.Firewall.Config',
'Device.Firewall.AdvancedLevel',
'Device.Firewall.Type',
'Device.Firewall.Version',
'Device.Firewall.LastChange',
'Device.Firewall.LevelNumberOfEntries',
'Device.Firewall.ChainNumberOfEntries',
'Device.Firewall.Level.{i}.',
'Device.Firewall.Level.{i}.Alias',
'Device.Firewall.Level.{i}.Name',
'Device.Firewall.Level.{i}.Description',
'Device.Firewall.Level.{i}.Order',
'Device.Firewall.Level.{i}.Chain',
'Device.Firewall.Level.{i}.PortMappingEnabled',
'Device.Firewall.Level.{i}.DefaultPolicy',
'Device.Firewall.Level.{i}.DefaultLogPolicy',
'Device.Firewall.Chain.{i}.',
'Device.Firewall.Chain.{i}.Enable',
'Device.Firewall.Chain.{i}.Alias',
'Device.Firewall.Chain.{i}.Name',
'Device.Firewall.Chain.{i}.Creator',
'Device.Firewall.Chain.{i}.RuleNumberOfEntries',
'Device.Firewall.Chain.{i}.Rule.{i}.',
'Device.Firewall.Chain.{i}.Rule.{i}.Enable',
'Device.Firewall.Chain.{i}.Rule.{i}.Status',
'Device.Firewall.Chain.{i}.Rule.{i}.Order',
'Device.Firewall.Chain.{i}.Rule.{i}.Alias',
'Device.Firewall.Chain.{i}.Rule.{i}.Description',
'Device.Firewall.Chain.{i}.Rule.{i}.Target',
'Device.Firewall.Chain.{i}.Rule.{i}.TargetChain',
'Device.Firewall.Chain.{i}.Rule.{i}.Log',
'Device.Firewall.Chain.{i}.Rule.{i}.CreationDate',
'Device.Firewall.Chain.{i}.Rule.{i}.ExpiryDate',
'Device.Firewall.Chain.{i}.Rule.{i}.SourceInterface',
'Device.Firewall.Chain.{i}.Rule.{i}.SourceInterfaceExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.SourceAllInterfaces',
'Device.Firewall.Chain.{i}.Rule.{i}.DestInterface',
'Device.Firewall.Chain.{i}.Rule.{i}.DestInterfaceExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.DestAllInterfaces',
'Device.Firewall.Chain.{i}.Rule.{i}.IPVersion',
'Device.Firewall.Chain.{i}.Rule.{i}.DestIP',
'Device.Firewall.Chain.{i}.Rule.{i}.DestMask',
'Device.Firewall.Chain.{i}.Rule.{i}.DestIPExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.SourceIP',
'Device.Firewall.Chain.{i}.Rule.{i}.SourceMask',
'Device.Firewall.Chain.{i}.Rule.{i}.SourceIPExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.Protocol',
'Device.Firewall.Chain.{i}.Rule.{i}.ProtocolExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.DestPort',
'Device.Firewall.Chain.{i}.Rule.{i}.DestPortRangeMax',
'Device.Firewall.Chain.{i}.Rule.{i}.DestPortExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.SourcePort',
'Device.Firewall.Chain.{i}.Rule.{i}.SourcePortRangeMax',
'Device.Firewall.Chain.{i}.Rule.{i}.SourcePortExclude',
'Device.Firewall.Chain.{i}.Rule.{i}.DSCP',
'Device.Firewall.Chain.{i}.Rule.{i}.DSCPExclude',
'Device.PeriodicStatistics.',
'Device.PeriodicStatistics.MinSampleInterval',
'Device.PeriodicStatistics.MaxReportSamples',
'Device.PeriodicStatistics.SampleSetNumberOfEntries',
'Device.PeriodicStatistics.SampleSet.{i}.',
'Device.PeriodicStatistics.SampleSet.{i}.Alias',
'Device.PeriodicStatistics.SampleSet.{i}.Enable',
'Device.PeriodicStatistics.SampleSet.{i}.Status',
'Device.PeriodicStatistics.SampleSet.{i}.Name',
'Device.PeriodicStatistics.SampleSet.{i}.SampleInterval',
'Device.PeriodicStatistics.SampleSet.{i}.ReportSamples',
'Device.PeriodicStatistics.SampleSet.{i}.TimeReference',
'Device.PeriodicStatistics.SampleSet.{i}.FetchSamples',
'Device.PeriodicStatistics.SampleSet.{i}.ForceSample',
'Device.PeriodicStatistics.SampleSet.{i}.ReportStartTime',
'Device.PeriodicStatistics.SampleSet.{i}.ReportEndTime',
'Device.PeriodicStatistics.SampleSet.{i}.SampleSeconds',
'Device.PeriodicStatistics.SampleSet.{i}.ParameterNumberOfEntries',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.Alias',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.Enable',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.Reference',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.SampleMode',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.CalculationMode',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.LowThreshold',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.HighThreshold',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.SampleSeconds',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.SuspectData',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.Values',
'Device.PeriodicStatistics.SampleSet.{i}.Parameter.{i}.Failures',
'Device.FaultMgmt.',
'Device.FaultMgmt.SupportedAlarmNumberOfEntries',
'Device.FaultMgmt.MaxCurrentAlarmEntries',
'Device.FaultMgmt.CurrentAlarmNumberOfEntries',
'Device.FaultMgmt.HistoryEventNumberOfEntries',
'Device.FaultMgmt.ExpeditedEventNumberOfEntries',
'Device.FaultMgmt.QueuedEventNumberOfEntries',
'Device.FaultMgmt.SupportedAlarm.{i}.',
'Device.FaultMgmt.SupportedAlarm.{i}.EventType',
'Device.FaultMgmt.SupportedAlarm.{i}.ProbableCause',
'Device.FaultMgmt.SupportedAlarm.{i}.SpecificProblem',
'Device.FaultMgmt.SupportedAlarm.{i}.PerceivedSeverity',
'Device.FaultMgmt.SupportedAlarm.{i}.ReportingMechanism',
'Device.FaultMgmt.CurrentAlarm.{i}.',
'Device.FaultMgmt.CurrentAlarm.{i}.AlarmIdentifier',
'Device.FaultMgmt.CurrentAlarm.{i}.AlarmRaisedTime',
'Device.FaultMgmt.CurrentAlarm.{i}.AlarmChangedTime',
'Device.FaultMgmt.CurrentAlarm.{i}.ManagedObjectInstance',
'Device.FaultMgmt.CurrentAlarm.{i}.EventType',
'Device.FaultMgmt.CurrentAlarm.{i}.ProbableCause',
'Device.FaultMgmt.CurrentAlarm.{i}.SpecificProblem',
'Device.FaultMgmt.CurrentAlarm.{i}.PerceivedSeverity',
'Device.FaultMgmt.CurrentAlarm.{i}.AdditionalText',
'Device.FaultMgmt.CurrentAlarm.{i}.AdditionalInformation',
'Device.FaultMgmt.HistoryEvent.{i}.',
'Device.FaultMgmt.HistoryEvent.{i}.EventTime',
'Device.FaultMgmt.HistoryEvent.{i}.AlarmIdentifier',
'Device.FaultMgmt.HistoryEvent.{i}.NotificationType',
'Device.FaultMgmt.HistoryEvent.{i}.ManagedObjectInstance',
'Device.FaultMgmt.HistoryEvent.{i}.EventType',
'Device.FaultMgmt.HistoryEvent.{i}.ProbableCause',
'Device.FaultMgmt.HistoryEvent.{i}.SpecificProblem',
'Device.FaultMgmt.HistoryEvent.{i}.PerceivedSeverity',
'Device.FaultMgmt.HistoryEvent.{i}.AdditionalText',
'Device.FaultMgmt.HistoryEvent.{i}.AdditionalInformation',
'Device.FaultMgmt.ExpeditedEvent.{i}.',
'Device.FaultMgmt.ExpeditedEvent.{i}.EventTime',
'Device.FaultMgmt.ExpeditedEvent.{i}.AlarmIdentifier',
'Device.FaultMgmt.ExpeditedEvent.{i}.NotificationType',
'Device.FaultMgmt.ExpeditedEvent.{i}.ManagedObjectInstance',
'Device.FaultMgmt.ExpeditedEvent.{i}.EventType',
'Device.FaultMgmt.ExpeditedEvent.{i}.ProbableCause',
'Device.FaultMgmt.ExpeditedEvent.{i}.SpecificProblem',
'Device.FaultMgmt.ExpeditedEvent.{i}.PerceivedSeverity',
'Device.FaultMgmt.ExpeditedEvent.{i}.AdditionalText',
'Device.FaultMgmt.ExpeditedEvent.{i}.AdditionalInformation',
'Device.FaultMgmt.QueuedEvent.{i}.',
'Device.FaultMgmt.QueuedEvent.{i}.EventTime',
'Device.FaultMgmt.QueuedEvent.{i}.AlarmIdentifier',
'Device.FaultMgmt.QueuedEvent.{i}.NotificationType',
'Device.FaultMgmt.QueuedEvent.{i}.ManagedObjectInstance',
'Device.FaultMgmt.QueuedEvent.{i}.EventType',
'Device.FaultMgmt.QueuedEvent.{i}.ProbableCause',
'Device.FaultMgmt.QueuedEvent.{i}.SpecificProblem',
'Device.FaultMgmt.QueuedEvent.{i}.PerceivedSeverity',
'Device.FaultMgmt.QueuedEvent.{i}.AdditionalText',
'Device.FaultMgmt.QueuedEvent.{i}.AdditionalInformation',
'Device.Security.',
'Device.Security.CertificateNumberOfEntries',
'Device.Security.Certificate.{i}.',
'Device.Security.Certificate.{i}.Enable',
'Device.Security.Certificate.{i}.LastModif',
'Device.Security.Certificate.{i}.SerialNumber',
'Device.Security.Certificate.{i}.Issuer',
'Device.Security.Certificate.{i}.NotBefore',
'Device.Security.Certificate.{i}.NotAfter',
'Device.Security.Certificate.{i}.Subject',
'Device.Security.Certificate.{i}.SubjectAlt',
'Device.Security.Certificate.{i}.SignatureAlgorithm',
'Device.FAP.',
'Device.FAP.GPS.',
'Device.FAP.GPS.ScanOnBoot',
'Device.FAP.GPS.ScanPeriodically',
'Device.FAP.GPS.PeriodicInterval',
'Device.FAP.GPS.PeriodicTime',
'Device.FAP.GPS.ContinuousGPS',
'Device.FAP.GPS.ScanTimeout',
'Device.FAP.GPS.ScanStatus',
'Device.FAP.GPS.ErrorDetails',
'Device.FAP.GPS.LastScanTime',
'Device.FAP.GPS.LastSuccessfulScanTime',
'Device.FAP.GPS.LockedLatitude',
'Device.FAP.GPS.LockedLongitude',
'Device.FAP.GPS.NumberOfSatellites',
'Device.FAP.GPS.GPSReset',
'Device.FAP.GPS.ContinuousGPSStatus.',
'Device.FAP.GPS.ContinuousGPSStatus.CurrentFix',
'Device.FAP.GPS.ContinuousGPSStatus.GotFix',
'Device.FAP.GPS.ContinuousGPSStatus.TimingGood',
'Device.FAP.GPS.ContinuousGPSStatus.Latitude',
'Device.FAP.GPS.ContinuousGPSStatus.Longitude',
'Device.FAP.GPS.ContinuousGPSStatus.Elevation',
'Device.FAP.GPS.ContinuousGPSStatus.LastFixTime',
'Device.FAP.GPS.ContinuousGPSStatus.LastFixDuration',
'Device.FAP.GPS.ContinuousGPSStatus.FirstFixTimeout',
'Device.FAP.GPS.ContinuousGPSStatus.SatellitesTracked',
'Device.FAP.GPS.ContinuousGPSStatus.SatelliteTrackingInterval',
'Device.FAP.GPS.ContinuousGPSStatus.ReceiverStatus',
'Device.FAP.GPS.ContinuousGPSStatus.LocationType',
'Device.FAP.GPS.ContinuousGPSStatus.LockTimeOutDuration',
'Device.FAP.GPS.AGPSServerConfig.',
'Device.FAP.GPS.AGPSServerConfig.Enable',
'Device.FAP.GPS.AGPSServerConfig.ServerURL',
'Device.FAP.GPS.AGPSServerConfig.ServerPort',
'Device.FAP.GPS.AGPSServerConfig.Username',
'Device.FAP.GPS.AGPSServerConfig.Password',
'Device.FAP.GPS.AGPSServerConfig.ReferenceLatitude',
'Device.FAP.GPS.AGPSServerConfig.ReferenceLongitude',
'Device.FAP.GPS.AGPSServerConfig.ServerInUse',
'Device.FAP.PerfMgmt.',
'Device.FAP.PerfMgmt.ConfigNumberOfEntries',
'Device.FAP.PerfMgmt.Config.{i}.',
'Device.FAP.PerfMgmt.Config.{i}.Enable',
'Device.FAP.PerfMgmt.Config.{i}.Alias',
'Device.FAP.PerfMgmt.Config.{i}.URL',
'Device.FAP.PerfMgmt.Config.{i}.Username',
'Device.FAP.PerfMgmt.Config.{i}.Password',
'Device.FAP.PerfMgmt.Config.{i}.PeriodicUploadInterval',
'Device.FAP.PerfMgmt.Config.{i}.PeriodicUploadTime',
'Device.FAP.ApplicationPlatform.',
'Device.FAP.ApplicationPlatform.Version',
'Device.FAP.ApplicationPlatform.Enable',
'Device.FAP.ApplicationPlatform.Status',
'Device.FAP.ApplicationPlatform.MaxNumberOfApplications',
'Device.FAP.ApplicationPlatform.CurrentNumberofApplications',
'Device.FAP.ApplicationPlatform.Capabilities.',
'Device.FAP.ApplicationPlatform.Capabilities.PresenceApplicationSupport',
'Device.FAP.ApplicationPlatform.Capabilities.FemtoAwarenessAPISupport',
'Device.FAP.ApplicationPlatform.Capabilities.SMSAPISupport',
'Device.FAP.ApplicationPlatform.Capabilities.SubscribeToNotificationsOfSMSSentToApplicationSupport',
'Device.FAP.ApplicationPlatform.Capabilities.QuerySMSDeliveryStatusSupport',
'Device.FAP.ApplicationPlatform.Capabilities.MMSAPISupport',
'Device.FAP.ApplicationPlatform.Capabilities.QueryMMSDeliveryStatusSupport',
'Device.FAP.ApplicationPlatform.Capabilities.SubscribeToNotificationsOfMMSSentToApplicationSupport',
'Device.FAP.ApplicationPlatform.Capabilities.TerminalLocationAPISupport',
'Device.FAP.ApplicationPlatform.Capabilities.AuthenticationMethodsSupported',
'Device.FAP.ApplicationPlatform.Capabilities.AccessLevelsSupported',
'Device.FAP.ApplicationPlatform.Capabilities.SendSMSTargetAddressType',
'Device.FAP.ApplicationPlatform.Capabilities.SendMMSTargetAddressType',
'Device.FAP.ApplicationPlatform.Control.',
'Device.FAP.ApplicationPlatform.Control.AuthenticationMethod',
'Device.FAP.ApplicationPlatform.Control.TunnelInst',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.APIEnable',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.QueueEnable',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.Queueing',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.MaxAPIUsersNumber',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.FemtozoneID',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.NotificationsUserIdentifierMSISDN',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.SubscribeToNotificationsResponseCallbackData',
'Device.FAP.ApplicationPlatform.Control.FemtoAwareness.QueryFemtocellResponseTimezone',
'Device.FAP.ApplicationPlatform.Control.SMS.',
'Device.FAP.ApplicationPlatform.Control.SMS.APIEnable',
'Device.FAP.ApplicationPlatform.Control.SMS.QueueEnable',
'Device.FAP.ApplicationPlatform.Control.SMS.Queueing',
'Device.FAP.ApplicationPlatform.Control.SMS.MaxAPIUsersNumber',
'Device.FAP.ApplicationPlatform.Control.SMS.MinSendSMSTimeInterval',
'Device.FAP.ApplicationPlatform.Control.SMS.EnableQuerySMSDeliveryStatus',
'Device.FAP.ApplicationPlatform.Control.SMS.EnableSubscribeToNotificationsOfMessageSentToApplication',
'Device.FAP.ApplicationPlatform.Control.MMS.',
'Device.FAP.ApplicationPlatform.Control.MMS.APIEnable',
'Device.FAP.ApplicationPlatform.Control.MMS.QueueEnable',
'Device.FAP.ApplicationPlatform.Control.MMS.Queueing',
'Device.FAP.ApplicationPlatform.Control.MMS.MaxAPIUsersNumber',
'Device.FAP.ApplicationPlatform.Control.MMS.MinSendMMSTimeInterval',
'Device.FAP.ApplicationPlatform.Control.MMS.EnableQueryMMSDeliveryStatus',
'Device.FAP.ApplicationPlatform.Control.MMS.EnableSubscribeToNotificationsOfMessageSentToApplication',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.APIEnable',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.QueueEnable',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.Queueing',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.MaxAPIUsersNumber',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.QueryMobileLocationResponseAddress',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.QueryMobileLocationResponseLongitudeLatitude',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.QueryMobileLocationResponseAltitude',
'Device.FAP.ApplicationPlatform.Control.TerminalLocation.QueryMobileLocationResponseTimestamp',
'Device.FAP.ApplicationPlatform.Monitoring.',
'Device.FAP.ApplicationPlatform.Monitoring.Enable',
'Device.FAP.ApplicationPlatform.Monitoring.MonitoringInterval',
'Device.FAP.ApplicationPlatform.Monitoring.AuthenticationRequestsReceived',
'Device.FAP.ApplicationPlatform.Monitoring.AuthenticationRequestsRejected',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.APIAvailable',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.APIUsers',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.QueueState',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.QueueNum',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.QueueReceived',
'Device.FAP.ApplicationPlatform.Monitoring.FemtoAwareness.QueueDiscarded',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.APIAvailable',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.APIUsers',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.QueueState',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.QueueNum',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.QueueReceived',
'Device.FAP.ApplicationPlatform.Monitoring.SMS.QueueDiscarded',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.APIAvailable',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.APIUsers',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.QueueState',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.QueueNum',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.QueueReceived',
'Device.FAP.ApplicationPlatform.Monitoring.MMS.QueueDiscarded',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.APIAvailable',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.APIUsers',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.QueueState',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.QueueNum',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.QueueReceived',
'Device.FAP.ApplicationPlatform.Monitoring.TerminalLocation.QueueDiscarded',
'Device.BulkData.',
'Device.BulkData.Enable',
'Device.BulkData.Status',
'Device.BulkData.MinReportingInterval',
'Device.BulkData.Protocols',
'Device.BulkData.EncodingTypes',
'Device.BulkData.ParameterWildCardSupported',
'Device.BulkData.MaxNumberOfProfiles',
'Device.BulkData.MaxNumberOfParameterReferences',
'Device.BulkData.ProfileNumberOfEntries',
'Device.BulkData.Profile.{i}.',
'Device.BulkData.Profile.{i}.Enable',
'Device.BulkData.Profile.{i}.Alias',
'Device.BulkData.Profile.{i}.Name',
'Device.BulkData.Profile.{i}.NumberOfRetainedFailedReports',
'Device.BulkData.Profile.{i}.Protocol',
'Device.BulkData.Profile.{i}.EncodingType',
'Device.BulkData.Profile.{i}.ReportingInterval',
'Device.BulkData.Profile.{i}.TimeReference',
'Device.BulkData.Profile.{i}.ParameterNumberOfEntries',
'Device.BulkData.Profile.{i}.StreamingHost',
'Device.BulkData.Profile.{i}.StreamingPort',
'Device.BulkData.Profile.{i}.StreamingSessionID',
'Device.BulkData.Profile.{i}.FileTransferURL',
'Device.BulkData.Profile.{i}.FileTransferUsername',
'Device.BulkData.Profile.{i}.FileTransferPassword',
'Device.BulkData.Profile.{i}.ControlFileFormat',
'Device.BulkData.Profile.{i}.Parameter.{i}.',
'Device.BulkData.Profile.{i}.Parameter.{i}.Name',
'Device.BulkData.Profile.{i}.Parameter.{i}.Reference',
'Device.BulkData.Profile.{i}.CSVEncoding.',
'Device.BulkData.Profile.{i}.CSVEncoding.FieldSeparator',
'Device.BulkData.Profile.{i}.CSVEncoding.RowSeparator',
'Device.BulkData.Profile.{i}.CSVEncoding.EscapeCharacter',
'Device.BulkData.Profile.{i}.CSVEncoding.ReportFormat',
'Device.BulkData.Profile.{i}.CSVEncoding.RowTimestamp',
'Device.BulkData.Profile.{i}.JSONEncoding.',
'Device.BulkData.Profile.{i}.JSONEncoding.ReportFormat',
'Device.BulkData.Profile.{i}.JSONEncoding.ReportTimestamp',
'Device.BulkData.Profile.{i}.HTTP.',
'Device.BulkData.Profile.{i}.HTTP.URL',
'Device.BulkData.Profile.{i}.HTTP.Username',
'Device.BulkData.Profile.{i}.HTTP.Password',
'Device.BulkData.Profile.{i}.HTTP.CompressionsSupported',
'Device.BulkData.Profile.{i}.HTTP.Compression',
'Device.BulkData.Profile.{i}.HTTP.MethodsSupported',
'Device.BulkData.Profile.{i}.HTTP.Method',
'Device.BulkData.Profile.{i}.HTTP.UseDateHeader',
'Device.BulkData.Profile.{i}.HTTP.RetryEnable',
'Device.BulkData.Profile.{i}.HTTP.RetryMinimumWaitInterval',
'Device.BulkData.Profile.{i}.HTTP.RetryIntervalMultiplier',
'Device.BulkData.Profile.{i}.HTTP.RequestURIParameterNumberOfEntries',
'Device.BulkData.Profile.{i}.HTTP.PersistAcrossReboot',
'Device.BulkData.Profile.{i}.HTTP.RequestURIParameter.{i}.',
'Device.BulkData.Profile.{i}.HTTP.RequestURIParameter.{i}.Name',
'Device.BulkData.Profile.{i}.HTTP.RequestURIParameter.{i}.Reference',
'Device.BulkData.Profile.{i}.MQTT.',
'Device.BulkData.Profile.{i}.MQTT.Reference',
'Device.BulkData.Profile.{i}.MQTT.PublishTopic',
'Device.BulkData.Profile.{i}.MQTT.PublishQoS',
'Device.BulkData.Profile.{i}.MQTT.PublishRetain',
'Device.SoftwareModules.',
'Device.SoftwareModules.ExecEnvNumberOfEntries',
'Device.SoftwareModules.DeploymentUnitNumberOfEntries',
'Device.SoftwareModules.ExecutionUnitNumberOfEntries',
'Device.SoftwareModules.ExecEnv.{i}.',
'Device.SoftwareModules.ExecEnv.{i}.Enable',
'Device.SoftwareModules.ExecEnv.{i}.Status',
'Device.SoftwareModules.ExecEnv.{i}.Reset',
'Device.SoftwareModules.ExecEnv.{i}.Alias',
'Device.SoftwareModules.ExecEnv.{i}.Name',
'Device.SoftwareModules.ExecEnv.{i}.Type',
'Device.SoftwareModules.ExecEnv.{i}.InitialRunLevel',
'Device.SoftwareModules.ExecEnv.{i}.RequestedRunLevel',
'Device.SoftwareModules.ExecEnv.{i}.CurrentRunLevel',
'Device.SoftwareModules.ExecEnv.{i}.InitialExecutionUnitRunLevel',
'Device.SoftwareModules.ExecEnv.{i}.Vendor',
'Device.SoftwareModules.ExecEnv.{i}.Version',
'Device.SoftwareModules.ExecEnv.{i}.ParentExecEnv',
'Device.SoftwareModules.ExecEnv.{i}.AllocatedDiskSpace',
'Device.SoftwareModules.ExecEnv.{i}.AvailableDiskSpace',
'Device.SoftwareModules.ExecEnv.{i}.AllocatedMemory',
'Device.SoftwareModules.ExecEnv.{i}.AvailableMemory',
'Device.SoftwareModules.ExecEnv.{i}.ActiveExecutionUnits',
'Device.SoftwareModules.ExecEnv.{i}.ProcessorRefList',
'Device.SoftwareModules.DeploymentUnit.{i}.',
'Device.SoftwareModules.DeploymentUnit.{i}.UUID',
'Device.SoftwareModules.DeploymentUnit.{i}.DUID',
'Device.SoftwareModules.DeploymentUnit.{i}.Alias',
'Device.SoftwareModules.DeploymentUnit.{i}.Name',
'Device.SoftwareModules.DeploymentUnit.{i}.Status',
'Device.SoftwareModules.DeploymentUnit.{i}.Resolved',
'Device.SoftwareModules.DeploymentUnit.{i}.URL',
'Device.SoftwareModules.DeploymentUnit.{i}.Description',
'Device.SoftwareModules.DeploymentUnit.{i}.Vendor',
'Device.SoftwareModules.DeploymentUnit.{i}.Version',
'Device.SoftwareModules.DeploymentUnit.{i}.VendorLogList',
'Device.SoftwareModules.DeploymentUnit.{i}.VendorConfigList',
'Device.SoftwareModules.DeploymentUnit.{i}.ExecutionUnitList',
'Device.SoftwareModules.DeploymentUnit.{i}.ExecutionEnvRef',
'Device.SoftwareModules.ExecutionUnit.{i}.',
'Device.SoftwareModules.ExecutionUnit.{i}.EUID',
'Device.SoftwareModules.ExecutionUnit.{i}.Alias',
'Device.SoftwareModules.ExecutionUnit.{i}.Name',
'Device.SoftwareModules.ExecutionUnit.{i}.ExecEnvLabel',
'Device.SoftwareModules.ExecutionUnit.{i}.Status',
'Device.SoftwareModules.ExecutionUnit.{i}.RequestedState',
'Device.SoftwareModules.ExecutionUnit.{i}.ExecutionFaultCode',
'Device.SoftwareModules.ExecutionUnit.{i}.ExecutionFaultMessage',
'Device.SoftwareModules.ExecutionUnit.{i}.AutoStart',
'Device.SoftwareModules.ExecutionUnit.{i}.RunLevel',
'Device.SoftwareModules.ExecutionUnit.{i}.Vendor',
'Device.SoftwareModules.ExecutionUnit.{i}.Version',
'Device.SoftwareModules.ExecutionUnit.{i}.Description',
'Device.SoftwareModules.ExecutionUnit.{i}.DiskSpaceInUse',
'Device.SoftwareModules.ExecutionUnit.{i}.MemoryInUse',
'Device.SoftwareModules.ExecutionUnit.{i}.References',
'Device.SoftwareModules.ExecutionUnit.{i}.AssociatedProcessList',
'Device.SoftwareModules.ExecutionUnit.{i}.VendorLogList',
'Device.SoftwareModules.ExecutionUnit.{i}.VendorConfigList',
'Device.SoftwareModules.ExecutionUnit.{i}.SupportedDataModelList',
'Device.SoftwareModules.ExecutionUnit.{i}.ExecutionEnvRef',
'Device.SoftwareModules.ExecutionUnit.{i}.Extensions.',
'Device.XMPP.',
'Device.XMPP.ConnectionNumberOfEntries',
'Device.XMPP.SupportedServerConnectAlgorithms',
'Device.XMPP.Connection.{i}.',
'Device.XMPP.Connection.{i}.Enable',
'Device.XMPP.Connection.{i}.Alias',
'Device.XMPP.Connection.{i}.Username',
'Device.XMPP.Connection.{i}.Password',
'Device.XMPP.Connection.{i}.Domain',
'Device.XMPP.Connection.{i}.Resource',
'Device.XMPP.Connection.{i}.JabberID',
'Device.XMPP.Connection.{i}.Status',
'Device.XMPP.Connection.{i}.LastChangeDate',
'Device.XMPP.Connection.{i}.ServerConnectAlgorithm',
'Device.XMPP.Connection.{i}.KeepAliveInterval',
'Device.XMPP.Connection.{i}.ServerConnectAttempts',
'Device.XMPP.Connection.{i}.ServerRetryInitialInterval',
'Device.XMPP.Connection.{i}.ServerRetryIntervalMultiplier',
'Device.XMPP.Connection.{i}.ServerRetryMaxInterval',
'Device.XMPP.Connection.{i}.UseTLS',
'Device.XMPP.Connection.{i}.TLSEstablished',
'Device.XMPP.Connection.{i}.ServerNumberOfEntries',
'Device.XMPP.Connection.{i}.Server.{i}.',
'Device.XMPP.Connection.{i}.Server.{i}.Enable',
'Device.XMPP.Connection.{i}.Server.{i}.Alias',
'Device.XMPP.Connection.{i}.Server.{i}.Priority',
'Device.XMPP.Connection.{i}.Server.{i}.Weight',
'Device.XMPP.Connection.{i}.Server.{i}.ServerAddress',
'Device.XMPP.Connection.{i}.Server.{i}.Port',
'Device.XMPP.Connection.{i}.Stats.',
'Device.XMPP.Connection.{i}.Stats.ReceivedMessages',
'Device.XMPP.Connection.{i}.Stats.TransmittedMessages',
'Device.XMPP.Connection.{i}.Stats.ReceivedErrorMessages',
'Device.XMPP.Connection.{i}.Stats.TransmittedErrorMessages',
'Device.IEEE1905.',
'Device.IEEE1905.Version',
'Device.IEEE1905.AL.',
'Device.IEEE1905.AL.IEEE1905Id',
'Device.IEEE1905.AL.Status',
'Device.IEEE1905.AL.LastChange',
'Device.IEEE1905.AL.LowerLayers',
'Device.IEEE1905.AL.RegistrarFreqBand',
'Device.IEEE1905.AL.InterfaceNumberOfEntries',
'Device.IEEE1905.AL.Interface.{i}.',
'Device.IEEE1905.AL.Interface.{i}.InterfaceId',
'Device.IEEE1905.AL.Interface.{i}.Status',
'Device.IEEE1905.AL.Interface.{i}.LastChange',
'Device.IEEE1905.AL.Interface.{i}.LowerLayers',
'Device.IEEE1905.AL.Interface.{i}.InterfaceStackReference',
'Device.IEEE1905.AL.Interface.{i}.MediaType',
'Device.IEEE1905.AL.Interface.{i}.GenericPhyOUI',
'Device.IEEE1905.AL.Interface.{i}.GenericPhyVariant',
'Device.IEEE1905.AL.Interface.{i}.GenericPhyURL',
'Device.IEEE1905.AL.Interface.{i}.SetIntfPowerStateEnabled',
'Device.IEEE1905.AL.Interface.{i}.PowerState',
'Device.IEEE1905.AL.Interface.{i}.VendorPropertiesNumberOfEntries',
'Device.IEEE1905.AL.Interface.{i}.LinkNumberOfEntries',
'Device.IEEE1905.AL.Interface.{i}.VendorProperties.{i}.',
'Device.IEEE1905.AL.Interface.{i}.VendorProperties.{i}.OUI',
'Device.IEEE1905.AL.Interface.{i}.VendorProperties.{i}.Information',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.InterfaceId',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.IEEE1905Id',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.MediaType',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.GenericPhyOUI',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.GenericPhyVariant',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.GenericPhyURL',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.IEEE802dot1Bridge',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.PacketErrors',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.PacketErrorsReceived',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.TransmittedPackets',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.PacketsReceived',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.MACThroughputCapacity',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.LinkAvailability',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.PHYRate',
'Device.IEEE1905.AL.Interface.{i}.Link.{i}.Metric.RSSI',
'Device.IEEE1905.AL.ForwardingTable.',
'Device.IEEE1905.AL.ForwardingTable.SetForwardingEnabled',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRuleNumberOfEntries',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.InterfaceList',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.MACDestinationAddress',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.MACDestinationAddressFlag',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.MACSourceAddress',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.MACSourceAddressFlag',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.EtherType',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.EtherTypeFlag',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.Vid',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.VidFlag',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.PCP',
'Device.IEEE1905.AL.ForwardingTable.ForwardingRule.{i}.PCPFlag',
'Device.IEEE1905.AL.NetworkTopology.',
'Device.IEEE1905.AL.NetworkTopology.Enable',
'Device.IEEE1905.AL.NetworkTopology.Status',
'Device.IEEE1905.AL.NetworkTopology.MaxChangeLogEntries',
'Device.IEEE1905.AL.NetworkTopology.LastChange',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905DeviceNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.ChangeLogNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.TimeStamp',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.EventType',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.ReporterDeviceId',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.ReporterInterfaceId',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.NeighborType',
'Device.IEEE1905.AL.NetworkTopology.ChangeLog.{i}.NeighborId',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Id',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Version',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.RegistrarFreqBand',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.FriendlyName',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.ManufacturerName',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.ManufacturerModel',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.ControlURL',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.AssocWiFiNetworkDeviceRef',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.VendorPropertiesNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv4AddressNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv6AddressNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.InterfaceNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.NonIEEE1905NeighborNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905NeighborNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.L2NeighborNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.BridgingTupleNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv4Address.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv4Address.{i}.MACAddress',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv4Address.{i}.IPv4Address',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv4Address.{i}.IPv4AddressType',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv4Address.{i}.DHCPServer',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv6Address.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv6Address.{i}.MACAddress',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv6Address.{i}.IPv6Address',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv6Address.{i}.IPv6AddressType',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IPv6Address.{i}.IPv6AddressOrigin',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.VendorProperties.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.VendorProperties.{i}.MessageType',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.VendorProperties.{i}.OUI',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.VendorProperties.{i}.Information',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.InterfaceId',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.MediaType',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.PowerState',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.GenericPhyOUI',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.GenericPhyVariant',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.GenericPhyURL',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.NetworkMembership',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.Role',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.APChannelBand',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.FrequencyIndex1',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.Interface.{i}.FrequencyIndex2',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.NonIEEE1905Neighbor.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.NonIEEE1905Neighbor.{i}.LocalInterface',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.NonIEEE1905Neighbor.{i}.NeighborInterfaceId',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.L2Neighbor.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.L2Neighbor.{i}.LocalInterface',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.L2Neighbor.{i}.NeighborInterfaceId',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.L2Neighbor.{i}.BehindInterfaceIds',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.LocalInterface',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.NeighborDeviceId',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.MetricNumberOfEntries',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.NeighborMACAddress',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.IEEE802dot1Bridge',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.PacketErrors',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.PacketErrorsReceived',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.TransmittedPackets',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.PacketsReceived',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.MACThroughputCapacity',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.LinkAvailability',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.PHYRate',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.IEEE1905Neighbor.{i}.Metric.{i}.RSSI',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.BridgingTuple.{i}.',
'Device.IEEE1905.AL.NetworkTopology.IEEE1905Device.{i}.BridgingTuple.{i}.InterfaceList',
'Device.IEEE1905.AL.Security.',
'Device.IEEE1905.AL.Security.SetupMethod',
'Device.IEEE1905.AL.Security.Password',
'Device.IEEE1905.AL.NetworkingRegistrar.',
'Device.IEEE1905.AL.NetworkingRegistrar.Registrar2dot4',
'Device.IEEE1905.AL.NetworkingRegistrar.Registrar5',
'Device.IEEE1905.AL.NetworkingRegistrar.Registrar60',
'Device.MQTT.',
'Device.MQTT.ClientNumberOfEntries',
'Device.MQTT.BrokerNumberOfEntries',
'Device.MQTT.Capabilities.',
'Device.MQTT.Capabilities.ProtocolVersionsSupported',
'Device.MQTT.Capabilities.TransportProtocolSupported',
'Device.MQTT.Capabilities.MaxNumberOfClientSubscriptions',
'Device.MQTT.Capabilities.MaxNumberOfBrokerBridges',
'Device.MQTT.Capabilities.MaxNumberOfBrokerBridgeSubscriptions',
'Device.MQTT.Client.{i}.',
'Device.MQTT.Client.{i}.Alias',
'Device.MQTT.Client.{i}.Name',
'Device.MQTT.Client.{i}.Enable',
'Device.MQTT.Client.{i}.Status',
'Device.MQTT.Client.{i}.ProtocolVersion',
'Device.MQTT.Client.{i}.EnableEncryption',
'Device.MQTT.Client.{i}.BrokerAddress',
'Device.MQTT.Client.{i}.BrokerPort',
'Device.MQTT.Client.{i}.TransportProtocol',
'Device.MQTT.Client.{i}.CleanSession',
'Device.MQTT.Client.{i}.CleanStart',
'Device.MQTT.Client.{i}.WillEnable',
'Device.MQTT.Client.{i}.WillQoS',
'Device.MQTT.Client.{i}.WillRetain',
'Device.MQTT.Client.{i}.KeepAliveTime',
'Device.MQTT.Client.{i}.ForceReconnect',
'Device.MQTT.Client.{i}.SessionExpiryInterval',
'Device.MQTT.Client.{i}.ReceiveMaximum',
'Device.MQTT.Client.{i}.MaximumPacketSize',
'Device.MQTT.Client.{i}.TopicAliasMaximum',
'Device.MQTT.Client.{i}.RequestResponseInfo',
'Device.MQTT.Client.{i}.RequestProblemInfo',
'Device.MQTT.Client.{i}.AuthenticationMethod',
'Device.MQTT.Client.{i}.ClientID',
'Device.MQTT.Client.{i}.WillDelayInterval',
'Device.MQTT.Client.{i}.WillMessageExpiryInterval',
'Device.MQTT.Client.{i}.WillContentType',
'Device.MQTT.Client.{i}.WillResponseTopic',
'Device.MQTT.Client.{i}.WillTopic',
'Device.MQTT.Client.{i}.WillValue',
'Device.MQTT.Client.{i}.Username',
'Device.MQTT.Client.{i}.Password',
'Device.MQTT.Client.{i}.PublishMessageExpiryInterval',
'Device.MQTT.Client.{i}.MessageRetryTime',
'Device.MQTT.Client.{i}.ConnectRetryTime',
'Device.MQTT.Client.{i}.ConnectRetryIntervalMultiplier',
'Device.MQTT.Client.{i}.ConnectRetryMaxInterval',
'Device.MQTT.Client.{i}.ResponseInformation',
'Device.MQTT.Client.{i}.SubscriptionNumberOfEntries',
'Device.MQTT.Client.{i}.UserPropertyNumberOfEntries',
'Device.MQTT.Client.{i}.Subscription.{i}.',
'Device.MQTT.Client.{i}.Subscription.{i}.Alias',
'Device.MQTT.Client.{i}.Subscription.{i}.Enable',
'Device.MQTT.Client.{i}.Subscription.{i}.Status',
'Device.MQTT.Client.{i}.Subscription.{i}.Topic',
'Device.MQTT.Client.{i}.Subscription.{i}.QoS',
'Device.MQTT.Client.{i}.UserProperty.{i}.',
'Device.MQTT.Client.{i}.UserProperty.{i}.Alias',
'Device.MQTT.Client.{i}.UserProperty.{i}.Enable',
'Device.MQTT.Client.{i}.UserProperty.{i}.Name',
'Device.MQTT.Client.{i}.UserProperty.{i}.Value',
'Device.MQTT.Client.{i}.UserProperty.{i}.PacketType',
'Device.MQTT.Client.{i}.Stats.',
'Device.MQTT.Client.{i}.Stats.BrokerConnectionEstablished',
'Device.MQTT.Client.{i}.Stats.LastPublishMessageSent',
'Device.MQTT.Client.{i}.Stats.LastPublishMessageReceived',
'Device.MQTT.Client.{i}.Stats.PublishSent',
'Device.MQTT.Client.{i}.Stats.PublishReceived',
'Device.MQTT.Client.{i}.Stats.SubscribeSent',
'Device.MQTT.Client.{i}.Stats.UnSubscribeSent',
'Device.MQTT.Client.{i}.Stats.MQTTMessagesSent',
'Device.MQTT.Client.{i}.Stats.MQTTMessagesReceived',
'Device.MQTT.Client.{i}.Stats.ConnectionErrors',
'Device.MQTT.Client.{i}.Stats.PublishErrors',
'Device.MQTT.Broker.{i}.',
'Device.MQTT.Broker.{i}.Alias',
'Device.MQTT.Broker.{i}.Name',
'Device.MQTT.Broker.{i}.Enable',
'Device.MQTT.Broker.{i}.Status',
'Device.MQTT.Broker.{i}.Port',
'Device.MQTT.Broker.{i}.Interface',
'Device.MQTT.Broker.{i}.Username',
'Device.MQTT.Broker.{i}.Password',
'Device.MQTT.Broker.{i}.BridgeNumberOfEntries',
'Device.MQTT.Broker.{i}.Bridge.{i}.',
'Device.MQTT.Broker.{i}.Bridge.{i}.Alias',
'Device.MQTT.Broker.{i}.Bridge.{i}.Name',
'Device.MQTT.Broker.{i}.Bridge.{i}.Enable',
'Device.MQTT.Broker.{i}.Bridge.{i}.Status',
'Device.MQTT.Broker.{i}.Bridge.{i}.ProtocolVersion',
'Device.MQTT.Broker.{i}.Bridge.{i}.TransportProtocol',
'Device.MQTT.Broker.{i}.Bridge.{i}.CleanSession',
'Device.MQTT.Broker.{i}.Bridge.{i}.CleanStart',
'Device.MQTT.Broker.{i}.Bridge.{i}.KeepAliveTime',
'Device.MQTT.Broker.{i}.Bridge.{i}.ForceReconnect',
'Device.MQTT.Broker.{i}.Bridge.{i}.ClientID',
'Device.MQTT.Broker.{i}.Bridge.{i}.Username',
'Device.MQTT.Broker.{i}.Bridge.{i}.Password',
'Device.MQTT.Broker.{i}.Bridge.{i}.MessageRetryTime',
'Device.MQTT.Broker.{i}.Bridge.{i}.ConnectRetryTime',
'Device.MQTT.Broker.{i}.Bridge.{i}.ServerSelectionAlgorithm',
'Device.MQTT.Broker.{i}.Bridge.{i}.ServerConnection',
'Device.MQTT.Broker.{i}.Bridge.{i}.ServerNumberOfEntries',
'Device.MQTT.Broker.{i}.Bridge.{i}.SubscriptionNumberOfEntries',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.Enable',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.Alias',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.Priority',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.Weight',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.Address',
'Device.MQTT.Broker.{i}.Bridge.{i}.Server.{i}.Port',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.Alias',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.Enable',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.Status',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.Topic',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.Direction',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.QoS',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.LocalPrefix',
'Device.MQTT.Broker.{i}.Bridge.{i}.Subscription.{i}.RemotePrefix',
'Device.MQTT.Broker.{i}.Stats.',
'Device.MQTT.Broker.{i}.Stats.TotalNumberOfClients',
'Device.MQTT.Broker.{i}.Stats.NumberOfActiveClients',
'Device.MQTT.Broker.{i}.Stats.NumberOfInactiveClients',
'Device.MQTT.Broker.{i}.Stats.Subscriptions',
'Device.MQTT.Broker.{i}.Stats.PublishSent',
'Device.MQTT.Broker.{i}.Stats.PublishReceived',
'Device.MQTT.Broker.{i}.Stats.MQTTMessagesSent',
'Device.MQTT.Broker.{i}.Stats.MQTTMessagesReceived',
'Device.MQTT.Broker.{i}.Stats.ConnectionErrors',
'Device.MQTT.Broker.{i}.Stats.PublishErrors',
'Device.DynamicDNS.',
'Device.DynamicDNS.ClientNumberOfEntries',
'Device.DynamicDNS.ServerNumberOfEntries',
'Device.DynamicDNS.SupportedServices',
'Device.DynamicDNS.Client.{i}.',
'Device.DynamicDNS.Client.{i}.Enable',
'Device.DynamicDNS.Client.{i}.Status',
'Device.DynamicDNS.Client.{i}.Alias',
'Device.DynamicDNS.Client.{i}.LastError',
'Device.DynamicDNS.Client.{i}.Server',
'Device.DynamicDNS.Client.{i}.Interface',
'Device.DynamicDNS.Client.{i}.Username',
'Device.DynamicDNS.Client.{i}.Password',
'Device.DynamicDNS.Client.{i}.HostnameNumberOfEntries',
'Device.DynamicDNS.Client.{i}.Hostname.{i}.',
'Device.DynamicDNS.Client.{i}.Hostname.{i}.Enable',
'Device.DynamicDNS.Client.{i}.Hostname.{i}.Status',
'Device.DynamicDNS.Client.{i}.Hostname.{i}.Name',
'Device.DynamicDNS.Client.{i}.Hostname.{i}.LastUpdate',
'Device.DynamicDNS.Server.{i}.',
'Device.DynamicDNS.Server.{i}.Enable',
'Device.DynamicDNS.Server.{i}.Name',
'Device.DynamicDNS.Server.{i}.Alias',
'Device.DynamicDNS.Server.{i}.ServiceName',
'Device.DynamicDNS.Server.{i}.ServerAddress',
'Device.DynamicDNS.Server.{i}.ServerPort',
'Device.DynamicDNS.Server.{i}.SupportedProtocols',
'Device.DynamicDNS.Server.{i}.Protocol',
'Device.DynamicDNS.Server.{i}.CheckInterval',
'Device.DynamicDNS.Server.{i}.RetryInterval',
'Device.DynamicDNS.Server.{i}.MaxRetries',
'Device.LEDs.',
'Device.LEDs.LEDNumberOfEntries',
'Device.LEDs.LED.{i}.',
'Device.LEDs.LED.{i}.Alias',
'Device.LEDs.LED.{i}.Name',
'Device.LEDs.LED.{i}.Status',
'Device.LEDs.LED.{i}.Reason',
'Device.LEDs.LED.{i}.CyclePeriodRepetitions',
'Device.LEDs.LED.{i}.Location',
'Device.LEDs.LED.{i}.RelativeXPosition',
'Device.LEDs.LED.{i}.RelativeYPosition',
'Device.LEDs.LED.{i}.CycleElementNumberOfEntries',
'Device.LEDs.LED.{i}.CycleElement.{i}.',
'Device.LEDs.LED.{i}.CycleElement.{i}.Alias',
'Device.LEDs.LED.{i}.CycleElement.{i}.Enable',
'Device.LEDs.LED.{i}.CycleElement.{i}.Order',
'Device.LEDs.LED.{i}.CycleElement.{i}.Color',
'Device.LEDs.LED.{i}.CycleElement.{i}.Duration',
'Device.LEDs.LED.{i}.CycleElement.{i}.FadeInterval',
'Device.LEDs.LED.{i}.CurrentCycleElement.',
'Device.LEDs.LED.{i}.CurrentCycleElement.CycleElementReference',
'Device.LEDs.LED.{i}.CurrentCycleElement.Color',
'Device.LEDs.LED.{i}.CurrentCycleElement.Duration',
'Device.BASAPM.',
'Device.BASAPM.MeasurementEndpointNumberOfEntries',
'Device.BASAPM.MeasurementEndpoint.{i}.',
'Device.BASAPM.MeasurementEndpoint.{i}.Alias',
'Device.BASAPM.MeasurementEndpoint.{i}.Enable',
'Device.BASAPM.MeasurementEndpoint.{i}.MeasurementAgent',
'Device.BASAPM.MeasurementEndpoint.{i}.DeviceOwnership',
'Device.BASAPM.MeasurementEndpoint.{i}.OperationalDomain',
'Device.BASAPM.MeasurementEndpoint.{i}.InternetDomain',
'Device.BASAPM.MeasurementEndpoint.{i}.UseMeasurementEndpointInReports',
'Device.BASAPM.MeasurementEndpoint.{i}.ISPDevice.',
'Device.BASAPM.MeasurementEndpoint.{i}.ISPDevice.ReferencePoint',
'Device.BASAPM.MeasurementEndpoint.{i}.ISPDevice.GeographicalLocation',
'Device.BASAPM.MeasurementEndpoint.{i}.CustomerDevice.',
'Device.BASAPM.MeasurementEndpoint.{i}.CustomerDevice.EquipmentIdentifier',
'Device.BASAPM.MeasurementEndpoint.{i}.CustomerDevice.CustomerIdentifier',
'Device.LMAP.',
'Device.LMAP.MeasurementAgentNumberOfEntries',
'Device.LMAP.ReportNumberOfEntries',
'Device.LMAP.EventNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Version',
'Device.LMAP.MeasurementAgent.{i}.LastStarted',
'Device.LMAP.MeasurementAgent.{i}.CapabilityTags',
'Device.LMAP.MeasurementAgent.{i}.Identifier',
'Device.LMAP.MeasurementAgent.{i}.GroupIdentifier',
'Device.LMAP.MeasurementAgent.{i}.MeasurementPoint',
'Device.LMAP.MeasurementAgent.{i}.UseAgentIdentifierInReports',
'Device.LMAP.MeasurementAgent.{i}.UseGroupIdentifierInReports',
'Device.LMAP.MeasurementAgent.{i}.UseMeasurementPointInReports',
'Device.LMAP.MeasurementAgent.{i}.PublicCredential',
'Device.LMAP.MeasurementAgent.{i}.PrivateCredential',
'Device.LMAP.MeasurementAgent.{i}.EventLog',
'Device.LMAP.MeasurementAgent.{i}.TaskCapabilityNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.ScheduleNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.TaskNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannelNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.InstructionNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.Version',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.TaskCapabilityRegistryNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.Registry.{i}.',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.Registry.{i}.RegistryEntry',
'Device.LMAP.MeasurementAgent.{i}.TaskCapability.{i}.Registry.{i}.Roles',
'Device.LMAP.MeasurementAgent.{i}.Controller.',
'Device.LMAP.MeasurementAgent.{i}.Controller.ControllerTimeout',
'Device.LMAP.MeasurementAgent.{i}.Controller.ControlSchedules',
'Device.LMAP.MeasurementAgent.{i}.Controller.ControlTasks',
'Device.LMAP.MeasurementAgent.{i}.Controller.ControlChannels',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.State',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Start',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.End',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Duration',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Tags',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.SuppressionTags',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.ExecutionMode',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.LastInvocation',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Storage',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.ActionNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Stats.',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Stats.Invocations',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Stats.Suppressions',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Stats.Overlaps',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Stats.Failures',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.State',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Order',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Task',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.OutputDestination',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.SuppressionTags',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Tags',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Storage',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastInvocation',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastSuccessfulCompletion',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastSuccessfulStatusCode',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastSuccessfulMessage',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastFailedCompletion',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastFailedStatusCode',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.LastFailedMessage',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.OptionNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Stats.',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Stats.Invocations',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Stats.Suppressions',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Stats.Overlaps',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Stats.Failures',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Option.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Option.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Option.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Option.{i}.Order',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Option.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.Schedule.{i}.Action.{i}.Option.{i}.Value',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Tags',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.OptionNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.RegistryNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Registry.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Registry.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Registry.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Registry.{i}.RegistryEntry',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Registry.{i}.Roles',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Option.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Option.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Option.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Option.{i}.Order',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Option.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.Task.{i}.Option.{i}.Value',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.UseBulkDataProfile',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.BulkDataProfile',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.Target',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.TargetPublicCredential',
'Device.LMAP.MeasurementAgent.{i}.CommunicationChannel.{i}.Interface',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.LastChange',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.InstructionSchedules',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.InstructionTasks',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.ReportChannels',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppressionNumberOfEntries',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.Enable',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.Alias',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.Name',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.State',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.StopRunning',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.Start',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.End',
'Device.LMAP.MeasurementAgent.{i}.Instruction.{i}.MeasurementSuppression.{i}.SuppressionMatch',
'Device.LMAP.Report.{i}.',
'Device.LMAP.Report.{i}.ReportDate',
'Device.LMAP.Report.{i}.AgentIdentifier',
'Device.LMAP.Report.{i}.GroupIdentifier',
'Device.LMAP.Report.{i}.MeasurementPoint',
'Device.LMAP.Report.{i}.ResultNumberOfEntries',
'Device.LMAP.Report.{i}.Result.{i}.',
'Device.LMAP.Report.{i}.Result.{i}.TaskName',
'Device.LMAP.Report.{i}.Result.{i}.ScheduleName',
'Device.LMAP.Report.{i}.Result.{i}.ActionName',
'Device.LMAP.Report.{i}.Result.{i}.EventTime',
'Device.LMAP.Report.{i}.Result.{i}.StartTime',
'Device.LMAP.Report.{i}.Result.{i}.EndTime',
'Device.LMAP.Report.{i}.Result.{i}.CycleNumber',
'Device.LMAP.Report.{i}.Result.{i}.Status',
'Device.LMAP.Report.{i}.Result.{i}.Tags',
'Device.LMAP.Report.{i}.Result.{i}.OptionNumberOfEntries',
'Device.LMAP.Report.{i}.Result.{i}.ResultConflictNumberOfEntries',
'Device.LMAP.Report.{i}.Result.{i}.ResultReportTableNumberOfEntries',
'Device.LMAP.Report.{i}.Result.{i}.Option.{i}.',
'Device.LMAP.Report.{i}.Result.{i}.Option.{i}.Order',
'Device.LMAP.Report.{i}.Result.{i}.Option.{i}.Name',
'Device.LMAP.Report.{i}.Result.{i}.Option.{i}.Value',
'Device.LMAP.Report.{i}.Result.{i}.Conflict.{i}.',
'Device.LMAP.Report.{i}.Result.{i}.Conflict.{i}.TaskName',
'Device.LMAP.Report.{i}.Result.{i}.Conflict.{i}.ScheduleName',
'Device.LMAP.Report.{i}.Result.{i}.Conflict.{i}.ActionName',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.ColumnLabels',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.ResultReportRowNumberOfEntries',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.RegistryNumberOfEntries',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.ResultRow.{i}.',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.ResultRow.{i}.Values',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.Registry.{i}.',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.Registry.{i}.RegistryEntry',
'Device.LMAP.Report.{i}.Result.{i}.ReportTable.{i}.Registry.{i}.Roles',
'Device.LMAP.Event.{i}.',
'Device.LMAP.Event.{i}.Enable',
'Device.LMAP.Event.{i}.Alias',
'Device.LMAP.Event.{i}.Name',
'Device.LMAP.Event.{i}.Type',
'Device.LMAP.Event.{i}.RandomnessSpread',
'Device.LMAP.Event.{i}.CycleInterval',
'Device.LMAP.Event.{i}.PeriodicTimer.',
'Device.LMAP.Event.{i}.PeriodicTimer.StartTime',
'Device.LMAP.Event.{i}.PeriodicTimer.EndTime',
'Device.LMAP.Event.{i}.PeriodicTimer.Interval',
'Device.LMAP.Event.{i}.CalendarTimer.',
'Device.LMAP.Event.{i}.CalendarTimer.StartTime',
'Device.LMAP.Event.{i}.CalendarTimer.EndTime',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleMonths',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleDaysOfMonth',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleDaysOfWeek',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleHoursOfDay',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleMinutesOfHour',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleSecondsOfMinute',
'Device.LMAP.Event.{i}.CalendarTimer.EnableScheduleTimezoneOffset',
'Device.LMAP.Event.{i}.CalendarTimer.ScheduleTimezoneOffset',
'Device.LMAP.Event.{i}.OneOff.',
'Device.LMAP.Event.{i}.OneOff.StartTime',
'Device.WWC.',
'Device.WWC.HwCapabilities',
'Device.WWC.SwCapabilities',
'Device.WWC.Mode',
'Device.WWC.Status',
'Device.WWC.AccessNetworkNumberOfEntries',
'Device.WWC.URSPNumberOfEntries',
'Device.WWC.AccessNetwork.{i}.',
'Device.WWC.AccessNetwork.{i}.Alias',
'Device.WWC.AccessNetwork.{i}.Name',
'Device.WWC.AccessNetwork.{i}.Interface',
'Device.WWC.AccessNetwork.{i}.RegistrationStatus',
'Device.WWC.AccessNetwork.{i}.ConnectionStatus',
'Device.WWC.AccessNetwork.{i}.AccessNetworkType',
'Device.WWC.AccessNetwork.{i}.LastError',
'Device.WWC.AccessNetwork.{i}.GUTI.',
'Device.WWC.AccessNetwork.{i}.GUTI.PLMN',
'Device.WWC.AccessNetwork.{i}.GUTI.AMFId',
'Device.WWC.AccessNetwork.{i}.GUTI.TMSI',
'Device.WWC.URSP.{i}.',
'Device.WWC.URSP.{i}.Alias',
'Device.WWC.URSP.{i}.Precedence',
'Device.WWC.URSP.{i}.TrafficDescriptorNumberOfEntries',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.Alias',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.Type',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.Value',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptorNumberOfEntries',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.Alias',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.Precedence',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.SSC',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.DNN',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.PDUSessionType',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.AccessType',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.NetworkSlice.',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.NetworkSlice.SliceServiceType',
'Device.WWC.URSP.{i}.TrafficDescriptor.{i}.RouteSelectionDescriptor.{i}.NetworkSlice.SliceDifferentiator',
'Device.PDU.',
'Device.PDU.SessionNumberOfEntries',
'Device.PDU.Session.{i}.',
'Device.PDU.Session.{i}.Alias',
'Device.PDU.Session.{i}.Interface',
'Device.PDU.Session.{i}.SessionID',
'Device.PDU.Session.{i}.PTI',
'Device.PDU.Session.{i}.SessionType',
'Device.PDU.Session.{i}.SSC',
'Device.PDU.Session.{i}.SessionAMBRDownlink',
'Device.PDU.Session.{i}.SessionAMBRUplink',
'Device.PDU.Session.{i}.LastError',
'Device.PDU.Session.{i}.PDUIPv4Address',
'Device.PDU.Session.{i}.PDUIPv6InterfaceIdentifier',
'Device.PDU.Session.{i}.RQTimerValue',
'Device.PDU.Session.{i}.AlwaysOn',
'Device.PDU.Session.{i}.DNN',
'Device.PDU.Session.{i}.QoSRuleNumberOfEntries',
'Device.PDU.Session.{i}.QoSFlowNumberOfEntries',
'Device.PDU.Session.{i}.PCO.',
'Device.PDU.Session.{i}.PCO.IPv6PCSCF',
'Device.PDU.Session.{i}.PCO.IPv6DNS',
'Device.PDU.Session.{i}.PCO.IPv4PCSCF',
'Device.PDU.Session.{i}.PCO.IPv4DNS',
'Device.PDU.Session.{i}.NetworkSlice.',
'Device.PDU.Session.{i}.NetworkSlice.SliceServiceType',
'Device.PDU.Session.{i}.NetworkSlice.SliceDifferentiator',
'Device.PDU.Session.{i}.QoSRule.{i}.',
'Device.PDU.Session.{i}.QoSRule.{i}.Alias',
'Device.PDU.Session.{i}.QoSRule.{i}.Identifier',
'Device.PDU.Session.{i}.QoSRule.{i}.Precedence',
'Device.PDU.Session.{i}.QoSRule.{i}.Segregation',
'Device.PDU.Session.{i}.QoSRule.{i}.QFI',
'Device.PDU.Session.{i}.QoSRule.{i}.DQR',
'Device.PDU.Session.{i}.QoSRule.{i}.FilterNumberOfEntries',
'Device.PDU.Session.{i}.QoSRule.{i}.Filter.{i}.',
'Device.PDU.Session.{i}.QoSRule.{i}.Filter.{i}.Alias',
'Device.PDU.Session.{i}.QoSRule.{i}.Filter.{i}.Direction',
'Device.PDU.Session.{i}.QoSRule.{i}.Filter.{i}.Type',
'Device.PDU.Session.{i}.QoSRule.{i}.Filter.{i}.Value',
'Device.PDU.Session.{i}.QoSFlow.{i}.',
'Device.PDU.Session.{i}.QoSFlow.{i}.Alias',
'Device.PDU.Session.{i}.QoSFlow.{i}.QFI',
'Device.PDU.Session.{i}.QoSFlow.{i}.FiveQI',
'Device.PDU.Session.{i}.QoSFlow.{i}.GFBRUplink',
'Device.PDU.Session.{i}.QoSFlow.{i}.GFBRDownlink',
'Device.PDU.Session.{i}.QoSFlow.{i}.MFBRUplink',
'Device.PDU.Session.{i}.QoSFlow.{i}.MFBRDownlink',
'Device.PDU.Session.{i}.QoSFlow.{i}.AveragingWindow',
'Device.PDU.Session.{i}.QoSFlow.{i}.EPSBearer',
'Device.FWE.',
'Device.FWE.LinkNumberOfEntries',
'Device.FWE.Link.{i}.',
'Device.FWE.Link.{i}.Status',
'Device.FWE.Link.{i}.Alias',
'Device.FWE.Link.{i}.Name',
'Device.FWE.Link.{i}.LastChange',
'Device.FWE.Link.{i}.LowerLayers',
'Device.FWE.Link.{i}.Stats.',
'Device.FWE.Link.{i}.Stats.BytesSent',
'Device.FWE.Link.{i}.Stats.BytesReceived',
'Device.FWE.Link.{i}.Stats.PacketsSent',
'Device.FWE.Link.{i}.Stats.PacketsReceived',
'Device.FWE.Link.{i}.Stats.ErrorsSent',
'Device.FWE.Link.{i}.Stats.ErrorsReceived',
'Device.FWE.Link.{i}.Stats.UnicastPacketsSent',
'Device.FWE.Link.{i}.Stats.UnicastPacketsReceived',
'Device.FWE.Link.{i}.Stats.DiscardPacketsSent',
'Device.FWE.Link.{i}.Stats.DiscardPacketsReceived',
'Device.FWE.Link.{i}.Stats.MulticastPacketsSent',
'Device.FWE.Link.{i}.Stats.MulticastPacketsReceived',
'Device.FWE.Link.{i}.Stats.BroadcastPacketsSent',
'Device.FWE.Link.{i}.Stats.BroadcastPacketsReceived',
'Device.FWE.Link.{i}.Stats.UnknownProtoPacketsReceived',
'Device.ETSIM2M.',
'Device.ETSIM2M.SCLNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.',
'Device.ETSIM2M.SCL.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.AnnouncedToSCLList',
'Device.ETSIM2M.SCL.{i}.SAFPolicySetNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstanceNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstanceNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.Discovery.',
'Device.ETSIM2M.SCL.{i}.Discovery.MaxNumberOfDiscovRecords',
'Device.ETSIM2M.SCL.{i}.Discovery.MaxSizeOfDiscovAnswer',
'Device.ETSIM2M.SCL.{i}.Reregistration.',
'Device.ETSIM2M.SCL.{i}.Reregistration.RegTargetNSCLList',
'Device.ETSIM2M.SCL.{i}.Reregistration.RegSearchStrings',
'Device.ETSIM2M.SCL.{i}.Reregistration.RegAccessRightID',
'Device.ETSIM2M.SCL.{i}.Reregistration.RegExpirationDuration',
'Device.ETSIM2M.SCL.{i}.Reregistration.Reregistration',
'Device.ETSIM2M.SCL.{i}.Reregistration.ActionStatus.',
'Device.ETSIM2M.SCL.{i}.Reregistration.ActionStatus.Progress',
'Device.ETSIM2M.SCL.{i}.Reregistration.ActionStatus.FinalStatus',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.PolicyScope',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicyNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.ANName',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.BlockPeriodNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategoryNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.BlockPeriod.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.BlockPeriod.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.BlockPeriod.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.BlockPeriod.{i}.FailedAttempts',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.BlockPeriod.{i}.BlockDuration',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.RCAT',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.ScheduleNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.Schedules',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.AbsTimeSpanNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.AbsTimeSpan.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.AbsTimeSpan.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.AbsTimeSpan.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.AbsTimeSpan.{i}.StartTime',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.ANPPolicy.{i}.RequestCategory.{i}.Schedule.{i}.AbsTimeSpan.{i}.EndTime',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.DefaultRCATValue',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategoryNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.Enable',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.Alias',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.RCAT',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.TolerableDelay',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.Thresh',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.Mem',
'Device.ETSIM2M.SCL.{i}.SAFPolicySet.{i}.M2MSPPolicy.RequestCategory.{i}.RankedANList',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.ID',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.AreaNwkType',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.ListOfDevices',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.PropertyNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.Property.{i}.',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.Property.{i}.Name',
'Device.ETSIM2M.SCL.{i}.AreaNwkInstance.{i}.Property.{i}.Value',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.AreaNwkInstance',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.Host',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.ListOfDeviceNeighbors',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.ListOfDeviceApplications',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.SleepInterval',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.SleepDuration',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.Status',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.Active',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.PropertyNumberOfEntries',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.Property.{i}.',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.Property.{i}.Name',
'Device.ETSIM2M.SCL.{i}.AreaNwkDeviceInfoInstance.{i}.Property.{i}.Value',
'Device.STOMP.',
'Device.STOMP.ConnectionNumberOfEntries',
'Device.STOMP.Connection.{i}.',
'Device.STOMP.Connection.{i}.Alias',
'Device.STOMP.Connection.{i}.Enable',
'Device.STOMP.Connection.{i}.Status',
'Device.STOMP.Connection.{i}.LastChangeDate',
'Device.STOMP.Connection.{i}.Host',
'Device.STOMP.Connection.{i}.Port',
'Device.STOMP.Connection.{i}.Username',
'Device.STOMP.Connection.{i}.Password',
'Device.STOMP.Connection.{i}.VirtualHost',
'Device.STOMP.Connection.{i}.EnableHeartbeats',
'Device.STOMP.Connection.{i}.OutgoingHeartbeat',
'Device.STOMP.Connection.{i}.IncomingHeartbeat',
'Device.STOMP.Connection.{i}.ServerRetryInitialInterval',
'Device.STOMP.Connection.{i}.ServerRetryIntervalMultiplier',
'Device.STOMP.Connection.{i}.ServerRetryMaxInterval',
'Device.STOMP.Connection.{i}.IsEncrypted',
'Device.STOMP.Connection.{i}.EnableEncryption',
'Device.Services.VoiceService.{i}.',
'Device.Services.VoiceService.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfileNumberOfEntries',
'Device.Services.VoiceService.{i}.Capabilities.',
'Device.Services.VoiceService.{i}.Capabilities.MaxProfileCount',
'Device.Services.VoiceService.{i}.Capabilities.MaxLineCount',
'Device.Services.VoiceService.{i}.Capabilities.MaxSessionsPerLine',
'Device.Services.VoiceService.{i}.Capabilities.MaxSessionCount',
'Device.Services.VoiceService.{i}.Capabilities.SignalingProtocols',
'Device.Services.VoiceService.{i}.Capabilities.Regions',
'Device.Services.VoiceService.{i}.Capabilities.RTCP',
'Device.Services.VoiceService.{i}.Capabilities.SRTP',
'Device.Services.VoiceService.{i}.Capabilities.SRTPKeyingMethods',
'Device.Services.VoiceService.{i}.Capabilities.SRTPEncryptionKeySizes',
'Device.Services.VoiceService.{i}.Capabilities.RTPRedundancy',
'Device.Services.VoiceService.{i}.Capabilities.DSCPCoupled',
'Device.Services.VoiceService.{i}.Capabilities.EthernetTaggingCoupled',
'Device.Services.VoiceService.{i}.Capabilities.PSTNSoftSwitchOver',
'Device.Services.VoiceService.{i}.Capabilities.FaxT38',
'Device.Services.VoiceService.{i}.Capabilities.FaxPassThrough',
'Device.Services.VoiceService.{i}.Capabilities.ModemPassThrough',
'Device.Services.VoiceService.{i}.Capabilities.ToneGeneration',
'Device.Services.VoiceService.{i}.Capabilities.ToneDescriptionsEditable',
'Device.Services.VoiceService.{i}.Capabilities.PatternBasedToneGeneration',
'Device.Services.VoiceService.{i}.Capabilities.FileBasedToneGeneration',
'Device.Services.VoiceService.{i}.Capabilities.ToneFileFormats',
'Device.Services.VoiceService.{i}.Capabilities.RingGeneration',
'Device.Services.VoiceService.{i}.Capabilities.RingDescriptionsEditable',
'Device.Services.VoiceService.{i}.Capabilities.PatternBasedRingGeneration',
'Device.Services.VoiceService.{i}.Capabilities.RingPatternEditable',
'Device.Services.VoiceService.{i}.Capabilities.FileBasedRingGeneration',
'Device.Services.VoiceService.{i}.Capabilities.RingFileFormats',
'Device.Services.VoiceService.{i}.Capabilities.DigitMap',
'Device.Services.VoiceService.{i}.Capabilities.NumberingPlan',
'Device.Services.VoiceService.{i}.Capabilities.ButtonMap',
'Device.Services.VoiceService.{i}.Capabilities.VoicePortTests',
'Device.Services.VoiceService.{i}.Capabilities.SIP.',
'Device.Services.VoiceService.{i}.Capabilities.SIP.Role',
'Device.Services.VoiceService.{i}.Capabilities.SIP.Extensions',
'Device.Services.VoiceService.{i}.Capabilities.SIP.Transports',
'Device.Services.VoiceService.{i}.Capabilities.SIP.URISchemes',
'Device.Services.VoiceService.{i}.Capabilities.SIP.EventSubscription',
'Device.Services.VoiceService.{i}.Capabilities.SIP.ResponseMap',
'Device.Services.VoiceService.{i}.Capabilities.SIP.TLSAuthenticationProtocols',
'Device.Services.VoiceService.{i}.Capabilities.SIP.TLSAuthenticationKeySizes',
'Device.Services.VoiceService.{i}.Capabilities.SIP.TLSEncryptionProtocols',
'Device.Services.VoiceService.{i}.Capabilities.SIP.TLSEncryptionKeySizes',
'Device.Services.VoiceService.{i}.Capabilities.SIP.TLSKeyExchangeProtocols',
'Device.Services.VoiceService.{i}.Capabilities.MGCP.',
'Device.Services.VoiceService.{i}.Capabilities.MGCP.Extensions',
'Device.Services.VoiceService.{i}.Capabilities.H323.',
'Device.Services.VoiceService.{i}.Capabilities.H323.FastStart',
'Device.Services.VoiceService.{i}.Capabilities.H323.H235AuthenticationMethods',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.Alias',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.EntryID',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.Codec',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.BitRate',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.PacketizationPeriod',
'Device.Services.VoiceService.{i}.Capabilities.Codecs.{i}.SilenceSuppression',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Reset',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberOfLines',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Name',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SignalingProtocol',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MaxSessions',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.DTMFMethod',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.DTMFMethodG711',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Region',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.DigitMap',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.DigitMapEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.STUNEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.STUNServer',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NonVoiceBandwidthReservedUpstream',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NonVoiceBandwidthReservedDownstream',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.PSTNFailOver',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxPassThrough',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ModemPassThrough',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ServiceProviderInfo.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ServiceProviderInfo.Name',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ServiceProviderInfo.URL',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ServiceProviderInfo.ContactPhoneNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ServiceProviderInfo.EmailAddress',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ProxyServer',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ProxyServerPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ProxyServerTransport',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegistrarServer',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegistrarServerPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegistrarServerTransport',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.UserAgentDomain',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.UserAgentPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.UserAgentTransport',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.OutboundProxy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.OutboundProxyPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.Organization',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegistrationPeriod',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerT1',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerT2',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerT4',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerA',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerB',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerC',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerD',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerE',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerF',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerG',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerH',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerI',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerJ',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.TimerK',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.InviteExpires',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ReInviteExpires',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegisterExpires',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegistersMinExpires',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.RegisterRetryInterval',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.InboundAuth',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.InboundAuthUsername',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.InboundAuthPassword',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.UseCodecPriorityInSDPResponse',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.DSCPMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.VLANIDMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EthernetPriorityMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.SIPEventSubscribeNumberOfElements',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.SIPResponseMapNumberOfElements',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.Event',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.Notifier',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.NotifierPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.NotifierTransport',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.EventSubscribe.{i}.ExpireTime',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ResponseMap.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ResponseMap.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ResponseMap.{i}.SIPResponseNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ResponseMap.{i}.TextMessage',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.SIP.ResponseMap.{i}.Tone',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.CallAgent1',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.CallAgentPort1',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.CallAgent2',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.CallAgentPort2',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.RetranIntervalTimer',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.MaxRetranCount',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.RegisterMode',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.LocalPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.Domain',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.User',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.DSCPMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.VLANIDMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.EthernetPriorityMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.AllowPiggybackEvents',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.MGCP.SendRSIPImmediately',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.Gatekeeper',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.GatekeeperPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.GatekeeperID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.TimeToLive',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.H235Authentication',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.AuthPassword',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.SendersID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.DSCPMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.VLANIDMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.H323.EthernetPriorityMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.LocalPortMin',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.LocalPortMax',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.DSCPMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.VLANIDMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.EthernetPriorityMark',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.TelephoneEventPayloadType',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.RTCP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.RTCP.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.RTCP.TxRepeatInterval',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.RTCP.LocalCName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.SRTP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.SRTP.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.SRTP.KeyingMethods',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.SRTP.EncryptionKeySizes',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.PayloadType',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.BlockPayloadType',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.FaxAndModemRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.ModemRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.DTMFRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.VoiceRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.RTP.Redundancy.MaxSessionsUsingRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.MinimumNumberOfDigits',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.MaximumNumberOfDigits',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.InterDigitTimerStd',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.InterDigitTimerOpen',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.InvalidNumberTone',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfoMaxEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfoNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.PrefixRange',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.PrefixMinNumberOfDigits',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.PrefixMaxNumberOfDigits',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.NumberOfDigitsToRemove',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.PosOfDigitsToRemove',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.DialTone',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.FacilityAction',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.NumberingPlan.PrefixInfo.{i}.FacilityActionArgument',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.EventNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.DescriptionNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.PatternNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Event.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Event.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Event.{i}.Function',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Event.{i}.ToneID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.EntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.ToneEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.ToneName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.TonePattern',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.ToneFile',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.ToneRepetitions',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Description.{i}.ToneText',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.EntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.ToneOn',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Frequency1',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Power1',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Frequency2',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Power2',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Frequency3',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Power3',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Frequency4',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Power4',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.ModulationFrequency',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.ModulationPower',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.Duration',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Tone.Pattern.{i}.NextEntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.NumberOfButtons',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.ButtonName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.FacilityAction',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.FacilityActionArgument',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.QuickDialNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.ButtonMessage',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.ButtonMap.Button.{i}.UserAccess',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.BitRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.HighSpeedPacketRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.HighSpeedRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.LowSpeedRedundancy',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.FaxT38.TCFMethod',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.DirectoryNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Status',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallState',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.PhyReferenceList',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.RingMuteStatus',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.RingVolumeStatus',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.AuthUserName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.AuthPassword',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.URI',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.SIPEventSubscribeNumberOfElements',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.EventSubscribe.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.EventSubscribe.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.EventSubscribe.{i}.Event',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.EventSubscribe.{i}.AuthUserName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.SIP.EventSubscribe.{i}.AuthPassword',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.MGCP.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.MGCP.LineName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.H323.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.H323.H323ID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.EventNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.DescriptionNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.PatternNumberOfEntries',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Event.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Event.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Event.{i}.Function',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Event.{i}.RingID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.EntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.RingEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.RingName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.RingPattern',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Description.{i}.RingFile',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Pattern.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Pattern.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Pattern.{i}.EntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Pattern.{i}.RingerOn',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Pattern.{i}.Duration',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Ringer.Pattern.{i}.NextEntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallerIDEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallerIDNameEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallerIDName',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallWaitingEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallWaitingStatus',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.MaxSessions',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.ConferenceCallingStatus',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.ConferenceCallingSessionCount',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardUnconditionalEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardUnconditionalNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardOnBusyEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardOnBusyNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardOnNoAnswerEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardOnNoAnswerNumber',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallForwardOnNoAnswerRingCount',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallTransferEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.MWIEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.MessageWaiting',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.AnonymousCallBlockEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.AnonymousCalEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.DoNotDisturbEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.CallReturnEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.CallingFeatures.RepeatDialEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.VoiceProcessing.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.VoiceProcessing.TransmitGain',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.VoiceProcessing.ReceiveGain',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.VoiceProcessing.EchoCancellationEnable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.VoiceProcessing.EchoCancellationInUse',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.VoiceProcessing.EchoCancellationTail',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.TransmitCodec',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.ReceiveCodec',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.TransmitBitRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.ReceiveBitRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.TransmitSilenceSuppression',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.ReceiveSilenceSuppression',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.TransmitPacketizationPeriod',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.Alias',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.EntryID',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.Codec',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.BitRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.PacketizationPeriod',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.SilenceSuppression',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.Enable',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Codec.List.{i}.Priority',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Session.{i}.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Session.{i}.SessionStartTime',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Session.{i}.SessionDuration',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Session.{i}.FarEndIPAddress',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Session.{i}.FarEndUDPPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Session.{i}.LocalUDPPort',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.ResetStatistics',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.PacketsSent',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.PacketsReceived',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.BytesSent',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.BytesReceived',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.PacketsLost',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.Overruns',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.Underruns',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.IncomingCallsReceived',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.IncomingCallsAnswered',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.IncomingCallsConnected',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.IncomingCallsFailed',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.OutgoingCallsAttempted',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.OutgoingCallsAnswered',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.OutgoingCallsConnected',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.OutgoingCallsFailed',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.CallsDropped',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.TotalCallTime',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.ServerDownTime',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.ReceivePacketLossRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.FarEndPacketLossRate',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.ReceiveInterarrivalJitter',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.FarEndInterarrivalJitter',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.RoundTripDelay',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.AverageReceiveInterarrivalJitter',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.AverageFarEndInterarrivalJitter',
'Device.Services.VoiceService.{i}.VoiceProfile.{i}.Line.{i}.Stats.AverageRoundTripDelay',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.Alias',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.PhyPort',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.InterfaceID',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.Description',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.Tests.',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.Tests.TestState',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.Tests.TestSelector',
'Device.Services.VoiceService.{i}.PhyInterface.{i}.Tests.PhoneConnectivity'
]

export default tr181Parameters