import React, { useRef, useEffect, useState, useCallback } from "react";
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from '../card/Card'
import { Container } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAccessToken } from "../../utils/auth";

export const OpticsByState = ({ }) => {
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const apiEndpoint = 'https://goldilocks.api.fybrlabs.frontier.com/Prod/v1/data/';
  //const apiEndpoint = 'http://localhost:3000/v1/data/';

  function handleSubmission(e) {
    e.preventDefault();
    console.log(`Getting files for: ${date}`);
  }

  function onDateChange(newDate) {
    setDate(newDate);
  }

  async function getFiles() {
    setLoading(true);
    let formattedDate = date.toISOString().split('T')[0];
    const token = await getAccessToken();
    let r = await axios.get(apiEndpoint + formattedDate, {headers: {'auth-token': token}});
    console.log(r);
    setFiles(r.data);
    setLoading(false);
  }

  useEffect(() => {
    getFiles();
  }, [date]);

  function renderResults() {
    let content;
    if (loading) {
      content = <h3>Loading...</h3>;
    } else if (files.length) {
      const list = files.map(file => {
        let state = file.key.substring(34, 36);
        return <li key={'file-link-' + file.key}><Button variant="secondary" href={file.link} download>{state}</Button></li>
      });
      content = <ul>{list}</ul>;
    } else {
      content = <h3>No files found.</h3>;
    }

    return <Card>
      <header>Files</header>
      <section>{content}</section>
    </Card>;
  }

  return (
      <Container className='optics-by-state-wrapper'>
        <Row>
          <Col>
            <Card>
              <header>Get Optics Data by State</header>
              <section>
                <Form onSubmit={handleSubmission}>
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <DatePicker
                        selected={date}
                        onChange={onDateChange}
                        maxDate={new Date()}
                        minDate={new Date('2023-04-18')}
                        className="home-datepicker"
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </section>
            </Card>
          </Col>
        </Row>
        <Row><Col>{renderResults()}</Col></Row>
      </Container>
  );
}
