const speedTestServers = [
  {
    id: 1,
    name: 'Ashburn, VA',
    host: 'ashburn.va.speedtest.frontier.com'
  },
  {
    id: 2,
    name: 'Chicago, IL',
    host: 'chicago.il.speedtest.frontier.com'
  },
  {
    id: 3,
    name: 'Dallas, TX',
    host: 'dallas.tx.speedtest.frontier.com'
  },
  {
    id: 4,
    name: 'Los Angeles, CA',
    host: 'losangeles.ca.speedtest.frontier.com'
  },
  {
    id: 5,
    name: 'Miami, FL',
    host: 'miami.fl.speedtest.frontier.com'
  },
  {
    id: 6,
    name: 'Secaucus, NJ',
    host: 'secaucus.nj.speedtest.frontier.com'
  },
  {
    id: 7,
    name: 'Wallingford, CT',
    host: 'wallingford.ct.speedtest.frontier.com'
  }
];
export default speedTestServers;