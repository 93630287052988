import React, { useState, useEffect } from "react";

import { Card, Stack} from 'react-bootstrap';
import { getPostmanMonitorResults } from "../utils/postman.js";

import VqcCard from '../components/apiSupport/vqcStatusCard';

export default function Spark({}) {
	const [monitorData, setMonitor] = useState([]);
	const [includeVqcStatus, setVQCDisplay] = useState(false);

	function renderItems() {
		let renderedItems = [];      
		console.log('Rendering start');

		renderedItems = monitorData.map((monitorItem) => {
			let bgstatus = 'secondary';
			if (monitorItem.status.includes('success')) {
				bgstatus = 'success';
			}
			else if (monitorItem.status.includes('fail')) {
				bgstatus = 'warning';
				if (monitorItem.failures > 5) {
					bgstatus = 'danger';
				}
			}

			if (!includeVqcStatus && monitorItem.status.includes('success')) {
				setVQCDisplay(true);				
			}			

			return  <div>
				<Card className="mb-2" bg={bgstatus} style={{ width: '18rem' }}>
					<Card.Header>{monitorItem.monitorTitle}</Card.Header>
						<Card.Body>
							<Card.Title>{monitorItem.collectionName}</Card.Title>
							<Card.Subtitle className="mb-2"> Status: {monitorItem.status}</Card.Subtitle>
							<Card.Text># of Calls Failed: {monitorItem.failures} </Card.Text>
							<Card.Text>{monitorItem.timeLapsed} at {monitorItem.startDate} </Card.Text>                
						</Card.Body>
				</Card>				
			</div>
			
		});
		

		return renderedItems
	};



	const getMonitor = async () => {
		let res = await getPostmanMonitorResults();
		setMonitor(res);
	}

	useEffect(() => {
		getMonitor();
	}, []);

	

	return (
		<div className="sparkapi">
			<h2>POSTMAN Monitors</h2>
			<Stack direction="horizontal" gap={5}> 
				{renderItems()}
			</Stack>
			{includeVqcStatus && 
				<Stack direction="horizontal" gap={5}> 
					<VqcCard></VqcCard>
				</Stack>}
		</div>
	);
}

