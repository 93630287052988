import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SmithHistory from "../components/smith/smithHistory";
import SmithSchedules from "../components/smith/smithSchedules";
import SmithAddSchedules from "../components/smith/smithAddSchedule";
import SmithConfig from "../components/smith/smithConfig";

export default function Smith({}) {
  return (
    <div className="mqtt-control-server agent-smith">
      <h2>Agent Smith</h2>

      <Tab.Container id="agent-smith" defaultActiveKey="history">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="history">History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="schedules">Schedules</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="add-schedule">Add schedule</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="config">Configuration</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="history">
                <SmithHistory />
              </Tab.Pane>
              <Tab.Pane eventKey="schedules">
                <SmithSchedules />
              </Tab.Pane>
              <Tab.Pane eventKey="add-schedule">
                <SmithAddSchedules />
              </Tab.Pane>
              <Tab.Pane eventKey="config">
                <SmithConfig />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
