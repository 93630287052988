import { useEffect } from "react";
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import "leaflet.heat";

export default function LeafletHeatmap ( { points }) {
   const map = useMap()
   map.eachLayer(function(layer){
      if (layer._heat) {
        map.removeLayer(layer);
      }
  });
   useEffect(() => {
     L.heatLayer(points || [] ).addTo(map);
   }, [points]);
}