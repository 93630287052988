import React, { useRef, useEffect, useState } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import AlertsList from "../components/cms/alert/alertsList";
import Alarms from "../components/cms/alarm/alarms";
import OntSpeedTestConfigList from "../components/cms/ontSpeetTestConfig";
import WifiAnalyzerConfigs from "../components/cms/wifiAnalyzer/wifiAnalyzer";
import RGManagement from "../components/cms/rgManagement/rgManagement";

export default function CMS({}) {
  return (
    <div className="mqtt-control-server spark-cms">
      <h2>SPARK  CMS</h2>

      <Tab.Container id="spark-cms" defaultActiveKey="alarms">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="alarms">Alarms</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alerts">Alerts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="analyzer">Wi-Fi Analyzer Thresholds</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ontSpeedTestConfig">ONT Speed Test Configuration</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="rgmanage">RG Management</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="alarms">
                <Alarms />
              </Tab.Pane>
              <Tab.Pane eventKey="alerts">
                <AlertsList />
              </Tab.Pane>
              <Tab.Pane eventKey="ontSpeedTestConfig">
                <OntSpeedTestConfigList />
              </Tab.Pane>
              <Tab.Pane eventKey="analyzer">
                <WifiAnalyzerConfigs />
              </Tab.Pane>
              <Tab.Pane eventKey="rgmanage">
                <RGManagement />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
