import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from "react-bootstrap";
import Card from '../card/Card';
import axios from "axios";
import { getAccessToken } from "../../utils/auth";

//const url = 'http://localhost:3000/getrequestsdata';
const url = 'https://ont.api.fybrlabs.frontier.com/Prod/getrequestsdata';

export default function SparkMonitor({ }) {
  const [items, setItems] = useState([]);
  let interval = null;

  const getItems = async () => {
    const token = await getAccessToken();
    let r = await axios.get(url, {headers: {'auth-token': token }});
    let processedItems = {};

    r.data.Items = r.data.Items.sort((a, b) => {
      return b.timeStamp - a.timeStamp;
    })

    r.data.Items.forEach(element => {
      if (!processedItems[element.uuid]) {
        processedItems[element.uuid] = {
          serialNumber: element.serialNumber
        };
      }
      let time = new Date(element.timeStamp);
      let stepContent =  `${time.toLocaleDateString()} ${time.toLocaleTimeString()}`;
      if (element.status) {
        stepContent += ` (${element.status})`;
      }
      processedItems[element.uuid].techId = element.techId;
      processedItems[element.uuid][`step${element.step}`] = stepContent;
    });

    let itemsList = [];
    Object.keys(processedItems).forEach((uuid) => {
      itemsList.push({
        uuid,
        ...processedItems[uuid]
      })
    })

    setItems(itemsList);
  }
  useEffect(() => {
    getItems();
    if (!interval) {
      //interval = setInterval(getItems, 30000);
    }
    return function cleanup() {
      clearInterval(interval);
      interval = null;
    };
  }, []);

  const renderItems = () => {
    let renderedItems = [];
    renderedItems = items.map((el) => {
      let className = '';
      if ((el.step0 && el.step0.indexOf('Error') > 0)
        || (el.step1 && el.step1.indexOf('Error') > 0)
        || (el.step2 && el.step2.indexOf('Error') > 0)
        || (el.step3 && el.step3.indexOf('Error') > 0)
        || (el.step4 && el.step4.indexOf('Error') > 0)) {
          className = 'error'
        }
      return <tr key={el.uuid} className={className}>
        <td>{el.uuid}</td>
        <td>{el.techId}</td>
        <td>{el.serialNumber}</td>
        <td>{el.step0}</td>
        <td>{el.step1}</td>
        <td>{el.step2}</td>
        <td>{el.step3}</td>
        <td>{el.step4}</td>
      </tr>;
    });
    return renderedItems;
  };

  return (
    <Card className="spark-monitor">
      <header>SPARK Monitor</header>
      <section>
        <table className="table table-dark table-striped table-hover">
          <thead>
            <tr>
              <td>UUID</td>
              <td>Tech ID</td>
              <td>Serial Number</td>
              <td>Start</td>
              <td>Prefetch</td>
              <td>Summary</td>
              <td>Full Data</td>
              <td>Feedback</td>
            </tr>
          </thead>
          <tbody>
            {renderItems()}
          </tbody>
        </table>
      </section>
    </Card>
  )
}
