import axios from "axios";

const token = 'PMAK-65caaf7fb1cf9800016e07f6-ac6885c0963d3fb2ebac73996ee28a5359'; //cya618 API token
const postManurl = 'https://api.getpostman.com/monitors/';
const monitors = [    
    {
        'Topic' : 'ONT Diagnostics',        
        'Id' : '1ee8407d-aa38-40d0-9bd0-d537e45ce339'
    },
    {
        'Topic' : 'FDH Info',        
        'Id' : '1ee1b540-9336-44f0-bacc-f5772e50c7eb'
        
    },
    {
        'Topic' : 'Subscriber Info',        
        'Id' : '1ee9bda3-f8a0-4610-ab77-f65b97ca1dbe'
        
    }
]

//get monitor results based
export const getPostmanMonitorResults = async () => {
    console.log(`getPostmanMonitorResults START`);

    let monitorResults = [];

    const promises = monitors.map((monitorItem) => {
        let url = `${postManurl}${monitorItem.Id}`;
        let results = axios.get(url, {headers: { 'X-API-Key': token }, monitorTopic: monitorItem.Topic} );
        
        return results;
    });
    
    const responses = await Promise.allSettled(promises);
    responses.forEach((r) => {
        if (r.status === 'fulfilled') {           
            
            let results = r.value.data;
            let config = r.value.config;
            var lastRun = results.monitor.lastRun;
            var stDate = new Date(lastRun.startedAt);
            let nwDate = new Date();
            var timeLapsed = (nwDate - stDate) / 1000;
            
            if (timeLapsed < 59) {
                timeLapsed = Math.round(timeLapsed) + ' secs ago';
            }
            else {
                timeLapsed = Math.round((timeLapsed/60)) + ' mins ago';
            }
            console.log(`getPostmanMonitorResults Monitor Name: ${results.monitor.name}`);
            console.log(`getPostmanMonitorResults Started At: ${stDate.toDateString()}`);
            console.log(`getPostmanMonitorResults ${timeLapsed}.`);
            console.log(`getPostmanMonitorResults status ${lastRun.status}`);
            console.log(`getPostmanMonitorResults ${lastRun.stats.assertions.failed} failures `);                    

            monitorResults.push({     
                monitorTitle: config.monitorTopic || '',            
                collectionName: results.monitor.name || '',
                startDate: stDate.toLocaleTimeString() || '',
                timeLapsed: timeLapsed || '',
                status: lastRun.status || '',
                failures: '' + lastRun.stats.assertions.failed || ''
            })
            
        }
        else {
            console.error('failed monitor check ', r.value.reason);
        }

    });
    
    return monitorResults;

}

