/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 import React, { useEffect, useRef, ReactElement, Component } from "react";
 import ReactDOM from "react-dom";
 import { Wrapper, Status } from "@googlemaps/react-wrapper";
 
var render = function(status) {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;  
}

//  const render = (status: Status): ReactElement => {
//    if (status === Status.LOADING) return <h3>{status} ..</h3>;
//    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
//    return null;
//  };
 
class Map extends Component {
    ref = useRef();
    constructor(props) {
        super(props);
    }
 
   useEffect() {
     new window.google.maps.Map(this.ref.current, {
       center: this.props.center,
       zoom: this.props.zoom,
     });
   };
 
   render() {
    return <div ref={this.ref} id="map" />;
   } 
}

function MyMapComponent({ center, zoom, }) {
    const ref = useRef();
    useEffect(() => {
        new window.google.maps.Map(ref.current, {
            center,
            zoom,
        });
    });
    return React.createElement("div", { ref: ref, id: "map" });
}

 class MapWrapper extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Wrapper apiKey="AIzaSyA4tzfAToPj514boJZRr71Jk5SvsWN_UVU" render={render}>
              <MyMapComponent center={this.props.center} zoom={this.props.zoom} />
            </Wrapper>
          );
    }

 }
 
export default MapWrapper;