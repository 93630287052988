import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";

import 'react-bootstrap-typeahead/css/Typeahead.css';

import tr181Parameters from "./tr181-parameters";
import { FormGroup } from "react-bootstrap";
import { getAccessToken } from "../../utils/auth";

const apiEndpoint = 'https://mqtt.api.fybrlabs.frontier.com/Prod/v1';
//const apiEndpoint = 'http://localhost:3000';

async function handleFormSubmission(sn, command, days, setResp, event) {
  event.preventDefault();
  if (command[0] === '') {
    setResp('');
    return;
  }

  setResp('Loading...');

  console.log(`Sending request to ${sn} for action: ${command}`);

  try {
    const authToken = await getAccessToken();
    const r = await axios.post(`${apiEndpoint}/device/${sn}/cpuMemMonitor/${command}`, {days: days}, {timeout: 10000, headers: {'auth-token': authToken}});
    
    console.log('response: ', r.data);
    setResp(r.data);
  } catch(e) {
    if (e.code === 'ECONNABORTED') {
      setResp({
        message: e.message,
        description: `Device ${sn} did not respond.`
      });
    } else {
      console.error(e.code);
      console.log('error: ', e);
      let error;
      if (e.response && e.response.data) {
        error = e.response.data;
      } else {
        error = e;
      }
      setResp(error);
    }
  }
}

export default function CPUMemoryMonitor({ }) {
  const [command, setCommand] = useState('start');
  const [sn, setSN] = useState('');
  const [resp, setResp] = useState(null);
  const [days, setDays] = useState(1);
  let response;

  if (resp) {
    response = <div className="response mt-4"><h4>Response: </h4><pre>{JSON.stringify(resp, null, 2)}</pre></div>
  }
  else {
    response = false;
  }

  let setValueForm;
  if (command === 'start') {
    setValueForm = <Form.Group className="mb-3" as={Col} md="3">
      <Form.Label>Number of days</Form.Label>
      <Form.Control type="number" placeholder="Enter Value" value={days} onChange={e => setDays(e.target.value)} required />
    </Form.Group>;
  }

  return (
    <div className="mqtt-form">
      <h3>CPU and Memory Monitor</h3>
      <Form onSubmit={handleFormSubmission.bind(null, sn, command, days, setResp)}>
        <Row className="mb-3">
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Serial Number</Form.Label>
            <Form.Control type="text" placeholder="Enter Device Serial Number" value={sn} onChange={e => setSN(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="3">
            <Form.Label>Command</Form.Label>
            <Form.Select value={command} required onChange={e => {
              console.log("e.target.value", e.target.value);
              setCommand(e.target.value);
            }}>
              <option value="start">Start monitoring</option>
              <option value="stop">Stop monitoring</option>
            </Form.Select>
          </Form.Group>
          {setValueForm}
        </Row>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      {response}
    </div>
  )
}
